<nz-modal [nzVisible]="show"
          nzTitle="Straße/Abfuhrbezirk verwalten"
          nzOkText="Speichern"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="submitForm()"
          (nzOnCancel)="close()"
          nzWidth="800px"
>
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>

    <nz-card class="custom-card" [nzBodyStyle]="{ padding: '.65rem 1rem 0.3rem' }" *ngIf="('WASTE_CALENDAR_STREET::CREATE' | checkPerm)">
      <form nz-form [formGroup]="streetForm" (ngSubmit)="submitForm()" nzLayout="vertical" class="ant-form-vertical ng-pristine ng-invalid">
        <div class="street-wrapper">

          <nz-form-item>
            <nz-form-label nzRequired>Straße/Abfuhrbezirk</nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="name" [disabled]="streetForm.get('allStreets')?.value"/>
            </nz-form-control>
            <label nz-checkbox formControlName="allStreets" (nzCheckedChange)="toggleStreetField()">Alle Straßen</label>
          </nz-form-item>

          <nz-form-item>
            <nz-form-control>
              <button nz-button nzType="primary" style="margin-top: 32px;" [disabled]="!streetForm.valid" [nzLoading]="execLoading">{{ mode === 'add' ? 'Hinzufügen' : 'Bearbeiten' }}</button>
            </nz-form-control>
          </nz-form-item>

        </div>

      </form>
    </nz-card>


    <nz-list nzBordered style="margin-top: 6px;" class="street-list">
      <nz-list-item>Angelegte Straßen</nz-list-item>
      <nz-list-item *ngFor="let street of wasteCalendarStreets">
        <span class="street">
          <fa-icon [icon]="['fas', 'road']" /> <span>{{ street.name }}</span>
        </span>
        <div class="street-actions">
          <nz-upload
            [nzAction]="uploadUrl(street)"
            [nzHeaders]="{ authorization: 'Bearer ' + getToken()  }"
            (nzChange)="handleIcsChange($event)"
            nzAccept=".ics"
          >
            <button nz-button>
              <span nz-icon nzType="upload"></span>
              Import .ics file
            </button>
          </nz-upload>
          <fa-icon-btn class="btn-url"
                       [stopPropagation]="true"
                       buttonSize="30"
                       iconSize="14"
                       perm="WASTE_CALENDAR_STREET::EDIT"
                       (btnClick)="editStreet(street)"
                       icon="pen" />
        <fa-icon-btn class="btn-url"
                     [stopPropagation]="true"
                     buttonSize="30"
                     iconSize="14"
                     perm="WASTE_CALENDAR_STREET::DELETE"
                     [nzDanger]="true"
                     (btnClick)="deleteStreet(street)"
                     icon="trash" />
        </div>
      </nz-list-item>
    </nz-list>

  </ng-container>
</nz-modal>

