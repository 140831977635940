import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./pages-common/login/login.component";
import {DashboardComponent} from "./pages-admin/dashboard/dashboard.component";
import {OrgUserAuthGuard} from "./utils/orguser-auth.guard";
import {AdminUserListComponent} from "./pages-admin/adminuser-list/adminuser-list.component";
import {OrgUserListComponent} from "./pages-admin/orguser-list/orguser-list.component";
import {AppUserListComponent} from "./pages-admin/appuser-list/appuser-list.component";
import {CrawlerOverviewComponent} from "./pages-admin/crawler-overview/crawler-overview.component";
import {PostListComponent} from "./pages-admin/post-list/post-list.component";
import {SettingsComponent} from "./pages-admin/settings/settings.component";
import {OrgUserViewComponent} from "./pages-admin/orguser-view/orguser-view.component";
import {AdminAuthGuard} from "./utils/admin-auth.guard";
import {OrgDashboardComponent} from "./pages-orguser/org-dashboard/org-dashboard.component";
import {ForbiddenComponent} from "./pages-common/forbidden/forbidden.component";
import {AnyAuthGuard} from "./utils/any-auth.guard";
import {OrgSettingsComponent} from "./pages-orguser/org-settings/org-settings.component";
import {RegisterLinkComponent} from "./pages-common/register-link/register-link.component";
import {PWResetLinkComponent} from "./pages-common/pwreset-link/pwreset-link.component";
import {PWResetComponent} from "./pages-common/pw-reset/pw-reset.component";
import {OrgPostListComponent} from "./pages-orguser/org-post-list/org-post-list.component";
import {OrganizationListComponent} from "./pages-admin/organization-list/organization-list.component";
import { GroupsListComponent } from './pages-admin/groups-list/groups-list.component';
import {OrgGroupListComponent} from "./pages-orguser/org-group-list/org-group-list.component";
import {OrgUserProfileComponent} from "./pages-orguser/org-userprofile/org-userprofile.component";
import {OrgOrgProfileComponent} from "./pages-orguser/org-orgprofile/org-orgprofile.component";
import { OrgViewComponent } from './pages-admin/org-view/org-view.component';
import {OrgOrgUserListComponent} from "./pages-orguser/org-orguser-list/org-orguser-list.component";
import {OrgOrgUserViewComponent} from "./pages-orguser/org-orguser-view/org-orguser-view.component";
import {EventsListComponent} from "./pages-admin/events-list/events-list.component";
import {OrgEventListComponent} from "./pages-orguser/org-event-list/org-event-list.component";
import {SolseitConfigComponent} from "./pages-admin/solseit-config/solseit-config.component";
import {AppUserViewComponent} from "./pages-admin/appuser-view/appuser-view.component";
import {CommunityListComponent} from "./pages-admin/community-list/community-list.component";
import {ReportListComponent} from "./pages-admin/report-list/report-list.component";
import { EventDetailsComponent } from './pages-admin/event-details/event-details.component';

const routes: Routes = [
  { path: '',                                        component: LoginComponent,                                             },
  { path: 'login',                                   component: LoginComponent,                                             },
  { path: 'register/:uid/:sec',                      component: RegisterLinkComponent,                                      },
  { path: 'pwreset',                                 component: PWResetComponent,                                           },
  { path: 'pwreset/:uid/:sec',                       component: PWResetLinkComponent,                                       },

  { path: 'forbidden',                               component: ForbiddenComponent,        canActivate: [AnyAuthGuard],     },

  { path: 'admin',                                   component: DashboardComponent,        canActivate: [AdminAuthGuard],   },
  { path: 'admin/adminusers',                        component: AdminUserListComponent,    canActivate: [AdminAuthGuard],   },
  { path: 'admin/organizations',                     component: OrganizationListComponent, canActivate: [AdminAuthGuard],   },
  { path: 'admin/organizations/:id',                 component: OrgViewComponent,          canActivate: [AdminAuthGuard],   },
  { path: 'admin/orgusers',                          component: OrgUserListComponent,      canActivate: [AdminAuthGuard],   },
  { path: 'admin/orgusers/:id',                      component: OrgUserViewComponent,      canActivate: [AdminAuthGuard],   },
  { path: 'admin/users',                             component: AppUserListComponent,      canActivate: [AdminAuthGuard],   },
  { path: 'admin/users/:id',                         component: AppUserViewComponent,      canActivate: [AdminAuthGuard],   },
  { path: 'admin/posts',                             component: PostListComponent,         canActivate: [AdminAuthGuard],   },
  { path: 'admin/groups',                            component: GroupsListComponent,       canActivate: [AdminAuthGuard],   },
  { path: 'admin/events',                            component: EventsListComponent,       canActivate: [AdminAuthGuard],   },
  { path: 'admin/edit-event/:id/:orgid',             component: EventDetailsComponent,     canActivate: [AdminAuthGuard],   },
  { path: 'admin/crawlers',                          component: CrawlerOverviewComponent,  canActivate: [AdminAuthGuard],   },
  { path: 'admin/crawlers/solseit-config',           component: SolseitConfigComponent,    canActivate: [AdminAuthGuard],   },
  { path: 'admin/settings',                          component: SettingsComponent,         canActivate: [AdminAuthGuard],   },
  { path: 'admin/communities',                       component: CommunityListComponent,    canActivate: [AdminAuthGuard],   },
  { path: 'admin/reports',                           component: ReportListComponent,       canActivate: [AdminAuthGuard],   },

  { path: 'org',                                     component: OrgOrgProfileComponent,    canActivate: [OrgUserAuthGuard], },
  { path: 'org/statistics',                          component: OrgDashboardComponent,     canActivate: [OrgUserAuthGuard], },
  { path: 'org/userprofile',                         component: OrgUserProfileComponent,   canActivate: [OrgUserAuthGuard], },
  { path: 'org/posts',                               component: OrgPostListComponent,      canActivate: [OrgUserAuthGuard], },
  { path: 'org/groups',                              component: OrgGroupListComponent,     canActivate: [OrgUserAuthGuard], },
  { path: 'org/events',                              component: OrgEventListComponent,     canActivate: [OrgUserAuthGuard], },
  { path: 'org/settings',                            component: OrgSettingsComponent,      canActivate: [OrgUserAuthGuard], },
  { path: 'org/orgusers',                            component: OrgOrgUserListComponent,   canActivate: [OrgUserAuthGuard], },
  { path: 'org/orgusers/:id',                        component: OrgOrgUserViewComponent,   canActivate: [OrgUserAuthGuard], },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
