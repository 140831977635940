import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {NzTableComponent} from "ng-zorro-antd/table";

@Component({
  selector: 'app-pagination-button',
  templateUrl: './pagination-button.component.html',
  styleUrls: ['./pagination-button.component.scss']
})
export class PaginationButtonComponent implements OnInit, OnChanges {

  @Input('linkedTable') table: NzTableComponent<any>|null = null;
  @Input('nextToken') token: string|null = null;
  @Input('count') count: number|undefined|null = undefined;
  @Input('total') total: number|undefined|null = -999;
  @Input('loading') loading: boolean = false;

  enabled: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.enabled = (this.token !== '@end');
  }

  getText(base: string, countDisplayed: number|undefined|null, countTotal: number|undefined|null) {

    if (countTotal === -999) { return base; } // unset

    const hasCurr  = (countDisplayed !== null && countDisplayed !== undefined);
    const hasTotal = (countTotal     !== null && countTotal     !== undefined);

    if (!hasCurr && !hasTotal) { return base; }

    if (hasCurr && hasTotal) { return `${base} ( ${countDisplayed} / ${countTotal} )`; }

    if (hasCurr && !hasTotal) { return `${base} ( ${countDisplayed} / [..loading..] )`; }

    if (!hasCurr && hasTotal) { return `${base} ( ${countTotal} )`; }

    return "huh ?!?";
  }
}
