import { Component } from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Router} from "@angular/router";
import {PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, isErrorCode, showAPIError} from "../../utils/api";
import {AdminUser} from "../../interfaces/adminUser";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent {
  loading: boolean = false;

  self: AdminUser|null = null;

  username: string = '';
  editUsername: boolean = false;
  updatingUsername: boolean = false;

  firstName: string = '';
  editFirstName: boolean = false;
  updatingFirstName: boolean = false;

  lastName: string = '';
  editLastName: boolean = false;
  updatingLastName: boolean = false;

  email: string = '';
  editMail: boolean = false;
  updatingMail: boolean = false;

  editPassword: boolean = false;
  updatingPassword: boolean = false;
  password0: string = '';
  password1: string = '';
  password2: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private auth: AuthenticationService) {

  }

  ngOnInit(): void {
    this.updateOrgUser(this.auth.getSelfID()!).then(() => {});
  }

  private async updateOrgUser(id: ObjectId) {
    this.loading = true;
    try {
      this.self = await this.api.getAdmin(id);

      this.username = this.self.username;
      this.firstName = this.self.firstName;
      this.lastName = this.self.lastName;
      this.email = this.self.email;

    } catch (err: any) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidPWOld(val: string) {
    return val.length >= 1;
  }

  isValidPW1(v: string) {
    return v.length >= 8;
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1(this.password1) && v == this.password1;
    }
  }

  async updatePassword() {
    if (this.self === null) return;

    try {
      this.updatingPassword = true;

      const data = await this.api.updateAdminPassword(this.self.id, this.password1, this.password0);

      this.editPassword = false;
      this.notification.success('Aktualisiert', 'Dein Passwort wurde geändert');

      this.self = data;

    } catch (err) {
      if (isErrorCode(err, 'WRONG_OLD_PASSWORD')) {
        showAPIError(this.notification, 'Bisheriges Passwort ist falsch', err)
      } else {
        showAPIError(this.notification, 'Passwort konnte nicht aktualisiert werden', err)
      }
    } finally {
      this.updatingPassword = false;
    }
  }

  async updateUsername() {
    if (this.self === null) return;

    try {
      this.updatingUsername = true;

      const data = await this.api.updateAdminUsername(this.self.id, this.username);

      this.editUsername=false;
      this.notification.success('Aktualisiert', 'Dein Benutzername wurde geändert');

      this.self = data;

    } catch (err) {
      showAPIError(this.notification, 'Benutzername konnte nicht aktualisiert werden', err)
    } finally {
      this.updatingUsername = false;
    }
  }

  async updateFirstName() {
    if (this.self === null) return;

    try {
      this.updatingFirstName = true;

      const data = await this.api.updateAdminFirstName(this.self.id, this.firstName);

      this.editFirstName=false;
      this.notification.success('Aktualisiert', 'Dein Vorname wurde geändert');

      this.self = data;

    } catch (err) {
      showAPIError(this.notification, 'Benutzername konnte nicht aktualisiert werden', err)
    } finally {
      this.updatingFirstName = false;
    }
  }

  async updateLastName() {
    if (this.self === null) return;

    try {
      this.updatingLastName = true;

      const data = await this.api.updateAdminLastName(this.self.id, this.lastName);

      this.editLastName=false;
      this.notification.success('Aktualisiert', 'Dein Nachname wurde geändert');

      this.self = data;

    } catch (err) {
      showAPIError(this.notification, 'Benutzername konnte nicht aktualisiert werden', err)
    } finally {
      this.updatingLastName = false;
    }
  }

  async updateMail() {
    if (this.self === null) return;

    try {
      this.updatingMail = true;

      const data = await this.api.updateAdminMail(this.self.id, this.email);

      this.editMail=false;
      this.notification.success('Aktualisiert', 'Deine E-Mail wurde geändert');

      this.self = data;

    } catch (err) {
      showAPIError(this.notification, 'Benutzername konnte nicht aktualisiert werden', err)
    } finally {
      this.updatingMail = false;
    }
  }

  cancelUsername() {
    this.username = this.self!.username;
    this.updatingUsername = false;
    this.editUsername = false;
  }

  cancelFirstName() {
    this.firstName = this.self!.firstName;
    this.updatingFirstName = false;
    this.editFirstName = false;
  }

  cancelLastName() {
    this.lastName = this.self!.lastName;
    this.updatingLastName = false;
    this.editLastName = false;
  }

  cancelMail() {
    this.email = this.self!.email;
    this.updatingMail = false;
    this.editMail = false;
  }

  cancelPassword() {
    this.password0 = '';
    this.password1 = '';
    this.password2 = '';
    this.updatingPassword = false;
    this.editPassword = false;
  }
}
