import {ObjectId} from "./utils";
import {RFC3339} from "./datetime";



export enum WasteTypeEnum {
  WASTE = 'WASTE',
  RECYCLING = 'RECYCLING',
  PAPER = 'PAPER',
  COMPOST = 'COMPOST',
  HAZARDOUS = 'HAZARDOUS',
  BULKY_WASTE = 'BULKY_WASTE',
}

export interface WasteEntry{
  id: ObjectId;
  organizationID: ObjectId;
  date: string;
  type: WasteTypeEnum;
  description: string;
  creationTime: RFC3339;
  updatedAt: RFC3339;
}
