import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, showAPIError} from "../../utils/api";
import {Group} from "../../interfaces/group";
import {Organization} from "../../interfaces/organization";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {isNgDiff} from "../../utils/angular";
import {ImageCropperComponent} from "ngx-image-cropper";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-create-group-modal',
  templateUrl: './create-group-modal.component.html',
  styleUrls: ['./create-group-modal.component.scss']
})
export class CreateGroupModalComponent implements OnInit, OnChanges {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Group[]> = new EventEmitter<Group[]>();

  @Input() forcedOrgId: ObjectId|null = null;

  @Input() avatarMinWidth: number = 250;
  @Input() avatarMinHeight: number = 250;

  @Input() backgroundImageMinWidth: number = 823;
  @Input() backgroundImageMinHeight: number = 360;

  initLoading: boolean = false;
  okayLoading: boolean = false;

  allOrgs: Organization[] = []

  selectedOrg: ObjectId|null = null;
  title: string = '';

  private imageCropperAvatar: ImageCropperComponent|null = null;
  @ViewChild('imageCropperAvatar') set contentAvatar(content: ImageCropperComponent) { if(content) { this.imageCropperAvatar = content; } }

  private imageCropperBackground: ImageCropperComponent|null = null;
  @ViewChild('imageCropperBackground') set contentBackground(content: ImageCropperComponent) { if(content) { this.imageCropperBackground = content; } }

  fileAvatar: File|null = null;
  fileBackground: File|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (isNgDiff(changes, 'show')) {
      if (this.show) {
        this.clear();
        this.loadData(true).then(() => {});
      } else {
        this.clear();
      }
    }

  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {

    this.initLoading    = false;
    this.okayLoading    = false;
    this.allOrgs        = []
    this.selectedOrg    = null;
    this.title          = '';
    this.fileAvatar     = null;
    this.fileBackground = null;

  }

  async loadData(initial:boolean = false) {
    try {
      if (initial) this.initLoading = true;

      if (this.forcedOrgId === null) {

        const data = await this.api.listOrganizations("@start", null); //TODO Pgaesize
        this.allOrgs = data.orgs;

      } else {

        const data = await this.api.getOrganization(this.forcedOrgId);
        this.allOrgs = [data];
        this.selectedOrg = this.forcedOrgId;

      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      if (initial) this.initLoading = false;
    }
  }

  async execute() {
    if(this.selectedOrg === null)          { this.notification.error('Fehler', 'Bitte ContentProvider auswählen'); return; }
    if (!this.isValidStr(this.title))      { this.notification.error('Fehler', 'Bitte valider Name eingeben'); return; }

    try {
      this.okayLoading = true;

      const croppedAvatar = this.imageCropperAvatar?.crop() ?? null;
      const croppedBackground = this.imageCropperBackground?.crop() ?? null;

      if (croppedAvatar !== null) {
        if (!croppedAvatar.base64) return;
        if (croppedAvatar.width < this.avatarMinWidth || croppedAvatar.height < this.avatarMinHeight) {
          this.notification.warning('Bild zu klein', `Der Avatar muss mindestens ${this.avatarMinWidth}x${this.avatarMinHeight} pixel groß sein`);
          return;
        }
      }

      if (croppedBackground !== null) {
        if (!croppedBackground.base64) return;
        if (croppedBackground.width < this.backgroundImageMinWidth || croppedBackground.height < this.backgroundImageMinHeight) {
          this.notification.warning('Bild zu klein', `Das Hintergrundbild muss mindestens ${this.backgroundImageMinWidth}x${this.backgroundImageMinHeight} pixel groß sein`);
          return;
        }
      }

      const res = await this.api.createGroup(
        this.selectedOrg,
        this.title,
        croppedAvatar?.base64?.split(',')?.pop() ?? null,
        'image/png',
        croppedBackground?.base64?.split(',')?.pop() ?? null,
        'image/png');

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(res.groups);

      this.notification.success('Erfolg!', 'Gruppe wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Gruppe konnte nicht erstellt werden', err)
    } finally {
      this.okayLoading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  beforeUploadAvatar = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.fileAvatar = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.fileAvatar = null;
    }
    return false;
  }

  beforeUploadBackground = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.fileBackground = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.fileBackground = null;
    }
    return false;
  }

  loadImageFailed() {
    this.notification.error('Fehler', 'Datei konnte nicht geladen werden');
  }

}
