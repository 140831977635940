<div>
  <nz-input-group nzSearch [nzAddOnAfter]="suffixIconButton" [nzSuffix]="suffixClear">
    <input nz-input
           type="text"
           value="{{value}}"
           [(ngModel)]="value"
           [disabled]="disabled"
           placeholder="{{placeholder}}"
           (change)="onChangeInput($event)"
           (input)="onInput($event)"
           (keydown.enter)="onClickSearch()"
           [ngModelOptions]="{standalone: true}"/>
  </nz-input-group>

  <ng-template #suffixIconButton>
    <fa-icon-btn [disabled]="disabled" [icon]="['fas', 'magnifying-glass']" nzType="primary" [buttonSize]="'32'" [iconSize]="'16'" (click)="onClickSearch()"></fa-icon-btn>
  </ng-template>

  <ng-template #suffixClear>
    <fa-icon-btn [disabled]="disabled" *ngIf="showClear && (value??'') !== ''" [icon]="['fas', 'xmark']" buttonSize="16" iconSize="16" class="clearIcon" nzType="text" (click)="onClickClear()"></fa-icon-btn>
  </ng-template>

</div>
