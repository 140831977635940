import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {ObjectId} from "../../interfaces/utils";
import {showAPIError} from "../../utils/api";
import {Event} from "../../interfaces/event";
import {ImageCropperComponent} from "ngx-image-cropper";
import {NzUploadFile} from "ng-zorro-antd/upload";
import { Organization } from 'src/app/interfaces/organization';

@Component({
  selector: 'app-create-event-modal',
  templateUrl: './create-event-modal.component.html',
  styleUrls: ['./create-event-modal.component.scss']
})
export class CreateEventModalComponent implements OnInit {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Event> = new EventEmitter<Event>();

  @Input() orgId: ObjectId|null = null;

  private imageCropperAvatar: ImageCropperComponent|null = null;
  @ViewChild('imageCropperAvatar') set contentAvatar(content: ImageCropperComponent) { if(content) { this.imageCropperAvatar = content; } }

  eventForm: FormGroup<{
    title: FormControl<string>;
    contentProvider: FormControl<ObjectId | null>;
    dateTime: FormControl<[Date, Date] | null>;
    address: FormGroup<{
      locationName: FormControl<string>;
      zipcode: FormControl<string>;
      city: FormControl<string>;
      street: FormControl<string>;
      number: FormControl<string>;
    }>;
    website: FormControl<string>;
    description: FormControl<string>;
    avatar: FormControl<File | null>
  }> = this.fb.group({
    title: ['', [Validators.required, Validators.minLength(3)]],
    contentProvider: this.fb.control<ObjectId | null>(null, []),
    dateTime: this.fb.control<[Date, Date] | null>(null, [Validators.required]),
    address: this.fb.group({
      locationName: ['', []],
      zipcode: ['', [Validators.required]],
      city: ['', [Validators.required]],
      street: ['', [Validators.required]],
      number: ['', [Validators.required]],
    }),
    website: [''],
    description: ['', [Validators.required]],
    avatar: this.fb.control<File | null>(null, [])
  })

  loading: boolean = false
  allOrgs: Organization[] = [];
  selectedOrg: ObjectId | null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private fb: NonNullableFormBuilder) {
  }

    async ngOnInit(): Promise<void> {

        this.loadData();
    }

    async loadData() {

        if (this.orgId === null) {

            if (this.allOrgs.length <= 1) {
              const data = await this.api.listOrganizations("@start", null);
              this.allOrgs = data.orgs;
            }
    
        } else {
    
          if (this.allOrgs.length != 1 || this.allOrgs[0].id !== this.orgId) {
            const data = await this.api.getOrganization(this.orgId);
            this.allOrgs = [data];
            this.selectedOrg = this.orgId;
            this.eventForm.controls.contentProvider.setValue(this.orgId);
          }
    
        }
    }

  close() {
    this.showChange.emit(false);
  }

  validateElement(control: FormControl) {
    return !control.valid && control.dirty ? 'error' : 'success'
  }

  async submitForm() {
    console.log('submit', this.eventForm.value)

    if(!this.eventForm.valid){
      this.notification.warning('Fehlende Felder', 'Bitte füllen Sie alle Felder aus');
      return;
    }

    if(!this.selectedOrg) return

    this.loading = true

    console.log("läuft")

    try {
      let images: {avatar: {data: string | null, mime: string}} = { avatar: { data: null, mime: 'image/png' }};
      const croppedAvatar = this.imageCropperAvatar?.crop() ?? null;

      if (croppedAvatar !== null) {
        if (!croppedAvatar.base64) return;
      }

      images.avatar.data = croppedAvatar?.base64?.split(',')?.pop() ?? null;

      const res = await this.api.createEvent(this.selectedOrg, this.eventForm.value, images);

      this.showChange.emit(false);

      this.success.emit(res);

      this.notification.success('Erfolg!', 'Event wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Event konnte nicht erstellt werden', err)
    } finally {
      this.loading = false
    }
  }

  beforeUploadAvatar = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.eventForm.controls.avatar.setValue(fany);
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.eventForm.controls.avatar.setValue(null);
    }
    return false;
  }

  loadImageFailed() {
    this.notification.error('Fehler', 'Datei konnte nicht geladen werden');
  }

}
