import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Event} from "../../interfaces/event";
import {DataBlob} from "../../interfaces/datablobs";
import {Post} from "../../interfaces/post";
import {Group} from "../../interfaces/group";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {environment} from "../../../environments/environment";
import {getIndirectFieldValue} from "../../utils/indirect";
import { Organization } from 'src/app/interfaces/organization';
import {ObjectId} from "../../interfaces/utils";
import {Impressum} from "../../interfaces/impressum";

@Component({
  selector: 'app-org-orgprofile',
  templateUrl: './org-orgprofile.component.html',
  styleUrls: ['./org-orgprofile.component.scss']
})
export class OrgOrgProfileComponent implements OnInit {
  loading = true;

  orgid!: ObjectId;
  org: Organization|null = null;

  selectedEditAvatar: Organization|null = null;
  selectedEditBackgroundImage: Organization|null = null;

  avatarBlobId: string = 'INITIAL';
  backgroundImageBlobId: string = 'INITIAL';

  clearingAvatar: boolean = false;
  clearingBackgroundImage: boolean = false;

  loadingBlobAvatar: boolean = false;
  loadingBlobBackgroundImage: boolean = false;

  avatarBlob: DataBlob<any>|null = null;
  backgroundImageBlob: DataBlob<any>|null = null;

  editProfileText: string = '';
  updatingProfileText: boolean = false;
  profileTextRef: string = '';
  profileTextValue: string = '';

  posts: Post[] = [];
  postsLoading: boolean = true;

  groups: Group[] = [];
  groupsLoading: boolean = true;

  events: Event[] = [];
  eventsLoading: boolean = true;

  impress: Impressum[] = [];
  impressLoading: boolean = true;
  selectedImpress: Impressum|null = null;

  showCreateNewPost: boolean = false;
  showCreateNewGroup: boolean = false;
  showCreateNewEvent: boolean = false;
  showCreateImpress: boolean = false;
  showEditImpress: boolean = false;

  @ViewChild('col1Ref')     col1Ref:     ElementRef<HTMLElement>|undefined;
  @ViewChild('col2Ref')     col2Ref:     ElementRef<HTMLElement>|undefined;
  @ViewChild('previewBox')  previewBox:  ElementRef<HTMLElement>|undefined;
  @ViewChild('previewComp') previewComp: ElementRef<HTMLElement>|undefined;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.orgid = this.auth.getSelfOrgID()!;
    await this.loadUser();

    this.adjustPreviewSize();
    setTimeout(() => {this.adjustPreviewSize();}, 0);

    await Promise.all([this.loadPosts(), this.loadGroups(), this.loadEvents(), this.loadImpress()]);
  }

  async loadUser() {

    this.loading = true;

    try {
      this.org = await this.api.getOrganization(this.orgid);
      this.avatarBlobId = this.org.avatarImageID ?? 'UNSET';
      this.backgroundImageBlobId = this.org.backgroundImageID ?? 'UNSET';

      if (this.org.avatarImageID !== null) this.loadBlobAvatar().then(()=>{});
      if (this.org.backgroundImageID !== null) this.loadBlobBackgroundImage().then(()=>{});

    } catch (err) {
      showAPIError(this.notification, 'Account konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async loadPosts() {

    if (!this.org) return;

    this.postsLoading = true;

    try {
      this.posts = (await this.api.listOrgPosts(this.orgid, '@start', 10)).posts;

    } catch (err) {
      showAPIError(this.notification, 'Posts konnte nicht geladen werden', err)
    } finally {
      this.postsLoading = false;
    }
  }

  async loadGroups() {

    if (!this.org) return;

    this.groupsLoading = true;

    try {
      this.groups = (await this.api.listOrgGroups(this.orgid, '@start', 10)).groups;

    } catch (err) {
      showAPIError(this.notification, 'Gruppen konnte nicht geladen werden', err)
    } finally {
      this.groupsLoading = false;
    }
  }

  async loadEvents() {

    if (!this.org) return;

    this.eventsLoading = true;

    try {
      this.events = (await this.api.listOrgEvents(this.orgid, '@start', 10)).events;

    } catch (err) {
      showAPIError(this.notification, 'Veranstaltungen konnte nicht geladen werden', err)
    } finally {
      this.eventsLoading = false;
    }
  }

  async loadImpress() {

    if (!this.org) return;

    this.impressLoading = true;

    try {
      this.impress = (await this.api.listOrgImpresss(this.orgid, '@start', 50)).impress;

    } catch (err) {
      showAPIError(this.notification, 'Impressums konnten nicht geladen werden', err)
    } finally {
      this.impressLoading = false;
    }
  }

  async loadBlobAvatar() {
    if (this.org === null) return;
    if (this.org.avatarImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.avatarBlob = await this.api.getBlob(this.org.avatarImageID);

    } catch (err) {
      showAPIError(this.notification, 'Profilbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobAvatar = false;
    }
  }

  async loadBlobBackgroundImage() {
    if (this.org === null) return;
    if (this.org.backgroundImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.backgroundImageBlob = await this.api.getBlob(this.org.backgroundImageID);

    } catch (err) {
      showAPIError(this.notification, 'Hintergrundbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobBackgroundImage = false;
    }
  }

  async setAvatar(img: string, mime: string) {
    try {
      if (this.org === null) return;

      const blob = await this.api.setOrgAvatar(this.orgid, img, mime);

      this.avatarBlobId = blob.id;
      this.org.avatarImageID = blob.id;
      this.avatarBlob = blob;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    } finally {
      this.selectedEditAvatar = null;
    }
  }

  async setBackgroundImage(img: string, mime: string) {
    try {
      if (this.org === null) return;

      const blob = await this.api.setOrgBackgroundImage(this.orgid, img, mime);

      this.backgroundImageBlobId = blob.id;
      this.org.backgroundImageID = blob.id;
      this.backgroundImageBlob = blob;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    } finally {
      this.selectedEditBackgroundImage = null;
    }
  }

  async clearAvatar() {
    if (this.org === null) return;

    try {
      this.clearingAvatar = true;

      await this.api.deleteOrgAvatar(this.orgid);

      this.avatarBlobId = 'UNSET';
      this.org.avatarImageID = null;
      this.avatarBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingAvatar = false;
    }
  }

  async clearBackgroundImage() {
    if (this.org === null) return;

    try {
      this.clearingBackgroundImage = true;

      await this.api.deleteOrgBackgroundImage(this.orgid);

      this.backgroundImageBlobId = 'UNSET';
      this.org.backgroundImageID = null;
      this.backgroundImageBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Hintergrundbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingBackgroundImage = false;
    }
  }

  avatarSource() {
    if (this.org === null) return undefined;
    if (this.org.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when image has changed
    return `${environment.apiBaseUrl}organizations/${this.orgid}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.avatarBlobId}`;
  }

  backgroundImageSource() {
    if (this.org === null) return undefined;
    if (this.org.backgroundImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when image has changed
    return `${environment.apiBaseUrl}organizations/${this.orgid}/background-image?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.backgroundImageBlobId}`;
  }

  editProfileField(ref: string, name: string) {
    this.editProfileText = name;
    this.updatingProfileText = false;
    this.profileTextRef = ref;
    this.profileTextValue = getIndirectFieldValue<string>(this.org, ref)!;
  }

  async updateProfileText() {
    if (this.org === null) return;

    try {
      this.updatingProfileText = true;

      this.org = await this.api.updateOrgProfileField(this.orgid, this.profileTextRef, this.profileTextValue);
      this.editProfileText = '';

    } catch (err) {
      showAPIError(this.notification, 'Profil konnte nicht editiert werden', err)
    } finally {
      this.updatingProfileText = false;
    }
  }

  adjustPreviewSize() {

    //console.log('adjustPreviewSize', this.col2Ref, this.previewComp, this.previewBox);

    if (this.col2Ref     === undefined) return
    if (this.previewComp === undefined) return
    if (this.previewBox  === undefined) return

    const availableWidth = this.col2Ref.nativeElement.getBoundingClientRect().width - 32 - 32;
    const totalWidth = 1700;
    const totalHeight = 950;

    const scale = availableWidth / totalWidth;

    const realHeight = totalHeight * scale;

    //console.log('adjustPreviewSize', {availableWidth, totalWidth, totalHeight, scale, realHeight});

    this.previewComp.nativeElement.style.transform = `scale(${scale})`;
    this.previewBox.nativeElement.style.width = `${availableWidth}px`;
    this.previewBox.nativeElement.style.height = `${realHeight}px`;
  }

  onPostCreated(v: Post) {
    this.posts = [v, ...this.posts].slice(0, 10);
  }

  onGroupCreated(groups: Group[]) {
   // TODO: alert('TODO: implement onGroupCreated')
    //this.groups = [v, ...this.groups].slice(0, 10);
  }

  onPostUpdated(newdata: Post) {
    this.posts = this.posts.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onGroupUpdated(newdata: Group | Group[]) {
    // TODO: alert('TODO: implement onGroupUpdated in org-orgprofile.component.ts')
    //this.groups = this.groups.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onEventUpdated(newdata: Event) {
    this.events = this.events.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onPostDeleted(newdata: Post) {
    this.posts = this.posts.filter(g => g.id !== newdata.id);
  }

  onGroupDeleted(groups: Group) {
    alert('TODO: implement onGroupDeleted');
    //this.groups = this.groups.filter(g => g.id !== newdata.id);
  }

  onEventDeleted(newdata: Event) {
    this.events = this.events.filter(g => g.id !== newdata.id);
  }

  onImpressCreated(impress: Impressum) {
    console.log('onImpressCreated', impress)
    this.impress.push(impress);
  }

  onImpressUpdated(newdata: Impressum) {
    this.selectedImpress = null;
    this.impress = this.impress.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onImpressDeleted(impress: Impressum) {
    this.modal.confirm({
      nzTitle: 'Impressum löschen?',
      nzContent: 'Möchtest du das Impressum "'+impress.name+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: this.loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          this.loading = true;
          const res = await this.api.deleteImpress(impress.organizationID, impress);
          this.notification.success('Impressum gelöscht', 'Das Impressum "' + impress.name + '" wurde gelöscht');
          this.impress = this.impress.filter(g => g.id !== impress.id);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht gelöscht werden', err)
        } finally {
          this.loading = false;
        }
      }
    });

  }

}
