import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BlobDataOrgUserAttachment, DataBlob} from "../../interfaces/datablobs";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {filetoBase64} from "../../utils/base64";
import {formatAPIError, showAPIError} from "../../utils/api";
import {NzUploadChangeParam, NzUploadFile} from "ng-zorro-antd/upload";
import {isNgDiff} from "../../utils/angular";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-edit-org-attachment-modal',
  templateUrl: './edit-org-attachment-modal.component.html',
  styleUrls: ['./edit-org-attachment-modal.component.scss']
})
export class EditOrgAttachmentModalComponent implements OnChanges {

  @Input() orgId: ObjectId|null = null;

  @Input() show: DataBlob<BlobDataOrgUserAttachment>|null = null;
  @Output() showChange: EventEmitter<DataBlob<BlobDataOrgUserAttachment>|null> = new EventEmitter<DataBlob<BlobDataOrgUserAttachment>|null>();
  @Output() success: EventEmitter<DataBlob<BlobDataOrgUserAttachment>> = new EventEmitter<DataBlob<BlobDataOrgUserAttachment>>();

  filename: string = '';
  comment: string = '';
  selectedAttachmentType: string|null = null;

  loading: boolean = false;

  typesLoading: boolean = false;
  showAddType: boolean = false;
  addType: string = '';
  attachmentTypes: string[] = [];

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.filename = this.show.data.filename;
        this.comment = this.show.data.comment;
        this.attachmentTypes = (this.show.data.type==='') ? [] : [this.show.data.type];
        this.selectedAttachmentType = (this.show.data.type==='') ? null : this.show.data.type;
        this.loadAttachmentTypes().then(() => {});
      }
      return;
    }
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.filename = '';
    this.comment = '';
    this.attachmentTypes = [];
    this.typesLoading = false;
    this.loading = false;
  }

  async execute() {
    if (this.orgId === null) return;
    if (this.show === null) return;

    try {
      this.loading = true;

      const data = await this.api.updateOrgUserAttachment(this.orgId, this.show.id, this.filename, this.selectedAttachmentType ?? '', this.comment);

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'ContentProvider konnte nicht aktualisiert werden', err)
    } finally {
      this.loading = false;
    }
  }

  async loadAttachmentTypes() {
    try {
      this.typesLoading = true;

      const data = await this.api.listAttachmentTypes();

      this.attachmentTypes = data.types;

    } catch (err) {
      showAPIError(this.notification, 'Typen konnten nicht geladen werden', err)
    } finally {
      this.typesLoading = false;
    }
  }

  addNewType(t: string) {
    if (this.attachmentTypes.includes(t)) return;
    this.attachmentTypes = [...this.attachmentTypes, t];
    if (this.selectedAttachmentType === null) {
      this.selectedAttachmentType = t;
    }
  }
}
