
<app-default-frame>

  <app-create-post-modal [(show)]="showCreateNewPost"
                         [forcedOrgId]="orgid"
                         (success)="onPostCreated($event)"></app-create-post-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">

      <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Posts"></nz-page-header>

      <fa-icon-btn
                   nzType="primary"
                   text="Neuen Post erstellen"
                   iconSize="18" buttonSize="32" textSize="16"
                   icon="note-sticky"
                   perm="POSTS::CREATE"
                   (btnClick)="showCreateNewPost = true"></fa-icon-btn>


    </div>

    <div class="post-list">

      <app-list-entry-post *ngFor="let post of posts"
                           [post]="post"
                           (postUpdated)="onPostUpdated($event)"
                           (postDeleted)="onPostDeleted($event)"></app-list-entry-post>

      <app-pagination-button [count]="posts.length" [nextToken]="nextToken" (click)="fetchPosts(false)"></app-pagination-button>

    </div>

  </main>

</app-default-frame>
