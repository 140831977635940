<nz-modal [nzVisible]="show !== null"
          nzTitle="Administrator Passwort ändern"
          nzOkText="Bearbeiten"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span>Neues Passwort</span>
      <div style="display: grid; grid-template-columns: 1fr auto auto; grid-column-gap: 0.5rem">
        <app-validateable-input [preventAutofill]="true" placeholder="" [type]="showPW ? 'text' : 'password'" [(value)]="password1" [validate]="isValidPW1"></app-validateable-input>
        <fa-icon-btn icon="dice-six" nz-tooltip="Zufälliges Passwort generieren" buttonSize="32"  iconSize="17" (btnClick)="randomPassword()"></fa-icon-btn>
        <fa-icon-btn icon="eye"  buttonSize="32"  iconSize="17" (btnClick)="showPW = !showPW"></fa-icon-btn>
      </div>

      <span>Passwort wiederholen</span>
      <app-validateable-input [preventAutofill]="true" placeholder="" [type]="showPW ? 'text' : 'password'" [(value)]="password2" [validate]="isValidPW2()" ></app-validateable-input>

    </div>
  </ng-container>
</nz-modal>
