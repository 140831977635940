import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {Post} from "../../interfaces/post";
import { showAPIError} from "../../utils/api";
import {isNgDiff} from "../../utils/angular";
import {NzUploadChangeParam, NzUploadFile} from "ng-zorro-antd/upload";
import {filetoBase64, filetoDataURL} from "../../utils/base64";
import { Subscription, timer} from "rxjs";
import {NzUploadXHRArgs} from "ng-zorro-antd/upload/interface";
import {Organization} from "../../interfaces/organization";
import {Group} from "../../interfaces/group";
import { Event } from '../../interfaces/event';
import {ObjectId} from "../../interfaces/utils";
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";

type TargetDef = {type: 'ROOT'|'GROUP'|'EVENT', value: string};

function TargetToStr(v: TargetDef): string { return (v.type === 'ROOT') ? 'ROOT' : `${v.type}|${v.value}`; }
function StrToTarget(v: string): TargetDef { return (v === 'ROOT') ? {'type':v,'value':''} : {'type':<'ROOT'|'GROUP'|'EVENT'>(v.split('|')[0]),'value':v.split('|')[1]}; }

@Component({
  selector: 'app-create-post-modal',
  templateUrl: './create-post-modal.component.html',
  styleUrls: ['./create-post-modal.component.scss']
})
export class CreatePostModalComponent implements OnInit, OnChanges {
  initLoading: boolean = true;
  execLoading: boolean = false;

  fallbackImage = "data:image/svg+xml;base64," +
                  "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzUwIDc2OCI+CjxyZWN0IGZpbGw9IiNDQ0MiIHdpZHRoPSIxMzUwIiBoZWlnaHQ9Ijc2OCIgLz4KPHBhdGggZmlsbD0iIzQ0NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDE5LDEyOCkiIGQ9Ik0wIDk2QzAgNjAuNyAyOC43IDMyIDY0IDMySDQ0OGMzNS4zIDAgNjQgMjguNyA2NCA2NFY0MTZjMCAzNS4zLTI4LjcgNjQtNjQgNjRINjRjLTM1LjMgMC02NC0yOC43LTY0LTY0Vjk2ek0zMjMuOCAyMDIuNWMtNC41LTYuNi0xMS45LTEwLjUtMTkuOC0xMC41cy0xNS40IDMuOS0xOS44IDEwLjVsLTg3IDEyNy42TDE3MC43IDI5N2MtNC42LTUuNy0xMS41LTktMTguNy05cy0xNC4yIDMuMy0xOC43IDlsLTY0IDgwYy01LjggNy4yLTYuOSAxNy4xLTIuOSAyNS40czEyLjQgMTMuNiAyMS42IDEzLjZoOTYgMzJINDI0YzguOSAwIDE3LjEtNC45IDIxLjItMTIuOHMzLjYtMTcuNC0xLjQtMjQuN2wtMTIwLTE3NnpNMTEyIDE5MmE0OCA0OCAwIDEgMCAwLTk2IDQ4IDQ4IDAgMSAwIDAgOTZ6Ii8+Cjwvc3ZnPg==";

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Post> = new EventEmitter<Post>();

  @Input() forcedOrgId: ObjectId|null = null;
  @Input() forcedGroupId: ObjectId|null = null;
  @Input() forcedEventId: ObjectId|null = null;

  allOrgs: Organization[] = []
  allGroups: Group[] = []
  allEvents: Event[] = []

  linkedEvent: Event|null = null;

  selectedOrg: ObjectId|null = null;
  selectedTarget: string|null = null;

  imageList: NzUploadFile[] = [];
  file: File|null = null;
  preview: string|null = null;
  status: 'initial'|'loading'|'active' = 'initial';

  validateForm: FormGroup = this.fb.group({
    title: new FormControl<string>('', [Validators.required]),
    text: new FormControl<string>('', []),
    url: new FormControl<string>('', []),
    imageURL: new FormControl<string>('', []),
    publish: new FormControl<'INACTIVE' | 'ACTIVE' | 'TIMESTAMP'>('ACTIVE', [Validators.required]),
    timestampPublishDate: new FormControl<string>('', [])
  });


  protected readonly TargetToStr = TargetToStr; // pass-through

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private fb: NonNullableFormBuilder,
              private modal: NzModalService) {
  }

  ngOnInit(): void {

    this.validateForm.get('publish')?.valueChanges.subscribe((value) => {
      console.log('publish', value);
      const timestampControl = this.validateForm.get('timestampPublishDate');
      if (value === 'TIMESTAMP') {
        timestampControl?.setValidators([Validators.required]);
        timestampControl?.enable();
      } else {
        timestampControl?.clearValidators();
        timestampControl?.disable();
      }
      timestampControl?.updateValueAndValidity();
    });

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (isNgDiff(changes, 'show')) {
      if (this.show) {
        this.clear();
        this.loadData(true).then(() => {});
      } else {
        this.clear();
      }
    }

    if (isNgDiff(changes, 'forcedOrgId')) {
      this.clear();
      if (this.show) this.loadData().then(() => {});
    }

    if (isNgDiff(changes, 'forcedOrgUserId')) {
      this.clear();
      if (this.show) this.loadData().then(() => {});
    }

    if (isNgDiff(changes, 'forcedGroupId')) {
      this.clear();
      if (this.show) this.loadData().then(() => {});
    }

  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.initLoading = true;
    this.allOrgs = [];
    this.allGroups = [];
    this.allEvents = [];

    this.selectedOrg = this.forcedOrgId;

    if      (this.forcedGroupId !== null) this.selectedTarget = TargetToStr({ 'type':'GROUP','value':this.forcedGroupId });
    else if (this.forcedEventId !== null) this.selectedTarget = TargetToStr({ 'type':'EVENT','value':this.forcedEventId });
    else                                  this.selectedTarget = null;

/*    this.url= '';
    this.title= '';
    this.text= '';*/
 /*   this.imageURL= '';*/
 /*   this.imageList = [];*/
    this.preview = null;
    this.file = null;
    this.status = 'initial';
    this.validateForm.reset()
  }

  async loadData(initial:boolean = false) {
    try {
      if (initial) this.initLoading = true;

      // ================== LOAD ORGANIZATIONS ==================

      if (this.forcedOrgId === null) {

        if (this.allOrgs.length <= 1) {
          const data = await this.api.listOrganizations("@start", null);
          this.allOrgs = data.orgs;
        }

      } else {

        if (this.allOrgs.length != 1 || this.allOrgs[0].id !== this.forcedOrgId) {
          const data = await this.api.getOrganization(this.forcedOrgId);
          this.allOrgs = [data];
          this.selectedOrg = this.forcedOrgId;
        }

      }

      // ================== LOAD GROUPS && EVENTS ==================

      if (this.forcedGroupId !== null) {

        const data = await ((this.forcedOrgId !== null) ? this.api.getOrgGroup(this.forcedOrgId, this.forcedGroupId) : this.api.getGroup(this.forcedGroupId));
        this.allGroups = [data];
        this.allEvents = [];
        this.selectedTarget = TargetToStr({ 'type':'GROUP', 'value': this.forcedGroupId });

      } else if (this.forcedEventId !== null) {

        const data = await ((this.forcedOrgId !== null) ? this.api.getOrgEvent(this.forcedOrgId, this.forcedEventId) : this.api.getEvent(this.forcedEventId));
        this.allGroups = [];
        this.allEvents = [data];
        this.selectedTarget = TargetToStr({ 'type':'EVENT', 'value': this.forcedEventId });

      } else if (this.selectedOrg === null) {

        this.allGroups = [];
        this.allEvents = [];
        this.selectedTarget = null;

      } else {

        if (this.allGroups.length === 0 || this.allGroups[0].organizationID !== this.selectedOrg) {
          const data = await this.api.listOrgGroups(this.selectedOrg, "@start", 999999); //TODO Pgaesize
          this.allGroups = data.groups;
        }

        if (this.allEvents.length === 0 || this.allEvents[0].organizationID !== this.selectedOrg) {
          const data = await this.api.listOrgEvents(this.selectedOrg, "@start", 999999); //TODO Pgaesize
          this.allEvents = data.events;
        }

        if (this.selectedTarget !== null) {
          const target =  StrToTarget(this.selectedTarget);
          if (target.type === 'EVENT' && this.allEvents.filter(p => p.id === target.value).length === 0) this.selectedTarget = null;
          if (target.type === 'GROUP' && this.allGroups.filter(p => p.id === target.value).length === 0) this.selectedTarget = null;
        }

      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      if (initial) this.initLoading = false;
    }
  }

  async submit () {

    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
      await this.execute();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(innerControl => {
            innerControl.markAsDirty();
            innerControl.updateValueAndValidity({ onlySelf: true });
          });
        } else {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async execute() {

    try {
      this.execLoading = true;

      let images: {id?: ObjectId, data?: string, mime?: string}[] = [];
      for (const f of this.imageList) {
        if (f.originFileObj !== null && f.originFileObj !== undefined) {
          const b64 = await filetoBase64(f.originFileObj!); // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744
          const mim = f.type;
          images.push({data: b64!, mime: mim!});
        } else {
          images.push({id: f.uid as ObjectId});
        }
      }

      let filedata = undefined;
      if (this.file !== null && typeof this.file !== 'string') {

        const b64 = (await filetoBase64(this.file!))!; // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744
        const mim = this.file!.type;

        filedata = {"mime": mim, "data": b64};

      }

      const formData = {
        org: this.selectedOrg!,
        dsttyp: StrToTarget(this.selectedTarget!).type,
        dst: StrToTarget(this.selectedTarget!).value,
        title: this.validateForm.get('title')?.value.trim(),
        text: this.validateForm.get('text')?.value?.trim() ?? undefined,
        url: this.validateForm.get('url')?.value?.trim()   ?? undefined,
        imageURL: this.validateForm.get('imageURL')?.value?.trim() ?? undefined,
        images: images ?? undefined,
        file: filedata,
        enabled: this.validateForm.get('publish')?.value === 'ACTIVE',
        publishTS: this.validateForm.get('publish')?.value === 'TIMESTAMP' ? this.validateForm.get('timestampPublishDate')?.value : null
      };

      console.log('formData', formData);

      const data = await this.api.createPost(
        formData.org,
        formData.dsttyp,
        formData.dst,
        formData.title,
        formData.text,
        formData.url,
        formData.imageURL,
        formData.images,
        formData.file,
        formData.enabled,
        formData.publishTS);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Beitrag konnte nicht erstellt werden', err)
    } finally {
      this.execLoading = false;
    }
  }

  isValidLink(val: string) {
    return /^(https?:\/\/).+\..+$/.test(val);
  }

  isValidVideoLink(val: string) {
    if (!(val.toLowerCase().includes('youtube.com') || val.toLowerCase().includes('youtu.be'))) return false;

    return /^(https?:\/\/).+\..+$/.test(val);
  }

  isValidStr(val: string) {
    return val.length >= 1;
  }

  queryData () {
    const url = this.validateForm.get('url')?.value;
    if (this.isValidLink(url)) {
      this.queryLinkData();
    } else if (this.isValidVideoLink(url)) {
      this.queryVideoData();
    } else {
      this.notification.error('Fehler', 'Bitte gültige URL eingeben');
    }
  }

  async queryLinkData() {

    try {
      this.status = "loading";

      const data = await this.api.scrapeLinkPost(this.validateForm.get('url')?.value);

      this.validateForm.get('url')?.setValue(data.metaURL);
      this.validateForm.get('title')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('text')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('imageURL')?.setValue(data.metaImageURL?.trim() ?? '');

      if (this.validateForm.get('imageURL')?.value === '') {
        this.notification.error('Fehler', 'Kein Vorschaubild auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('text')?.value === '') {
        this.notification.error('Fehler', 'Keine Beschreibung auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('title')?.value === '') {
        this.notification.error('Fehler', 'Kein Titel auf der Seite gefunden');
        this.status = "initial";
      } else {
        this.status = "active";
      }


    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht aus der URL geladen werden', err)
      this.status = "initial";
    }

  }

  async queryVideoData() {
    try {
      this.status = "loading";

      const data = await this.api.scrapeVideoPost(this.validateForm.get('url')?.value);

      this.validateForm.get('url')?.setValue(data.metaURL);
      this.validateForm.get('title')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('imageURL')?.setValue(data.metaImageURL?.trim() ?? '');

      if (this.validateForm.get('imageURL')?.value === '') {
        this.notification.error('Fehler', 'Kein Vorschaubild auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('title')?.value === '') {
        this.notification.error('Fehler', 'Kein Titel auf der Seite gefunden');
        this.status = "initial";
      } else {
        this.status = "active";
      }


    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht aus der URL geladen werden', err)
      this.status = "initial";
    }
  }


  handleGalleryPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await filetoDataURL(file.originFileObj!);
    }
  };

  galleryAction = (args: NzUploadXHRArgs): Subscription =>  {
    return timer(500).subscribe(() => {
      args.onProgress!({percent: 100}, args.file);
      args.onSuccess!("ok", args.file, {});
    });
  };

  beforeUploadFilePostFile (file: NzUploadFile)  {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744
    console.log('beforeUploadFilePostFile', fany);

    if (fany instanceof File) {
      this.file = fany;
      console.log('beforeUploadFilePostFile', this.file);
      //this.handleFilePreview(this.file).then()
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.file = null;
    }
    return false;
  }

}
