import { Component } from '@angular/core';
import {Organization, OrgType} from "../../interfaces/organization";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Group} from "../../interfaces/group";
import {AdminUser} from "../../interfaces/adminUser";
import {environment} from "../../../environments/environment";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {EntityCacheService} from "../../services/entity-cache.service";

@Component({
  selector: 'app-groups-list',
  templateUrl: './groups-list.component.html',
  styleUrls: ['./groups-list.component.scss']
})
export class GroupsListComponent {

  groups: Group[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;
  loadingMore: boolean = false;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  showCreateNewGroup: boolean = false;
  showEditGroup: Group|null = null;

  orgTranslationMap = new Map<ObjectId, Organization>();

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private ecache: EntityCacheService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      if (reset) this.loading = true; else this.loadingMore = true;

      const data = await this.api.listGroups(this.nextToken, 24, this.filters);

      if (reset) {
        this.groups = data.groups;
      } else {
        this.groups = [...this.groups, ...data.groups];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

      for (const p of this.groups) this.ecache.getOrganization(p.organizationID).then(org => this.orgTranslationMap.set(org.id, org));

    } catch (err) {
      showAPIError(this.notification, 'Gruppen konnten nicht geladen werden', err)
    } finally {
      if (reset) this.loading = false; else this.loadingMore = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'groups'], this.filters));
  }

  onGroupCreated(groups: Group[]) {
    alert('TODO: Implement onGroupCreated() in groups-list.component.ts');
//    this.groups = [entry, ...this.groups];
  }

  onGroupEdited(entry: Group) {
    this.groups = this.groups.map(p => p.id == entry.id ? entry : p);
  }

  avatarSource(data: Group) {
    if (data.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${data.organizationID}/groups/${data.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${data.avatarImageID}`;
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }

  activateGroup(data: Group) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gruppe aktivieren?',
      nzContent: 'Möchtest du die Gruppe "'+data.title+'" wirklich im Frontend aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enableGroup(data.organizationID, data.id);
          this.notification.success('Gruppe aktiviert', 'Die Gruppe "' + data.title + '" wurde aktiviert');
          this.groups = this.groups.map(g => (g.id === newdata.id) ? newdata : g);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivateGroup(data: Group) {
    alert('TODO: Refactor deactivateGroup() in groups-list.component.ts')
/*    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gruppe deaktivieren?',
      nzContent: 'Möchtest du die Gruppe "'+data.title+'" wirklich im Frontend deaktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.disableGroup(data.organizationID, data.id);
          this.notification.success('Gruppe deaktiviert', 'Die Gruppe "' + data.title + '" wurde deaktiviert');
          this.groups = this.groups.map(g => (g.id === newdata.id) ? newdata : g);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });*/
  }

  translateOrgID(orgid: ObjectId) {
    let org = this.orgTranslationMap.get(orgid);
    if (org === undefined) return '???';
    return org.name;
  }

  deleteGroup(entry: Group) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gruppe löschen?',
      nzContent: 'Möchtest du die Gruppe "'+entry.title+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deleteGroup(entry.organizationID, entry.id);
          this.notification.success('Gruppe gelöscht', 'Die Gruppe "' + entry.title + '" wurde gelöscht');
          this.groups = this.groups.filter(g => g.id !== entry.id);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }
}
