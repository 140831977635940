import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Post} from "../../interfaces/post";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {OrganizationUser} from "../../interfaces/organizationUser";

@Component({
  selector: 'app-list-entry-orguser',
  templateUrl: './list-entry-orguser.component.html',
  styleUrls: ['./list-entry-orguser.component.scss']
})
export class ListEntryOrguserComponent {

  @Input() orguser: OrganizationUser|null = null;

  @Input() urlprefix: string = '/admin/orguser';

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

}
