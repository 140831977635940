<app-default-frame>

  <nz-modal [nzVisible]="jwtModalText !== null" nzTitle="JWT Token" (nzOnOk)="jwtModalText = null" (nzOnCancel)="jwtModalText = null">
    <ng-container *nzModalContent>
      <textarea rows="8" nz-input [ngModel]="jwtModalText ?? ''" [readonly]="true" style="resize: none"></textarea>
    </ng-container>
  </nz-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading && user !== null">

    <div class="topbar">

      <div class="avatarbox">
        <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="128" [nzIcon]="'user'" [nzSrc]="''"></nz-avatar>
      </div>

      <span class="name">{{user.id}}</span>

      <span class="usertype">App-User</span>

      <span class="actionbuttons">
        <fa-icon-btn                                   nzType="default"                   nz-tooltip="Nutzer bearbeiten"          iconSize="18" buttonSize="32"               icon="edit"            [disabled]="true"                   ></fa-icon-btn>
        <fa-icon-btn                                   nzType="default"                   nz-tooltip="JWT generieren"             iconSize="18" buttonSize="32"               icon="shield-keyhole"  (btnClick)="createJWT()"            ></fa-icon-btn>
        <fa-icon-btn                                   nzType="default" [nzDanger]="true" nz-tooltip="Benutzer löschen"           iconSize="18" buttonSize="32"               icon="trash"           (btnClick)="deleteAppUser()"        ></fa-icon-btn>
      </span>

    </div>

    <nz-divider></nz-divider>

    <div class="rootdiv">

      <nz-card class="card-maindata" nzTitle="Daten" [nzExtra]="maindata_extra">
        <ng-template #maindata_extra>
          <fa-icon-btn nzType="default"
                       nz-tooltip="Nutzer bearbeiten"
                       iconSize="12" buttonSize="26" icon="edit"
                       [disabled]="true"
                       (btnClick)="editAppUser()"></fa-icon-btn>
        </ng-template>
        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
          <span class="key">ID</span>
          <span class="value">{{user.id}}</span>

          <span class="icon"><fa-icon icon="clock"></fa-icon></span>
          <span class="key">Erstellt am</span>
          <span class="value">{{user.creationTime | displaydate}}</span>

          <span class="icon"><fa-icon icon="envelope"></fa-icon></span>
          <span class="key">Email</span>
          <span class="value">{{user.email ?? '-'}}</span>

          <span class="icon"><fa-icon icon="phone"></fa-icon></span>
          <span class="key">Telefon</span>
          <span class="value">{{user.phoneNumber ?? '-'}}</span>

          <span class="icon"><fa-icon icon="church"></fa-icon></span>
          <span class="key">Gemeinde</span>
          <span class="value">{{user.communityID ?? '-'}}</span>

          <span class="icon"><fa-icon icon="universal-access"></fa-icon></span>
          <span class="key">AuthType</span>
          <span class="value">{{user.authType}}</span>

        </div>
      </nz-card>

    </div>

  </main>

</app-default-frame>
