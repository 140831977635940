import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {formatAPIError, showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff, sleep} from "../../utils/angular";

@Component({
  selector: 'app-number-card',
  templateUrl: './number-card.component.html',
  styleUrls: ['./number-card.component.scss']
})
export class NumberCardComponent implements OnChanges {

  @Input() title = '';
  @Input() type: 'error' | 'warn' | 'okay' | '' = '';
  @Input() valueFunc: () => Promise<[string, 'error' | 'warn' | 'okay' | '']> = (async () => ['', '']);

  initial = true;

  value: string = '';

  loading = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'valueFunc')) {
      this.update().then(() => {});
    }
  }

  async update() {

    const fakesleep = 300 + (this.initial ? Math.random()*700 : 0);
    this.initial = false;

    try {

      this.value = '';
      this.loading = true;

      const res = (await Promise.all([this.valueFunc(), sleep(fakesleep)]))[0];

      this.loading = false;
      this.value = res[0];
      this.type  = res[1];

    } catch (err) {
      showAPIError(this.notification, 'Daten konnte nicht geladen werden', err)
      this.value = '(ERR)';
      this.type = 'error';
      this.loading = false;
    }
  }

}
