<nz-modal [nzVisible]="show"
          nzTitle="Mit anderer Entität verknüpfen"
          nzOkText="Speichern"
          [nzOkLoading]="loading"
          [nzFooter]="null"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <nz-input-group [nzSuffix]="suffixIconSearch">
        <input type="text" [ngModel]="searchString" (ngModelChange)="searchString=$event; startSearch$.next($event)" nz-input placeholder="ContentProvider suchen" />
        <ng-template #suffixIconSearch>
          <span nz-icon nzType="search"></span>
        </ng-template>
      </nz-input-group>

      <div class="org-list">
        <ng-container *ngFor="let v of searchResults">
          <div class="org-entry">
            <span class="orgname" (click)="execute(v)">{{v.name}}</span>
          </div>
        </ng-container>

        <ng-container *ngIf="searching">
          <nz-spin nzSize="large" style="margin: auto;"></nz-spin>
        </ng-container>

        <ng-container *ngIf="realSearchString.length > 0 && !searching && searchResults.length === 0">
          <nz-empty style="margin: auto;"></nz-empty>
        </ng-container>
      </div>

    </div>
  </ng-container>
</nz-modal>
