<app-edit-post-modal [(postID)]="editPostID" (postUpdated)="onPostUpdated($event)"></app-edit-post-modal>
<nz-table [nzData]="eventPosts">
    <thead>
        <tr>
            <th></th>
            <th>ID</th>
            <th>Titel</th>
            <th>ContentProvider</th>
            <th>Quelle</th>
            <th>Erstellt am</th>
            <th>Veröffentlichung</th>
            <th></th>
        </tr>
    </thead>

    <tbody>
        <tr *ngFor="let post of eventPosts">
            <!-- image -->
            <td></td> 

            <!-- id -->
            <td>{{post.id}}</td>

            <!-- title -->
            <td class="rlink"><a (click)="editPostID = {org: post.organizationID, post: post.id}">{{post.title}}</a></td>

            <!-- content provider -->
            <td class="rlink" routerLink="/admin/organizations/{{post.organizationID}}">
                <a class="nostyle" [routerLink]="'/admin/organizations/' + post.organizationID">{{translateOrgID(post.organizationID)}}</a>
            </td>

            <!-- source -->
            <td>{{translateCreatorType(post.creatorType)}}</td>

            <!-- creation time -->
            <td>{{post.creationTime | date}}</td>

            <!-- publishing -->
            <td>{{post.autoEnableTime | date}}</td>

            <td class="actionlist">
                <nz-switch
                style="margin-top: -1px;"
                [ngModel]="post.enabled"
                [nz-tooltip]="post.enabled ? 'Post deaktivieren' : 'Post aktivieren'"
                [nzControl]="true"
                [nzCheckedChildren]="checkedTemplate"
                [nzUnCheckedChildren]="unCheckedTemplate"
                (click)="changePostActive(post, !post.enabled)"
              ></nz-switch>
              <ng-template #checkedTemplate><fa-icon icon="eye"></fa-icon></ng-template>
              <ng-template #unCheckedTemplate><fa-icon icon="eye-slash"></fa-icon></ng-template>

                <a *ngIf="post.url !== ''" nz-tooltip="Link" [href]="post.url" target="_blank">
                    <fa-icon-btn class="btn-url"
                                 nzType="primary"
                                 [stopPropagation]="true"
                                 buttonSize="24"
                                 iconSize="12"
                                 icon="arrow-up-right-from-square"></fa-icon-btn>
                  </a>
                  <span *ngIf="post.url === ''" style="width: 26px; height: 26px"><!-- Placeholder, because url icon is hidden --></span>
      
                  <fa-icon-btn nzType="default"
                               nz-tooltip="Post bearbeiten"
                               buttonSize="24"
                               iconSize="12"
                               icon="edit"
                               (btnClick)="editPostID = {org:post.organizationID, post:post.id}"></fa-icon-btn>
      
                  <fa-icon-btn nzType="default"
                               [nzDanger]="true"
                               nz-tooltip="Post löschen"
                               buttonSize="24"
                               iconSize="14"
                               icon="trash"
                               (btnClick)="deletePost(post)"></fa-icon-btn>
            </td>


        </tr>
    </tbody>
</nz-table>