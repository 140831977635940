import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {isNgDiff} from "../../utils/angular";
import * as uuid from 'uuid';

@Component({
  selector: 'app-validateable-input',
  templateUrl: './validateable-input.component.html',
  styleUrls: ['./validateable-input.component.scss']
})
export class ValidateableInputComponent implements OnInit, OnChanges {

  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onKeyboardEnter: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() onKeyboardEscape: EventEmitter<Event> = new EventEmitter<Event>();

  @Input() validate: ((v:string)=>(boolean|null)) = (_)=>null;

  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() inputtitle: string = '';
  @Input() disabled: boolean = false;
  @Input() mintitlewidth: number|null = null;

  autoFillDisabledOverride: boolean = false;

  @Input() focusTrigger: any = null;
  @Input() focusDelay: number|null = null;

  @Input() preventAutofill: boolean = false;

  @ViewChild('inputComp') inputComponent!: ElementRef<HTMLInputElement>;

  uuidName: string = uuid.v4();
  uuidAutocomplete: string = `chrome-disable-${uuid.v4()}`;

  constructor() { }

  ngOnInit(): void {
    if (this.preventAutofill) {
      this.startAutofillPrevention();
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isNgDiff(changes, 'focusTrigger')) {
      if (this.focusDelay === null) this.inputComponent.nativeElement.focus();
      else setTimeout(() => { this.inputComponent.nativeElement.focus(); }, this.focusDelay);
    }

    if (isNgDiff(changes, 'preventAutofill')) {
      if (this.preventAutofill) {
        this.startAutofillPrevention();
      } else {
        this.cancelAutofillPrevention();
      }
    }
  }

  startAutofillPrevention() {
    this.autoFillDisabledOverride = true;
    setTimeout(() => { this.autoFillDisabledOverride = false; }, 100)
  }

  cancelAutofillPrevention() {
    this.autoFillDisabledOverride = false;
  }
}
