
<app-default-frame>

  <app-upload-orguser-avatar-modal [(show)]="selectedEditAvatar"
                                   (success)="setAvatar($event, 'image/png')"></app-upload-orguser-avatar-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading && user !== null">

    <div class="topbar">

      <div class="avatarbox">
        <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="128" [nzIcon]="'user'" [nzSrc]="avatarSource() ?? ''"></nz-avatar>

        <fa-icon-btn nzType="default" nz-tooltip="Profilbild bearbeiten"   iconSize="18" buttonSize="32" icon="edit"  (btnClick)="selectedEditAvatar = user"   ></fa-icon-btn>
      </div>

      <span class="name">{{user.firstName}} {{user.lastName}}</span>

      <span class="usertype">baden online Nutzer</span>

      <span class="actionbuttons">

        <!-- -->

      </span>

    </div>

    <nz-divider></nz-divider>

    <div class="rootdiv">

      <div  #col1Ref class="col1Ref" ></div>
      <div  #col2Ref class="col2Ref" ></div>

      <nz-card class="card-maindata" nzTitle="Daten">

        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
          <span class="key">ID</span>
          <span class="value">{{user.id}}</span>

          <span class="icon"><fa-icon icon="clock"></fa-icon></span>
          <span class="key">Erstellt am</span>
          <span class="value">{{user.creationTime | displaydate}}</span>

          <span class="icon"><fa-icon icon="shirt"></fa-icon></span>
          <span class="key">Benutzername</span>
          <span class="value">{{user.username}}</span>

          <span class="icon"><fa-icon icon="envelope"></fa-icon></span>
          <span class="key">Email</span>
          <span class="value">{{user.email}}</span>

          <span class="icon"><fa-icon icon="input-text"></fa-icon></span>
          <span class="key">Vorname</span>
          <span class="value">{{user.firstName}}</span>

          <span class="icon"><fa-icon icon="input-text"></fa-icon></span>
          <span class="key">Nachname</span>
          <span class="value">{{user.lastName}}</span>

          <span class="icon"><fa-icon icon="image"></fa-icon></span>
          <span class="key">Profilbild</span>
          <span class="value" *ngIf="user.avatarImageID !== null &&  loadingBlobAvatar"><fa-icon icon="loader" [spin]="true"></fa-icon></span>
          <span class="value" *ngIf="user.avatarImageID !== null && !loadingBlobAvatar && avatarBlob !== null">{{avatarBlob.mimeType}} ({{avatarBlob.filesize | filesize}})</span>
          <span class="value image-empty" *ngIf="user.avatarImageID === null">(nicht gesetzt)</span>
          <span class="extra" *ngIf="!loadingBlobAvatar"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" (btnClick)="selectedEditAvatar = user" nz-tooltip="Profilbild ändern"></fa-icon-btn></span>
          <span class="extra2" *ngIf="!loadingBlobAvatar && user.avatarImageID !== null"><fa-icon-btn icon="trash" buttonSize="22" iconSize="10" nzType="primary" [nzDanger]="true" (btnClick)="clearAvatar()" [loading]="clearingAvatar" nz-tooltip="Profilbild löschen"></fa-icon-btn></span>

        </div>

      </nz-card>

    </div>

  </main>

</app-default-frame>
