import {SimpleChanges} from '@angular/core';

export function isNgDiff(changes: SimpleChanges, key: string) {
  if (changes[key] === undefined) return false;

  if (typeof changes[key].currentValue === 'function' || typeof changes[key].previousValue === 'function') {
    return `${changes[key].currentValue}` !== `${changes[key].previousValue}`;
  } else {
    return JSON.stringify(changes[key].previousValue) !== JSON.stringify(changes[key].currentValue);
  }
}

export function uniqueRandID() {
  return Date.now().toString(26).padStart(10, '0') + "_" +
         Math.random().toString(26).substring(2, 6) + "_" +
         Math.random().toString(26).substring(2, 6) + "_" +
         Math.random().toString(26).substring(2, 6);
}

export function deepclone<T>(v: T): T { return structuredClone(v); }

export const sleep = (milliseconds: number) => {
  return new Promise(resolve => setTimeout(resolve, milliseconds));
};
