
<app-default-frame>

  <nz-card class="card-data" nzTitle="Daten" style="max-width: 750px" [nzLoading]="loading">
    <div class="infocardcontainer" *ngIf="self !== null">

      <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
      <span class="key">ID</span>
      <span class="value"><input nz-input [disabled]="true" [ngModel]="self.id" /></span>

      <span class="icon"><fa-icon icon="calendar-days"></fa-icon></span>
      <span class="key">Erstellt am</span>
      <span class="value"><input nz-input [disabled]="true" [ngModel]="self.creationTime | displaydate" /></span>

      <span class="icon"><fa-icon icon="shirt"></fa-icon></span>
      <span class="key">Benutzername</span>
      <span class="value"><input nz-input [disabled]="!editUsername" [(ngModel)]="username" /></span>
      <span class="extra"  *ngIf="!editUsername"><fa-icon-btn icon="pen"                          buttonSize="32" [loading]="updatingUsername" (btnClick)="editUsername=true;"></fa-icon-btn></span>
      <span class="extra"  *ngIf=" editUsername"><fa-icon-btn icon="floppy-disk" nzType="primary" buttonSize="32" [loading]="updatingUsername" (btnClick)="updateUsername()"  ></fa-icon-btn></span>
      <span class="extra2" *ngIf=" editUsername"><fa-icon-btn icon="xmark"                        buttonSize="32"                              (btnClick)="cancelUsername()"  ></fa-icon-btn></span>

      <span class="icon"><fa-icon icon="input-text"></fa-icon></span>
      <span class="key">Vorname</span>
      <span class="value"><input nz-input [disabled]="!editFirstName" [(ngModel)]="firstName" /></span>
      <span class="extra"  *ngIf="!editFirstName"><fa-icon-btn icon="pen"                          buttonSize="32" [loading]="updatingFirstName" (btnClick)="editFirstName=true;"></fa-icon-btn></span>
      <span class="extra"  *ngIf=" editFirstName"><fa-icon-btn icon="floppy-disk" nzType="primary" buttonSize="32" [loading]="updatingFirstName" (btnClick)="updateFirstName()"  ></fa-icon-btn></span>
      <span class="extra2" *ngIf=" editFirstName"><fa-icon-btn icon="xmark"                        buttonSize="32"                               (btnClick)="cancelFirstName()"  ></fa-icon-btn></span>

      <span class="icon"><fa-icon icon="input-text"></fa-icon></span>
      <span class="key">Nachname</span>
      <span class="value"><input nz-input [disabled]="!editLastName" [(ngModel)]="lastName" /></span>
      <span class="extra"  *ngIf="!editLastName"><fa-icon-btn icon="pen"                          buttonSize="32" [loading]="updatingLastName" (btnClick)="editLastName=true;"></fa-icon-btn></span>
      <span class="extra"  *ngIf=" editLastName"><fa-icon-btn icon="floppy-disk" nzType="primary" buttonSize="32" [loading]="updatingLastName" (btnClick)="updateLastName()"  ></fa-icon-btn></span>
      <span class="extra2" *ngIf=" editLastName"><fa-icon-btn icon="xmark"                        buttonSize="32"                              (btnClick)="cancelLastName()"  ></fa-icon-btn></span>

      <span class="icon"><fa-icon icon="envelope"></fa-icon></span>
      <span class="key">Mail</span>
      <span class="value"><input nz-input [disabled]="!editMail" [(ngModel)]="email" /></span>
      <span class="extra"  *ngIf="!editMail"><fa-icon-btn icon="pen"                          buttonSize="32" [loading]="updatingMail" (btnClick)="editMail=true;"></fa-icon-btn></span>
      <span class="extra"  *ngIf=" editMail"><fa-icon-btn icon="floppy-disk" nzType="primary" buttonSize="32" [loading]="updatingMail" (btnClick)="updateMail()"  ></fa-icon-btn></span>
      <span class="extra2" *ngIf=" editMail"><fa-icon-btn icon="xmark"                        buttonSize="32"                          (btnClick)="cancelMail()"  ></fa-icon-btn></span>

      <ng-container *ngIf="!editPassword">
        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Passwort</span>
        <span class="value"><input nz-input [disabled]="true" [ngModel]="12345678" type="password" /></span>
        <span class="extra"><fa-icon-btn icon="pen" buttonSize="32" (btnClick)="editPassword=true;password0=password1=password2=''"></fa-icon-btn></span>
      </ng-container>

      <ng-container *ngIf="editPassword">

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Bisheriges Passwort</span>
        <span class="value"><app-validateable-input [(value)]="password0" type="password" [validate]="isValidPWOld"></app-validateable-input></span>

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Neues Passwort</span>
        <span class="value"><app-validateable-input [(value)]="password1" type="password" [validate]="isValidPW1"></app-validateable-input></span>

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Neues Passwort wiederholen</span>
        <span class="value"><app-validateable-input [(value)]="password2" type="password" [validate]="isValidPW2()"></app-validateable-input></span>
        <span class="extra"><fa-icon-btn nzType="primary"
                                         [disabled]="!(password0 != '' && isValidPW1(password1) && password2 === password1)"
                                         [loading]="updatingPassword"
                                         icon="floppy-disk"
                                         buttonSize="32"
                                         (btnClick)="updatePassword()"></fa-icon-btn></span>

        <span class="extra2"><fa-icon-btn icon="xmark" buttonSize="32" (btnClick)="cancelPassword()"  ></fa-icon-btn></span>


      </ng-container>

    </div>
  </nz-card>

</app-default-frame>
