import { Component } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isErrorCode} from "../../utils/api";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {
  loginLoading: boolean = false;
  errorText: string = "";

  email: string = '';
  password: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (this.auth.isAuthenticated() && this.auth.isAdminUser()) {
        await this.router.navigate(['/admin/'], {queryParams: {}});
      } else if (this.auth.isAuthenticated() && this.auth.isOrgUser()) {
        await this.router.navigate(['/org/'], {queryParams: {}});
      }
    });
  }

  async login() {
   try {
     this.loginLoading = true;
     this.errorText = '';
     const data = await this.api.anyLogin(this.email, this.password);

     if (data.userType == 'ADMIN_USER') {

       this.auth.setAuth(data.token);
       this.notification.success('Angemeldet', 'Du wurdest erfolgreich angemeldet');
       await this.router.navigate(['/admin/'], {queryParams: {}});

     } else if (data.userType == 'ORGANIZATION_USER') {

       this.auth.setAuth(data.token);
       this.notification.success('Angemeldet', 'Du wurdest erfolgreich angemeldet');
       await this.router.navigate(['/org/'], {queryParams: {}});

     } else if (data.userType == 'APPLICATION_USER') {

       this.notification.error('TODO', 'APPLICATION_USER LOGIN'); //TODO

     } else {

       this.errorText = 'Es ist ein Fehler aufgetreten';

     }

   } catch (err: any) {
     if (isErrorCode(err, 'AUTH_FAILED')) {
       this.errorText = 'Die eingegebenen Login-Daten sind nicht richtig.';
     } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
       this.errorText = 'Interner Server Fehler';
     } else {
       this.errorText = 'Es ist ein Fehler aufgetreten';
     }
   } finally {
     this.loginLoading = false;
   }
  }
}
