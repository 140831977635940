import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {environment} from "../../environments/environment";
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {AdminUser} from "../interfaces/adminUser";
import {OrganizationUser} from "../interfaces/organizationUser";
import {UserType} from "../interfaces/userType";

@Injectable({ providedIn: 'root' })
export class SidebarService {
  isCollapsed: boolean = false;
}
