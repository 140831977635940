import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff} from "../../utils/angular";
import {formatAPIError, showAPIError} from "../../utils/api";
import {isValidIBANNumber} from "../../utils/iban";
import {Organization, OrgType} from 'src/app/interfaces/organization';

@Component({
  selector: 'app-edit-org-modal',
  templateUrl: './edit-org-modal.component.html',
  styleUrls: ['./edit-org-modal.component.scss']
})
export class EditOrgModalComponent implements OnInit, OnChanges {

  @Input() show: Organization|null = null;
  @Output() showChange: EventEmitter<Organization|null> = new EventEmitter<Organization|null>();
  @Output() success: EventEmitter<Organization> = new EventEmitter<Organization>();

  loading: boolean = false;

  name: string = '';
  orgType: OrgType|null = null;
  orgPurpose: string = '';

  customernumber: string = '';
  addressPLZ: string = '';
  addressCity: string = '';
  addressStreet: string = '';
  addressNumber: string = '';
  addressExtra: string = '';

  bankingIban: string = '';
  bankingName: string = '';

  telephonenumber: string = '';

  purposes: string[] = [];

  isPreselected: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.name            = this.show.name;
        this.orgType         = this.show.type;
        this.orgPurpose      = this.show.purpose;
        this.customernumber  = this.show.customerNumber;
        this.addressPLZ      = this.show.address.zipcode;
        this.addressCity     = this.show.address.city;
        this.addressStreet   = this.show.address.street;
        this.addressNumber   = this.show.address.number;
        this.addressExtra    = this.show.address.extra;
        this.bankingIban     = this.show.banking.iban
        this.bankingName     = this.show.banking.owner;
        this.telephonenumber = this.show.phone;
        this.isPreselected   = this.show.isPreselected
      }
      return;
    }
  }

  ngOnInit(): void {
    this.listPurposeSuggestions().then(() => {})
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.name                  = '';
    this.orgType               = null;
    this.orgPurpose            = '';
    this.customernumber        = '';
    this.addressPLZ            = '';
    this.addressCity           = '';
    this.addressStreet         = '';
    this.addressNumber         = '';
    this.addressExtra          = '';
    this.bankingIban           = '';
    this.bankingName           = '';
    this.telephonenumber       = '';
    this.isPreselected         = false;
  }

  async execute() {
    if (!this.isValidStr(this.name))                      { this.notification.error('Fehler', 'Bitte valider Name eingeben');          return; }

    if (!this.isValidCustomerNumber(this.customernumber)) { this.notification.error('Fehler', 'Bitte valide Kundennummer eingeben'); return; }

    if (this.orgType === null)                            { this.notification.error('Fehler', 'Bitte valide Typ eingeben'); return; }

    if (!this.isValidIBAN(this.bankingIban))              { this.notification.error('Fehler', 'Bitte valide Bankdaten eingeben'); return; }

    if (!this.isValidTelephone(this.telephonenumber))     { this.notification.error('Fehler', 'Bitte valide Telefonnummer eingeben'); return; }

    try {
      this.loading = true;

      const data = await this.api.updateOrganization(
        this.show!.id,
        this.name,
        this.customernumber,
        this.addressPLZ, this.addressCity, this.addressStreet, this.addressNumber, this.addressExtra,
        this.bankingIban, this.bankingName,
        this.telephonenumber,
        this.orgType,
        this.orgPurpose,
        this.isPreselected
      );

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'ContentProvider konnte nicht aktualisiert werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }

  isValidCustomerNumber(v: string) {
    return true;
  }

  isValidIBAN(iban: string) {
    if (iban === '') return true;
    return isValidIBANNumber(iban);
  }

  isValidTelephone(tel: string) {
    return true;
  }

  async listPurposeSuggestions() {
    try {
      const data = await this.api.listOrgPurposes();
      this.purposes = data.purposes;
    } catch (err) {
      showAPIError(this.notification, 'Purposes konnte nicht geladen werden', err)
    }
  }

}
