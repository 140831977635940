import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {
  Crawler,
  CrawlerExecution,
  CrawlerHTTPRequest,
  CrawlerHTTPResponse,
  CrawlerLog
} from "../../../../interfaces/crawler";
import {CursorToken, ObjectId} from "../../../../interfaces/utils";
import {APIService} from "../../../../services/api.service";
import {AuthenticationService} from "../../../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff, sleep} from "../../../../utils/angular";
import {showAPIError} from "../../../../utils/api";
import {DateUtils} from "../../../../utils/date";

@Component({
  selector: 'app-crawler-log-list',
  templateUrl: './crawler-log-list.component.html',
  styleUrls: ['./crawler-log-list.component.scss']
})
export class CrawlerLogListComponent implements OnInit, OnChanges  {

  @Input() execution: [Crawler, CrawlerExecution]|null = null;

  logs: CrawlerLog[] = [];

  loading: boolean = true;

  expandedData: Set<ObjectId> = new Set<ObjectId>();
  @Input() set trigger(v: number) { this.refreshData().then(() => {})};

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'execution')) {
      if (this.execution === null) {
        this.logs = [];
        this.loading = true;
      } else {
        this.loadData().then(() => {});
      }
      return;
    }
  }

  async refreshData() {

    if (this.execution === null) return;

    try {
      const data = (await Promise.all([this.api.listCrawlerLogs(this.execution[0].id, this.execution[1].id), sleep(600)]))[0];

      if (JSON.stringify(this.logs) !== JSON.stringify(data.logs)) {
        this.logs  = data.logs;
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    }
  }

  async loadData() {

    this.logs = [];

    if (this.execution === null) return;

    try {
      this.loading = true;

      const data = (await Promise.all([this.api.listCrawlerLogs(this.execution[0].id, this.execution[1].id), sleep(600)]))[0];

      this.logs  = data.logs;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  toggle(log: CrawlerLog) {
    if (this.expandedData.has(log.id))
      this.expandedData.delete(log.id)
    else
      this.expandedData.add(log.id)
  }

  async formatRespBody(response: CrawlerHTTPResponse) {
    try {
      response.responseBody = (await this.api.prettyPrintJson(response.responseBody)).data;
    } catch (err) {
      showAPIError(this.notification, 'PrettyPrint ist fehlgeschlagen', err)
    }
  }

  async formatReqBody(response: CrawlerHTTPRequest) {
    try {
      response.requestBody = (await this.api.prettyPrintJson(response.requestBody)).data;
    } catch (err) {
      showAPIError(this.notification, 'PrettyPrint ist fehlgeschlagen', err)
    }
  }
}
