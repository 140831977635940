import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {showAPIError} from "../../utils/api";
import {Community} from "../../interfaces/community";

@Component({
  selector: 'app-create-community-modal',
  templateUrl: './create-community-modal.component.html',
  styleUrls: ['./create-community-modal.component.scss']
})
export class CreateCommunityModalComponent implements OnInit {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Community> = new EventEmitter<Community>();

  loading: boolean = false;

  name: string = '';
  zip: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {}

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.name = '';
    this.zip  = '';
  }

  async execute() {
    if (!this.isValidPLZ(this.zip))       { this.notification.error('Fehler', 'Bitte valide PLZ eingeben'); return; }
    if (!this.isValidStr(this.name))      { this.notification.error('Fehler', 'Bitte valider Name eingeben'); return; }

    try {
      this.loading = true;

      const data = await this.api.createCommunity(this.zip, this.name);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

      this.notification.success('Erfolg!', 'Gemeinde wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Gemeinde konnte nicht erstellt werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidPLZ(val: string) {
    return /^[0-9]{5}$/.test(val);
  }
}
