import { Component } from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {SerializedParamCollection} from "../../utils/serializedParameter";
import {DateUtils} from "../../utils/date";

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

  spanXS  = 24;
  spanSM  = 12;
  spanMD  = 12;
  spanLG  =  8;
  spanXL  =  6;
  spanXXL =  6;

  constructor(private api: APIService,
              private notification: NzNotificationService) {
  }

  queryContentProviderCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countOrgs();
      return [`${count.count}`, ''];
    }
  }

  queryAdminUserCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countAdminUser();
      return [`${count.count}`, ''];
    }
  }

  queryPublishingUserCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countAllOrgUser();
      return [`${count.count}`, ''];
    }
  }

  queryAppUserCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countAppUser();
      return [`${count.count}`, ''];
    }
  }

  queryPostCount(filter: 'all'|'today'|'thisweek'|'lastweek'|'crawled') {

    if (filter === 'all') {
      return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
        let count = await this.api.countPosts();
        return [`${count.count}`, ''];
      }
    }

    if (filter === 'today') {
      return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
        const t0 = DateUtils.formatLocalIsoDateTime(DateUtils.startOfDay(DateUtils.now()));
        const t1 = DateUtils.formatLocalIsoDateTime(DateUtils.endOfDay(DateUtils.now()));
        const filter: SerializedParamCollection = {"after": { "active": t0, "default": null, "type": "string"}, "before": { "active": t1, "default": null, "type": "string"}};
        let count = await this.api.countPosts(filter);
        return [`${count.count}`, ''];
      }
    }

    if (filter === 'thisweek') {
      return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
        const t0 = DateUtils.formatLocalIsoDateTime(DateUtils.startOfWeek(DateUtils.now()));
        const t1 = DateUtils.formatLocalIsoDateTime(DateUtils.endOfWeek(DateUtils.now()));
        const filter: SerializedParamCollection = {"after": { "active": t0, "default": null, "type": "string"}, "before": { "active": t1, "default": null, "type": "string"}};
        let count = await this.api.countPosts(filter);
        return [`${count.count}`, ''];
      }
    }

    if (filter === 'lastweek') {
      return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
        const t0 = DateUtils.formatLocalIsoDateTime(DateUtils.addDays(DateUtils.startOfWeek(DateUtils.now()), -7));
        const t1 = DateUtils.formatLocalIsoDateTime(DateUtils.addDays(DateUtils.endOfWeek(DateUtils.now()), -7));
        const filter: SerializedParamCollection = {"after": { "active": t0, "default": null, "type": "string"}, "before": { "active": t1, "default": null, "type": "string"}};
        let count = await this.api.countPosts(filter);
        return [`${count.count}`, ''];
      }
    }

    if (filter === 'crawled') {
      return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
        return ['?', ''];
      }
    }

    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      return ['ERR', 'error'];
    }
  }

  queryCrawledPostCount() {
    return async function (): Promise<[string, 'error'|'warn'|'okay'|'']> {
      return ['0', ''];
    }
  }

  queryCrawlerCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countCrawler();
      return [`${count.count}`, ''];
    }
  }

  queryErrorCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      return ['0', 'okay'];
    }
  }
}
