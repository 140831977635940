import {Injector, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {NZ_I18N} from 'ng-zorro-antd/i18n';
import {de_DE} from 'ng-zorro-antd/i18n';
import {NgOptimizedImage, registerLocaleData} from '@angular/common';
import de from '@angular/common/locales/de';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NzLayoutModule} from 'ng-zorro-antd/layout';
import {NzMenuModule} from 'ng-zorro-antd/menu';
import {LoginComponent} from './pages-common/login/login.component';
import {IconsProviderModule} from "./utils/icons-provider.module";
import {NoFrameComponent} from './frames/no-frame/no-frame.component';
import {DefaultFrameComponent} from './frames/default-frame/default-frame.component';
import {NzIconModule} from "ng-zorro-antd/icon";
import {DashboardComponent} from './pages-admin/dashboard/dashboard.component';
import {NzCardModule} from "ng-zorro-antd/card";
import {NzPageHeaderModule} from "ng-zorro-antd/page-header";
import {NzInputModule} from "ng-zorro-antd/input";
import {NzAlertModule} from "ng-zorro-antd/alert";
import {NzButtonModule} from "ng-zorro-antd/button";
import {NzMessageService} from "ng-zorro-antd/message";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {NzSpinModule} from "ng-zorro-antd/spin";
import {AdminUserListComponent} from './pages-admin/adminuser-list/adminuser-list.component';
import {NzTableModule} from "ng-zorro-antd/table";
import {BooleanNotPipe} from "./pipes/boolean-not";
import {CastDatePipe} from "./pipes/cast-date.pipe";
import {CastNumberPipe} from "./pipes/cast-number.pipe";
import {CastNumberArrayPipe} from "./pipes/cast-numberarray.pipe";
import {CastStringPipe} from "./pipes/cast-string.pipe";
import {CastStringArrayPipe} from "./pipes/cast-stringarray.pipe";
import {NumberArrayPipe} from "./pipes/number-array.pipe";
import {ShortIDPipe} from "./pipes/shortid";
import {TrustHTMLPipe} from "./pipes/trust-html.pipe";
import {TrustResourceURLPipe} from "./pipes/trust-resourceurl.pipe";
import {TrustScriptPipe} from "./pipes/trust-script.pipe";
import {TrustStylePipe} from "./pipes/trust-style.pipe";
import {TrustURLPipe} from "./pipes/trust-url.pipe";
import {PaginationButtonComponent} from './components/pagination-button/pagination-button.component';
import {IconBtnComponent} from './components/icon-btn/icon-btn.component';
import {NzPipesModule} from "ng-zorro-antd/pipes";
import {NzModalModule} from "ng-zorro-antd/modal";
import {CreateAdminModalComponent} from './modals/create-admin-modal/create-admin-modal.component';
import {ValidateableInputComponent} from './components/validateable-input/validateable-input.component';
import {ValidateableTextareaComponent} from './components/validateable-textarea/validateable-textarea.component';
import {NzToolTipModule} from "ng-zorro-antd/tooltip";
import {EditAdminModalComponent} from './modals/edit-admin-modal/edit-admin-modal.component';
import {EditAdminPasswordModalComponent} from './modals/edit-admin-password-modal/edit-admin-password-modal.component';
import {OrgUserListComponent} from './pages-admin/orguser-list/orguser-list.component';
import {AppUserListComponent} from './pages-admin/appuser-list/appuser-list.component';
import {CrawlerOverviewComponent} from './pages-admin/crawler-overview/crawler-overview.component';
import {CreateOrgModalComponent} from './modals/create-org-modal/create-org-modal.component';
import {EditOrgUserModalComponent} from './modals/edit-orguser-modal/edit-orguser-modal.component';
import {
  EditOrgUserPasswordModalComponent
} from './modals/edit-orguser-password-modal/edit-orguser-password-modal.component';
import {DisplayDatePipe} from "./pipes/displaydate.pipe";
import {DisplaydurationPipe} from './pipes/displayduration.pipe';
import {PostListComponent} from './pages-admin/post-list/post-list.component';
import {SettingsComponent} from './pages-admin/settings/settings.component';
import {NumberCardComponent} from './components/number-card/number-card.component';
import {NzGridModule} from "ng-zorro-antd/grid";
import {NzCheckboxModule} from "ng-zorro-antd/checkbox";
import {OrgUserViewComponent} from './pages-admin/orguser-view/orguser-view.component';
import {NzAvatarModule} from "ng-zorro-antd/avatar";
import {NzDividerModule} from "ng-zorro-antd/divider";
import {NzUploadModule} from "ng-zorro-antd/upload";
import {FilesizePipe} from './pipes/filesize.pipe';
import {NzTagModule} from "ng-zorro-antd/tag";
import {OrgDashboardComponent} from './pages-orguser/org-dashboard/org-dashboard.component';
import {ForbiddenComponent} from './pages-common/forbidden/forbidden.component';
import {NzEmptyModule} from "ng-zorro-antd/empty";
import {
  UploadOrgUserAvatarModalComponent
} from './modals/upload-orguser-avatar-modal/upload-orguser-avatar-modal.component';
import {ImageCropperModule} from "ngx-image-cropper";
import {NzSelectModule} from "ng-zorro-antd/select";
import {
  CreateConversationNoteModalComponent
} from './modals/create-conversation-note-modal/create-conversation-note-modal.component';
import {
  EditConversationNoteModalComponent
} from './modals/edit-conversation-note-modal/edit-conversation-note-modal.component';
import {NzDatePickerModule} from "ng-zorro-antd/date-picker";
import {NzTimePickerModule} from "ng-zorro-antd/time-picker";
import {OrgSettingsComponent} from './pages-orguser/org-settings/org-settings.component';
import {SuffixPipe} from "./pipes/suffix";
import {ProfilePreviewComponent} from './components/profile-preview/profile-preview.component';
import {RegisterLinkComponent} from './pages-common/register-link/register-link.component';
import {PWResetLinkComponent} from './pages-common/pwreset-link/pwreset-link.component';
import {PWResetComponent} from './pages-common/pw-reset/pw-reset.component';
import {CreatePostModalComponent} from './modals/create-post-modal/create-post-modal.component';
import {EditPostModalComponent} from './modals/edit-post-modal/edit-post-modal.component';
import {NzSegmentedModule} from "ng-zorro-antd/segmented";
import {NzImageModule} from "ng-zorro-antd/image";
import {OrgPostListComponent} from "./pages-orguser/org-post-list/org-post-list.component";
import {OrganizationListComponent} from './pages-admin/organization-list/organization-list.component';
import {GroupsListComponent} from './pages-admin/groups-list/groups-list.component';
import {CreateGroupModalComponent} from './modals/create-group-modal/create-group-modal.component';
import {OrgGroupListComponent} from './pages-orguser/org-group-list/org-group-list.component';
import {OrgViewComponent} from './pages-admin/org-view/org-view.component';
import {EditOrgModalComponent} from './modals/edit-org-modal/edit-org-modal.component';
import {
  CreateOrgAttachmentModalComponent
} from './modals/create-org-attachment-modal/create-org-attachment-modal.component';
import {EditOrgAttachmentModalComponent} from './modals/edit-org-attachment-modal/edit-org-attachment-modal.component';
import {OrgOrgProfileComponent} from './pages-orguser/org-orgprofile/org-orgprofile.component';
import {OrgUserProfileComponent} from './pages-orguser/org-userprofile/org-userprofile.component';
import {
  UploadOrgBackgroundimageModalComponent
} from "./modals/upload-org-backgroundimage-modal/upload-org-backgroundimage-modal.component";
import {UploadOrgAvatarModalComponent} from "./modals/upload-org-avatar-modal/upload-org-avatar-modal.component";
import {FmtOrgTypePipe} from "./pipes/fmt-orgtype";
import {AddOrgLinkModalComponent} from './modals/add-org-link-modal/add-org-link-modal.component';
import {EditGroupModalComponent} from './modals/edit-group-modal/edit-group-modal.component';
import {CreateOrguserModalComponent} from './modals/create-orguser-modal/create-orguser-modal.component';
import {NzSkeletonModule} from "ng-zorro-antd/skeleton";
import {OrgOrgUserViewComponent} from "./pages-orguser/org-orguser-view/org-orguser-view.component";
import { OrgOrgUserListComponent } from './pages-orguser/org-orguser-list/org-orguser-list.component';
import { EventsListComponent } from './pages-admin/events-list/events-list.component';
import { EventPostListComponent } from './components/event-post-list/event-post-list.component';
import { EventDetailsComponent } from './pages-admin/event-details/event-details.component';
import { OrgEventListComponent } from './pages-orguser/org-event-list/org-event-list.component';
import { SearchBarComponent } from './components/search-bar/search-bar.component';
import { LayoutModule } from '@angular/cdk/layout';
import { ListEntryGroupComponent } from './components/list-entry-group/list-entry-group.component';
import { ListEntryPostComponent } from './components/list-entry-post/list-entry-post.component';
import { ListEntryEventComponent } from './components/list-entry-event/list-entry-event.component';
import { ListEntryOrguserComponent } from './components/list-entry-orguser/list-entry-orguser.component';
import { SolseitConfigComponent } from './pages-admin/solseit-config/solseit-config.component';
import {NzAutocompleteModule} from "ng-zorro-antd/auto-complete";
import {NzSwitchModule} from "ng-zorro-antd/switch";
import {CheckPermPipe} from "./pipes/check-perm";
import {EditCrawlerModalComponent} from './modals/edit-crawler-modal/edit-crawler-modal.component';
import {
  CrawlerExecutionListComponent
} from './pages-admin/crawler-overview/components/crawler-execution-list/crawler-execution-list.component';
import {
  CrawlerLogListComponent
} from './pages-admin/crawler-overview/components/crawler-log-list/crawler-log-list.component';
import {SingleLinePipe} from "./pipes/single-line.pipe";
import {AppUserViewComponent} from './pages-admin/appuser-view/appuser-view.component';
import {CommunityListComponent} from './pages-admin/community-list/community-list.component';
import {CreateCommunityModalComponent} from './modals/create-community-modal/create-community-modal.component';
import {NzTreeComponent, NzTreeModule} from "ng-zorro-antd/tree";
import {CreateEventModalComponent} from "./modals/create-event-modal/create-event-modal.component";
import {NzFormModule} from "ng-zorro-antd/form";
import {NzInputNumberModule} from "ng-zorro-antd/input-number";
import {CreateCrawlerModalComponent} from './modals/create-crawler-modal/create-crawler-modal.component';
import {NzPopconfirmModule} from "ng-zorro-antd/popconfirm";
import {
  ProfilePreviewSmartphoneComponent
} from "./components/profile-preview-smartphone/profile-preview-smartphone.component";
import {ListEntryImpressComponent} from "./components/list-entry-impress/list-entry-impress.component";
import {EditImpressumModalComponent} from "./modals/edit-impressum-modal/edit-impressum-modal.component";
import {ReportListComponent} from "./pages-admin/report-list/report-list.component";
import {NzListComponent, NzListItemComponent} from "ng-zorro-antd/list";
import {AddImpressumModalComponent} from "./modals/add-impressum-modal/add-impressum-modal.component";
import {AddWasteEntryModalComponent} from "./modals/add-waste-entry-modal/add-waste-entry-modal.component";
import {
  ManageWasteCalendarStreetsModalComponent
} from "./modals/manage-waste-calendar-streets-modal/manage-waste-calendar-streets-modal.component";
import {CastToDatePipe} from "./pipes/cast-to-date.pipe";
import { EditOpeningHoursModalComponent } from './modals/edit-opening-hours-modal/edit-opening-hours-modal.component';
import { NzRadioModule } from 'ng-zorro-antd/radio';

registerLocaleData(de);

export let AppInjector: Injector;

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    NoFrameComponent,
    DefaultFrameComponent,
    DashboardComponent,
    AdminUserListComponent,
    BooleanNotPipe,
    CastDatePipe,
    CastToDatePipe,
    CastNumberPipe,
    CastNumberArrayPipe,
    CastStringPipe,
    CastStringArrayPipe,
    NumberArrayPipe,
    ShortIDPipe,
    TrustHTMLPipe,
    TrustResourceURLPipe,
    TrustScriptPipe,
    TrustStylePipe,
    TrustURLPipe,
    SuffixPipe,
    DisplayDatePipe,
    PaginationButtonComponent,
    IconBtnComponent,
    CreateAdminModalComponent,
    ValidateableInputComponent,
    ValidateableTextareaComponent,
    EditAdminModalComponent,
    EditAdminPasswordModalComponent,
    OrgUserListComponent,
    OrgOrgUserListComponent,
    AppUserListComponent,
    CrawlerOverviewComponent,
    CreateOrgModalComponent,
    EditOrgUserModalComponent,
    EditOrgUserPasswordModalComponent,
    DisplaydurationPipe,
    PostListComponent,
    SettingsComponent,
    NumberCardComponent,
    OrgUserViewComponent,
    OrgOrgUserViewComponent,
    CreateOrgAttachmentModalComponent,
    EditOrgAttachmentModalComponent,
    FilesizePipe,
    OrgDashboardComponent,
    ForbiddenComponent,
    UploadOrgUserAvatarModalComponent,
    CreateConversationNoteModalComponent,
    EditConversationNoteModalComponent,
    OrgOrgProfileComponent,
    OrgUserProfileComponent,
    OrgSettingsComponent,
    UploadOrgBackgroundimageModalComponent,
    ProfilePreviewComponent,
    RegisterLinkComponent,
    PWResetLinkComponent,
    PWResetComponent,
    CreatePostModalComponent,
    EditPostModalComponent,
    OrgPostListComponent,
    OrganizationListComponent,
    GroupsListComponent,
    CreateGroupModalComponent,
    OrgGroupListComponent,
    OrgViewComponent,
    EditOrgModalComponent,
    OrgOrgProfileComponent,
    UploadOrgAvatarModalComponent,
    FmtOrgTypePipe,
    AddOrgLinkModalComponent,
    EditGroupModalComponent,
    CreateOrguserModalComponent,
    EventDetailsComponent,
    EventsListComponent,
    EventPostListComponent,
    OrgEventListComponent,
    SearchBarComponent,
    ListEntryGroupComponent,
    ListEntryPostComponent,
    ListEntryEventComponent,
    ListEntryOrguserComponent,
    ListEntryImpressComponent,
    SolseitConfigComponent,
    CheckPermPipe,
    SingleLinePipe,
    EditCrawlerModalComponent,
    CrawlerExecutionListComponent,
    CrawlerLogListComponent,
    AppUserViewComponent,
    CommunityListComponent,
    CreateEventModalComponent,
    CreateCommunityModalComponent,
    CreateCrawlerModalComponent,
    ProfilePreviewSmartphoneComponent,
    AddImpressumModalComponent,
    EditImpressumModalComponent,
    ReportListComponent,
    AddWasteEntryModalComponent,
    ManageWasteCalendarStreetsModalComponent,
    EditOpeningHoursModalComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NzLayoutModule,
    NzMenuModule,
    IconsProviderModule,
    NzIconModule,
    NzCardModule,
    NzPageHeaderModule,
    NzInputModule,
    NzAlertModule,
    NzButtonModule,
    FontAwesomeModule,
    NzSpinModule,
    NzTableModule,
    NzPipesModule,
    NzModalModule,
    NzToolTipModule,
    NzGridModule,
    NzCheckboxModule,
    NzAvatarModule,
    NzDividerModule,
    NzUploadModule,
    NzTagModule,
    NzEmptyModule,
    ImageCropperModule,
    NzSelectModule,
    NzDatePickerModule,
    NzTimePickerModule,
    NzImageModule,
    NzSegmentedModule,
    NzImageModule,
    NzSkeletonModule,
    NgOptimizedImage,
    LayoutModule,
    NzAutocompleteModule,
    NzSwitchModule,
    ReactiveFormsModule,
    NzInputNumberModule,
    NzFormModule,
    NzTreeModule,
    NzPopconfirmModule,
    NzListComponent,
    NzListItemComponent,
    NzRadioModule,
  ],
  providers: [
    {provide: NZ_I18N, useValue: de_DE},
    NzMessageService,
    NzNotificationService,
  ],
  exports: [
    ValidateableInputComponent
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
