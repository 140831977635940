import { Component } from '@angular/core';
import {SolseitCommunity, SolseitMapping, SolseitRubric} from "../../interfaces/solseit";
import {SerializedParamCollection} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {ObjectId} from "../../interfaces/utils";
import * as uuid from 'uuid';
import {Organization} from "../../interfaces/organization";
import {Group} from "../../interfaces/group";

interface SolseitExtMapping {
  uuid: string;
  localData:  SolseitExtMappingDataLocal;
  serverData: SolseitExtMappingDataServer;
}

interface SolseitExtMappingData {
  id: ObjectId|'',

  solseitCommunityID: number|null,
  solseitRubricID: number|null,
  badennetOrg: ObjectId|null,
  badennetGroup: ObjectId|'@direct'|null,
}

type SolseitExtMappingDataLocal = {
  id: ObjectId|'',

  solseitCommunityID: number|null,
  solseitRubricID: number|null,
  badennetOrg: ObjectId|null,
  badennetGroup: ObjectId|'@direct'|null,
}

type SolseitExtMappingDataServer = {
  id: ObjectId|'',

  solseitCommunityID: number|null,
  solseitRubricID: number|null,
  badennetOrg: ObjectId|null,
  badennetGroup: ObjectId|'@direct'|null,

  isValidSolseitCommunityID: boolean;
  isValidSolseitRubricID: boolean;
}

interface FlatSolseitRubric {
  community: SolseitCommunity,
  referenceID: number,
  name: string,
  fullName: string,
}

function mappingToAPI(p: SolseitExtMappingData, communities: SolseitCommunity[], rubrics: FlatSolseitRubric[]): SolseitMapping {
  return (
    {
      id: p.id,
      badennetOrg: p.badennetOrg!,
      badennetGroup: ((p.badennetGroup === '@direct') ? null : p.badennetGroup) as ObjectId,
      solseitCommunityName: communities.find(q => q.referenceID === p.solseitCommunityID)?.name ?? '',
      solseitRubricName: rubrics.find(q => q.referenceID === p.solseitRubricID)?.name ?? '',
      solseitRubricID: p.solseitRubricID!,
      solseitCommunityID: p.solseitCommunityID!,
    });
}

function mappingFromAPI(mapping: SolseitMapping, communities: SolseitCommunity[]|null, rubrics: FlatSolseitRubric[]|null): SolseitExtMappingDataServer {
  return  (
    {
      ...mapping,
      badennetGroup:             ((mapping.badennetGroup===null) ? '@direct' : mapping.badennetGroup) as ('@direct'|ObjectId),
      isValidSolseitCommunityID: communities === null || (communities.find(p => p.referenceID === mapping.solseitCommunityID) !== undefined),
      isValidSolseitRubricID:    rubrics === null     || (rubrics.find(p => p.referenceID === mapping.solseitRubricID && p.community.referenceID === mapping.solseitCommunityID) !== undefined),
    });
}

@Component({
  selector: 'app-solseit-config',
  templateUrl: './solseit-config.component.html',
  styleUrls: ['./solseit-config.component.scss']
})
export class SolseitConfigComponent {

  config: SolseitExtMapping[] = [];
  communities: SolseitCommunity[] = [];
  rubrics: FlatSolseitRubric[] = [];
  allOrgs: Organization[] = [];
  allGroups: Group[] = [];

  communityRubrics: Map<number, FlatSolseitRubric[]> = new Map<number, FlatSolseitRubric[]>();
  orgGroups: Map<ObjectId, Group[]> = new Map<ObjectId, Group[]>();

  communityMapping: Map<number, string>   = new Map<number, string>();
  rubricMapping:    Map<number, string>   = new Map<number, string>();
  orgMapping:       Map<ObjectId, string> = new Map<ObjectId, string>();
  groupMapping:     Map<ObjectId, string> = new Map<ObjectId, string>();

  loading: boolean = true;
  sortLoading: boolean = false;

  filters: SerializedParamCollection = {};

  deleteLoading: Set<string> = new Set<ObjectId>();
  saveLoading: Set<string> = new Set<ObjectId>();

  editingMappings: Set<string> = new Set<string>();

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    await this.fetchData();
  }

  async fetchData() {
    try {
      this.loading = true;

      const data1 = await this.api.listOrganizations("@start", null);

      const data2 = await this.api.listGroups("@start", 999999); //TODO Pgaesize

      const data4 = await this.api.listSolseitCommunities();

      const data5 = this.convertToRubrics(data4.communities);

      const data3 = await this.api.listSolseitConfig();
      const data3Conv = data3.mappings
        .map(p => ({
          uuid:uuid.v4(),
          serverData: mappingFromAPI(p, data4.communities, data5),
          localData: mappingFromAPI(p, null, null),
        }));

      this.communityMapping = new Map<number,   string>(data4.communities.map(p => ([p.referenceID, p.name])));
      this.rubricMapping    = new Map<number,   string>(data5.map(p => ([p.referenceID, p.fullName])));
      this.orgMapping       = new Map<ObjectId, string>(data1.orgs.map(p => ([p.id, p.name])));
      this.groupMapping     = new Map<ObjectId, string>(data2.groups.map(p => ([p.id, p.title])));

      this.communityRubrics = new Map<number, FlatSolseitRubric[]>(data4.communities.map(p => ([p.referenceID, data5.filter(q => q.community.referenceID === p.referenceID)])));
      this.orgGroups        = new Map<ObjectId, Group[]>(data1.orgs.map(p => ([p.id, data2.groups.filter(q => q.organizationID === p.id)])));

      this.allOrgs     = data1.orgs;
      this.allGroups   = data2.groups;
      this.config      = data3Conv;
      this.communities = data4.communities;
      this.rubrics     = data5;

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async saveEntry(c: SolseitExtMapping, idx: number) {

    let beforeID: ObjectId|null = null;
    for (let i = idx-1; i >= 0; i--) {
      const servid = this.config[i].serverData.id
      if (servid !== '') {
        beforeID = servid;
        break;
      }
    }

    try {
      this.saveLoading.add(c.uuid);

      let data: SolseitMapping;

      if (c.serverData.id !== '') {
        data = await this.api.updateSolseitConfig(c.serverData.id, mappingToAPI(c.localData, this.communities, this.rubrics));
      } else {
        data = await this.api.createSolseitConfig(mappingToAPI(c.localData, this.communities, this.rubrics), beforeID);
      }

      this.config = this.config.map(p => p.uuid !== c.uuid ? p : ({
        uuid: c.uuid,
        serverData: mappingFromAPI(data, this.communities, this.rubrics),
        localData: mappingFromAPI(data, null, null),
      }));

      this.editingMappings.delete(c.uuid);

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht aktualisiert werden', err)
    } finally {
      this.saveLoading.delete(c.uuid);
    }
  }

  async moveEntryUp(c: SolseitExtMapping, idx: number) {
    try {
      this.saveLoading.add(c.uuid);

      if (c.serverData.id === '') return;

      if (idx <= 0) return;
      if (idx === 1) {
        await this.moveEntryDown(this.config[0], 0);
        return;
      }

      let before2ID: ObjectId|null = null;
      const servid = this.config[idx-2].serverData.id
      if (servid !== '') {
        before2ID = servid;
      }

      if (before2ID !== null) {
        await this.api.moveSolseitConfig(c.serverData.id, before2ID);
      }

      let v = [...this.config];

      let tmp = v[idx-1];
      v[idx-1] = v[idx];
      v[idx] = tmp;

      this.config = [ ...v.map(p => structuredClone(p)) ];

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht verschoben werden', err)
    } finally {
      this.saveLoading.delete(c.uuid);
    }
  }

  async moveEntryDown(c: SolseitExtMapping, idx: number) {
    try {
      this.saveLoading.add(c.uuid);

      if (c.serverData.id === '') return;

      if (idx >= this.config.length-1) return;

      let nextID: ObjectId|null = null;
      const servid = this.config[idx+1].serverData.id
      if (servid !== '') {
        nextID = servid;
      }

      if (nextID !== null) {
        await this.api.moveSolseitConfig(c.serverData.id, nextID);
      }

      let v = [...this.config];

      let tmp = v[idx+1];
      v[idx+1] = v[idx];
      v[idx] = tmp;

      this.config = [ ...v.map(p => structuredClone(p)) ];

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht verschoben werden', err)
    } finally {
      this.saveLoading.delete(c.uuid);
    }
  }

  async deleteEntry(c: SolseitExtMapping) {
    try {
      this.deleteLoading.add(c.uuid);

      if (c.serverData.id !== '') {
        await this.api.deleteSolseitConfig(c.serverData.id);
      } else {
        // nothing to do, wasn't added on the server anyway
      }

      this.config = this.config.filter(p => p.uuid !== c.uuid);

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht gelöscht werden', err)
    } finally {
      this.saveLoading.delete(c.uuid);
    }
  }

  addEntry(c: SolseitExtMapping|null, idx: number) {

    const newEntry: SolseitExtMapping = {
      'uuid': uuid.v4(),
      'serverData': {
        'id': '',
        'solseitCommunityID': null,
        'solseitRubricID': null,
        'badennetOrg': null,
        'badennetGroup': null,
        'isValidSolseitCommunityID': true,
        'isValidSolseitRubricID': true,
      },
      'localData': {
        'id': '',
        'solseitCommunityID': c?.localData?.solseitCommunityID ?? null,
        'solseitRubricID': null,
        'badennetOrg': c?.localData?.badennetOrg ?? null,
        'badennetGroup': null,
      },
    };

    this.config = [ ...this.config.slice(0, idx+1), newEntry, ...this.config.slice(idx+1) ];

    this.editingMappings.add(newEntry.uuid);
  }

  canSave(c: SolseitExtMapping) {
    if ((c.localData.badennetGroup   ?? '') === '') return false;
    if ((c.localData.badennetOrg     ?? '') === '') return false;

    if ((c.localData.solseitCommunityID ?? 0) === 0) return false;
    if ((c.localData.solseitRubricID    ?? 0) === 0) return false;

    return true;
  }

  localEqualsServer(c: SolseitExtMapping) {
    const a = JSON.stringify([c.localData.id,  c.localData.solseitCommunityID,  c.localData.solseitRubricID,  c.localData.badennetOrg,  c.localData.badennetGroup ]);
    const b = JSON.stringify([c.serverData.id, c.serverData.solseitCommunityID, c.serverData.solseitRubricID, c.serverData.badennetOrg, c.serverData.badennetGroup]);
    return a === b;
  }

  hasChanged(c: SolseitExtMapping) {
    if (this.localEqualsServer(c)) return false;

    if ((c.localData.badennetGroup   ?? '') === '') return false;
    if ((c.localData.badennetOrg     ?? '') === '') return false;

    if ((c.localData.solseitCommunityID ?? 0) === 0) return false;
    if ((c.localData.solseitRubricID    ?? 0) === 0) return false;

    return true;
  }

  convertToRubrics(communities: SolseitCommunity[]): FlatSolseitRubric[] {
    let r: FlatSolseitRubric[] = [];

    let stack: {community: SolseitCommunity, parentPath: string[], elem: SolseitRubric}[] = []

    for (const c of communities) {
      for (const r of c.rubrics) stack.push({community: c, parentPath: [], elem: r});
    }

    while (stack.length > 0) {
      const value = stack.pop()!;
      r.push({community: value.community, fullName: [...value.parentPath, value.elem.name].join(' / '), name: value.elem.name, referenceID: value.elem.referenceID});
      for (const r of value!.elem.rubrics) stack.push({community: value.community, parentPath: [...value.parentPath, value.elem.name], elem: r});
    }

    return r
  }

  mappingExists(p: FlatSolseitRubric) {
    return this.config.find(e => e.localData.solseitCommunityID === p.community.referenceID && e.localData.solseitRubricID === p.referenceID) !== undefined;
  }

  isDuplicate(c: SolseitExtMapping) {
    if (c.localData.solseitRubricID == null) return false;
    if (c.localData.solseitCommunityID == null) return false;
    return this.config.find(e => e.uuid !== c.uuid && e.localData.solseitCommunityID === c.localData.solseitCommunityID && e.localData.solseitRubricID === c.localData.solseitRubricID) !== undefined;
  }

  autoAddMissingEntries() {
    const activeCommunities = [...new Set(this.config.map(p => p.localData.solseitCommunityID).filter(p => p != null))];

    const newEntries: SolseitExtMapping[] = []

    for (const communityID of activeCommunities) {

      const defaultOrgs = [...new Set(this.config.filter(c => c.localData.solseitCommunityID === communityID).map(p => p.localData.badennetOrg).filter(p => p !== null))];

      for (const rubric of this.rubrics.filter(r => r.community.referenceID === communityID)) {
        if (!this.config.find(c => c.localData.solseitCommunityID === communityID && c.localData.solseitRubricID === rubric.referenceID)) {
          newEntries.push({
            'uuid': uuid.v4(),
            'serverData': {
              'id': '',
              'solseitCommunityID': null,
              'solseitRubricID': null,
              'badennetOrg': null,
              'badennetGroup': null,
              'isValidSolseitCommunityID': true,
              'isValidSolseitRubricID': true,
            },
            'localData': {
              'id': '',
              'solseitCommunityID': communityID,
              'solseitRubricID': rubric.referenceID,
              'badennetOrg': defaultOrgs.length === 1 ? defaultOrgs[0] : null,
              'badennetGroup': null,
            },
          })
        }
      }
    }

    this.config = [ ...this.config, ...newEntries ];

    for (const e of newEntries) this.editingMappings.add(e.uuid);

    this.notification.info("Einträge hinzugefügt", `${newEntries.length} Einträge hinzugefügt`);
  }

  async autoSortEntries() {

    for (const m of this.config) {
      if (!this.localEqualsServer(m)) {
        this.notification.warning("Warnung", "Es existieren ungespeicherte Einträge");
        return;
      }
    }

    const sortedMappings = this.config.sort((a, b) =>
    {
      const sa = `${this.communityMapping.get(a.serverData.solseitCommunityID!) ?? a.serverData.solseitCommunityID}|${this.rubricMapping.get(a.localData.solseitRubricID!) ?? a.localData.solseitRubricID}`;
      const sb = `${this.communityMapping.get(b.serverData.solseitCommunityID!) ?? b.serverData.solseitCommunityID}|${this.rubricMapping.get(b.localData.solseitRubricID!) ?? b.localData.solseitRubricID}`;
      return sa.localeCompare(sb);
    });

    this.config = sortedMappings;

    try {
      this.sortLoading = true;

      let beforeID: ObjectId|null = null;
      for (const m of sortedMappings) {

        if (m.serverData.id === '') {
          this.notification.warning("Warnung", "Eintrag " + m.uuid + " existiert nicht");
          return;
        }

        this.saveLoading.add(m.uuid);
        await this.api.moveSolseitConfig(m.serverData.id, beforeID);
        beforeID = m.serverData.id;
        this.saveLoading.delete(m.uuid);
      }

      await this.fetchData();

    } catch (err) {
      showAPIError(this.notification, 'Config konnten nicht sortiert werden', err)
    } finally {
      this.sortLoading = false;
    }

  }
}
