import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff} from "../../utils/angular";
import {formatAPIError, showAPIError} from "../../utils/api";
import {Group} from "../../interfaces/group";
import {ImageCropperComponent} from "ngx-image-cropper";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {environment} from "../../../environments/environment";
import {ObjectId} from "../../interfaces/utils";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-edit-group-modal',
  templateUrl: './edit-group-modal.component.html',
  styleUrls: ['./edit-group-modal.component.scss']
})
export class EditGroupModalComponent implements OnChanges, OnInit {

  @Input() show: Group|null = null;
  @Output() showChange: EventEmitter<Group|null> = new EventEmitter<Group|null>();
  @Output() success: EventEmitter<Group> = new EventEmitter<Group>();

  loading: boolean = false;

  title: string = '';

  serverAvatar:          ObjectId|null = null;
  serverBackgroundImage: ObjectId|null = null;

  avatar:          ObjectId|null|'deleted' = null;
  backgroundImage: ObjectId|null|'deleted' = null;

  avatarMode:     'hidden'|'cropper'|'view' = 'hidden';
  backgroundMode: 'hidden'|'cropper'|'view' = 'hidden';

  @Input() avatarMinWidth: number = 250;
  @Input() avatarMinHeight: number = 250;

  @Input() backgroundImageMinWidth: number = 823;
  @Input() backgroundImageMinHeight: number = 360;

  private imageCropperAvatar: ImageCropperComponent|null = null;
  @ViewChild('imageCropperAvatar') set contentAvatar(content: ImageCropperComponent) { if(content) { this.imageCropperAvatar = content; } }

  private imageCropperBackground: ImageCropperComponent|null = null;
  @ViewChild('imageCropperBackground') set contentBackground(content: ImageCropperComponent) { if(content) { this.imageCropperBackground = content; } }

  fileAvatar: File|null = null;
  fileBackground: File|null = null;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.title                 = this.show.title;
        this.serverAvatar          = this.show.avatarImageID;
        this.serverBackgroundImage = this.show.backgroundImageID;
        this.avatar                = this.show.avatarImageID;
        this.backgroundImage       = this.show.backgroundImageID;
        this.avatarMode            = 'hidden';
        this.backgroundMode        = 'hidden';
        this.fileAvatar            = null;
        this.fileBackground        = null;
      }
      return;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.title                 = '';
    this.avatar                = null;
    this.backgroundImage       = null;
    this.avatarMode            = 'hidden';
    this.backgroundMode        = 'hidden';
    this.fileAvatar            = null;
    this.fileBackground        = null;
  }

  async execute() {
    if (!this.isValidStr(this.title)) { this.notification.error('Fehler', 'Bitte valider Name eingeben'); return; }

    try {
      this.loading = true;

      let avatarBase64:string|null = null;
      let bgImageBase64:string|null = null;

      if (this.avatarMode === 'cropper') {

        if(this.imageCropperAvatar === null) {
          this.notification.warning('Fehlendes Bild', `Bitte Gruppenbild hochladen`);
          return;
        }
        const croppedAvatar = this.imageCropperAvatar.crop();
        if (croppedAvatar === null || !croppedAvatar.base64) {
          this.notification.warning('Fehlendes Bild', `Bitte Gruppenbild hochladen`);
          return;
        }
        avatarBase64 = croppedAvatar.base64.split(',').pop()!;

        if (croppedAvatar.width < this.avatarMinWidth || croppedAvatar.height < this.avatarMinHeight) {
          this.notification.warning('Bild zu klein', `Das Gruppenbild muss mindestens ${this.avatarMinWidth}x${this.avatarMinHeight} pixel groß sein`);
          return;
        }
      }

      if (this.backgroundMode === 'cropper') {

        if(this.imageCropperBackground === null) {
          this.notification.warning('Fehlendes Bild', `Bitte Hintergrundbild hochladen`);
          return;
        }
        const croppedBackground = this.imageCropperBackground.crop();
        if (croppedBackground === null || !croppedBackground.base64) {
          this.notification.warning('Fehlendes Bild', `Bitte Hintergrundbild hochladen`);
          return;
        }
        bgImageBase64 = croppedBackground.base64.split(',').pop()!;

        if (croppedBackground.width < this.backgroundImageMinWidth || croppedBackground.height < this.backgroundImageMinHeight) {
          this.notification.warning('Bild zu klein', `Das Hintergrundbild muss mindestens ${this.backgroundImageMinWidth}x${this.backgroundImageMinHeight} pixel groß sein`);
          return;
        }
      }

      if (avatarBase64 === null && this.avatar === 'deleted') {
        await this.api.deleteGroupAvatar(this.show!.organizationID, this.show!.id);
      }

      if (bgImageBase64 === null && this.backgroundImage === 'deleted') {
        await this.api.deleteGroupBackgroundImage(this.show!.organizationID, this.show!.id);
      }

      const data = await this.api.updateGroup(
        this.show!.organizationID,
        this.show!.id,
        this.title,
        (avatarBase64  === null) ? null : [avatarBase64,  'image/png'],
        (bgImageBase64 === null) ? null : [bgImageBase64, 'image/png'],
      );

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Gruppe konnte nicht aktualisiert werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  beforeUploadAvatar = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.fileAvatar = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.fileAvatar = null;
    }
    return false;
  }

  beforeUploadBackground = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.fileBackground = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.fileBackground = null;
    }
    return false;
  }

  loadImageFailed() {
    this.notification.error('Fehler', 'Datei konnte nicht geladen werden');
  }

  imageSource(blobid: ObjectId) {
    if (this.show === null) return '';

    return `${environment.apiBaseUrl}organizations/${this.show.organizationID}/blobs/${blobid}/image?xx-bearer-token=@${this.auth.getToken()}`;
  }

  deleteAvatar() {
    this.avatar = (this.serverAvatar === null) ? null : 'deleted';
    this.fileAvatar = null;
  }

  deleteBackgroundImage() {
    this.backgroundImage = (this.serverBackgroundImage === null) ? null : 'deleted';
    this.fileBackground = null;
  }
}
