
<ng-template  #providerSelector>
  <nz-form-item>
    <nz-form-label>ContentProvider</nz-form-label>
    <nz-form-control>
      <input nz-input readonly disabled class="value" [ngModel]="translateOrg(serverData?.organizationID) ?? '???'">
    </nz-form-control>
  </nz-form-item>
  <span class="key"></span>
</ng-template>

<ng-template  #destinationSelector>

  <nz-form-item>
    <nz-form-label>Ziel</nz-form-label>
    <nz-form-control>
      <input nz-input readonly disabled *ngIf="this.serverData?.parent?.type === 'ROOT'"  class="value" [ngModel]="'Direkt'">

      <input nz-input readonly disabled *ngIf="this.serverData?.parent?.type === 'EVENT'" class="value" [ngModel]="'Event: ' + (translateEvent(this.serverData?.parent?.reference) ?? '???')">

      <input nz-input readonly disabled *ngIf="this.serverData?.parent?.type === 'GROUP'" class="value" [ngModel]="'Gruppe: ' + (translateGroup(this.serverData?.parent?.reference) ?? '???')">

    </nz-form-control>
  </nz-form-item>

</ng-template>


<nz-modal [nzVisible]="postID !== null"
          nzTitle="Post bearbeiten"
          nzOkText="Speichern"
          [nzOkLoading]="execLoading"
          (nzOnOk)="execute()"
          (nzOnCancel)="cancel()"
          [nzWidth]="768"
          [nzOkDisabled]="!validateForm.valid">
  <ng-container *nzModalContent>

    <ng-container *ngIf="initLoading">

      <nz-spin></nz-spin>

    </ng-container>

    <form *ngIf="!initLoading" nz-form [nzLayout]="'vertical'" class="edit-post-form" [formGroup]="validateForm">

      <ng-container *ngTemplateOutlet="providerSelector"></ng-container>

      <ng-container *ngTemplateOutlet="destinationSelector"></ng-container>

      <nz-form-item class="custom-input">
        <nz-form-label nzRequired>Titel</nz-form-label>
        <nz-form-control nzErrorTip="Please input your username!">
          <input nz-input formControlName="title" placeholder="Titel" required/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Beschreibung</nz-form-label>
        <nz-form-control nzErrorTip="Please input your username!">
          <textarea nz-input formControlName="text" ></textarea>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-label>URL</nz-form-label>
        <nz-form-control >
          <nz-input-group [nzSuffix]="suffixIconLoad">
            <input type="text" nz-input formControlName="url" placeholder="Externe URL" />
          </nz-input-group>
          <ng-template #suffixIconLoad>
            <button nz-button (click)="queryData()" [nzLoading]="status === 'loading'">
              <span nz-icon nzType="cloud-download"></span>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="custom-group-input">
        <nz-form-label>Image URL</nz-form-label>
        <nz-form-control >
          <nz-input-group [nzPrefix]="prefixImage">
            <input type="text" nz-input formControlName="imageURL" placeholder="Thumbnail URL" />
          </nz-input-group>
          <ng-template #prefixImage>
            <ng-container *ngIf="validateForm.get('imageURL')?.value">
              <img nz-image [nzSrc]="validateForm.get('imageURL')?.value" width="50" height="28" style="cursor: pointer">
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-label>Bilder</nz-form-label>
        <nz-form-control>
          <nz-upload nzListType="picture-card"
                     id="imageSelection"
                     [(nzFileList)]="imageList"
                     [nzShowButton]="true"
                     [nzPreview]="handleGalleryPreview"
                     [nzDownload]="undefined"
                     [nzMultiple]="true"
                     nzFileType="image/png,image/jpeg,image/gif,image/bmp"
                     [nzShowUploadList]="{showDownloadIcon: false, showPreviewIcon:true, showRemoveIcon:true}"
                     [nzCustomRequest]="galleryAction">
            <div>
              <span nz-icon nzType="plus"></span>
              Auswählen
            </div>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Datei</nz-form-label>
        <nz-form-control>
          <nz-upload  [nzMultiple]="false"
                      nzListType="picture"
                      [nzBeforeUpload]="beforeUploadFilePostFile">
            <button nz-button>
              <span nz-icon [nzType]="'file'"></span>
              Datei auswählen
            </button>
          </nz-upload>

          <p>{{ getFileName() }}</p>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="margin-top: 2rem;">
        <nz-form-label nzRequired>Veröffentlichen</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="publish" nzButtonStyle="solid">
            <label nz-radio-button nzValue="INACTIVE">Später manuell veröffentlichen</label>
            <label nz-radio-button nzValue="ACTIVE">Sofort veröffentlichen</label>
            <label nz-radio-button nzValue="TIMESTAMP">Geplante Veröffentlichung</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="validateForm.get('publish')?.value === 'TIMESTAMP'" class="custom-input">
        <nz-form-label nzRequired>Veröffentlichen am:</nz-form-label>
        <nz-form-control>
          <nz-date-picker [nzAllowClear]="true"
                          formControlName="timestampPublishDate"
                          nzShowTime
                          nzShowToday="true"
                          nzPlaceHolder="Datum und Uhrzeit auswählen"
                          style="width: 100%"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>


    </form>
    <!--

    <div class="content" *ngIf="!initLoading">

      <ng-container *ngTemplateOutlet="providerSelector"></ng-container>

      <ng-container *ngTemplateOutlet="destinationSelector"></ng-container>

      <span class="key">URL</span>
      <app-validateable-input class="value-extra" placeholder="Link" [(value)]="url" [validate]="isValidLink"></app-validateable-input>
      <fa-icon-btn class="extra1" nzType="primary" buttonSize="32" icon="cloud-arrow-down"      (btnClick)="queryLinkData()"       [loading]="status === 'loading'" [disabled]="!isValidLink(url)"></fa-icon-btn>
      <fa-icon-btn class="extra2" nzType="primary" buttonSize="32" icon="video-arrow-down-left" (btnClick)="queryVideoData()"      [loading]="status === 'loading'" [disabled]="!isValidVideoLink(url)"></fa-icon-btn>
      <fa-icon-btn class="extra3" nzType="primary" buttonSize="32" icon="wrench-simple"         (btnClick)="manuallySetStatusActive()"                              [disabled]="status !== 'initial'"></fa-icon-btn>

      <img nz-image class="fullspan linkpost-image" alt="preview image" src="" [nzSrc]="str2Null(imageURL) ?? firstImageSrc(imageList) ?? fallbackImage">
      <app-validateable-input class="fullspan" placeholder="URL (Thumbnail)" [(value)]="imageURL" [validate]="isValidLink" [disabled]="status !== 'active'"></app-validateable-input>

      <span class="key">Titel</span>
      <app-validateable-input class="value" placeholder="Titel" [(value)]="title" [validate]="isValidStr" [disabled]="status !== 'active'"></app-validateable-input>

      <span class="key">Text</span>
      <app-validateable-textarea class="value" [rows]="5" [(value)]="text" [disabled]="status !== 'active'" [validate]="isValidStr"></app-validateable-textarea>

      <span class="key">Images</span>
      <div class="fullspan gallery-box">
        <nz-upload nzListType="picture-card"
                   [(nzFileList)]="imageList"
                   [nzShowButton]="true"
                   [nzPreview]="handleGalleryPreview"
                   [nzDownload]="undefined"
                   nzFileType="image/png,image/jpeg,image/gif,image/bmp"
                   [nzShowUploadList]="{showDownloadIcon: false, showPreviewIcon:true, showRemoveIcon:true}"
                   [nzCustomRequest]="galleryAction">
          <div>
            <span nz-icon nzType="plus"></span>
            <div style="margin-top: 8px">Upload</div>
          </div>
        </nz-upload>
        <nz-modal
          [nzVisible]="preview != null"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="preview = null">
          <ng-template #modalContent>
            <img [src]="preview" [ngStyle]="{ width: '100%' }"  alt="image"/>
          </ng-template>
        </nz-modal>
      </div>

      <span class="key">File</span>
      <div class="fullspan filepost-box">

        <nz-upload nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadFilePostFile">
          <p class="ant-upload-drag-icon">
            <span nz-icon [nzType]="file === null ? 'upload' : 'file'"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe eine Datei hierher um sie hochzuladen</p>
          <p *ngIf="file !== null && !isString(file) && file.name !== undefined" class="ant-upload-hint">{{file.name}}</p>
          <p *ngIf="file !== null && isString(file)" class="ant-upload-hint">{{file}}</p>
        </nz-upload>
      </div>

      <ng-container *ngTemplateOutlet="timingSelector"></ng-container>

    </div>-->

  </ng-container>
</nz-modal>
