import { WeekDay } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { OpeningHours, WeekDayWithTime } from 'src/app/interfaces/opening-hours';
import { Organization } from 'src/app/interfaces/organization';
import { ObjectId } from 'src/app/interfaces/utils';
import { APIService } from 'src/app/services/api.service';
import * as moment from "moment";

@Component({
  selector: 'app-edit-opening-hours-modal',
  templateUrl: './edit-opening-hours-modal.component.html',
  styleUrl: './edit-opening-hours-modal.component.scss'
})
export class EditOpeningHoursModalComponent implements OnInit {


    @Input() show: boolean = false;
    @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();

    @Input() orgId: ObjectId|null = null;
    @Input() organization: Organization|null = null;

    okayLoading: boolean = false;
    newOpeningTime: WeekDayWithTime = {} as WeekDayWithTime;
    days: string[] = ['Montag', 'Dienstag', 'Mitwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
    // map days to WeekDay enum
    dayMap: Map<string, WeekDay> = new Map([
        [this.days[0], WeekDay.Monday],
        [this.days[1], WeekDay.Tuesday],
        [this.days[2], WeekDay.Wednesday],
        [this.days[3], WeekDay.Thursday],
        [this.days[4], WeekDay.Friday],
        [this.days[5], WeekDay.Saturday],
        [this.days[6], WeekDay.Sunday]
    ]);
    dayOptions = Array.from(this.dayMap.entries()).map(([label, value]) => ({ label, value }));

    newOpeningWeekDay: WeekDay = WeekDay.Monday;

    constructor(private api: APIService, private notification: NzNotificationService
    ) { }

    ngOnInit(): void {

        if(this.organization === null)
        {
            this.fetchOrganizationData();
        }
    }

    async fetchOrganizationData() {
        try {
            if(this.orgId === null) {
                this.notification.error("Error", "Keine Organisations ID übergeben");
                return
            }
            // fetch organization data from API
            this.organization = await this.api.getOrganization(this.orgId);

        } catch(err) {
            this.notification.error("Error", "Fehler beim Laden der Organisation");
        }
    }

    submitForm() {
        this.updateOpeningTime().then(() => this.close());
    }

    close() {
        this.show = false;
        this.showChange.emit(false);
        this.clear();
    }

    clear() {
        this.okayLoading = false;
    }

    async addOpeningTime() {
        if(this.orgId == null) return;
        if(this.organization == null) return;
        if(this.organization.openingHours == null) {
            this.organization.openingHours = {} as OpeningHours;
        }

        if(this.organization.openingHours.openTimes == undefined) {
            this.organization.openingHours.openTimes = [];
        }


        this.newOpeningTime.day = this.newOpeningWeekDay;
        this.organization.openingHours.openTimes.push(this.newOpeningTime);

        try{
            await this.api.updateOrganizationPartial(this.orgId, {openingHours: this.organization.openingHours});
            this.newOpeningTime = {} as WeekDayWithTime;
        }catch(err) {
            this.notification.error("Error", "Fehler beim Speichern der Öffnungszeiten");
        }
    }

    async removeOpeningTime(openingTime: WeekDayWithTime) {
        if(this.orgId== null) return;
        if(this.organization== null) return;
        if(this.organization.openingHours == null) return;
        if(this.organization.openingHours.openTimes === undefined) return;

        const index = this.organization.openingHours.openTimes.indexOf(openingTime);
        if(index > -1) {
            this.organization.openingHours.openTimes.splice(index, 1);
        }
    }

    async updateOpeningTime() {
        if(this.orgId== null) return;
        if(this.organization== null) return;
        if(this.organization.openingHours == null) return;
        if(this.organization.openingHours.openTimes === undefined) return;

        this.okayLoading = true;
        try{
            await this.api.updateOrganizationPartial(this.orgId, {openingHours: this.organization.openingHours});
            this.notification.success("Erfolg", "Öffnungszeiten gespeichert");
        }catch(err) {
            this.notification.error("Error", "Fehler beim Speichern der Öffnungszeiten");
        } finally {
          this.okayLoading = false;
        }
    }

  /**
   * Sort by day asc and time asc
   */
  sortedOpeningTimes = () => {
      const times = this.organization?.openingHours?.openTimes?.sort((a, b) => a.day - b.day);
      console.log('times', times)
      const t = {};
      times?.forEach(time => {
        // @ts-ignore
        if (t[time.day] === undefined) {
          // @ts-ignore
          t[time.day] = [];
        }
        // @ts-ignore
        t[time.day].push(time);
      });
      Object.keys(t).forEach(key => {
        // @ts-ignore
        t[key] = t[key].sort((a, b) => {
          return moment(a.open).diff(moment(b.open));
        });
      });

      return Object.values(t).flatMap(v => v) as WeekDayWithTime[];
  };

}
