import {Component, OnInit} from '@angular/core';
import {ObjectId} from "../../interfaces/utils";
import {Organization} from "../../interfaces/organization";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {AppUser} from "../../interfaces/applicationUser";

@Component({
  selector: 'app-appuser-view',
  templateUrl: './appuser-view.component.html',
  styleUrls: ['./appuser-view.component.scss']
})
export class AppUserViewComponent implements OnInit {
  loading = true;

  id!: ObjectId;
  user: AppUser|null = null;
  org: Organization|null = null;
  jwtModalText: string|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    await this.loadUser();
    if (!this.user === null) return;
  }

  async loadUser() {

    this.loading = true;

    try {
      this.user = await this.api.getAppUser(this.id);
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async createJWT() {
    try {
      const data = await this.api.getAppUserJWT(this.id);
      this.jwtModalText = data.token;
    } catch (err) {
      showAPIError(this.notification, 'JWT konnten nicht erstellt werden', err)
    }
  }

  async deleteAppUser() {
    this.notification.error('Not Implemented', 'Not Implemented');
  }

  async editAppUser() {
    this.notification.error('Not Implemented', 'Not Implemented');
  }
}
