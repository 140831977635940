import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectId} from "../../interfaces/utils";
import {Impressum} from "../../interfaces/impressum";
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {WasteEntry, WasteTypeEnum} from "../../interfaces/wasteEntry";
import * as moment from "moment";

@Component({
  selector: 'app-add-waste-entry-modal',
  templateUrl: './add-waste-entry-modal.component.html',
  styleUrl: './add-waste-entry-modal.component.scss'
})
export class AddWasteEntryModalComponent implements OnChanges{
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<WasteEntry> = new EventEmitter<WasteEntry>();

  @Input() month: string = '';
  @Input() forcedOrgId: ObjectId|null = null;

  validateForm: FormGroup = this.fb.group({
    wasteType: ['', [Validators.required]],
    date: [null, [Validators.required]],
    description: ['', []]
  });

  wasteTypes: Record<WasteTypeEnum, string> = {
    [WasteTypeEnum.WASTE]:       'Graue Tonne',
    [WasteTypeEnum.RECYCLING]:   'Gelber Sack',
    [WasteTypeEnum.PAPER]:       'Grüne Tonne',
    [WasteTypeEnum.BULKY_WASTE]: 'Sperrmüll',
    [WasteTypeEnum.COMPOST]:     'Strauchgutabfuhr',
    [WasteTypeEnum.HAZARDOUS]:   'Problemabfallsammeltermine',
  }

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private fb: NonNullableFormBuilder) {}

  initLoading: boolean = false;
  okayLoading: boolean = false;
  execLoading: boolean = false;

  ngOnChanges(changes: SimpleChanges) {
    const months = ['january', 'february', 'march', 'april', 'may', 'june', 'july', 'august', 'september', 'october', 'november', 'december'];
    // preselect the selected month
    const date = moment().set('month', months.findIndex(m => m === this.month));
    this.validateForm.controls['date'].setValue(date.toDate());
  }


  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {

    this.initLoading    = false;
    this.okayLoading    = false;

  }

  onChange(result: Date): void {
    console.log('onChange: ', result);
  }

  wasteTypeKeys(): WasteTypeEnum[] {
    return Object.keys(this.wasteTypes) as WasteTypeEnum[];
  }

  submitForm() {

    console.log('submit', this.validateForm.value);

    return;
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
      this.execute();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(innerControl => {
            innerControl.markAsDirty();
            innerControl.updateValueAndValidity({ onlySelf: true });
          });
        } else {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async execute(){

    try {
      this.execLoading = true;

        const data = await this.api.addImpress(
          this.forcedOrgId!,
          this.validateForm.value as Impressum
        )

        this.show = false;
        this.showChange.emit(false);
        this.clear();

        //this.success.emit({});
        this.notification.success('Eintrag erstellt', 'Eintrag erfolgreich erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Eintrag konnte nicht erstellt werden', err)
    } finally {
      this.execLoading = false;
    }
  }

  protected readonly WasteTypeEnum = WasteTypeEnum;
  protected readonly Object = Object;
}
