import { Injectable } from '@angular/core';
import {APIMethod, APIRequestMethod, APIServiceRef} from "../utils/apiMethod";
import {HttpClient} from "@angular/common/http";
import {AdminUser} from "../interfaces/adminUser";
import {AuthenticationService} from "./authentication.service";
import { showAPIError} from "../utils/api";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {UserType} from "../interfaces/userType";
import {OrganizationUser, OrgUserPermKey} from "../interfaces/organizationUser";
import {AppUser} from "../interfaces/applicationUser";
import {Crawler, CrawlerExecution, CrawlerImpl, CrawlerLog} from "../interfaces/crawler";
import {
  BlobDataOrgUserAttachment,
  BlobDataOrgUserAvatar,
  BlobDataOrgUserBackground,
  DataBlob
} from "../interfaces/datablobs";
import {RFC3339, Seconds} from "../interfaces/datetime";
import {ConversationNote} from "../interfaces/conversationNotes";
import {Post} from "../interfaces/post";
import {Organization, OrgType} from "../interfaces/organization";
import {Group, GroupSmall} from '../interfaces/group';
import { Event } from '../interfaces/event';
import {EnumMetaValue} from "../interfaces/enums";
import {SerializedParamCollection} from "../utils/serializedParameter";
import {CursorToken, JWTToken, ObjectId} from "../interfaces/utils";
import {SolseitCommunity, SolseitMapping} from "../interfaces/solseit";
import {DateUtils} from "../utils/date";
import {Community} from "../interfaces/community";
import {Impressum} from "../interfaces/impressum";
import {Report, ReportStatus} from "../interfaces/report";
import {Calendar, WasteCalendarStreet} from "../interfaces/wasteDistrict";

type Pagination = {nextPageToken: CursorToken, pageSize: number};

type AnyLoginResponse = {token: CursorToken, userType: UserType, organizationUser: OrganizationUser, user: AdminUser, applicationUser: AppUser};

type scrapeResponse = {
  metaTitle: string|null,
  metaDescription: string|null,
  metaImageURL: string|null,
  metaURL: string,
  scaperData: {key: string, value: string}[],
};

type CrawlerCreate = {
  name: string;
  enabled: boolean;
  implementation: string;
  interval: Seconds;
  retryInterval: Seconds;
  timeout: Seconds
  maxRetryCount: number;
  configuration: {[p: string]: string};
};

type CrawlerUpdate = Partial<{
  name: string;
  enabled: boolean;
  interval: Seconds;
  retryInterval: Seconds;
  timeout: Seconds
  maxRetryCount: number;
  configuration: {[p: string]: string};
}>;

type AttachmentBlob = DataBlob<BlobDataOrgUserAttachment>;
type AvatarBlob     = DataBlob<BlobDataOrgUserAvatar>;
type BackgroundBlob = DataBlob<BlobDataOrgUserBackground>;

type AnyUser = AdminUser|OrganizationUser;

@Injectable({ providedIn: 'root' })
export class APIService {

  private static ENDPOINTS = {

    adminTokenRefresh:         new APIMethod<{token: JWTToken, user: AdminUser}>                       (APIRequestMethod.POST,   'refresh-admin'      ),
    anyLogin:                  new APIMethod<AnyLoginResponse>                                         (APIRequestMethod.POST,   'login'              ),
    reqPasswordReset:          new APIMethod<{}>                                                       (APIRequestMethod.POST,   'reset-password'     ),

    finUserRegister:           new APIMethod<{token: JWTToken, user: AnyUser, userType: UserType}>     (APIRequestMethod.POST,   'anyusers/{id}/pwregister' ),
    finPasswordReset:          new APIMethod<{token: JWTToken, user: AnyUser, userType: UserType}>     (APIRequestMethod.POST,   'anyusers/{id}/pwreset'    ),
    getUsername:               new APIMethod<{username: string}>                                       (APIRequestMethod.GET,    'anyusers/{id}/username'    ),

    getAdmin:                  new APIMethod<AdminUser>                                                (APIRequestMethod.GET,    'admins/{id}'        ),
    deleteAdmin:               new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'admins/{id}'        ),
    updateAdmin:               new APIMethod<AdminUser>                                                (APIRequestMethod.PATCH,  'admins/{id}'        ),
    listAdmins:                new APIMethod<Pagination & {users:AdminUser[]}>                         (APIRequestMethod.GET,    'admins'             ),
    countAdmins:               new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'admins'             ),
    createAdmin:               new APIMethod<AdminUser>                                                (APIRequestMethod.POST,   'admins'             ),

    listAllOrgUsers:           new APIMethod<Pagination & {users:OrganizationUser[]}>                  (APIRequestMethod.GET,    'orgusers'                 ),
    createOrgUserWithOrg:      new APIMethod<{org:Organization, user:OrganizationUser}>                (APIRequestMethod.POST,   'orgusers'                 ),
    getOrgUserDirect:          new APIMethod<OrganizationUser>                                         (APIRequestMethod.GET,    'orgusers/{id}'            ),
    countAllOrgUser:           new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'orgusers'                 ),

    getAppUser:                new APIMethod<AppUser>                                                  (APIRequestMethod.GET,    'users/{id}'               ),
    getAppUserJWT:             new APIMethod<{token:JWTToken}>                                         (APIRequestMethod.POST,   'users/{id}/token'         ),
    listAppUsers:              new APIMethod<Pagination & {users:AppUser[]}>                           (APIRequestMethod.GET,    'users'                    ),
    countAppUsers:             new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'users'                    ),

    listCrawlers:              new APIMethod<Pagination & {crawlers:Crawler[]}>                        (APIRequestMethod.GET,    'crawler'                                    ),
    createCrawler:             new APIMethod<Crawler>                                                  (APIRequestMethod.POST,   'crawler'                                    ),
    countCrawlers:             new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'crawler'                                    ),
    getCrawler:                new APIMethod<Crawler>                                                  (APIRequestMethod.GET,    'crawler/{crwid}'                            ),
    updateCrawler:             new APIMethod<Crawler>                                                  (APIRequestMethod.PATCH,  'crawler/{crwid}'                            ),
    enableCrawler:             new APIMethod<Crawler>                                                  (APIRequestMethod.POST,   'crawler/{crwid}/enable'                     ),
    disableCrawler:            new APIMethod<Crawler>                                                  (APIRequestMethod.POST,   'crawler/{crwid}/disable'                    ),
    deleteCrawler:             new APIMethod<Crawler>                                                  (APIRequestMethod.DELETE, 'crawler/{crwid}'                            ),
    runCrawlerNow:             new APIMethod<Crawler>                                                  (APIRequestMethod.POST,   'crawler/{crwid}/run-now'                    ),
    listCrawlerExecutions:     new APIMethod<Pagination & {executions:CrawlerExecution[]}>             (APIRequestMethod.GET,    'crawler/{crwid}/executions'                 ),
    listCrawlerLogs:           new APIMethod<{logs:CrawlerLog[]}>                                      (APIRequestMethod.GET,    'crawler/{crwid}/executions/{execid}/logs'   ),

    listCrawlerImpl:           new APIMethod<{implementations:CrawlerImpl[]}>                          (APIRequestMethod.GET,    'crawler-impl'               ),
    listCrawlerImplKeys:       new APIMethod<{configKeys:string[]}>                                    (APIRequestMethod.GET,    'crawler-impl/{impl}/config' ),

    listSolseitConfig:         new APIMethod<{mappings:SolseitMapping[]}>                              (APIRequestMethod.GET,    'crawler/solseit/config'       ),
    deleteSolseitConfig:       new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'crawler/solseit/config/{sid}' ),
    createSolseitConfig:       new APIMethod<SolseitMapping>                                           (APIRequestMethod.POST,   'crawler/solseit/config'       ),
    updateSolseitConfig:       new APIMethod<SolseitMapping>                                           (APIRequestMethod.PATCH,  'crawler/solseit/config/{sid}' ),
    listSolseitCommunities:    new APIMethod<{communities:SolseitCommunity[]}>                         (APIRequestMethod.GET,    'crawler/solseit/communities'  ),

    scrapeLinkPost:            new APIMethod<scrapeResponse>                                           (APIRequestMethod.POST,   'scraper/linkpost'   ),
    scrapeVideoPost:           new APIMethod<scrapeResponse>                                           (APIRequestMethod.POST,   'scraper/videopost'  ),

    listOrganizations:         new APIMethod<Pagination & {orgs:Organization[]}>                       (APIRequestMethod.GET,    'organizations'                              ),
    countOrgs:                 new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'organizations'                              ),
    updateOrganization:        new APIMethod<Organization>                                             (APIRequestMethod.PATCH,  'organizations/{orgid}'                      ),
    getOrganization:           new APIMethod<Organization>                                             (APIRequestMethod.GET,    'organizations/{orgid}'                      ),
    deleteOrganization:        new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}'                      ),
    publishOrg:                new APIMethod<Organization>                                             (APIRequestMethod.POST,   'organizations/{orgid}/publish'              ),
    enableOrganization:        new APIMethod<Organization>                                             (APIRequestMethod.POST,   'organizations/{orgid}/enable'               ),
    disableOrganization:       new APIMethod<Organization>                                             (APIRequestMethod.POST,   'organizations/{orgid}/disable'              ),
    updateOrgLinks:            new APIMethod<Organization>                                             (APIRequestMethod.PATCH,  'organizations/{orgid}/linkedOrganizations'  ),
    guessCustomerNumber:       new APIMethod<{next:string}>                                            (APIRequestMethod.GET,    'organizations/nextCustomerNumber'           ),
    listOrgPurposes:           new APIMethod<{purposes:string[]}>                                      (APIRequestMethod.GET,    'organizations/purposes'                     ),

    getAttachment:             new APIMethod<AttachmentBlob>                                           (APIRequestMethod.GET,    'organizations/{orgid}/attachments/{bid}'             ),
    deleteAttachment:          new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/attachments/{bid}'             ),
    updateAttachment:          new APIMethod<AttachmentBlob>                                           (APIRequestMethod.PATCH,  'organizations/{orgid}/attachments/{bid}'             ),
    listAttachments:           new APIMethod<Pagination & {blobs:AttachmentBlob[]}>                    (APIRequestMethod.GET,    'organizations/{orgid}/attachments'                   ),
    createAttachment:          new APIMethod<AttachmentBlob>                                           (APIRequestMethod.POST,   'organizations/{orgid}/attachments'                   ),
    downloadAttachment:        new APIMethod<AttachmentBlob>                                           (APIRequestMethod.GET,    'organizations/{orgid}/attachments/{bid}/download'    ),

    listConversationNotes:     new APIMethod<Pagination & {notes:ConversationNote[]}>                  (APIRequestMethod.GET,    'organizations/{orgid}/conversation-notes'            ),
    deleteConversationNote:    new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/conversation-notes/{nid}'      ),
    getConversationNote:       new APIMethod<ConversationNote>                                         (APIRequestMethod.GET,    'organizations/{orgid}/conversation-notes/{nid}'      ),
    updateConversationNote:    new APIMethod<ConversationNote>                                         (APIRequestMethod.PATCH,  'organizations/{orgid}/conversation-notes/{nid}'      ),
    createConversationNote:    new APIMethod<ConversationNote>                                         (APIRequestMethod.POST,   'organizations/{orgid}/conversation-notes'            ),

    setOrgAvatar:              new APIMethod<AvatarBlob>                                               (APIRequestMethod.POST,   'organizations/{orgid}/avatar'           ),
    setOrgBackground:          new APIMethod<BackgroundBlob>                                           (APIRequestMethod.POST,   'organizations/{orgid}/background-image' ),
    deleteOrgAvatar:           new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/avatar'           ),
    deleteOrgBackground:       new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/background-image' ),

    listOrgPosts:              new APIMethod<Pagination & {posts:Post[]}>                              (APIRequestMethod.GET,    'organizations/{orgid}/posts'                 ),
    createOrgPost:             new APIMethod<Post>                                                     (APIRequestMethod.POST,   'organizations/{orgid}/posts'                 ),
    countOrgPosts:             new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'organizations/{orgid}/posts'                 ),
    getPost:                   new APIMethod<Post>                                                     (APIRequestMethod.GET,    'organizations/{orgid}/posts/{pstid}'         ),
    enablePost:                new APIMethod<Post>                                                     (APIRequestMethod.POST,   'organizations/{orgid}/posts/{pstid}/enable'  ),
    disablePost:               new APIMethod<Post>                                                     (APIRequestMethod.POST,   'organizations/{orgid}/posts/{pstid}/disable' ),
    deletePost:                new APIMethod<Post>                                                     (APIRequestMethod.DELETE, 'organizations/{orgid}/posts/{pstid}'         ),
    updatePost:                new APIMethod<Post>                                                     (APIRequestMethod.PATCH,  'organizations/{orgid}/posts/{pstid}'         ),

    listOrgGroups:             new APIMethod<Pagination & {groups:Group[]}>                            (APIRequestMethod.GET,    'organizations/{orgid}/groups'                           ),
    createOrgGroup:            new APIMethod<{groups:Group[]}>                                         (APIRequestMethod.POST,   'organizations/{orgid}/groups'                           ),
    updateOrgGroup:            new APIMethod<Group>                                                    (APIRequestMethod.PATCH,  'organizations/{orgid}/groups/{grpid}'                   ),
    updateOrgGroups:           new APIMethod<{}>                                                      (APIRequestMethod.PATCH,  'organizations/{orgid}/groups'                           ),
    deleteOrgGroupAvatar:      new APIMethod<Group>                                                    (APIRequestMethod.DELETE, 'organizations/{orgid}/groups/{grpid}/avatar'            ),
    deleteOrgGroupBackground:  new APIMethod<Group>                                                    (APIRequestMethod.DELETE, 'organizations/{orgid}/groups/{grpid}/background-image'  ),
    getOrgGroup:               new APIMethod<Group>                                                    (APIRequestMethod.PATCH,  'organizations/{orgid}/groups/{grpid}'                   ),
    countOrgGroups:            new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'organizations/{orgid}/groups'                           ),
    enableGroup:               new APIMethod<Group>                                                    (APIRequestMethod.POST,   'organizations/{orgid}/groups/{grpid}/enable'            ),
    disableGroup:              new APIMethod<{ groups:Group[] }>                                       (APIRequestMethod.POST,   'organizations/{orgid}/groups/{grpid}/disable'           ),
    deleteGroup:               new APIMethod<{ groups:Group[] }>                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/groups/{grpid}'                   ),

    listOrgEvents:             new APIMethod<Pagination & {events:Event[]}>                            (APIRequestMethod.GET,    'organizations/{orgid}/events'                ),
    createOrgEvent:            new APIMethod<Event>                                                    (APIRequestMethod.POST,   'organizations/{orgid}/events'                ),
    getOrgEvent:               new APIMethod<Event>                                                    (APIRequestMethod.GET,   'organizations/{orgid}/events/{evtid}'                ),
    updateOrgEvent:            new APIMethod<Event>                                                    (APIRequestMethod.PATCH,  'organizations/{orgid}/events/{evtid}'        ),
    countOrgEvents:            new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'organizations/{orgid}/events'                ),
    enableEvent:               new APIMethod<Event>                                                    (APIRequestMethod.POST,   'organizations/{orgid}/events/{evtid}/enable' ),
    disableEvent:              new APIMethod<Event>                                                    (APIRequestMethod.POST,   'organizations/{orgid}/events/{evtid}/disable'),
    deleteEvent:               new APIMethod<Event>                                                    (APIRequestMethod.DELETE, 'organizations/{orgid}/events/{evtid}'        ),

    listOrgUser:               new APIMethod<Pagination & {users:OrganizationUser[]}>                  (APIRequestMethod.GET,    'organizations/{orgid}/users'                 ),
    deleteOrgUser:             new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/users/{id}'            ),
    updateOrgUser:             new APIMethod<OrganizationUser>                                         (APIRequestMethod.PATCH,  'organizations/{orgid}/users/{id}'            ),
    getOrgUser:                new APIMethod<OrganizationUser>                                         (APIRequestMethod.GET,    'organizations/{orgid}/users/{id}'            ),
    countOrgUser:              new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'organizations/{orgid}/users'                 ),
    impersonateOrgUser:        new APIMethod<{token: CursorToken, organizationUser: OrganizationUser}> (APIRequestMethod.POST,   'organizations/{orgid}/users/{id}/impersonate'),

    createOrgUser:             new APIMethod<OrganizationUser>                                         (APIRequestMethod.POST,   'organizations/{orgid}/users'                 ),

    setOrgUserAvatar:          new APIMethod<AvatarBlob>                                               (APIRequestMethod.POST,   'organizations/{orgid}/users/{aid}/avatar'    ),
    deleteOrgUserAvatar:       new APIMethod<{}>                                                       (APIRequestMethod.DELETE, 'organizations/{orgid}/users/{aid}/avatar'    ),

    updateOrgProfile:          new APIMethod<Organization>                                             (APIRequestMethod.PATCH,  'organizations/{orgid}/profile'               ),

    listOrgImpress:            new APIMethod<Pagination & {impress:Impressum[]}>                       (APIRequestMethod.GET,    'organizations/{orgid}/impress'               ),
    addOrgImpress:             new APIMethod<Impressum>                                                (APIRequestMethod.POST,  'organizations/{orgid}/impress'                ),
    updateOrgImpress:          new APIMethod<Impressum>                                                (APIRequestMethod.PATCH,  'organizations/{orgid}/impress/{impressid}'   ),
    deleteOrgImpress:          new APIMethod<{}>                                                       (APIRequestMethod.DELETE,  'organizations/{orgid}/impress/{impressid}'  ),

    listAttachmentTypes:       new APIMethod<{types: string[]}>                                        (APIRequestMethod.GET,    'blobs/attachment-types'    ),

    getBlob:                   new APIMethod<DataBlob<any>>                                            (APIRequestMethod.GET,    'blobs/{id}'                ),

    listPosts:                 new APIMethod<Pagination & {posts:Post[]}>                              (APIRequestMethod.GET,    'posts'               ),
    countPosts:                new APIMethod<{count:number}>                                           (APIRequestMethod.COUNT,  'posts'               ),

    getGroup:                  new APIMethod<Group>                                                    (APIRequestMethod.GET,    'groups/{id}'  ),
    listGroups:                new APIMethod<Pagination & {groups:Group[]}>                            (APIRequestMethod.GET,    'groups'       ),

    getEvent:                  new APIMethod<Event>                                                    (APIRequestMethod.GET,    'events/{id}'  ),
    listEvents:                new APIMethod<Pagination & {events:Event[]}>                            (APIRequestMethod.GET,    'events'       ),

    listCommunities:           new APIMethod<Pagination & {communities:Community[]}>                   (APIRequestMethod.GET,    'communities'               ),
    createCommunity:           new APIMethod<Community>                                                (APIRequestMethod.POST,   'communities'               ),

    listReports:               new APIMethod<Pagination & {reports:Report[]}>                           (APIRequestMethod.GET,    'reports'               ),
    updateReport:              new APIMethod<Report>                                                    (APIRequestMethod.PATCH,  'reports/{id}'               ),

    listWasteCalendarStreets:  new APIMethod<Pagination & {wasteCalendarStreets:WasteCalendarStreet[]}> (APIRequestMethod.GET,     'organizations/{orgid}/waste/streets'           ),
    getWasteCalendarByStreet:  new APIMethod<Pagination & {calendar:Calendar}>                          (APIRequestMethod.GET,     'organizations/{orgid}/waste/streets/{streetid}/calendar'           ),
    addWasteCalendarStreet:    new APIMethod<WasteCalendarStreet>                                       (APIRequestMethod.POST,    'organizations/{orgid}/waste/streets'           ),
    updateWasteCalendarStreet: new APIMethod<WasteCalendarStreet>                                       (APIRequestMethod.PATCH,   'organizations/{orgid}/waste/streets/{streetid}'),
    deleteWasteCalendarStreet: new APIMethod<{}>                                                        (APIRequestMethod.DELETE,  'organizations/{orgid}/waste/streets/{streetid}'),

    getOrgUserPermList:        new APIMethod<{permissions: EnumMetaValue<OrgUserPermKey, string>[]}>   (APIRequestMethod.GET,    'meta/orguser-permissions'  ),
    prettyPrintJson:           new APIMethod<{data: string}>                                           (APIRequestMethod.POST,   'meta/pretty-print-json'    ),

  };

  private readonly ref: APIServiceRef;

  constructor(private http: HttpClient,
              private auth: AuthenticationService,
              private notification: NzNotificationService,) {
    this.ref = ({http: http, auth: auth, api: this});
  }

  anyLogin(ident: string, password: string) {
    return APIService.ENDPOINTS.anyLogin.run(this.ref, {}, {'identifier': ident, 'password': password});
  }

  refreshAdminToken() {
    return APIService.ENDPOINTS.adminTokenRefresh.run(this.ref, {}, {});
  }

  getAdmin(userid: ObjectId) {
    return APIService.ENDPOINTS.getAdmin.run(this.ref, {':id': userid}, {});
  }

  async refreshToken() {
    try {
      const data = await this.refreshAdminToken();
      if (data.token) {
        this.auth.setAuth(data.token);
      } else {
        this.notification.error('Fehler', 'Token konnte nicht aktualisiert werden (empty)');
      }
    } catch (err) {
      showAPIError(this.notification, 'Token konnte nicht aktualisiert werden', err)
    }
  }

  listAdminUser(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listAdmins.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  countAdminUser() {
    return APIService.ENDPOINTS.countAdmins.run(this.ref, {}, {});
  }

  countAppUser() {
    return APIService.ENDPOINTS.countAppUsers.run(this.ref, {}, {});
  }

  deleteAdmin(userid: ObjectId) {
    return APIService.ENDPOINTS.deleteAdmin.run(this.ref, {':id': userid}, {});
  }

  createAdmin(firstName: string, lastName: string, username: string, mail: string, password: string) {
    return APIService.ENDPOINTS.createAdmin.run(this.ref, {}, {
      "username": username,
      "email": mail,
      "password": password,
      "firstname": firstName,
      "lastname": lastName,
    });
  }

  updateAdmin(userid: ObjectId, firstName: string, lastName: string, username: string, mail: string) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "username": username,
      "email": mail,
      "firstname": firstName,
      "lastname": lastName,
    });
  }

  updateAdminPassword(userid: ObjectId, password: string, pwOld: string|undefined = undefined) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "password": password,
      "passwordOld": pwOld,
    });
  }

  updateAdminFirstName(userid: ObjectId, firstName: string) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "firstname": firstName,
    });
  }

  updateAdminLastName(userid: ObjectId, lastName: string) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "lastname": lastName,
    });
  }

  updateAdminMail(userid: ObjectId, mail: string) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "email": mail,
    });
  }

  updateAdminUsername(userid: ObjectId, username: string) {
    return APIService.ENDPOINTS.updateAdmin.run(this.ref, {':id': userid}, {
      "username": username,
    });
  }

  listAllOrgUser(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listAllOrgUsers.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  listOrgUser(orgid:string, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listOrgUser.run(this.ref, {':orgid':orgid,'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  countOrgUser(orgid:string) {
    return APIService.ENDPOINTS.countOrgUser.run(this.ref, {':orgid':orgid}, {});
  }

  countAllOrgUser() {
    return APIService.ENDPOINTS.countAllOrgUser.run(this.ref, {}, {});
  }

  deleteOrgUser(orgid:string, userid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgUser.run(this.ref, {':orgid':orgid, ':id': userid}, {});
  }

  createOrgUser(orgID: ObjectId,
                firstName: string, lastName: string,
                username: string, mail: string,
                sendPasswordMail: boolean, password: string,
                permissions: OrgUserPermKey[]) {

    return APIService.ENDPOINTS.createOrgUser.run(this.ref, {":orgid": orgID,}, {
      "username": username,
      "email": mail,
      "password": password,
      "sendPasswordMail": sendPasswordMail,
      "firstname": firstName,
      "lastname": lastName,
      "permissions": permissions,
    });

  }

  createOrgUserWithNewOrg(firstName: string, lastName: string,
                username: string, mail: string,
                sendPasswordMail: boolean, password: string,
                customernumber: string,
                addressPLZ: string, addressCity: string, addressStreet: string, addressNumber: string, addressExtra: string,
                bankingIban: string, bankingName: string,
                telephonenumber: string,
                orgName: string, orgType: OrgType, orgPurpose: string) {

    return APIService.ENDPOINTS.createOrgUserWithOrg.run(this.ref, {}, {
      "username": username,
      "email": mail,
      "password": password,
      "sendPasswordMail": sendPasswordMail,
      "firstname": firstName,
      "lastname": lastName,
      "organization": {
        "address": {
          "zipcode": addressPLZ,
          "city": addressCity,
          "street": addressStreet,
          "number": addressNumber,
          "extra": addressExtra,
        },
        "customerNumber": customernumber,
        "banking": {
          "iban": bankingIban,
          "owner": bankingName,
        },
        "phone": telephonenumber,
        "name": orgName,
        "type": orgType,
        "purpose": orgPurpose,
      }
    });

  }

  updateOrgUser(orgid:string, userid: ObjectId, firstName: string, lastName: string, username: string, mail: string, permissions: OrgUserPermKey[]) {
    return APIService.ENDPOINTS.updateOrgUser.run(this.ref, {':orgid':orgid, ':id': userid}, {
      "username":    username,
      "email":       mail,
      "firstname":   firstName,
      "lastname":    lastName,
      "permissions": permissions,
    });
  }

  updateOrganization(orgid: ObjectId, name: string,
                customernumber: string,
                addressPLZ: string, addressCity: string, addressStreet: string, addressNumber: string, addressExtra: string,
                bankingIban: string, bankingName: string,
                telephonenumber: string,
                orgType: OrgType, orgPurpose: string,
                     isPreselected: boolean
  ) {
    return APIService.ENDPOINTS.updateOrganization.run(this.ref, {':orgid': orgid}, {
      "name": name,
      "address_zipcode": addressPLZ,
      "address_city": addressCity,
      "address_street": addressStreet,
      "address_number": addressNumber,
      "address_extra": addressExtra,
      "customerNumber": customernumber,
      "banking_iban": bankingIban,
      "banking_owner": bankingName,
      "phone": telephonenumber,
      "type": orgType,
      "purpose": orgPurpose,
      "isPreselected": isPreselected
    });
  }

  updateOrganizationPartial(orgid: ObjectId, org: Partial<Organization>) {
    return APIService.ENDPOINTS.updateOrganization.run(this.ref, {':orgid': orgid}, org);
  }

  updateOrgUserPassword(orgid:string, userid: ObjectId, password: string, pwOld: string|undefined = undefined) {
    return APIService.ENDPOINTS.updateOrgUser.run(this.ref, {':orgid':orgid, ':id': userid}, {
      "password": password,
      "passwordOld": pwOld,
    });
  }

  listAppUser(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listAppUsers.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  listCrawler(token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listCrawlers.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  countCrawler() {
    return APIService.ENDPOINTS.countCrawlers.run(this.ref, {}, {});
  }

  getOrgUser(orgid: ObjectId, userid: ObjectId) {
    return APIService.ENDPOINTS.getOrgUser.run(this.ref, {':orgid': orgid, ':id': userid}, {});
  }

  getOrgUserDirect(userid: ObjectId) {
    return APIService.ENDPOINTS.getOrgUserDirect.run(this.ref, {':id': userid}, {});
  }

  updateOrgUserComment(orgid:string, userid: ObjectId, comment: string) {
    return APIService.ENDPOINTS.updateOrgUser.run(this.ref, {':orgid':orgid, ':id': userid}, {
      "comment": comment,
    });
  }

  updateOrgComment(orgid: ObjectId, comment: string) {
    return APIService.ENDPOINTS.updateOrganization.run(this.ref, {':orgid': orgid}, {
      "comment": comment,
    });
  }

  updateOrgProfileField(orgid: ObjectId, key: string, value: string) {
    return APIService.ENDPOINTS.updateOrgProfile.run(this.ref, {':orgid': orgid}, { "key": key, "value": value });
  }

  listOrgUserAttachments(orgid: ObjectId, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listAttachments.run(this.ref, {':orgid': orgid, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  deleteOrgUserAttachment(orgid: ObjectId, blobid: ObjectId) {
    return APIService.ENDPOINTS.deleteAttachment.run(this.ref, {':orgid': orgid, ':bid': blobid}, {});
  }

  getOrgUserAttachment(orgid: ObjectId, blobid: ObjectId) {
    return APIService.ENDPOINTS.getAttachment.run(this.ref, {':orgid': orgid, ':bid': blobid}, {});
  }

  updateOrgUserAttachment(orgid: ObjectId, blobid: ObjectId, filename: string, attachmentType: string, comment: string) {
    return APIService.ENDPOINTS.updateAttachment.run(this.ref, {':orgid': orgid, ':bid': blobid}, {'filename': filename, 'attachmentType':attachmentType, 'comment':comment});
  }

  createOrgUserAttachment(orgid: ObjectId, filename: string, data: any, mime: string) {
    return APIService.ENDPOINTS.createAttachment.run(this.ref, {':orgid': orgid}, {'filename': filename, 'data': data, 'mime': mime});
  }

  downloadAttachment(orgid: ObjectId, blobid: ObjectId) {
    return APIService.ENDPOINTS.downloadAttachment.url(this.ref, {':orgid': orgid, ':bid': blobid, 'xx-bearer-token': "@"+this.ref.auth.getToken()});
  }

  setOrgUserAvatar(orgid: ObjectId, userid: ObjectId, data: string, mime: string) {
    return APIService.ENDPOINTS.setOrgUserAvatar.run(this.ref, {':orgid': orgid, ':aid': userid}, {'data': data, 'mime': mime});
  }

  deleteOrgUserAvatar(orgid: ObjectId, userid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgUserAvatar.run(this.ref, {':orgid': orgid, ':aid': userid}, {});
  }

  setOrgAvatar(orgid: ObjectId, data: string, mime: string) {
    return APIService.ENDPOINTS.setOrgAvatar.run(this.ref, {':orgid': orgid}, {'data': data, 'mime': mime});
  }

  deleteOrgAvatar(orgid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgAvatar.run(this.ref, {':orgid': orgid}, {});
  }

  setOrgBackgroundImage(orgid: ObjectId, data: string, mime: string) {
    return APIService.ENDPOINTS.setOrgBackground.run(this.ref, {':orgid': orgid}, {'data': data, 'mime': mime});
  }

  deleteOrgBackgroundImage(orgid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgBackground.run(this.ref, {':orgid': orgid}, {});
  }

  listAttachmentTypes() {
    return APIService.ENDPOINTS.listAttachmentTypes.run(this.ref, {}, {});
  }

  listOrgUserConversationNotes(orgid: ObjectId, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listConversationNotes.run(this.ref, {':orgid': orgid, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  deleteConversationNote(orgid: ObjectId, noteid: ObjectId) {
    return APIService.ENDPOINTS.deleteConversationNote.run(this.ref, {':orgid': orgid, ':nid': noteid}, {});
  }

  getConversationNote(orgid: ObjectId, noteid: ObjectId) {
    return APIService.ENDPOINTS.getConversationNote.run(this.ref, {':orgid': orgid, ':nid': noteid}, {});
  }

  updateConversationNote(orgid: ObjectId, noteid: ObjectId, title: string, body: string, date: RFC3339, participants: string[]) {
    return APIService.ENDPOINTS.updateConversationNote.run(this.ref, {':orgid': orgid, ':nid': noteid}, {'title': title, 'body': body, 'date': date, 'participants': participants});
  }

  createConversationNote(orgid: ObjectId, title: string, body: string, date: RFC3339, participants: string[]) {
    return APIService.ENDPOINTS.createConversationNote.run(this.ref, {':orgid': orgid}, {'title': title, 'body': body, 'date': date, 'participants': participants});
  }

  getBlob(blobid: ObjectId) {
    return APIService.ENDPOINTS.getBlob.run(this.ref, {':id': blobid}, {});
  }

  finishUserRegistration(userid: ObjectId, secret:string, pw: string) {
    return APIService.ENDPOINTS.finUserRegister.run(this.ref, {':id': userid}, {'secret': secret, 'password': pw});
  }

  finishResetPassword(userid: ObjectId, secret:string, pw: string) {
    return APIService.ENDPOINTS.finPasswordReset.run(this.ref, {':id': userid}, {'secret': secret, 'password': pw});
  }

  requestPasswordResetByID(userid: ObjectId, utype: UserType) {
    return APIService.ENDPOINTS.reqPasswordReset.run(this.ref, {}, {'userID': userid, 'userType': utype});
  }

  requestPasswordResetByIdent(ident: string) {
    return APIService.ENDPOINTS.reqPasswordReset.run(this.ref, {}, {'identifier': ident});
  }

  getUserName(userid: ObjectId) {
    return APIService.ENDPOINTS.getUsername.run(this.ref, {':id': userid}, {});
  }

  scrapeLinkPost(url: string) {
    return APIService.ENDPOINTS.scrapeLinkPost.run(this.ref, {}, {'url': url});
  }

  scrapeVideoPost(url: string) {
    return APIService.ENDPOINTS.scrapeVideoPost.run(this.ref, {}, {'url': url});
  }

  async createPost(org: ObjectId,
                   dsttyp: "ROOT" | "GROUP" | "EVENT", dst: string,
                   title: string, text: string|undefined,
                   url: undefined | string, imageURL: undefined | string,
                   images: {id?: ObjectId, data?: string, mime?: string}[] | undefined,
                   file: { mime:string, data:string}|undefined,
                   enabled: boolean, publishTS: Date | null) {
    return APIService.ENDPOINTS.createOrgPost.run(this.ref, {':orgid':org}, {
      'destination':     dsttyp,
      'group':           dst,
      'event':           dst,
      'title':           title,
      'url':             url,
      'imageURL':        imageURL,
      'text':            text,
      'images':          images,
      'fileData':        file?.data,
      'fileMime':        file?.mime,
      'enabled':         enabled,
      'autoEnableTime':  publishTS !== null ? DateUtils.formatRFC3339(publishTS) : null,
    });
 }

  listPosts(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listPosts.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  countPosts(filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.countPosts.run(this.ref, {}, {}, filter);
  }

  listOrgPosts(org: string, token: CursorToken, pageSize: number, filterCreatorID: ObjectId|undefined = undefined, additionalParams?: Record<string, any>) {
    return APIService.ENDPOINTS.listOrgPosts.run(this.ref, {':orgid':org, 'nextPageToken': token, 'pageSize': pageSize, 'creator': filterCreatorID, ...additionalParams}, {});
  }

  countOrgPosts(org: string) {
    return APIService.ENDPOINTS.countOrgPosts.run(this.ref, {':orgid':org}, {});
  }

  listOrganizations(token: CursorToken, pageSize: number|null, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listOrganizations.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize ?? -1}, {}, filter);
  }

  listGroups(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listGroups.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  listEvents(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listEvents.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  listOrgGroups(orgid: ObjectId, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listOrgGroups.run(this.ref, {':orgid': orgid, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  listOrgEvents(orgid: ObjectId, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listOrgEvents.run(this.ref, {':orgid': orgid, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  countOrgEvents(orgid: ObjectId) {
    return APIService.ENDPOINTS.countOrgEvents.run(this.ref, {':orgid': orgid}, {});
  }

  getEvent(eventId: ObjectId) {
    return APIService.ENDPOINTS.getEvent.run(this.ref, {':id': eventId}, {});
  }

  getOrgEvent(orgid: ObjectId, eventId: ObjectId) {
    return APIService.ENDPOINTS.getOrgEvent.run(this.ref, {':orgid': orgid, ':evtid': eventId}, {});
  }

  getOrganization(orgid: ObjectId) {
    return APIService.ENDPOINTS.getOrganization.run(this.ref, {':orgid': orgid}, {});
  }

  deleteOrganization(orgid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrganization.run(this.ref, {':orgid': orgid}, {});
  }

  enableOrganization(orgid: ObjectId) {
    return APIService.ENDPOINTS.enableOrganization.run(this.ref, {':orgid': orgid}, {});
  }

  disableOrganization(orgid: ObjectId) {
    return APIService.ENDPOINTS.disableOrganization.run(this.ref, {':orgid': orgid}, {});
  }

  getGroup(groupId: ObjectId) {
    return APIService.ENDPOINTS.getGroup.run(this.ref, {':id': groupId}, {});
  }

  getOrgGroup(orgid: ObjectId, groupId: ObjectId) {
    return APIService.ENDPOINTS.getGroup.run(this.ref, {':orgid': orgid, ':id': groupId}, {});
  }

  createGroup(orgid: ObjectId, title: string, avatar: string|null, avatarMime: string, background: string|null, backgroundMime: string) {
    return APIService.ENDPOINTS.createOrgGroup.run(this.ref, {':orgid': orgid}, {
      'title':           title,
      'avatarData':      avatar,
      'avatarMime':      avatarMime,
      'backgroundData':  background,
      'backgroundMime':  backgroundMime,
      'enabled':         false,
    });
  }

  updateGroup(orgid: ObjectId, groupid: ObjectId, title: string, avatar: [string, string]|null, background: [string, string]|null) {
    return APIService.ENDPOINTS.updateOrgGroup.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {
      'title':          title,
      'avatarData':     avatar?.[0] ?? undefined,
      'avatarMime':     avatar?.[1] ?? undefined,
      'backgroundData': background?.[0] ?? undefined,
      'backgroundMime': background?.[1] ?? undefined,
    });
  }

  updateGroups(orgid: ObjectId, groups: GroupSmall[]) {
    return APIService.ENDPOINTS.updateOrgGroups.run(this.ref, {':orgid': orgid }, {
      groups: groups,
    });
  }

  deleteGroupAvatar(orgid: ObjectId, groupid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgGroupAvatar.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {});
  }

  deleteGroupBackgroundImage(orgid: ObjectId, groupid: ObjectId) {
    return APIService.ENDPOINTS.deleteOrgGroupBackground.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {});
  }

  updateOrgLinks(orgid: any, links: string[]) {
    return APIService.ENDPOINTS.updateOrgLinks.run(this.ref, {':orgid': orgid}, {'linkedOrganizations': links});
  }

  publishOrg(orgid: ObjectId) {
    return APIService.ENDPOINTS.publishOrg.run(this.ref, {':orgid': orgid}, {});
  }

  guessCustomerNumber() {
    return APIService.ENDPOINTS.guessCustomerNumber.run(this.ref, {}, {});
  }

  countOrgGroups(orgid: ObjectId) {
    return APIService.ENDPOINTS.countOrgGroups.run(this.ref, {':orgid': orgid}, {});
  }

  countOrgs() {
    return APIService.ENDPOINTS.countOrgs.run(this.ref, {}, {});
  }

  getOrguserPemissionList() {
    return APIService.ENDPOINTS.getOrgUserPermList.run(this.ref, {}, {});
  }

  enableGroup(orgid: ObjectId, groupid: ObjectId) {
    return APIService.ENDPOINTS.enableGroup.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {});
  }

  disableGroup(orgid: ObjectId, groupid: ObjectId) {
    return APIService.ENDPOINTS.disableGroup.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {});
  }

  deleteGroup(orgid: ObjectId, groupid: ObjectId) {
    return APIService.ENDPOINTS.deleteGroup.run(this.ref, {':orgid': orgid, ':grpid': groupid}, {});
  }

  createEvent(orgID: ObjectId, event: any, images: {avatar: {data: string |null, mime: string}}) {
    return APIService.ENDPOINTS.createOrgEvent.run(this.ref, {':orgid': orgID}, {...event, images});
  }

  updateEvent(orgID: ObjectId, eventID: ObjectId, event: Partial<Event>, images: {avatar: {data: string |null, mime: string}} | null) {
    return APIService.ENDPOINTS.updateOrgEvent.run(this.ref, {':orgid': orgID, ':evtid': eventID}, {...event, images});
  }

  deleteEvent(orgid: ObjectId, eventid: ObjectId) {
    return APIService.ENDPOINTS.deleteEvent.run(this.ref, {':orgid': orgid, ':evtid': eventid}, {});
  }

  enableEvent(orgid: ObjectId, eventid: ObjectId) {
    return APIService.ENDPOINTS.enableEvent.run(this.ref, {':orgid': orgid, ':evtid': eventid}, {});
  }

  disableEvent(orgid: ObjectId, eventid: ObjectId) {
    return APIService.ENDPOINTS.disableEvent.run(this.ref, {':orgid': orgid, ':evtid': eventid}, {});
  }

  enablePost(orgid: ObjectId, postid: ObjectId) {
    return APIService.ENDPOINTS.enablePost.run(this.ref, {':orgid': orgid, ':pstid': postid}, {});
  }

  disablePost(orgid: ObjectId, postid: ObjectId) {
    return APIService.ENDPOINTS.disablePost.run(this.ref, {':orgid': orgid, ':pstid': postid}, {});
  }

  deletePost(orgid: ObjectId, postid: ObjectId) {
    return APIService.ENDPOINTS.deletePost.run(this.ref, {':orgid': orgid, ':pstid': postid}, {});
  }

  getPost(orgid: ObjectId, postid: ObjectId) {
    return APIService.ENDPOINTS.getPost.run(this.ref, {':orgid': orgid, ':pstid': postid}, {});
  }

  listSolseitConfig() {
    return APIService.ENDPOINTS.listSolseitConfig.run(this.ref, {}, {});
  }

  listSolseitCommunities() {
    return APIService.ENDPOINTS.listSolseitCommunities.run(this.ref, {}, {});
  }

  deleteSolseitConfig(id: ObjectId) {
    return APIService.ENDPOINTS.deleteSolseitConfig.run(this.ref, {':sid': id}, {});
  }

  updateSolseitConfig(id: ObjectId, data: SolseitMapping) {
    return APIService.ENDPOINTS.updateSolseitConfig.run(this.ref, {':sid': id}, {
      'solseitCommunityID':  data.solseitCommunityID,
      'solseitRubricID':     data.solseitRubricID,
      'badennetOrg':         data.badennetOrg,
      'badennetGroup':       data.badennetGroup,
      'badennetDirectGroup': data.badennetGroup === null,
    });
  }

  moveSolseitConfig(id: ObjectId, before: ObjectId|null) {
    return APIService.ENDPOINTS.updateSolseitConfig.run(this.ref, {':sid': id}, {
      'before':          before,
    });
  }

  createSolseitConfig(data: SolseitMapping, before: ObjectId|null) {
    return APIService.ENDPOINTS.createSolseitConfig.run(this.ref, {}, {
      'solseitCommunityID':  data.solseitCommunityID,
      'solseitRubricID':     data.solseitRubricID,
      'badennetOrg':         data.badennetOrg,
      'badennetGroup':       data.badennetGroup,
      'before':              before,
    });
  }

  impersonateOrgUser(orgid: ObjectId, userid: ObjectId) {
    return APIService.ENDPOINTS.impersonateOrgUser.run(this.ref, {':orgid': orgid, ':id': userid}, {});
  }

  updatePost(orgid: ObjectId, postid: ObjectId, data: {title?: string, text?: string, url?: string, imageURL?: string, images?: {id?: ObjectId, data?: string, mime?: string}[], file?: {data: string, mime: string}, autoEnableTime?: Date|null}) {
    return APIService.ENDPOINTS.updatePost.run(this.ref, {':orgid': orgid, ':pstid': postid}, {
      'title':               data.title,
      'text':                data.text,
      'url':                 data.url,
      'imageURL':            data.imageURL,
      'images':              data.images,
      'file':                data.file,
      'clearAutoEnableTime': data.autoEnableTime === null,
      'autoEnableTime':      (data.autoEnableTime !== null && data.autoEnableTime !== undefined) ? DateUtils.formatRFC3339(data.autoEnableTime) : undefined,
    });
  }

  listOrgPurposes() {
    return APIService.ENDPOINTS.listOrgPurposes.run(this.ref, {}, {});
  }

  createCrawler(create: CrawlerCreate) {
    return APIService.ENDPOINTS.createCrawler.run(this.ref, {}, create);
  }

  enableCrawler(crawlerid: ObjectId) {
    return APIService.ENDPOINTS.enableCrawler.run(this.ref, {':crwid': crawlerid}, {});
  }

  disableCrawler(crawlerid: ObjectId) {
    return APIService.ENDPOINTS.disableCrawler.run(this.ref, {':crwid': crawlerid}, {});
  }

  deleteCrawler(crawlerid: ObjectId) {
    return APIService.ENDPOINTS.deleteCrawler.run(this.ref, {':crwid': crawlerid}, {});
  }

  updateCrawler(crawlerid: ObjectId, update: CrawlerUpdate) {
    return APIService.ENDPOINTS.updateCrawler.run(this.ref, {':crwid': crawlerid}, update);
  }

  runCrawlerNow(crawlerid: ObjectId) {
    return APIService.ENDPOINTS.runCrawlerNow.run(this.ref, {':crwid': crawlerid}, {});
  }

  listCrawlerExecutions(crawlerid: ObjectId, token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listCrawlerExecutions.run(this.ref, {':crwid': crawlerid, 'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  listCrawlerLogs(crawlerid: ObjectId, executionid: ObjectId) {
    return APIService.ENDPOINTS.listCrawlerLogs.run(this.ref, {':crwid': crawlerid, ':execid': executionid}, {});
  }

  listCrawlerImpl() {
    return APIService.ENDPOINTS.listCrawlerImpl.run(this.ref, {}, {});
  }

  listCrawlerImplConfigKeys(implkey: string) {
    return APIService.ENDPOINTS.listCrawlerImplKeys.run(this.ref, {':impl': implkey}, {});
  }

  getAppUser(uid: ObjectId) {
    return APIService.ENDPOINTS.getAppUser.run(this.ref, {':id': uid}, {});
  }

  getAppUserJWT(uid: ObjectId) {
    return APIService.ENDPOINTS.getAppUserJWT.run(this.ref, {':id': uid}, {});
  }

  listCommunities(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listCommunities.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  createCommunity(zip: string, name: string) {
    return APIService.ENDPOINTS.createCommunity.run(this.ref, {}, {'zip': zip, 'name': name});
  }

  prettyPrintJson(data: string) {
    return APIService.ENDPOINTS.prettyPrintJson.run(this.ref, {}, {'data': data});
  }


  listOrgImpresss(orgid: ObjectId, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listOrgImpress.run(this.ref, {':orgid': orgid, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  addImpress(org: string, impress: Impressum) {
    return APIService.ENDPOINTS.addOrgImpress.run(this.ref, {':orgid':org}, impress);
  }

  updateImpress(org: string, impressId: string, impress: Impressum) {
    return APIService.ENDPOINTS.updateOrgImpress.run(this.ref, {':orgid':org, ':impressid': impressId}, impress);
  }

  deleteImpress(org: string, impress: Impressum) {
    return APIService.ENDPOINTS.deleteOrgImpress.run(this.ref, {':orgid':org, ':impressid': impress.id}, impress);
  }

  listReports(token: CursorToken, pageSize: number, filter?: SerializedParamCollection) {
    return APIService.ENDPOINTS.listReports.run(this.ref, {'nextPageToken': token, 'pageSize': pageSize}, {}, filter);
  }

  updateReport(reportId: ObjectId, status: ReportStatus) {
    return APIService.ENDPOINTS.updateReport.run(this.ref, {':id': reportId}, {
      status: status
    });
  }

  createWasteCalendarStreet(org: string, wasteCalendarStreet: WasteCalendarStreet) {
    return APIService.ENDPOINTS.addWasteCalendarStreet.run(this.ref, {':orgid':org}, wasteCalendarStreet);
  }
  updateWasteCalendarStreet(org: string, wasteCalendarStreet: WasteCalendarStreet) {
    return APIService.ENDPOINTS.updateWasteCalendarStreet.run(this.ref, {':orgid':org, ':streetid': wasteCalendarStreet.id}, wasteCalendarStreet);
  }
  uploadWasteCalendarStreetIcs(org: string, wasteCalendarStreet: WasteCalendarStreet) {
    return APIService.ENDPOINTS.updateWasteCalendarStreet.run(this.ref, {':orgid':org, ':streetid': wasteCalendarStreet.id}, wasteCalendarStreet);
  }
  deleteWasteCalendarStreet(org: string, wasteCalendarStreet: WasteCalendarStreet) {
    return APIService.ENDPOINTS.deleteWasteCalendarStreet.run(this.ref, {':orgid':org, ':streetid': wasteCalendarStreet.id}, {});
  }
  listWasteCalendarStreets(org: string, token: CursorToken, pageSize: number) {
    return APIService.ENDPOINTS.listWasteCalendarStreets.run(this.ref, {':orgid': org, 'nextPageToken': token, 'pageSize': pageSize}, {});
  }

  getWasteCalendarByStreet(org: string, streetId: string) {
    return APIService.ENDPOINTS.getWasteCalendarByStreet.run(this.ref, {':orgid': org, ':streetid': streetId}, {});
  }

}
