
<app-default-frame>

  <app-create-post-modal [(show)]="showCreateNewPost" (success)="onPostCreated($event)"></app-create-post-modal>

  <app-edit-post-modal [(postID)]="editPostID" (postUpdated)="onPostUpdated($event)"></app-edit-post-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary"
                     text="Neuen Post erstellen"
                     iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky"
                     (btnClick)="showCreateNewPost = true"></fa-icon-btn>
      </div>
    </div>

    <div class="post-list">

      <nz-table
        #mainTab
        style="width: 100%"
        [nzData]="posts"
        [nzFrontPagination]="false"
        [nzShowPagination]="false"
        [nzLoading]="loading">

        <thead>
        <tr>
          <th style="width: 48px"></th>
          <th>ID</th>
          <th>Titel</th>
          <th>ContentProvider</th>
          <th>Quelle</th>
          <th>Erstellt am</th>
          <th>Veröffentlicht</th>
          <th></th>
        </tr>
        </thead>

        <tbody>
        <tr *ngFor="let data of posts">

          <td>
            <nz-avatar *ngIf="postImageSource(data) != undefined" class="avatar" [nzShape]="'square'" [nzSize]="32" [nzIcon]="'link-slash'" [nzSrc]="postImageSource(data) ?? ''"></nz-avatar>
            <fa-icon   *ngIf="postImageSource(data) == undefined" [icon]="['fas', 'align-left']" ></fa-icon>
          </td>

          <td>{{ data.id | shortid }}</td>

          <td>{{ data.title }}</td>

          <td class="rlink" routerLink="/admin/organizations/{{data.organizationID}}">
            <a class="nostyle" [routerLink]="'/admin/organizations/' + data.organizationID">{{translateOrgID(data.organizationID)}}</a>
          </td>

          <td>{{ translateCreatorType(data.creatorType) }}</td>

          <td>{{ data.creationTime | displaydate }}</td>

          <td *ngIf="data.enabled">Öffentlich</td>
          <td *ngIf="!data.enabled && data.autoEnableTime !== null">{{ data.autoEnableTime | displaydate }}</td>
          <td *ngIf="!data.enabled && data.autoEnableTime === null">Versteckt</td>

          <td class="actionlist">

            <nz-switch
              style="margin-top: -1px;"
              [ngModel]="data.enabled"
              [nz-tooltip]="data.enabled ? 'Post deaktivieren' : 'Post aktivieren'"
              [nzControl]="true"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
              (click)="changePostActive(data, !data.enabled)"
            ></nz-switch>
            <ng-template #checkedTemplate><fa-icon icon="eye"></fa-icon></ng-template>
            <ng-template #unCheckedTemplate><fa-icon icon="eye-slash"></fa-icon></ng-template>

            <a *ngIf="data.url !== ''" nz-tooltip="Link" [href]="data.url" target="_blank">
              <fa-icon-btn class="btn-url"
                           nzType="primary"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="12"
                           icon="arrow-up-right-from-square"></fa-icon-btn>
            </a>
            <span *ngIf="data.url === ''" style="width: 26px; height: 26px"><!-- Placeholder, because url icon is hidden --></span>

            <fa-icon-btn nzType="default"
                         nz-tooltip="Post bearbeiten"
                         buttonSize="24"
                         iconSize="12"
                         icon="edit"
                         (btnClick)="editPostID = {org:data.organizationID, post:data.id}"></fa-icon-btn>

            <fa-icon-btn nzType="default"
                         [nzDanger]="true"
                         nz-tooltip="Post löschen"
                         buttonSize="24"
                         iconSize="14"
                         icon="trash"
                         (btnClick)="deletePost(data)"></fa-icon-btn>

          </td>

        </tr>
        </tbody>

        <tfoot>
        <td colspan="100%">
          <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)" [loading]="loadingMore"></app-pagination-button>
        </td>
        </tfoot>

      </nz-table>

    </div>

  </main>

</app-default-frame>
