
<app-default-frame>

  <app-create-group-modal [(show)]="showCreateNewGroup" (success)="onGroupCreated($event)"></app-create-group-modal>

  <app-edit-group-modal [(show)]="showEditGroup" (success)="onGroupEdited($event)"></app-edit-group-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary"
                     text="Neue Gruppe erstellen"
                     iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky"
                     (btnClick)="showCreateNewGroup = true"></fa-icon-btn>
      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="groups"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th style="width: 48px"></th>
        <th>ID</th>
        <th>Name</th>
        <th>Erstellt am</th>
        <th>ContentProvider</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of groups">

        <td><nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="32" [nzIcon]="'file-image'" [nzSrc]="avatarSource(data) ?? ''"></nz-avatar></td>

        <td>{{ data.id | shortid }}</td>

        <td>{{ data.title }}</td>

        <td>{{ data.creationTime | displaydate }}</td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="'/admin/organizations/' + data.organizationID">{{translateOrgID(data.organizationID)}}</a></td>

        <td class="actionlist">
          <fa-icon-btn nzType="default"
                       nz-tooltip="Gruppe bearbeiten"
                       buttonSize="24"
                       iconSize="14"
                       icon="edit"
                       (btnClick)="showEditGroup = data"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       nz-tooltip="Gruppe löschen"
                       buttonSize="24"
                       iconSize="14"
                       icon="trash"
                       (btnClick)="deleteGroup(data)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       nz-tooltip="Gruppe aktivieren"
                       buttonSize="24" iconSize="14" icon="eye"
                       [disabled]="data.enabled"
                       (btnClick)="activateGroup(data)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       nz-tooltip="Gruppe deaktivieren"
                       buttonSize="24"
                       iconSize="14"
                       icon="eye-slash"
                       [disabled]="!data.enabled"
                       (btnClick)="deactivateGroup(data)"></fa-icon-btn>
        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)" [loading]="loadingMore"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

