import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {OrganizationUser, OrgUserPermKey} from "../../interfaces/organizationUser";
import {BlobDataOrgUserAttachment, DataBlob} from "../../interfaces/datablobs";
import {environment} from "../../../environments/environment";
import {ConversationNote} from 'src/app/interfaces/conversationNotes';
import {getIndirectFieldValue} from "../../utils/indirect";
import {Organization} from "../../interfaces/organization";
import {Post} from "../../interfaces/post";
import {EnumMetaValue} from "../../interfaces/enums";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-orguser-view',
  templateUrl: './org-orguser-view.component.html',
  styleUrls: ['./org-orguser-view.component.scss']
})
export class OrgOrgUserViewComponent implements OnInit {
  orgid!: ObjectId;

  loading = true;

  id!: ObjectId;
  user: OrganizationUser|null = null;
  org: Organization|null = null;
  permlist: EnumMetaValue<OrgUserPermKey, string>[] = [];

  selectedEditOrgUser: OrganizationUser|null = null;
  selectedEditAvatar: OrganizationUser|null = null;

  avatarBlobId: string = 'INITIAL';

  clearingAvatar: boolean = false;

  loadingBlobAvatar: boolean = false;

  avatarBlob: DataBlob<any>|null = null;

  @ViewChild('commentBox') public commentBox!: ElementRef<HTMLDivElement>;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.orgid = this.auth.getSelfOrgID()!;

    this.id = this.activatedRoute.snapshot.params['id'];
    await this.loadUser();
    if (!this.user === null) return;
  }

  async loadUser() {

    this.loading = true;

    try {
      const d1 = await this.api.getOrgUser(this.orgid, this.id);
      const d2 = await this.api.getOrganization(d1.organizationID);
      const d3 = await this.api.getOrguserPemissionList();

      this.permlist = d3.permissions;
      this.user = d1;
      this.org = d2;
      this.avatarBlobId = d1.avatarImageID ?? 'UNSET';

      if (d1.avatarImageID !== null) this.loadBlobAvatar().then(()=>{});

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async loadBlobAvatar() {
    if (this.user === null) return;
    if (this.user.avatarImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.avatarBlob = await this.api.getBlob(this.user.avatarImageID);

    } catch (err) {
      showAPIError(this.notification, 'Profilbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobAvatar = false;
    }
  }

  deleteOrgUser() {
    if (this.user === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'Eintrag löschen?',
      nzContent: 'Möchtest du den Benutzer "'+this.user.username+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          if (this.user === null) return;

          loading = true;
          await this.api.deleteOrgUser(this.user.organizationID, this.user.id);
          await this.router.navigate(['/org/orgusers']);
          this.notification.success('Benutzer gelöscht', 'Der Benutzer "' + this.user.username + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Der Benutzer konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onOrgUserEdited(entry: OrganizationUser) {
    this.user = entry;
  }

  async setAvatar(img: string, mime: string) {
    try {
      if (this.user === null) return;

      const blob = await this.api.setOrgUserAvatar(this.user.organizationID, this.id, img, mime);

      this.avatarBlobId = blob.id;
      this.user.avatarImageID = blob.id;
      this.avatarBlob = blob;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    } finally {
      this.selectedEditAvatar = null;
    }
  }

  avatarSource() {
    if (this.user === null) return undefined;
    if (this.user.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${this.user.organizationID}/users/${this.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.avatarBlobId}`;
  }

  async clearAvatar() {
    if (this.user === null) return;

    try {
      this.clearingAvatar = true;

      await this.api.deleteOrgUserAvatar(this.user.organizationID, this.id);

      this.avatarBlobId = 'UNSET';
      this.user.avatarImageID = null;
      this.avatarBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingAvatar = false;
    }
  }
}
