
<div class="entry" *ngIf="event !== null">

  <span class="icon">
    <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="64" [nzIcon]="'calendar'" [nzSrc]="avatarSource(event) ?? ''"></nz-avatar>
  </span>

  <div class="content-wrapper">

    <div class="title">{{event.title}}</div>

    <div class="date-wrapper">
      <span class="startdate">{{event.startTime | date:'EEEE, dd MMMM, y, hh:mm:ss a'}}</span>
      <span>-></span>
      <span class="enddate">{{event.endTime | date:'EEEE, dd MMMM, y, hh:mm:ss a'}}</span>
    </div>

  </div>

  <span class="actionbuttons">

    <fa-icon-btn *ngIf="showEdit"
                           class="btn-url"
                           nzType="primary"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           perm="EVENTS::EDIT"
                           [disabled]="true"
                           (btnClick)="showEditEvent = event"
                           icon="pen"></fa-icon-btn>

    <fa-icon-btn *ngIf="showDelete"
                           class="btn-url"
                           nzType="primary"
                           [nzDanger]="true"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           perm="EVENTS::DELETE"
                           (btnClick)="deleteEvent(event)"
                           icon="trash"></fa-icon-btn>

    <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           nz-tooltip="Event aktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye"
                           perm="EVENTS::EDIT"
                           [disabled]="event.enabled"
                           (btnClick)="activateEvent(event)"></fa-icon-btn>

    <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           [nzDanger]="true"
                           nz-tooltip="Event deaktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye-slash"
                           perm="EVENTS::EDIT"
                           [disabled]="!event.enabled"
                           (btnClick)="deactivateEvent(event)"></fa-icon-btn>

          </span>

</div>
