<nz-modal [nzVisible]="show"
          nzTitle="Neuen Abfuhrtermin anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="submitForm()"
          (nzOnCancel)="close()"
          nzWidth="800px"
>
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>

      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" nzLayout="vertical" class="ant-form-vertical ng-pristine ng-invalid">
        <div class="wrapper">
          <nz-form-item>
            <nz-form-label nzRequired>Vollständiger Name</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="date" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzRequired>Stadt-/Ortsteil</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="date" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzRequired>Straße/Abfuhrbezirk</nz-form-label>
            <nz-form-control>
              <nz-date-picker formControlName="date" />
            </nz-form-control>
          </nz-form-item>

          <nz-form-item>
            <nz-form-label nzRequired>Abfalltyp</nz-form-label>
            <nz-form-control >
              <nz-select formControlName="wasteType" nzPlaceHolder="Bitte auswählen">
                @for (key of wasteTypeKeys(); track key){
                  <nz-option [nzValue]="key" [nzLabel]="wasteTypes[key]" />
                }
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>

        <nz-form-item>
          <nz-form-label>Beschreibung</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="description" />
          </nz-form-control>
        </nz-form-item>


        <p>
          <span style="color: red">*</span> Pflichtfelder
        </p>
<!--
        <nz-form-item nz-row>
          <nz-form-control>
            <button nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
          </nz-form-control>
        </nz-form-item>-->
      </form>


  </ng-container>
</nz-modal>

