
<nz-modal [nzVisible]="show"
          nzTitle="Neues Event erstellen"
          nzOkText="Erstellen"
          (nzOnCancel)="close()"
          (nzOnOk)="submitForm()"
          [nzOkDisabled]="!eventForm.valid"
          [nzWidth]="768">
  <ng-container *nzModalContent>

    <form nz-form [formGroup]="eventForm" (ngSubmit)="submitForm()" class="form">
      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="title" nzRequired>Veranstaltung</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" [nzValidateStatus]="validateElement(eventForm.controls['title'])" nzErrorTip="Needs to be filled">
          <input nz-input formControlName="title" id="title" placeholder="Veranstaltungsname"/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="orgId === null">
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="contentProvider" nzRequired>ContentProvider</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <nz-select formControlName="contentProvider" nzShowSearch nzAllowClear nzPlaceHolder="ContentProvider auswählen" [(ngModel)]="selectedOrg" (ngModelChange)="loadData()">
            <ng-container *ngFor="let org of allOrgs" >
              <nz-option [nzLabel]="org.name" [nzValue]="org.id"></nz-option>
            </ng-container>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzRequired>Zeitraum</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24" [nzValidateStatus]="validateElement(eventForm.controls['dateTime'])">
          <nz-range-picker nzShowTime formControlName="dateTime"></nz-range-picker>
        </nz-form-control>
      </nz-form-item>

      <div class="form" formGroupName="address">
      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="locationName">Location</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <input nz-input formControlName="locationName" id="locationName" placeholder="Location"/>
        </nz-form-control>
      </nz-form-item>


        <nz-form-item>
          <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="street" nzRequired>Straße</nz-form-label>
          <div nz-row [nzGutter]="8" style="flex-grow: 1">
            <div nz-col [nzSpan]="16">
              <nz-form-control [nzXs]="24" [nzValidateStatus]="validateElement(eventForm.controls['address'].controls['street'])" class="no-padding">
                <input nz-input formControlName="street" id="street" placeholder="Straße"/>
              </nz-form-control>
            </div>
            <div nz-col [nzSpan]="8">
              <nz-form-control [nzXs]="24" [nzValidateStatus]="validateElement(eventForm.controls['address'].controls['street'])" class="no-padding">
                <input nz-input formControlName="number" id="number" placeholder="Nummer"/>
              </nz-form-control>
            </div>
          </div>
        </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="zipCode" nzRequired>Postleitzahl / Ort</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <div nz-row [nzGutter]="8">
            <div nz-col [nzSpan]="8">
              <input nz-input formControlName="zipcode" id="zipCode" maxlength="5" placeholder="PLZ"/>
            </div>
            <div nz-col [nzSpan]="16">
              <input nz-input formControlName="city" id="city" placeholder="Ort"/>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item>

      </div>

      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="website" >Webseite</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <ng-template #prefixTemplateUser><span nz-icon nzType="link"></span></ng-template>
          <nz-input-group [nzPrefix]="prefixTemplateUser">
          <input nz-input formControlName="website" id="website" placeholder="Webseite"/>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="description" nzRequired>Beschreibung</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <textarea rows="4" nz-input formControlName="description" id="description"></textarea>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label [nzSm]="8" [nzXs]="24" nzFor="description" nzRequired>Eventbild</nz-form-label>
        <nz-form-control [nzSm]="16" [nzXs]="24">
          <ng-container>
            <nz-upload *ngIf="eventForm.controls.avatar.value === null" style="width: 475px;" nzType="drag" [nzMultiple]="false"
                       [nzBeforeUpload]="beforeUploadAvatar">
              <p class="ant-upload-drag-icon">
                <span nz-icon nzType="upload"></span>
              </p>
              <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
            </nz-upload>
            <image-cropper *ngIf="eventForm.controls.avatar.value != null" #imageCropperAvatar
                           [imageFile]="eventForm.controls.avatar.value"
                           [maintainAspectRatio]="true"
                           [aspectRatio]="1"
                           [autoCrop]="false"
                           style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                           format="png"
                           (loadImageFailed)="loadImageFailed()"></image-cropper>
          </ng-container>
        </nz-form-control>
      </nz-form-item>

    </form>

  </ng-container>
</nz-modal>
