import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NzModalService } from 'ng-zorro-antd/modal';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { Organization } from 'src/app/interfaces/organization';
import { Post } from 'src/app/interfaces/post';
import { PostCreatorType } from 'src/app/interfaces/userType';
import { CursorToken, ObjectId } from 'src/app/interfaces/utils';
import { APIService } from 'src/app/services/api.service';
import { EntityCacheService } from 'src/app/services/entity-cache.service';
import { showAPIError } from 'src/app/utils/api';
import { SerializedParamCollection } from 'src/app/utils/serializedParameter';

@Component({
  selector: 'app-event-post-list',
  templateUrl: './event-post-list.component.html',
  styleUrl: './event-post-list.component.scss'
})
export class EventPostListComponent implements OnInit {
    eventPosts: Post[] = [];
    cursorToken: CursorToken = "@start";
    pageSize: number = 10;
    filters: SerializedParamCollection = {  
        search: {active: "Veranstaltungen", default: "Veranstaltungen", type:"string"}
    };
    orgid: ObjectId | null = null;

    editPostID: {org: ObjectId, post: ObjectId}|null = null;
    orgTranslationMap = new Map<ObjectId, Organization>();

    constructor(
        private api: APIService,
        private activatedRoute: ActivatedRoute,
        private modal: NzModalService,
        private notification: NzNotificationService,
        private ecache: EntityCacheService,
    ) { }

    ngOnInit(): Promise<void> {        
        return this.fetchData();
    }

    async fetchData(): Promise<void>{
        try{
            let eventID = this.activatedRoute.snapshot.params['id'];
            let orgID = this.activatedRoute.snapshot.params['orgid'];

            const groupResp = await this.api.listOrgPosts(orgID, this.cursorToken, 99999, undefined, {'parentType': 'EVENT', 'parentGroupID': eventID});
            if(groupResp.posts === undefined){
                return;
            }

            this.eventPosts = groupResp.posts;

            for (const p of this.eventPosts) this.ecache.getOrganization(p.organizationID).then(org => this.orgTranslationMap.set(org.id, org));

        }catch(err){
            console.error(err);
            return;
        }
    }

    deletePost(_t43: Post) {
        throw new Error('Method not implemented.');
    }

    changePostActive(data: Post, v: boolean) {
        if (v) {
          this.activatePost(data);
        } else {
          this.deactivatePost(data);
        }
    }

    activatePost(data: Post) {
      let loading = false;
      this.modal.confirm({
        nzTitle: 'Post aktivieren?',
        nzContent: 'Möchtest du den Post "'+data.title+'" wirklich aktivieren?',
        nzCancelText: 'Abbrechen',
        nzOkLoading: loading,
        nzOkText: 'Aktivieren',
        nzOnOk: async () => {
          try {
            loading = true;
            const newdata = await this.api.enablePost(data.organizationID, data.id);
            this.notification.success('Gruppe aktiviert', 'Den Post "' + data.title + '" wurde aktiviert');
            this.eventPosts = this.eventPosts.map(g => (g.id === newdata.id) ? newdata : g);
          } catch (err) {
            showAPIError(this.notification, 'Der Post konnte nicht aktiviert werden', err)
          } finally {
            loading = false;
          }
        }
      });
    }

    deactivatePost(data: Post) {
      let loading = false;
      this.modal.confirm({
        nzTitle: 'Post deaktivieren?',
        nzContent: 'Möchtest du den Post "'+data.title+'" wirklich deaktivieren?',
        nzCancelText: 'Abbrechen',
        nzOkLoading: loading,
        nzOkText: 'Deaktivieren',
        nzOnOk: async () => {
          try {
            loading = true;
            const newdata = await this.api.disablePost(data.organizationID, data.id);
            this.notification.success('Post deaktiviert', 'Der Post "' + data.title + '" wurde deaktiviert');
            this.eventPosts = this.eventPosts.map(g => (g.id === newdata.id) ? newdata : g);
          } catch (err) {
            showAPIError(this.notification, 'Der Post konnte nicht deaktiviert werden', err)
          } finally {
            loading = false;
          }
        }
      });
    }

    translateOrgID(orgid: ObjectId) {
      let org = this.orgTranslationMap.get(orgid);
      if (org === undefined) return '???';
      return org.name;
    }

    translateCreatorType(ctype: PostCreatorType) {
      switch (ctype) {
        case "ADMIN_USER":        return "Administrator";
        case "ORGANIZATION_USER": return "Nutzer";
        case "APPLICATION_USER":  return "Frontend";
        case "CRAWLER_GENERIC":   return "Crawler";
        case "CRAWLER_SOLSEIT":   return "Solseit";
        case "CRAWLER_DCX":       return "DCX";
        case "CRAWLER_PEIQ":      return "PEIQ";
      }
    }

    onPostUpdated($event: Post) {
      this.fetchData();
    }
}
