import { Injectable } from '@angular/core';
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {environment} from "../../environments/environment";
import {JwtHelperService} from '@auth0/angular-jwt';
import {CookieService} from 'ngx-cookie-service';
import {AdminUser} from "../interfaces/adminUser";
import {OrganizationUser} from "../interfaces/organizationUser";
import {UserType} from "../interfaces/userType";
import {ObjectId} from "../interfaces/utils";

interface JWTClaims {
  id: ObjectId;
  orgid: ObjectId|null;
  username: string;
  userType: UserType;
  v: number;
  exp: number;
}

@Injectable({ providedIn: 'root' })
export class AuthenticationService {

  claimCache: [string, JWTClaims]|undefined = undefined;

  selfCache: {[_:string]: AdminUser} = {};

  constructor(private router: Router,
              private notification: NzNotificationService,
              private cookie: CookieService) {

    //

  }

  isAuthenticated(): boolean {
    return ((this.getClaimsFromToken()?.exp ?? 0) * 1000) > new Date().getTime();
  }

  setAuth(accessToken: string): void {
    this.cookie.set(environment.cookieKey, accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  setAuthImpersonation(accessToken: string, url: string): void {
    const currToken = this.getToken();
    this.cookie.set(environment.cookieKey, accessToken, new JwtHelperService().decodeToken(accessToken).exp * 1000, '/', environment.cookieBaseUrl);
    this.cookie.set(environment.cookieKeyImpersBackup, JSON.stringify({'tok':currToken, url:url}), new JwtHelperService().decodeToken(currToken).exp * 1000, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  stopImpersonating(): string {
    const newTok = JSON.parse(this.cookie.get(environment.cookieKeyImpersBackup)) as {tok:string, url:string};
    this.cookie.delete(environment.cookieKeyImpersBackup, environment.cookieBaseUrl);
    this.cookie.set(environment.cookieKey, newTok.tok, new JwtHelperService().decodeToken(newTok.tok).exp * 1000, '/', environment.cookieBaseUrl);
    return newTok.url;
  }

  isImpersonating():boolean {
    const ckib = this.cookie.get(environment.cookieKeyImpersBackup);
    return (ckib !== '' && ckib !== null && ckib !== undefined);
  }

  clearAuth(): void {
    this.cookie.delete(environment.cookieKey, '/', environment.cookieBaseUrl);
    this.claimCache = undefined;
  }

  getToken(): string {
    return this.cookie.get(environment.cookieKey);
  }

  getClaimsFromToken(): JWTClaims | null {
    const tok = this.getToken();
    if (this.claimCache !== undefined && tok === this.claimCache[0]) return this.claimCache[1];

    const claims = new JwtHelperService().decodeToken<JWTClaims>(tok);

    if (claims !== null) this.claimCache = [tok, claims];

    return claims;
  }

  getSelfID(): ObjectId|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.id;
  }

  getSelfOrgID(): ObjectId|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.orgid;
  }

  getSelfUserType(): UserType|null {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.userType;
  }

  getSelf(): AdminUser|OrganizationUser|null {
    let id = this.getSelfID();
    if (id === null) return null;
    let v = this.selfCache[id];
    if (v === undefined) return null;
    return v;
  }

  setSelf(s: string, data: AdminUser|OrganizationUser) {
    this.selfCache[s] = data;
  }

  isOrgUser() {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.userType === 'ORGANIZATION_USER';
  }

  isAdminUser() {
    const claims = this.getClaimsFromToken();
    if (!claims) return null;
    return claims.userType === 'ADMIN_USER';
  }
}
