import { Component } from '@angular/core';
import {Event} from "../../interfaces/event";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Organization} from "../../interfaces/organization";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {EntityCacheService} from "../../services/entity-cache.service";

@Component({
  selector: 'app-events-list',
  templateUrl: './events-list.component.html',
  styleUrls: ['./events-list.component.scss']
})
export class EventsListComponent {


  events: Event[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;
  orgId: ObjectId | null = null;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  orgTranslationMap = new Map<ObjectId, Organization>();
  isCreateEventModalVisible: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private ecache: EntityCacheService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    //org id can be null if user is not part of any organization and this is ok.
    this.orgId = this.auth.getSelfOrgID();    

    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });    
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listEvents(this.nextToken, 24, this.filters);

      if (reset) {
        this.events = data.events;
      } else {
        this.events = [...this.events, ...data.events];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

      for (const p of this.events) this.ecache.getOrganization(p.organizationID).then(org => this.orgTranslationMap.set(org.id, org));

    } catch (err) {
      showAPIError(this.notification, 'Veranstaltungen konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'events'], this.filters));
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }

  translateOrgID(orgid: ObjectId) {
    let org = this.orgTranslationMap.get(orgid);
    if (org === undefined) return '???';
    return org.name;
  }

  openCreateEventModal() {
    this.isCreateEventModalVisible = true;
  }
}
