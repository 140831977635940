import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {NzUploadFile} from "ng-zorro-antd/upload";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {ImageCropperComponent} from "ngx-image-cropper";
import {NzModalComponent} from "ng-zorro-antd/modal";
import {isNgDiff} from "../../utils/angular";
import {Organization} from "../../interfaces/organization";

@Component({
  selector: 'app-upload-org-avatar-modal',
  templateUrl: './upload-org-avatar-modal.component.html',
  styleUrls: ['./upload-org-avatar-modal.component.scss']
})
export class UploadOrgAvatarModalComponent implements OnChanges {

  @Input() show: Organization|null = null;
  @Output() showChange: EventEmitter<Organization|null> = new EventEmitter<Organization|null>();
  @Output() success: EventEmitter<string> = new EventEmitter<string>();

  @Input() minWidth: number = 250;
  @Input() minHeight: number = 250;

  private imageCropper: ImageCropperComponent|null = null;
  @ViewChild('imageCropper') set content(content: ImageCropperComponent) { if(content) { this.imageCropper = content; } }

  file: File|null = null;

  loading: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show') && this.show === null) this.clear();
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.file = null;
    this.loading = false;
  }

  beforeUpload = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.file = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.file = null;
    }
    return false;
  }

  loadImageFailed() {
    this.notification.error('Fehler', 'Datei konnte nicht geladen werden');
  }

  okay() {
    if(this.file === null) return;
    if(this.imageCropper === null) return;

    const cropped = this.imageCropper.crop();
    if (cropped === null || !cropped.base64) return;

    if (cropped.width < this.minWidth || cropped.height < this.minHeight) {
      this.notification.warning('Bild zu klein', `Das Bild muss mindestens ${this.minWidth}x${this.minHeight} pixel groß sein`);
     // return;
    }

    this.loading = true;
    this.success.emit(cropped.base64.split(',').pop());
  }
}
