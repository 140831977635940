
<app-edit-post-modal *ngIf="editPostID !== null" [(postID)]="editPostID" (postUpdated)="onPostUpdated($event)"></app-edit-post-modal>

<div class="entry" *ngIf="post !== null" (click)="editPostID = {org: post.organizationID, post: post.id}">

  <span class="icon">
    <fa-icon   *ngIf="avatarSource(post) === undefined" [icon]="['fas', 'align-left']" ></fa-icon>
    <nz-avatar *ngIf="avatarSource(post) !== undefined" class="avatar" [nzShape]="'square'" [nzSize]="64" [nzIcon]="'expand'" [nzSrc]="avatarSource(post) ?? ''"></nz-avatar>
  </span>

  <span class="title">{{post.title}}</span>

  <span class="actionbuttons">

            <nz-switch
              style="margin-top: -1px;"
              [ngModel]="post.enabled"
              [nz-tooltip]="post.enabled ? 'Post deaktivieren' : 'Post aktivieren'"
              [nzControl]="true"
              [nzCheckedChildren]="checkedTemplate"
              [nzUnCheckedChildren]="unCheckedTemplate"
              [disabled]="!('POSTS::EDIT' | checkPerm)"
              (click)="changePostActive($event, post, !post.enabled)"
            ></nz-switch>
            <ng-template #checkedTemplate><fa-icon icon="eye"></fa-icon></ng-template>
            <ng-template #unCheckedTemplate><fa-icon icon="eye-slash"></fa-icon></ng-template>

            <a *ngIf="showLink && post.url != ''" nz-tooltip="Link öffnen" [href]="post.url" target="_blank">
              <fa-icon-btn class="btn-url"
                           nzType="primary"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           icon="arrow-up-right-from-square"></fa-icon-btn>
            </a>

            <fa-icon-btn *ngIf="showEdit"
                         nzType="default"
                         nz-tooltip="Post bearbeiten"
                         buttonSize="24"
                         iconSize="14"
                         icon="edit"
                         perm="POSTS::EDIT"
                         (btnClick)="editPost($event, post)"></fa-icon-btn>

            <fa-icon-btn *ngIf="showDelete"
                         nzType="default"
                         [nzDanger]="true"
                         nz-tooltip="Post löschen"
                         buttonSize="24"
                         iconSize="14"
                         icon="trash"
                         perm="POSTS::DELETE"
                         (btnClick)="deletePost($event, post)"></fa-icon-btn>

            </span>

  <span class="date">{{post.creationTime | displaydate}}</span>

  <div class="creator">
    <span class="creator">{{post.parentGroup?.title}} | </span>
    <span class="creator" *ngIf="creatorStr === null"><span nz-icon [nzType]="'loading'"></span></span>
    <span class="creator" *ngIf="creatorStr !== null">{{creatorStr}}</span>
  </div>
</div>
