
<div class="main">

  <div class="header">
    <span class="title">baden online</span>
    <span class="searchbar"></span>
  </div>

  <div class="topbar">

    <img *ngIf="avatarSource     !== undefined"  [src]="avatarSource"     alt="profile-image"    class="avatar-img"     >
    <div *ngIf="avatarSource     === undefined"                                                  class="avatar-img"     ></div>
    <img *ngIf="backgroundSource !== undefined"  [src]="backgroundSource" alt="background-image" class="background-img" >
    <div *ngIf="backgroundSource === undefined"                                                  class="background-img" ></div>

    <div class="namebox">
      <span class="name">{{name}}</span>
      <div class="namecheck"></div>
    </div>

    <div class="tabheader"></div>

    <div class="actionbuttons">
      <div class="btn-action-1"></div>
      <div class="btn-action-2"></div>
      <div class="btn-action-3"></div>
    </div>

  </div>

  <div class="contentblock">
    <div class="contentheader"></div>
    <div class="contentdata"></div>
  </div>

</div>
