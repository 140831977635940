import { AuthenticationService } from '../services/authentication.service';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class OrgUserAuthGuard  {

  constructor(private authService: AuthenticationService, private router: Router) {

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (!this.authService.isAuthenticated()) {
      this.router.navigate(['/login']).then(() => {});
      return false;
    }
    if (!this.authService.isOrgUser()) {
      this.router.navigate(['/forbidden']).then(() => {});
      return false;
    }
    return true;
  }

}
