import {Component, OnInit} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {manipulateURLComponents} from "../../utils/url";
import {formatAPIError, showAPIError} from "../../utils/api";
import {NzModalService} from "ng-zorro-antd/modal";
import {CursorToken} from "../../interfaces/utils";

@Component({
  selector: 'app-adminuser-list',
  templateUrl: './adminuser-list.component.html',
  styleUrls: ['./adminuser-list.component.scss']
})
export class AdminUserListComponent implements OnInit {

  users: AdminUser[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  showCreateAdminModal: boolean = false;
  selectedEditAdmin: AdminUser|null = null;
  selectedEditPassword: AdminUser|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listAdminUser(this.nextToken, 24, this.filters);

      if (reset) {
        this.users = data.users;
      } else {
        this.users = [...this.users, ...data.users];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'adminusers'], this.filters));
  }

  deleteAdmin(entry: AdminUser) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Eintrag löschen?',
      nzContent: 'Möchtest du den Benutzer "'+entry.username+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deleteAdmin(entry.id);
          this.users = this.users.filter(p => p.id != entry.id);
          this.notification.success('Benutzer gelöscht', 'Der Benutzer "' + entry.username + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Der Benutzer konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onAdminCreated(entry: AdminUser) {
    this.users = [...this.users, entry];
  }

  onAdminEdited(entry: AdminUser) {
    this.users = this.users.map(p => p.id == entry.id ? entry : p);
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }
}
