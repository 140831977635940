import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'shortid', pure: true })
export class ShortIDPipe implements PipeTransform {

  transform(n: string): string {
    return (n.length === 24) ? n.substring(24-6) : n;
  }

}
