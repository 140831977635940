import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'booleanNot', pure: true })
export class BooleanNotPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: boolean, ...args: unknown[]) {
    return !value;
  }
}
