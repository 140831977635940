<nz-modal [nzVisible]="show !== null"
          nzTitle="Gruppe bearbeiten"
          nzOkText="Bearbeiten"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span class="key">ID</span>
      <input class="value" nz-input [ngModel]="show?.id ?? ''" readonly disabled>
      <span class="key">Parent ID</span>
      <input class="value" nz-input [ngModel]="show?.parentGroupID ?? ''" readonly disabled>
      <span class="key">Position</span>
      <input class="value" nz-input [ngModel]="show?.position ?? ''" readonly disabled>

      <span class="spacer"></span>

      <span class="key">Titel</span>
      <app-validateable-input class="value" (keyup.enter)="execute()" placeholder="Gruppenname" [(value)]="title" [validate]="isValidStr"></app-validateable-input>

      <span class="key">Gruppenbild</span>

      <div class="value cropper_hidden" [class.hidden]="false">
        <input nz-input [ngModel]="(avatar ?? '') === 'deleted' ? '' : (avatar ?? '')" readonly>
        <fa-icon-btn icon="edit"  iconSize="18" buttonSize="32" (btnClick)="avatarMode = 'cropper'; fileAvatar = null" [disabled]="avatarMode === 'cropper'"></fa-icon-btn>
        <fa-icon-btn icon="eye"   iconSize="18" buttonSize="32" (btnClick)="avatarMode = 'view'" [disabled]="avatar === null || avatar === 'deleted' || avatarMode === 'view'"></fa-icon-btn>
        <fa-icon-btn icon="trash" iconSize="18" buttonSize="32" (btnClick)="deleteAvatar()" [disabled]="(avatar === null || avatar === 'deleted') && fileAvatar === null" [nzDanger]="true"></fa-icon-btn>
      </div>
      <div class="value cropper_visible" [class.hidden]="avatarMode !== 'cropper'">
        <nz-upload *ngIf="fileAvatar === null" style="width: 475px;" nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadAvatar">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="upload"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
        </nz-upload>
        <image-cropper *ngIf="fileAvatar != null" #imageCropperAvatar
                       [imageFile]="fileAvatar"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="1"
                       [autoCrop]="false"
                       style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                       format="png"
                       (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="value image_visible" [class.hidden]="avatarMode !== 'view'">
        <!--suppress AngularNgOptimizedImage -->
        <img *ngIf="avatar !== null && avatar !== 'deleted'" [src]="imageSource(avatar)" alt="avatar">
      </div>

      <span class="key">Hintergrundbild</span>

      <div class="value cropper_hidden" [class.hidden]="false">
        <input nz-input [ngModel]="(backgroundImage ?? '') === 'deleted' ? '' : (backgroundImage ?? '')"  readonly>
        <fa-icon-btn icon="edit"  iconSize="18" buttonSize="32" (btnClick)="backgroundMode = 'cropper'; fileBackground = null" [disabled]="backgroundMode === 'cropper'"></fa-icon-btn>
        <fa-icon-btn icon="eye"   iconSize="18" buttonSize="32" (btnClick)="backgroundMode = 'view'" [disabled]="backgroundImage === null || backgroundImage === 'deleted' || backgroundMode === 'view'"></fa-icon-btn>
        <fa-icon-btn icon="trash" iconSize="18" buttonSize="32" (btnClick)="deleteBackgroundImage()" [disabled]="(backgroundImage === null || backgroundImage === 'deleted') && fileBackground === null" [nzDanger]="true"></fa-icon-btn>
      </div>
      <div class="value cropper_visible" [class.hidden]="backgroundMode !== 'cropper'">
        <nz-upload *ngIf="fileBackground === null" style="width: 475px;" nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadBackground">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="upload"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
        </nz-upload>
        <image-cropper *ngIf="fileBackground != null" #imageCropperBackground
                       [imageFile]="fileBackground"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="823/360"
                       [autoCrop]="false"
                       style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                       format="png"
                       (loadImageFailed)="loadImageFailed()"></image-cropper>
      </div>
      <div class="value image_visible" [class.hidden]="backgroundMode !== 'view'">
        <!--suppress AngularNgOptimizedImage -->
        <img *ngIf="backgroundImage !== null && backgroundImage !== 'deleted'" [src]="imageSource(backgroundImage)" alt="avatar">
      </div>

    </div>
  </ng-container>
</nz-modal>
