import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {NzNotificationService} from "ng-zorro-antd/notification";
import {APIService} from "../../services/api.service";
import {AdminUser} from "../../interfaces/adminUser";
import {formatAPIError, showAPIError} from "../../utils/api";
import {generatePronouncablePassword} from "../../utils/pw";

@Component({
  selector: 'app-create-admin-modal',
  templateUrl: './create-admin-modal.component.html',
  styleUrls: ['./create-admin-modal.component.scss']
})
export class CreateAdminModalComponent implements OnInit {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<AdminUser> = new EventEmitter<AdminUser>();

  loading: boolean = false;

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  mail: string = '';
  showPW: boolean = false;
  password1: string = '';
  password2: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.firstName = '';
    this.lastName  = '';
    this.username  = '';
    this.mail      = '';
    this.showPW    = false;
    this.password1 = '';
    this.password2 = '';
  }

  async execute() {
    if (!this.isValidStr(this.firstName))      { this.notification.error('Fehler', 'Bitte valider Name eingeben'); return; }
    if (!this.isValidStr(this.lastName))       { this.notification.error('Fehler', 'Bitte valider Name eingeben'); return; }
    if (!this.isValidStr(this.username))       { this.notification.error('Fehler', 'Bitte valider Benutzername eingeben'); return; }
    if (!this.isValidMail(this.mail))          { this.notification.error('Fehler', 'Bitte valide Email-Addresse eingeben'); return; }
    if (!this.isValidPW1()(this.password1))    { this.notification.error('Fehler', 'Bitte valides Passwort eingeben (midestens 8 Zeichen)'); return; }
    if (!this.isValidPW2()(this.password2))    { this.notification.error('Fehler', 'Passwörter stimmern nicht überein'); return; }


    try {
      this.loading = true;

      const data = await this.api.createAdmin(this.firstName, this.lastName, this.username, this.mail, this.password1);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

      this.notification.success('Erfolg!', 'Benutzer wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht erstellt werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }

  isValidPW1() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return v.length >= 8;
    }
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1()(this.password1) && v == this.password1;
    }
  }

  randomPassword() {
    this.showPW = true;
    this.password2 = this.password1 = generatePronouncablePassword(5);
  }
}
