
<div class="main">

  <figure class="iphone">
    <div class="header">
      <div class="image-missing">Kein Bild vorhanden</div>
      <div class="image" style="--background-source: url('{{backgroundSource}}');"></div>
    </div>
    <div class="body">
      <div class="organization-header">
        <div class="emblem" style="--avatar-source: url('{{avatarSource}}');">
        </div>
        <div class="title" style="">
          {{ org?.name }}
        </div>
      </div>
      <div class="organization-body">

        <div class="badge-wrapper">
          <div class="badge">Veranstaltungskalender</div>
          <div class="badge">Abfallkalender</div>
          <div class="badge">Infozentrum</div>
          <ng-template ngFor let-group [ngForOf]="groups" let-i="index">
            <div class="badge">{{group.title}}</div>
          </ng-template>
        </div>
      </div>
    </div>
  </figure>

</div>
