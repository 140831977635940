
<ng-template  #providerSelector>
  <nz-form-item *ngIf="forcedOrgId === null || selectedOrg === null" class="custom-input">
    <nz-form-label>Contentprovider</nz-form-label>
    <nz-form-control>
      <nz-select class="value" nzShowSearch nzAllowClear nzPlaceHolder="ContentProvider auswählen" [(ngModel)]="selectedOrg" (ngModelChange)="loadData()">
        <ng-container *ngFor="let org of allOrgs" >
          <nz-option [nzLabel]="org.name" [nzValue]="org.id"></nz-option>
        </ng-container>
      </nz-select>
    </nz-form-control>
  </nz-form-item>
</ng-template>

<ng-template  #destinationSelector>

  <nz-form-item *ngIf="(forcedGroupId === null && forcedEventId === null) || selectedTarget === null" class="custom-input">
    <nz-form-label nzRequired>Ziel/Gruppe</nz-form-label>
    <nz-form-control>
      <nz-select class="value" nzShowSearch nzAllowClear nzPlaceHolder="Ziel auswählen" [(ngModel)]="selectedTarget" (ngModelChange)="loadData()" [disabled]="selectedOrg === null">

        <nz-option nzLabel="Direkt" nzCustomContent [nzValue]="TargetToStr({'type':'ROOT','value':''})">
          <div class="target-select-option">
            <fa-icon class="icon" [icon]="['fas', 'building']"></fa-icon>
            <span class="text">Direkt</span>
          </div>
        </nz-option>

        <ng-container *ngFor="let grp of allGroups" >
          <nz-option nzLabel="Gruppe: {{grp.title}}" nzCustomContent [nzValue]="TargetToStr({'type':'GROUP','value':grp.id})">
            <div class="target-select-option">
              <fa-icon class="icon" [icon]="['fas', 'frame']"></fa-icon>
              <span class="type">Gruppe: </span>
              <span class="text">{{grp.title}}</span>
            </div>
          </nz-option>
        </ng-container>

        <ng-container *ngFor="let evt of allEvents" >
          <nz-option nzLabel="Event: {{evt.title}}" nzCustomContent [nzValue]="TargetToStr({'type':'EVENT','value':evt.id})">
            <div class="target-select-option">
              <fa-icon class="icon" [icon]="['fas', 'calendar-star']"></fa-icon>
              <span class="type">Event: </span>
              <span class="text">{{evt.title}}</span>
            </div>
          </nz-option>
        </ng-container>

      </nz-select>
    </nz-form-control>
  </nz-form-item>

</ng-template>

<nz-modal [nzVisible]="show"
          nzTitle="Neuen Post erstellen"
          nzOkText="Erstellen"
          [nzOkLoading]="execLoading"
          (nzOnOk)="submit()"
          (nzOnCancel)="close()"
          [nzWidth]="768"
          [nzOkDisabled]="!validateForm.valid">
  <ng-container *nzModalContent>

    <ng-container *ngIf="initLoading">

      <nz-spin></nz-spin>

    </ng-container>

    <form *ngIf="!initLoading" nz-form [nzLayout]="'vertical'" class="create-post-form" [formGroup]="validateForm">

      <ng-container *ngTemplateOutlet="providerSelector"></ng-container>

      <ng-container *ngTemplateOutlet="destinationSelector"></ng-container>


      <nz-form-item class="custom-input">
        <nz-form-label nzRequired>Titel</nz-form-label>
        <nz-form-control nzErrorTip="Please input your username!">
          <input nz-input formControlName="title" placeholder="Titel" required/>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Beschreibung</nz-form-label>
        <nz-form-control nzErrorTip="Please input your username!">
          <textarea nz-input formControlName="text" ></textarea>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-label>URL</nz-form-label>
        <nz-form-control >
          <nz-input-group [nzSuffix]="suffixIconLoad">
            <input type="text" nz-input formControlName="url" placeholder="Externe URL" />
          </nz-input-group>
          <ng-template #suffixIconLoad>
            <button nz-button (click)="queryData()" [nzLoading]="status === 'loading'">
              <span nz-icon nzType="cloud-download"></span>
            </button>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item class="custom-group-input">
        <nz-form-label>Image URL</nz-form-label>
        <nz-form-control >
          <nz-input-group [nzPrefix]="prefixImage">
            <input type="text" nz-input formControlName="imageURL" placeholder="Thumbnail URL" />
          </nz-input-group>
          <ng-template #prefixImage>
            <ng-container *ngIf="validateForm.get('imageURL')?.value">
                <img nz-image [nzSrc]="validateForm.get('imageURL')?.value" width="50" height="28" style="cursor: pointer">
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>


      <nz-form-item>
        <nz-form-label>Bilder</nz-form-label>
        <nz-form-control>
          <nz-upload nzListType="picture-card"
                     id="imageSelection"
                     [(nzFileList)]="imageList"
                     [nzShowButton]="true"
                     [nzPreview]="handleGalleryPreview"
                     [nzDownload]="undefined"
                     [nzMultiple]="true"
                     nzFileType="image/png,image/jpeg,image/gif,image/bmp"
                     [nzShowUploadList]="{showDownloadIcon: false, showPreviewIcon:true, showRemoveIcon:true}"
                     [nzCustomRequest]="galleryAction">
            <div>
              <span nz-icon nzType="plus"></span>
              Auswählen
            </div>
          </nz-upload>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item>
        <nz-form-label>Datei</nz-form-label>
        <nz-form-control>
          <nz-upload  [nzMultiple]="false"
                      nzListType="picture"
                      [nzBeforeUpload]="beforeUploadFilePostFile">
            <button nz-button>
              <span nz-icon [nzType]="'file'"></span>
              Datei auswählen {{ file?.name }}
            </button>
          </nz-upload>

          <p>{{ file?.name }}</p>

        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="margin-top: 2rem;">
        <nz-form-label nzRequired>Veröffentlichen</nz-form-label>
        <nz-form-control>
          <nz-radio-group formControlName="publish" nzButtonStyle="solid">
            <label nz-radio-button nzValue="INACTIVE">Später manuell veröffentlichen</label>
            <label nz-radio-button nzValue="ACTIVE">Sofort veröffentlichen</label>
            <label nz-radio-button nzValue="TIMESTAMP">Geplante Veröffentlichung</label>
          </nz-radio-group>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item *ngIf="validateForm.get('publish')?.value === 'TIMESTAMP'" class="custom-input">
        <nz-form-label nzRequired>Veröffentlichen am:</nz-form-label>
        <nz-form-control>
          <nz-date-picker [nzAllowClear]="true"
                          formControlName="timestampPublishDate"
                          nzShowTime
                          nzShowToday="true"
                          nzPlaceHolder="Datum und Uhrzeit auswählen"
                          style="width: 100%"></nz-date-picker>
        </nz-form-control>
      </nz-form-item>


    </form>

    <!--<div class="content" *ngIf="!initLoading">


      <span class="key">URL</span>
      <app-validateable-input class="value-extra" placeholder="Link" [(value)]="url" [validate]="isValidLink"></app-validateable-input>
      <fa-icon-btn class="extra1" nzType="primary" buttonSize="32" icon="cloud-arrow-down"      (btnClick)="queryLinkData()"       [loading]="status === 'loading'" [disabled]="!isValidLink(url)"></fa-icon-btn>
      <fa-icon-btn class="extra2" nzType="primary" buttonSize="32" icon="video-arrow-down-left" (btnClick)="queryVideoData()"      [loading]="status === 'loading'" [disabled]="!isValidVideoLink(url)"></fa-icon-btn>
      <fa-icon-btn class="extra3" nzType="primary" buttonSize="32" icon="wrench-simple"         (btnClick)="manuallySetStatusActive()"                              [disabled]="status !== 'initial'"></fa-icon-btn>

   &lt;!&ndash;   <img nz-image class="fullspan linkpost-image" alt="preview image" src="" [nzSrc]="str2Null(imageURL) ?? firstImageSrc(imageList) ?? fallbackImage">&ndash;&gt;
      <app-validateable-input class="fullspan" placeholder="URL (Thumbnail)" [(value)]="imageURL" [validate]="isValidLink" [disabled]="status !== 'active'"></app-validateable-input>

      <span class="key">Titel</span>
      <app-validateable-input class="value" placeholder="Titel" [(value)]="title" [validate]="isValidStr" [disabled]="status !== 'active'"></app-validateable-input>

      <span class="key">Text</span>
      <app-validateable-textarea class="value" [rows]="5" [(value)]="text" [disabled]="status !== 'active'" [validate]="isValidStr"></app-validateable-textarea>

      <span class="key">Images</span>
    &lt;!&ndash;  <div class="fullspan gallery-box">
        <nz-upload nzListType="picture-card"
                   [(nzFileList)]="imageList"
                   [nzShowButton]="true"
                   [nzPreview]="handleGalleryPreview"
                   [nzDownload]="undefined"
                   nzFileType="image/png,image/jpeg,image/gif,image/bmp"
                   [nzShowUploadList]="{showDownloadIcon: false, showPreviewIcon:true, showRemoveIcon:true}"
                   [nzCustomRequest]="galleryAction">
          <div>
            <span nz-icon nzType="plus"></span>
            <div style="margin-top: 8px">Upload</div>
          </div>
        </nz-upload>
        <nz-modal
          [nzVisible]="preview != null"
          [nzContent]="modalContent"
          [nzFooter]="null"
          (nzOnCancel)="preview = null">
          <ng-template #modalContent>
            <img [src]="preview" [ngStyle]="{ width: '100%' }"  alt="image"/>
          </ng-template>
        </nz-modal>
      </div>&ndash;&gt;

      <span class="key">File</span>
      <div class="fullspan filepost-box">

        <nz-upload nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadFilePostFile">
          <p class="ant-upload-drag-icon">
            <span nz-icon [nzType]="file === null ? 'upload' : 'file'"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe eine Datei hierher um sie hochzuladen</p>
          <p *ngIf="file !== null && !isString(file) && file.name !== undefined" class="ant-upload-hint">{{file.name}}</p>
        </nz-upload>
      </div>

      <ng-container *ngTemplateOutlet="timingSelector"></ng-container>

    </div>-->

  </ng-container>
</nz-modal>
