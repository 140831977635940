import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {showAPIError} from "../../utils/api";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {isValidIBANNumber} from "../../utils/iban";
import {Organization, OrgType} from "../../interfaces/organization";
import {generatePronouncablePassword} from "../../utils/pw";
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'app-create-org-modal',
  templateUrl: './create-org-modal.component.html',
  styleUrls: ['./create-org-modal.component.scss']
})
export class CreateOrgModalComponent implements OnInit, OnChanges {

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<{org:Organization,user:OrganizationUser}> = new EventEmitter<{org:Organization,user:OrganizationUser}>();

  loading: boolean = false;

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  mail: string = '';

  customernumber: string = '';
  addressPLZ: string = '';
  addressCity: string = '';
  addressStreet: string = '';
  addressNumber: string = '';
  addressExtra: string = '';

  bankingIban: string = '';
  bankingName: string = '';

  telephonenumber: string = '';

  sendPasswordMail: boolean = false;
  showPW: boolean = false;
  password1: string = '';
  password2: string = '';

  orgName: string = '';
  orgType: OrgType | null = null;
  orgPurpose: string = '';

  purposes: string[] = [];

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
    this.listPurposeSuggestions().then(() => {})
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      this.guessCustomerNumber().then(() => {})
    }
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.loading               = false;
    this.firstName             = '';
    this.lastName              = '';
    this.username              = '';
    this.mail                  = '';
    this.customernumber        = '';
    this.addressPLZ            = '';
    this.addressCity           = '';
    this.addressStreet         = '';
    this.addressNumber         = '';
    this.addressExtra          = '';
    this.bankingIban           = '';
    this.bankingName           = '';
    this.telephonenumber       = '';
    this.sendPasswordMail      = false;
    this.showPW                = false;
    this.password1             = '';
    this.password2             = '';
    this.orgName               = '';
    this.orgType               = null;
    this.orgPurpose            = '';
  }

  async guessCustomerNumber() {
    try {
      const data = await this.api.guessCustomerNumber();

      if (this.customernumber === '') this.customernumber = data.next;

    } catch (err) {
      showAPIError(this.notification, 'Nächste Kundennummer konnte nicht ermittelt werden', err)
    }
  }

  async listPurposeSuggestions() {
    try {
      const data = await this.api.listOrgPurposes();
      this.purposes = data.purposes;
    } catch (err) {
      showAPIError(this.notification, 'Purposes konnte nicht geladen werden', err)
    }
  }

  async execute() {
    if (!this.isValidStr(this.firstName))                 { this.notification.error('Fehler', 'Bitte validen Vorname eingeben'); return; }
    if (!this.isValidStr(this.lastName))                  { this.notification.error('Fehler', 'Bitte validen Nachname eingeben'); return; }
    if (!this.isValidStr(this.username))                  { this.notification.error('Fehler', 'Bitte validen Benutzername eingeben'); return; }
    if (!this.isValidMail(this.mail))                     { this.notification.error('Fehler', 'Bitte valide Email-Addresse eingeben'); return; }
    if (!(this.isValidPW1()(this.password1) ?? true))     { this.notification.error('Fehler', 'Bitte valides Passwort eingeben (midestens 8 Zeichen)'); return; }
    if (!(this.isValidPW2()(this.password2) ?? true))     { this.notification.error('Fehler', 'Passwörter stimmern nicht überein'); return; }

    if (!this.isValidCustomerNumber(this.customernumber)) { this.notification.error('Fehler', 'Bitte valide Kundennummer eingeben'); return; }

    if (!this.isValidIBAN(this.bankingIban))              { this.notification.error('Fehler', 'Bitte valider Bankdaten eingeben'); return; }

    if (!this.isValidTelephone(this.telephonenumber))     { this.notification.error('Fehler', 'Bitte valide Telefonnummer eingeben'); return; }

    if (!this.isValidStr(this.orgName))                   { this.notification.error('Fehler', 'Bitte validen Namen für ContentProvider eingeben'); return; }
    if (this.orgType === null)                            { this.notification.error('Fehler', 'Bitte validen Typ eingeben'); return; }

    try {
      this.loading = true;

      const data = await this.api.createOrgUserWithNewOrg(
        this.firstName, this.lastName,
        this.username, this.mail,
        this.sendPasswordMail, this.password1,
        this.customernumber,
        this.addressPLZ, this.addressCity, this.addressStreet, this.addressNumber, this.addressExtra,
        this.bankingIban, this.bankingName,
        this.telephonenumber,
        this.orgName,
        this.orgType,
        this.orgPurpose);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

      this.notification.success('Erfolg!', 'Benutzer wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht erstellt werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }

  isValidPW1() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
        if (this.sendPasswordMail) return null;
        return v.length >= 8;
    }
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      if (this.sendPasswordMail) return null;
      return this.isValidPW1()(this.password1) && v == this.password1;
    }
  }

  isValidCustomerNumber(v: string) {
    return true;
  }

  isValidIBAN(iban: string) {
    if (iban === '') return true;
    return isValidIBANNumber(iban);
  }

  isValidTelephone(tel: string) {
    return true;
  }

  randomPassword() {
    this.showPW = true;
    this.password2 = this.password1 = generatePronouncablePassword(5);
  }
}
