
<div class="entry" *ngIf="impress !== null">

  <div class="content-wrapper">

    <p><strong>Angaben gemäß § 5 TMG</strong></p>
    <dl>
      <dt>Firmenname:</dt>
      <dd>{{ impress.name }} {{ impress.legalForm }}</dd>

      <dt>Vertreten durch:</dt>
      <dd>{{ impress.representativeName }}</dd>

      <dt>Adresse:</dt>
      <dd>{{ impress.address.street }} {{ impress.address.houseNumber }}</dd>
      <dt></dt>
      <dd>{{ impress.address.postalCode }} {{ impress.address.city }}</dd>

      <dt>Kontakt:</dt>
      <dd>Telefon: {{ impress.contact.phone }}</dd>
      <dt></dt>
      <dd>E-Mail: {{ impress.contact.email }}</dd>

      <dt>Umsatzsteuer-ID gemäß §27:</dt>
      <dd>{{ impress.taxId ?? '-'}}</dd>

      <dt>Aufsichtsbehörde:</dt>
      <dd>{{ impress.supervisingAuthority ?? '-' }}</dd>

      <dt>Registereintrag:</dt>
      <dd>{{ impress.registerInfo ?? '-' }}</dd>
    </dl>

  </div>

  <span class="actionbuttons">

    <fa-icon-btn *ngIf="showEdit"
                           class="btn-url"
                           nzType="primary"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           perm="EVENTS::EDIT"
                           (btnClick)="editImpress(impress)"
                           icon="pen"></fa-icon-btn>

    <fa-icon-btn *ngIf="showDelete"
                            class="btn-url"
                            nzType="primary"
                            [nzDanger]="true"
                            [stopPropagation]="true"
                            buttonSize="24"
                            iconSize="14"
                            perm="EVENTS::DELETE"
                            (btnClick)="deleteImpress(impress)"
                            icon="trash"></fa-icon-btn>

    <!-- <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           nz-tooltip="Event aktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye"
                           perm="EVENTS::EDIT"
                           [disabled]="event.enabled"
                           (btnClick)="activateEvent(event)"></fa-icon-btn>

    <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           [nzDanger]="true"
                           nz-tooltip="Event deaktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye-slash"
                           perm="EVENTS::EDIT"
                           [disabled]="!event.enabled"
                           (btnClick)="deactivateEvent(event)"></fa-icon-btn>-->

          </span>

</div>
