import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Group} from "../../interfaces/group";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-list-entry-group',
  templateUrl: './list-entry-group.component.html',
  styleUrls: ['./list-entry-group.component.scss']
})
export class ListEntryGroupComponent {

  @Input() group: Group|null = null;

  @Input() showDelete: boolean = true;
  @Input() showChangeEnabled: boolean = true;
  @Input() showEdit: boolean = true;

  @Output() groupUpdated: EventEmitter<Group[] | Group> = new EventEmitter<Group[] | Group>();
  @Output() groupDeleted: EventEmitter<Group> = new EventEmitter<Group>();
  @Output() groupActivated: EventEmitter<Group> = new EventEmitter<Group>();

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  showEditGroup: Group|null = null;

  avatarSource(data: Group) {
    if (data.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${data.organizationID}/groups/${data.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${data.avatarImageID}`;
  }

  activateGroup(group: Group) {
    this.groupActivated.emit(group);
  }

  deactivateGroup(data: Group) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gruppe deaktivieren?',
      nzContent: 'Möchtest du die Gruppe "'+data.title+'" wirklich deaktivieren? Es werden alle Untergruppen ebenfalls deaktiviert.',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const res = await this.api.disableGroup(data.organizationID, data.id);
          this.notification.success('Gruppe deaktiviert', 'Die Gruppe "' + data.title + '" wurde deaktiviert');
          this.groupUpdated.emit(res.groups);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deleteGroup(group: Group) {
    this.groupDeleted.emit(group);
  }
}
