<nz-modal [nzVisible]="show"
          nzTitle="Neues Impressum anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="submitForm()"
          (nzOnCancel)="close()"
          nzWidth="800px"
>
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>


      <form nz-form [formGroup]="validateForm" (ngSubmit)="submitForm()" nzLayout="vertical" class="ant-form-vertical ng-pristine ng-invalid">
        <nz-form-item>
          <nz-form-label nzRequired>Vollständiger Name</nz-form-label>
          <nz-form-control>
            <input nz-input formControlName="name" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzRequired>Name des Vertretungsberechtigten</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="representativeName" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzRequired>Rechtsform</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="legalForm" />
          </nz-form-control>
        </nz-form-item>

        <div formGroupName="address">
          <div class="addressWrapper">
            <nz-form-item>
              <nz-form-label nzRequired>Straße</nz-form-label>
              <nz-form-control >
                <input nz-input formControlName="street"  />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired>Hausnummer</nz-form-label>
              <nz-form-control >
                <input nz-input formControlName="houseNumber"  />
              </nz-form-control>
            </nz-form-item>
          </div>

          <div class="addressWrapper">
            <nz-form-item>
              <nz-form-label nzRequired>Postleitzahl</nz-form-label>
              <nz-form-control >
                <input nz-input formControlName="postalCode"  />
              </nz-form-control>
            </nz-form-item>

            <nz-form-item>
              <nz-form-label nzRequired>Stadt</nz-form-label>
              <nz-form-control >
                <input nz-input formControlName="city"  />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>

        <div formGroupName="contact">
        <nz-form-item>
          <nz-form-label nzRequired>Kontaktmöglichkeit E-Mail</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="email" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label nzRequired>Kontaktmöglichkeit Telefon</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="phone" />
          </nz-form-control>
        </nz-form-item>
        </div>

        <nz-form-item>
          <nz-form-label>Umsatzsteuer-Identifikationsnummer</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="taxId" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Register mit Registernummer</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="registerInfo" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Zuständige Aufsichtsbehörde bzw. Kammer</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="supervisingAuthority" />
          </nz-form-control>
        </nz-form-item>

        <nz-form-item>
          <nz-form-label>Plattform zur Online-Streitbeilegung der Europäischen Kommission</nz-form-label>
          <nz-form-control >
            <input nz-input formControlName="onlineDisputeResolutionPlatform" />
          </nz-form-control>
        </nz-form-item>

        <p>
          <span style="color: red">*</span> Pflichtfelder
        </p>
<!--
        <nz-form-item nz-row>
          <nz-form-control>
            <button nz-button nzType="primary" [disabled]="!validateForm.valid">Submit</button>
          </nz-form-control>
        </nz-form-item>-->
      </form>


  </ng-container>
</nz-modal>

