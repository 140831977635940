
<nz-modal [nzVisible]="show !== null"
          nzTitle="Profilbild hochladen"
          nzOkText="Speichern"
          [nzOkLoading]="loading"
          [nzOkDisabled]="file === null"
          (nzOnCancel)="close()"
          (nzOnOk)="okay()">
  <ng-container *nzModalContent>
    <div class="content">

      <nz-upload *ngIf="file === null" nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUpload">
        <p class="ant-upload-drag-icon">
          <span nz-icon nzType="upload"></span>
        </p>
        <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
      </nz-upload>


      <image-cropper *ngIf="file != null" #imageCropper
                     [imageFile]="file"
                     [maintainAspectRatio]="true"
                     [aspectRatio]="1"
                     [autoCrop]="false"
                     style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                     format="png"
                     (loadImageFailed)="loadImageFailed()"
      ></image-cropper>


    </div>
  </ng-container>
</nz-modal>
