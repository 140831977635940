<app-default-frame>

    <app-create-post-modal [(show)]="showCreateNewPostModal" (success)="reloadPage()"
                         [forcedEventId]="eventId" [forcedOrgId]="orgid"></app-create-post-modal>
<div>
    <div class="top-right-elements">
        <span class="actionbuttons">
            <fa-icon-btn nzType="primary"
                        (btnClick)="showCreateNewPostModal = true"
                         text="Neuen Post erstellen"
                         iconSize="18" buttonSize="32" textSize="16"
                         icon="note-sticky">
            </fa-icon-btn>
        </span>
    </div>
    <div class="topbar">
      <div class="avatarbox">
        <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="128" [nzIcon]="'user'"
                   [nzSrc]="avatarSource() ?? ''"></nz-avatar>
      </div>

      <div>
        <h1 class="name">{{event?.title}}</h1>
        <h2>{{event?.startTime | date:'EEE, dd MMM YYYY hh:mm' }} - {{event?.endTime | date: 'EEE, dd MMM YYYY hh:mm'}}</h2>
      </div>
    </div>
</div>

<nz-divider></nz-divider>

<div class="cardPlacement" >
<nz-card [nzTitle]="'Daten'" >
    <div class="row">
        <span>Aktiv</span>

        <nz-switch
        style="margin-top: -1px; margin-bottom: 1rem;"
        [ngModel]="event?.enabled"
        [nz-tooltip]="event?.enabled ? 'Event deaktivieren' : 'Event aktivieren'"
        [nzControl]="true"
        (click)="changeEventActive(event, !event?.enabled)"
        ></nz-switch>

    </div>

    <div *ngIf="event" style="display: flex; flex-direction: column; gap: 1rem;">
        <div>
            <nz-input-group [nzAddOnBefore]="prefix2">
                <ng-template #prefix2>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Start</span>
                </ng-template>
                <nz-date-picker [(ngModel)]="startDate" nzShowTime nzFormat="MMM dd, yyyy" class="nz-input-group-picker"></nz-date-picker>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix3">
                <ng-template #prefix3>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Ende</span>
                </ng-template>
                <nz-date-picker [(ngModel)]="endDate" nzShowTime nzFormat="MMM dd, yyyy" class="nz-input-group-picker"></nz-date-picker>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix6">
                <ng-template #prefix6>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Website</span>
                </ng-template>
                <app-validateable-input class="value" placeholder="Website" [(value)]="event.website" [validate]="isValidLink"></app-validateable-input>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix4">
                <ng-template #prefix4>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">PLZ</span>
                </ng-template>
                <app-validateable-input class="value" placeholder="Zip Code" [(value)]="event.address.zipcode" [validate]="isValidNum()"></app-validateable-input>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix5">
                <ng-template #prefix5>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Stadt</span>
                </ng-template>
                <app-validateable-input class="value" placeholder="City" [(value)]="event.address.city" [validate]="isValidString()"></app-validateable-input>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix7">
                <ng-template #prefix7>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Straße</span>
                </ng-template>
            <app-validateable-input class="value" placeholder="Street" [(value)]="event.address.street" [validate]="isValidString()"></app-validateable-input>
            </nz-input-group>
        </div>
        <div>
            <nz-input-group [nzAddOnBefore]="prefix8">
                <ng-template #prefix8>
                    <span [style.display]="'inline-flex'" [style.min-width]="'60px'">Hausnr.</span>
                </ng-template>
            <app-validateable-input class="value" placeholder="Number" [(value)]="event.address.number" [validate]="isValidNum()"></app-validateable-input>
            </nz-input-group>
        </div>
        <button nz-button nzType="primary" (click)="saveEventData()">Speichern</button>
    </div>
</nz-card>

<nz-card [nzTitle]="'Beschreibung'">
<!--Text input for event.description-->
    <div *ngIf="event">
        <app-validateable-textarea [rows]="12" [(value)]="event.description"></app-validateable-textarea>
        <button nz-button nzType="primary" (click)="saveEventDescription()" style="margin-top: 1rem;">Speichern</button>
    </div>
</nz-card>
</div>

<nz-divider></nz-divider>

<!--Event Posts List-->

<nz-card [nzTitle]="'Posts'">
    <app-event-post-list></app-event-post-list>
</nz-card>

</app-default-frame>
