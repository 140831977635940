<nz-modal [nzVisible]="show"
          nzTitle="Neue Gruppe anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>

    <div *ngIf="!initLoading" class="content">

      <ng-container *ngIf="forcedOrgId === null || selectedOrg === null">
        <span class="key">ContentProvider</span>
        <nz-select class="value" nzShowSearch nzAllowClear nzPlaceHolder="ContentProvider auswählen" [(ngModel)]="selectedOrg" (ngModelChange)="loadData()">
          <ng-container *ngFor="let org of allOrgs" >
            <nz-option [nzLabel]="org.name" [nzValue]="org.id"></nz-option>
          </ng-container>
        </nz-select>
      </ng-container>

      <span>Titel</span>
      <app-validateable-input placeholder="Gruppenname" [(value)]="title" [validate]="isValidStr"   ></app-validateable-input>

      <span>Gruppenbild</span>
      <ng-container>
        <nz-upload *ngIf="fileAvatar === null" style="width: 475px;" nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadAvatar">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="upload"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
        </nz-upload>
        <image-cropper *ngIf="fileAvatar != null" #imageCropperAvatar
                       [imageFile]="fileAvatar"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="1"
                       [autoCrop]="false"
                       style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                       format="png"
                       (loadImageFailed)="loadImageFailed()"></image-cropper>
      </ng-container>

      <span>Hintergrundbild</span>
      <ng-container>
        <nz-upload *ngIf="fileBackground === null" style="width: 475px;" nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUploadBackground">
          <p class="ant-upload-drag-icon">
            <span nz-icon nzType="upload"></span>
          </p>
          <p class="ant-upload-text">Klicke oder ziehe ein Bild hierher um es hochzuladen</p>
        </nz-upload>
        <image-cropper *ngIf="fileBackground != null" #imageCropperBackground
                       [imageFile]="fileBackground"
                       [maintainAspectRatio]="true"
                       [aspectRatio]="823/360"
                       [autoCrop]="false"
                       style="width: 475px; --cropper-outline-color: rgba(255, 255, 255, .75)"
                       format="png"
                       (loadImageFailed)="loadImageFailed()"></image-cropper>
      </ng-container>

    </div>
  </ng-container>
</nz-modal>
