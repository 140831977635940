import {Component, OnInit} from '@angular/core';
import {CursorToken} from "../../interfaces/utils";
import {SerializedParamCollection} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Report, ReportStatus} from "../../interfaces/report";

@Component({
  selector: 'app-report-list',
  templateUrl: './report-list.component.html',
  styleUrls: ['./report-list.component.scss']
})
export class ReportListComponent implements OnInit {

  reports: Report[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;
  initialLoading: boolean = true;


  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    await this.fetchData(true, true);
  }

  async fetchData(reset: boolean, initial: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      if (initial) this.initialLoading = true;
      this.loading = true;

      const data = await this.api.listReports(this.nextToken, 64, this.filters);

      if (reset) {
        this.reports = data.reports;
      } else {
        this.reports = [...this.reports, ...data.reports];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Meldungen konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
      if (initial) this.initialLoading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'reports'], this.filters));
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true, false).then(()=>{});
  }

  async approveReport(report: Report) {

    this.loading = true;

    try {
      const res = await this.api.updateReport(report.id, ReportStatus.APPROVED)

      const index = this.reports.findIndex(r => r.id === report.id);
      if (index !== -1) {
        this.reports[index] = res;
      }

      this.notification.success('Meldung bearbeitet', 'Post/Event deaktiviert.');

    } catch (err) {
      showAPIError(this.notification, 'Meldung konnte nicht bearbeitet werden.', err)
    } finally {
      this.loading = false;
    }

  }

  async rejectReport(report: Report) {

    this.loading = true;

    try {
      const res = await this.api.updateReport(report.id, ReportStatus.REJECTED)

      const index = this.reports.findIndex(r => r.id === report.id);
      if (index !== -1) {
        this.reports[index] = res;
      }
      this.notification.success('Meldung bearbeitet', 'Post weiterhin sichtbar');

    } catch (err) {
      showAPIError(this.notification, 'Meldung konnte nicht bearbeitet werden.', err)
    } finally {
      this.loading = false;
    }

  }

  statusText(status: ReportStatus): string {
    switch (status) {
      case ReportStatus.PENDING: return 'Ausstehend';
      case ReportStatus.APPROVED: return 'Blockiert';
      case ReportStatus.REJECTED: return 'Abgelehnt';
    }
  }

}
