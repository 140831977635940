<nz-input-group [nzAddOnBefore]="inputtitle !== '' ? prefix : undefined"  [nzSuffix]="suffix">
  <input nz-input #inputComp
         [placeholder]="placeholder"
         [(ngModel)]="value"
         (ngModelChange)="valueChange.emit($event)"
         (keydown.enter)="onKeyboardEnter.emit($event)"
         (keydown.escape)="onKeyboardEscape.emit($event)"
         [type]="type"
         [attr.name]="preventAutofill ? uuidName : ''"
         [autocomplete]="preventAutofill ? (type === 'password' ? 'new-password' : uuidAutocomplete) : ''"
         [disabled]="disabled || autoFillDisabledOverride">

  <ng-template #suffix>
    <i nz-icon class="icn_warn" nzType="warning"      *ngIf="validate(value) === false" nzTheme="outline"></i>
    <i nz-icon class="icn_okay" nzType="check-square" *ngIf="validate(value) === true"  nzTheme="outline"></i>
  </ng-template>

  <ng-template #prefix>
    <span [style.display]="'inline-flex'" [style.min-width]="mintitlewidth != null ? mintitlewidth+'px' : undefined">{{inputtitle}}</span>
  </ng-template>

</nz-input-group>
