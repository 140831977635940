import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {Post} from "../../interfaces/post";
import {formatAPIError, showAPIError} from "../../utils/api";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {AuthenticationService} from "../../services/authentication.service";
import {Organization} from "../../interfaces/organization";
import {Group} from "../../interfaces/group";
import {CursorToken, ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-post-list',
  templateUrl: './org-post-list.component.html',
  styleUrls: ['./org-post-list.component.scss']
})
export class OrgPostListComponent implements OnInit {
  loading: boolean = true;

  showCreateNewPost = false;

  orgid: ObjectId|null = null;
  userid: ObjectId|null = null;

  org: Organization|null = null;

  posts: Post[] = [];
  nextToken: CursorToken = "@start";

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.userid = this.auth.getSelfID();
    this.orgid = this.auth.getSelfOrgID();
    await this.fetchOrg();
    await this.fetchPosts(true);
  }

  async fetchOrg() {
    if (this.orgid  === null) return;

    try {
      this.loading = true;

      this.org = await this.api.getOrganization(this.orgid);

    } catch (err) {
      showAPIError(this.notification, 'ContentProvider konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async fetchPosts(reset: boolean) {

    if (this.userid === null) return;
    if (this.orgid  === null) return;

    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listOrgPosts(this.orgid, this.nextToken, 24);

      if (reset) {
        this.posts = data.posts;
      } else {
        this.posts = [...this.posts, ...data.posts];
      }
      this.nextToken = data.nextPageToken;

    } catch (err) {
      showAPIError(this.notification, 'Posts konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  onPostCreated(v: Post) {
    this.posts = [v, ...this.posts];
  }

  onPostUpdated(newdata: Post) {
    this.posts = this.posts.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onPostDeleted(newdata: Post) {
    this.posts = this.posts.filter(g => g.id !== newdata.id);
  }
}
