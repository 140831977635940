import {Component, OnInit} from '@angular/core';
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {AppUser} from "../../interfaces/applicationUser";
import {CursorToken} from "../../interfaces/utils";

@Component({
  selector: 'app-appuser-list',
  templateUrl: './appuser-list.component.html',
  styleUrls: ['./appuser-list.component.scss']
})
export class AppUserListComponent implements OnInit {

  users: AppUser[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;
  jwt: string|null = null;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listAppUser(this.nextToken, 24, this.filters);

      if (reset) {
        this.users = data.users;
      } else {
        this.users = [...this.users, ...data.users];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'users'], this.filters));
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }

  async queryJWT(u: AppUser) {
    try {
      const data = await this.api.getAppUserJWT(u.id);
      this.jwt = data.token;
    } catch (err) {
      showAPIError(this.notification, 'JWT konnten nicht erstellt werden', err)
    }
  }
}
