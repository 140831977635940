
<app-no-frame>

  <main>

    <span class="header">
      <img ngSrc="/assets/bo_logo.png" width="500" height="401" alt="logo" style="height:4em; width:4em">
      <h1 style="margin-bottom: 0; align-self: center; margin-left: 0.661em;">Passwort zurücksetzen</h1>
    </span>

    <nz-alert *ngIf="errorText != ''" nzType="error" [nzMessage]="errorText"></nz-alert>

    <input type="email" [(ngModel)]="email" nz-input placeholder="Email" />

    <button nz-button
            class="login-form-button login-form-margin"
            style="margin-top: 16px"
            [nzType]="'primary'"
            [nzLoading]="resetLoading"
            (click)="pwreset()">Passwort zurücksetzen</button>

  </main>


</app-no-frame>

