
<app-default-frame>

  <app-upload-org-avatar-modal [(show)]="selectedEditAvatar"
                               (success)="setAvatar($event, 'image/png')"></app-upload-org-avatar-modal>

  <app-upload-org-backgroundimage-modal [(show)]="selectedEditBackgroundImage"
                                        (success)="setBackgroundImage($event, 'image/png')"></app-upload-org-backgroundimage-modal>

  <nz-modal [nzVisible]="editProfileText !== ''"
            nzTitle="{{editProfileText}} bearbeiten"
            nzOkText="Speichern"
            [nzOkLoading]="loading"
            (nzOnOk)="updateProfileText()"
            (nzOnCancel)="editProfileText = ''">
    <ng-container *nzModalContent>
      <app-validateable-input [focusTrigger]="editProfileText"
                              [focusDelay]="400"
                              (onKeyboardEnter)="updateProfileText()"
                              (onKeyboardEscape)="editProfileText = ''"
                              [(value)]="profileTextValue"></app-validateable-input>
    </ng-container>
  </nz-modal>

  <app-create-post-modal [(show)]="showCreateNewPost"
                         [forcedOrgId]="orgid"
                         (success)="onPostCreated($event)"></app-create-post-modal>

  <app-create-group-modal [(show)]="showCreateNewGroup"
                          [forcedOrgId]="orgid"
                          (success)="onGroupCreated($event)"></app-create-group-modal>

  <app-add-impressum-modal [(show)]="showCreateImpress"
                          [forcedOrgId]="orgid"
                          (success)="onImpressCreated($event)"></app-add-impressum-modal>

  <app-edit-impressum-modal [show]="selectedImpress != null"
                          [impress]="selectedImpress"
                          (success)="onImpressUpdated($event)"></app-edit-impressum-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading && org !== null">

    <div class="topbar">

      <div class="avatarbox">
        <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="128" [nzIcon]="'user'" [nzSrc]="avatarSource() ?? ''"></nz-avatar>

        <fa-icon-btn nzType="default" nz-tooltip="Profilbild bearbeiten"   iconSize="18" buttonSize="32" icon="edit" perm="ORG::EDIT"  (btnClick)="selectedEditAvatar = org"   ></fa-icon-btn>
      </div>

      <span class="name">{{org.name}}</span>

      <span class="usertype">{{ org.type | fmtorgtype }}</span>

      <span class="actionbuttons">

        <fa-icon-btn
                     nzType="primary"
                     text="Neuen Post erstellen"
                     iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky"
                     perm="POSTS::CREATE"
                     (btnClick)="showCreateNewPost = true"></fa-icon-btn>

      </span>

    </div>

    <nz-divider></nz-divider>

    <div class="rootdiv">

      <div  #col1Ref class="col1Ref" ></div>
      <div  #col2Ref class="col2Ref" ></div>

      <nz-card class="card-maindata" nzTitle="Stammdaten">

        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
          <span class="key">ID</span>
          <span class="value">{{org.id}}</span>

          <span class="icon"><fa-icon icon="clock"></fa-icon></span>
          <span class="key">Erstellt am</span>
          <span class="value">{{org.creationTime | displaydate}}</span>

          <span class="icon"><fa-icon icon="shirt"></fa-icon></span>
          <span class="key">Name</span>
          <span class="value">{{org.name}}</span>

<!--          <span class="icon"><fa-icon icon="hashtag"></fa-icon></span>
          <span class="key">Kundennummer</span>
          <span class="value">{{org.customerNumber}}</span>-->

<!--          <span class="icon"><fa-icon icon="phone"></fa-icon></span>
          <span class="key">Telefonnummer</span>
          <span class="value">{{org.phone}}</span>-->

        </div>

      </nz-card>

      <nz-card class="card-profile" nzTitle="Profil">

        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="image"></fa-icon></span>
          <span class="key">Profilbild</span>
          <span class="value" *ngIf="org.avatarImageID !== null &&  loadingBlobAvatar"><fa-icon icon="loader" [spin]="true"></fa-icon></span>
          <span class="value" *ngIf="org.avatarImageID !== null && !loadingBlobAvatar && avatarBlob !== null">{{avatarBlob.mimeType}} ({{avatarBlob.filesize | filesize}})</span>
          <span class="value image-empty" *ngIf="org.avatarImageID === null">(nicht gesetzt)</span>
          <span class="extra" *ngIf="!loadingBlobAvatar"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="selectedEditAvatar = org" nz-tooltip="Profilbild ändern"></fa-icon-btn></span>
          <span class="extra2" *ngIf="!loadingBlobAvatar && org.avatarImageID !== null"><fa-icon-btn icon="trash" buttonSize="22" iconSize="10" nzType="primary" [nzDanger]="true" perm="ORG::EDIT" (btnClick)="clearAvatar()" [loading]="clearingAvatar" nz-tooltip="Profilbild löschen"></fa-icon-btn></span>

          <span class="icon"><fa-icon icon="image"></fa-icon></span>
          <span class="key">Hintergrundbild</span>
          <span class="value" *ngIf="org.backgroundImageID !== null &&  loadingBlobBackgroundImage"><fa-icon icon="loader" [spin]="true"></fa-icon></span>
          <span class="value" *ngIf="org.backgroundImageID !== null && !loadingBlobBackgroundImage && backgroundImageBlob !== null">{{backgroundImageBlob.mimeType}} ({{backgroundImageBlob.filesize | filesize}})</span>
          <span class="value image-empty" *ngIf="org.backgroundImageID === null">(nicht gesetzt)</span>
          <span class="extra" *ngIf="!loadingBlobBackgroundImage"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="selectedEditBackgroundImage = org" nz-tooltip="Hintergrundbild ändern"></fa-icon-btn></span>
          <span class="extra2" *ngIf="!loadingBlobBackgroundImage && org.backgroundImageID !== null"><fa-icon-btn icon="trash" buttonSize="22" iconSize="10" nzType="primary" [nzDanger]="true" perm="ORG::EDIT" (btnClick)="clearBackgroundImage()" [loading]="clearingBackgroundImage" nz-tooltip="Hintergrundbild ändern"></fa-icon-btn></span>

        </div>

      </nz-card>

      <nz-card class="card-impressum" nzTitle="Impressum" [nzExtra]="addImpressumExtra">
        <ng-template #addImpressumExtra>

          <fa-icon-btn nzType="primary"
                       text="Impressum hinzufügen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       perm="GROUPS::CREATE"
                       (btnClick)="showCreateImpress = true"></fa-icon-btn>

        </ng-template>

        <div class="impress-list">

          <app-list-entry-impress *ngFor="let im of impress"
                                [impress]="im"
                                [showDelete]="true"
                                [showEdit]="true"
                                (showEditImpress)="selectedImpress = $event"
                                (impressUpdated)="onImpressUpdated($event)"
                                (impressDeleted)="onImpressDeleted($event)"></app-list-entry-impress>

          <ng-container *ngIf="impress.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>

        </div>
        <!--

        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="location-dot"></fa-icon></span>
          <span class="key">Lebt In</span>
          <span class="value">{{org.profileData.location}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.location', 'Ort')"></fa-icon-btn></span>

          <span class="icon"><fa-icon icon="globe"></fa-icon></span>
          <span class="key">Webseite</span>
          <span class="value" *ngIf="org.profileData.website !== ''"><a href="{{org.profileData.website}}">{{org.profileData.website}}</a></span>
          <span class="value" *ngIf="org.profileData.website === ''">{{org.profileData.website}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.website', 'Webseite')"></fa-icon-btn></span>

          <span class="icon"><fa-icon icon="phone"></fa-icon></span>
          <span class="key">Telefonnummer</span>
          <span class="value">{{org.profileData.phone}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.phone', 'Telefonnummer')"></fa-icon-btn></span>

        </div>-->

      </nz-card>

      <nz-card class="card-social" nzTitle="Sozial">

        <div class="infocardcontainer">

          <span class="icon"><fa-icon [icon]="['fab', 'whatsapp']"></fa-icon></span>
          <span class="key">WhatsApp</span>
          <span class="value">{{org.profileData.social.whatsapp}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.whatsapp', 'WhatsApp')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'facebook']"></fa-icon></span>
          <span class="key">Facebook</span>
          <span class="value">{{org.profileData.social.facebook}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.facebook', 'Facebook')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'tiktok']"></fa-icon></span>
          <span class="key">TikTok</span>
          <span class="value">{{org.profileData.social.tikTok}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.tikTok', 'TikTok')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'instagram']"></fa-icon></span>
          <span class="key">Instagram</span>
          <span class="value">{{org.profileData.social.instagram}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.instagram', 'Instagram')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'youtube']"></fa-icon></span>
          <span class="key">Youtube</span>
          <span class="value">{{org.profileData.social.youtube}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.youtube', 'Youtube')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></span>
          <span class="key">Snapchat</span>
          <span class="value">{{org.profileData.social.snapchat}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.snapchat', 'Snapchat')"></fa-icon-btn></span>

          <span class="icon"><fa-icon icon="messages"></fa-icon></span>
          <span class="key">Signal</span>
          <span class="value">{{org.profileData.social.signal}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.signal', 'Signal')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'telegram']"></fa-icon></span>
          <span class="key">Telegram</span>
          <span class="value">{{org.profileData.social.telegram}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.telegram', 'Telegram')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></span>
          <span class="key">Twitter (X)</span>
          <span class="value">{{org.profileData.social.twitter}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.twitter', 'Twitter')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'mastodon']"></fa-icon></span>
          <span class="key">Mastodon</span>
          <span class="value">{{org.profileData.social.mastodon}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.mastodon', 'Mastodon')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'tumblr']"></fa-icon></span>
          <span class="key">Tumblr</span>
          <span class="value">{{org.profileData.social.tumblr}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.tumblr', 'Tumblr')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'pinterest']"></fa-icon></span>
          <span class="key">Pinterest</span>
          <span class="value">{{org.profileData.social.pinterest}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.pinterest', 'Pinterest')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'linkedin']"></fa-icon></span>
          <span class="key">LinkedIn</span>
          <span class="value">{{org.profileData.social.linkedIn}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.linkedIn', 'LinkedIn')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'xing']"></fa-icon></span>
          <span class="key">Xing</span>
          <span class="value">{{org.profileData.social.xing}}</span>
          <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" perm="ORG::EDIT" (btnClick)="editProfileField('profileData.social.xing', 'Xing')"></fa-icon-btn></span>

        </div>

      </nz-card>

      <nz-card class="card-preview" nzTitle="Vorschau">

        <div class="profile-preview-wrapper">
          <div #previewBox class="profile-preview-box" (window:resize)="adjustPreviewSize()">
            <div class="" *ngIf="org !== null" >
              <app-profile-preview-smartphone [org]="org" [groups]="groups" [avatarSource]="avatarSource()" [backgroundSource]="backgroundImageSource()" [name]="org.name" ></app-profile-preview-smartphone>
            </div>
          </div>
        </div>

      </nz-card>

      <nz-card class="card-groups" nzTitle="Gruppen" [nzExtra]="groupsExtra">
        <ng-template #groupsExtra>

          <fa-icon-btn nzType="primary"
                       text="Neue Gruppe erstellen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       perm="ORG::EDIT"
                       (btnClick)="showCreateNewGroup = true"></fa-icon-btn>

        </ng-template>

        <div class="group-list">

          <app-list-entry-group *ngFor="let group of groups"
                                [group]="group"
                                [showDelete]="false"
                                [showChangeEnabled]="false"
                                [showEdit]="false"
                                (groupUpdated)="onGroupUpdated($event)"
                                (groupDeleted)="onGroupDeleted($event)"></app-list-entry-group>

          <ng-container *ngIf="groups.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>

        </div>
      </nz-card>

      <nz-card class="card-events" nzTitle="Veranstaltungen" [nzExtra]="eventsExtra">
        <ng-template #eventsExtra>

          <fa-icon-btn nzType="primary"
                       text="Neue Veranstaltung erstellen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       perm="EVENTS::CREATE"
                       [disabled]="true"
                       (btnClick)="showCreateNewEvent = true"></fa-icon-btn>

        </ng-template>

        <div class="events-list">

          <app-list-entry-event *ngFor="let event of events"
                                [event]="event"
                                [showDelete]="false"
                                [showChangeEnabled]="false"
                                [showEdit]="false"
                                (eventUpdated)="onEventUpdated($event)"
                                (eventDeleted)="onEventDeleted($event)"></app-list-entry-event>

          <ng-container *ngIf="events.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>

        </div>
      </nz-card>

      <nz-card class="card-posts" nzTitle="Letzte Posts" [nzExtra]="postsExtra">
        <ng-template #postsExtra>

          <fa-icon-btn
                       nzType="primary"
                       text="Neuen Post erstellen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       perm="POSTS::CREATE"
                       (btnClick)="showCreateNewPost = true"></fa-icon-btn>

        </ng-template>

        <div class="post-list">

          <ng-container *ngIf="postsLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!postsLoading">

            <app-list-entry-post *ngFor="let post of posts"
                                 [post]="post"
                                 [showLink]="true"
                                 [showDelete]="false"
                                 [showChangeEnabled]="false"
                                 [showEdit]="false"
                                 (postUpdated)="onPostUpdated($event)"
                                 (postDeleted)="onPostDeleted($event)"></app-list-entry-post>

            <ng-container *ngIf="posts.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

          </ng-container>


        </div>
      </nz-card>

    </div>

  </main>

</app-default-frame>
