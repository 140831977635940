
<app-default-frame>

  <main>

    <app-create-orguser-modal [(show)]="showCreateOrgUserModal" [forcedOrgId]="orgid" [forceAuthMail]="true"  (success)="onOrgUserCreated($event)" ></app-create-orguser-modal>
    <app-edit-orguser-modal   [(show)]="selectedEditOrgUser"                                                  (success)="onOrgUserEdited($event)"  ></app-edit-orguser-modal>

    <div class="action-header">

      <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Zugänge"></nz-page-header>

      <fa-icon-btn nzType="primary" text="Neuen Nutzer anlegen" perm="USER::CREATE"  (btnClick)="showCreateOrgUserModal = true"></fa-icon-btn>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="users"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Erstellt am</th>
        <th>Name</th>
        <th>Username</th>
        <th>Mail</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of users">

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.id | shortid }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.creationTime | displaydate}}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.firstName + ' ' + data.lastName}}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.username}}</a></td>

        <td><a href="mailto:{{data.email}}">{{ data.email }}</a></td>

        <td class="actionlist">
          <fa-icon-btn nzType="default"                   nz-tooltip="Nutzer bearbeiten"   buttonSize="24" iconSize="14" icon="edit"  perm="USER::EDIT"   (btnClick)="selectedEditOrgUser  = data" ></fa-icon-btn>
          <fa-icon-btn nzType="default" [nzDanger]="true" nz-tooltip="Benutzer löschen"    buttonSize="24" iconSize="14" icon="trash" perm="USER::DELETE" (btnClick)="deleteOrgUser(data)"         ></fa-icon-btn>
        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchUsers(false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

