import {Component, Input} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Organization} from "../../interfaces/organization";
import {Group} from "../../interfaces/group";

@Component({
  selector: 'app-profile-preview-smartphone',
  templateUrl: './profile-preview-smartphone.component.html',
  styleUrls: ['./profile-preview-smartphone.component.scss']
})
export class ProfilePreviewSmartphoneComponent {

  @Input() avatarSource: string|undefined = undefined;
  @Input() backgroundSource: string|undefined = undefined;
  @Input() name: string = '';
  @Input() org: Organization|null = null;
  @Input() groups: Group[] = [];
  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

}
