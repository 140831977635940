import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {ObjectId} from "../../interfaces/utils";
import {FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {WasteEntry} from "../../interfaces/wasteEntry";
import {NzModalService} from "ng-zorro-antd/modal";
import {WasteCalendarStreet} from "../../interfaces/wasteDistrict";
import {showAPIError} from "../../utils/api";
// @ts-ignore
import ICAL from "ical.js";
import {NzUploadChangeParam} from "ng-zorro-antd/upload";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";

@Component({
  selector: 'app-manage-waste-calendar-streets-modal',
  templateUrl: './manage-waste-calendar-streets-modal.component.html',
  styleUrl: './manage-waste-calendar-streets-modal.component.scss'
})
export class ManageWasteCalendarStreetsModalComponent implements OnInit, AfterViewInit{
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<WasteEntry> = new EventEmitter<WasteEntry>();

  @Input() orgId: ObjectId|null = null;
  @Input() wasteCalendarStreets: WasteCalendarStreet[] = [];

  @Output() wasteCalendarStreetCreated: EventEmitter<WasteCalendarStreet> = new EventEmitter<WasteCalendarStreet>();
  @Output() wasteCalendarStreetUpdated: EventEmitter<WasteCalendarStreet> = new EventEmitter<WasteCalendarStreet>();
  @Output() wasteCalendarStreetDeleted: EventEmitter<WasteCalendarStreet> = new EventEmitter<WasteCalendarStreet>();

  streetForm: FormGroup = this.fb.group({
    name: ['', [Validators.required]],
    allStreets: [false, [Validators.required]],
  });

  showAddEditStreetForm: boolean = false;
  mode: 'add' | 'edit' = 'add';
  selectedStreetId: ObjectId | null = null;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private fb: NonNullableFormBuilder) {}

  initLoading: boolean = false;
  okayLoading: boolean = false;
  execLoading: boolean = false;

  ngOnInit(): void {
  }


  getToken(){
    return this.auth.getToken();
  }

  ngAfterViewInit(): void {

  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {

    this.initLoading    = false;
    this.okayLoading    = false;

  }

  uploadUrl(street: WasteCalendarStreet): string {
    const uploadUrl = environment.apiBaseUrl + "organizations/:orgid/waste/streets/:streetid/ics"
    return uploadUrl.replace(':orgid', this.orgId!).replace(':streetid', street.id);
  }

  toggleStreetField(): void {

    const allStreets = this.streetForm.get('allStreets')?.value;
    const street = this.streetForm.get('name');

    if (allStreets) {
      street?.clearValidators();
      street?.disable();
    } else {
      street?.setValidators([Validators.required]);
      street?.enable();
    }
    street?.updateValueAndValidity();
  }

  editStreet(data: WasteCalendarStreet){
    this.selectedStreetId = data.id;
    this.showAddEditStreetForm = true;
    this.mode = 'edit';
    this.streetForm.setValue({
      name: data.name,
      allStreets: data.isAllStreets ?? false
    });
  }

  handleIcsChange(info: NzUploadChangeParam): void {

    if (info.file.status !== 'uploading') {
      //console.log(info.file, info.fileList);
    }
    if (info.file.status === 'done') {
      this.notification.success(`[${info.file.response.events.length}] Events importiert`,`${info.file.name} wurde erfolgreich importiert.`);
    } else if (info.file.status === 'error') {
      this.notification.error("",`${info.file.name} konnte nicht importiert werden.`);
    }
  }

  deleteStreet(data: WasteCalendarStreet){
    console.log('deleteStreet', data)
    let loading = false;
    const name = data.name;
    this.modal.confirm({
      nzTitle: 'Straße löschen?',
      nzContent: 'Möchtest du die Straße "'+name+'" wirklich löschen? Vorhandene Abfuhrtermine werden ebenfalls gelöscht.',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {

        loading = true;
        try {

          await this.api.deleteWasteCalendarStreet(this.orgId!, data);

          this.notification.success('Straße gelöscht', 'Straße "'+data.name+'" wurde gelöscht.');
          this.wasteCalendarStreetDeleted.emit(data);

        } catch (err){
          showAPIError(this.notification, 'Straße konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }

      }
    });
  }

  async addStreet() {

    this.execLoading = true;
    try {

      const data = await this.api.createWasteCalendarStreet(this.orgId!, this.streetForm.value);

      this.notification.success('Straße erstellt', 'Straße "'+data.name+'" wurde erfolgreich erstellt');
      this.wasteCalendarStreetCreated.emit(data);

    } catch (err){
      showAPIError(this.notification, 'Straße konnte nicht erstellt werden', err)
    } finally {
      this.execLoading = false;
    }

  }

  async updateStreet() {

    this.execLoading = true;
    try {

      const data = await this.api.updateWasteCalendarStreet(this.orgId!, {
        id: this.selectedStreetId!,
        ...this.streetForm.value
      });

      this.notification.success('Straße aktualisiert', 'Die Straße wurde erfolgreich erstellt');
      this.wasteCalendarStreetUpdated.emit(data);

    } catch (err){
      showAPIError(this.notification, 'Straße konnte nicht erstellt werden', err)
    } finally {
      this.execLoading = false;
    }

  }

  submitForm() {

    if (this.streetForm.valid) {
      console.log('submit', this.streetForm.value);

      if(this.mode == 'add'){
        this.addStreet().then();

      } else {
        this.updateStreet().then();
      }

      this.streetForm.get('name')?.setValue('')

    } else {
      Object.values(this.streetForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(innerControl => {
            innerControl.markAsDirty();
            innerControl.updateValueAndValidity({ onlySelf: true });
          });
        } else {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  protected readonly JSON = JSON;
}
