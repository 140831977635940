import { Component } from '@angular/core';

@Component({
  selector: 'app-no-frame',
  templateUrl: './no-frame.component.html',
  styleUrls: ['./no-frame.component.scss']
})
export class NoFrameComponent {

}
