import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ObjectId} from "../../interfaces/utils";
import {Impressum} from "../../interfaces/impressum";
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";
import {filetoBase64} from "../../utils/base64";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";

@Component({
  selector: 'app-edit-impressum-modal',
  templateUrl: './edit-impressum-modal.component.html',
  styleUrl: './edit-impressum-modal.component.scss'
})
export class EditImpressumModalComponent implements OnChanges {
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Impressum> = new EventEmitter<Impressum>();

  @Input() impress: Impressum|null = null;

  validateForm : FormGroup<{
    name: FormControl<string>,
    representativeName: FormControl<string>,
    legalForm: FormControl<string>,
    address: FormGroup<{
      street: FormControl<string>,
      houseNumber: FormControl<string>,
      postalCode: FormControl<string>,
      city: FormControl<string>
    }>
    contact: FormGroup<{
      email: FormControl<string>,
      phone: FormControl<string>
    }>
    taxId: FormControl<string>,
    registerInfo: FormControl<string>,
    supervisingAuthority: FormControl<string>,
    onlineDisputeResolutionPlatform: FormControl<string>
  }> = this.fb.group({
    name: ['', [Validators.required]],
    representativeName: ['', [Validators.required]],
    legalForm: ['', [Validators.required]],
    address: this.fb.group({
      street: ['', [Validators.required]],
      houseNumber: ['', [Validators.required]],
      postalCode: ['', [Validators.required]],
      city: ['', [Validators.required]]
    }),
    contact: this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      phone: ['', [Validators.required]]
    }),
    taxId: [''],
    registerInfo: [''],
    supervisingAuthority: [''],
    onlineDisputeResolutionPlatform: ['']
  })

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private fb: NonNullableFormBuilder) {}

  initLoading: boolean = false;
  okayLoading: boolean = false;
  execLoading: boolean = false;

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['impress'] && this.impress) {
      this.validateForm.patchValue({
        name: this.impress.name,
        representativeName: this.impress.representativeName,
        legalForm: this.impress.legalForm,
        address: {
          street: this.impress.address.street,
          houseNumber: this.impress.address.houseNumber,
          postalCode: this.impress.address.postalCode,
          city: this.impress.address.city
        },
        contact: {
          email: this.impress.contact.email,
          phone: this.impress.contact.phone
        },
        taxId: this.impress.taxId,
        registerInfo: this.impress.registerInfo,
        supervisingAuthority: this.impress.supervisingAuthority,
        onlineDisputeResolutionPlatform: this.impress.onlineDisputeResolutionPlatform
      });
    }
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {

    this.initLoading    = false;
    this.okayLoading    = false;

  }

  submitForm() {
    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
      this.execute();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(innerControl => {
            innerControl.markAsDirty();
            innerControl.updateValueAndValidity({ onlySelf: true });
          });
        } else {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async execute(){

    console.log('execute', this.validateForm.value, this.impress);

    try {
      this.execLoading = true;

        const data = await this.api.updateImpress(
          this.impress?.organizationID!,
          this.impress?.id!,
          this.validateForm.value as Impressum
        )

        this.show = false;
        this.showChange.emit(false);
        this.clear();

        this.success.emit(data);
        this.notification.success('Impressum aktualisiert', 'Das Impressum wurde erfolgreich aktualisiert');

    } catch (err) {
      showAPIError(this.notification, 'Impressum konnte nicht aktualisiert werden', err)
    } finally {
      this.execLoading = false;
    }
  }

}
