
<app-default-frame>

  <div class="fullpageloader" *ngIf="initialLoading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!initialLoading">

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" [disabled]="true" ></app-search-bar>
      </div>
      <div class="actions">

      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      class="report-table"
      [nzData]="reports"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Organization</th>
        <th>Post / Event</th>
        <th>Grund</th>
        <th>Status</th>
        <th>Gemeldet am</th>
        <th>User</th>
        <th>Dabei seit</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <ng-container *ngFor="let report of reports">

        <tr>

          <td>{{ report.id | shortid }}</td>
          <td>{{ report.organization.name }}</td>
          <td>{{ report.post?.title || report.event?.title }}</td>
          <td>{{ report.reason  }}</td>
          <td [class]="report.status">{{ statusText(report.status) }}</td>
          <td>{{ report.creationTime | displaydate }}</td>
          <td>{{ report.user.nickname }} | {{ report.user.email }}</td>
          <td>{{ report.user.creationTime | displaydate }}</td>

          <td class="">
            <fa-icon-btn *ngIf="report.status !== 'approved'"
                         nzType="default"
                         [nzDanger]="true"
                         nz-tooltip="Beitrag Sperren"
                         text="Sperren"
                         buttonSize="24"
                         iconSize="12"
                         icon="warning"
                         (btnClick)="approveReport(report)"
            ></fa-icon-btn>

            <fa-icon-btn *ngIf="report.status !== 'rejected'"
                         nzType="default"
                         nz-tooltip="Beitrag OK"
                         text="OK"
                         buttonSize="24"
                         iconSize="14"
                         icon="check"
                         (btnClick)="rejectReport(report)"
            ></fa-icon-btn>

          </td>

        </tr>
      </ng-container>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false, false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

