import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {ConversationNote} from "../../interfaces/conversationNotes";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, showAPIError} from "../../utils/api";
import {BlobDataOrgUserAttachment, DataBlob} from "../../interfaces/datablobs";
import {isNgDiff} from "../../utils/angular";
import {DateUtils} from "../../utils/date";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-edit-conversation-note-modal',
  templateUrl: './edit-conversation-note-modal.component.html',
  styleUrls: ['./edit-conversation-note-modal.component.scss']
})
export class EditConversationNoteModalComponent implements OnInit, OnChanges {
  @Input() orgId: ObjectId|null = null;

  @Input() show: ConversationNote|null = null;
  @Output() showChange: EventEmitter<ConversationNote|null> = new EventEmitter<ConversationNote|null>();
  @Output() success: EventEmitter<ConversationNote> = new EventEmitter<ConversationNote>();

  date: Date = new Date();
  time: Date = new Date();
  title: string = '';
  body: string = '';
  participants: {value: string}[] = [];

  loading: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.date         = new Date(this.show.date);
        this.time         = new Date(this.show.date);
        this.title        = this.show.title;
        this.body         = this.show.body;
        this.participants = this.show.participants.map(p => ({value: p}));
      }
      return;
    }
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.date = new Date();
    this.time = new Date();
    this.title = '';
    this.body = '';
    this.participants = [];
    this.loading = false;
  }

  async execute() {
    if (this.orgId === null) return;
    if (this.show === null) return;

    try {
      this.loading = true;

      if (!this.isValidStr(this.title)) { this.notification.error('Fehler', 'Bitte Title eingeben'); return; }
      if (!this.participants.every(p => this.isValidStr(p.value))) { this.notification.error('Fehler', 'Bitte Teilnehmer eingeben'); return; }

      let dt = DateUtils.mergeDateWithTime(this.date, this.time);
      dt.setSeconds(0, 0);

      const data = await this.api.updateConversationNote(
        this.orgId,
        this.show.id,
        this.title,
        this.body,
        DateUtils.formatRFC3339(dt),
        this.participants.map(p => p.value));

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Datei konnte nicht aktualisiert werden', err)
    } finally {
      this.loading = false;
    }
  }

  deleteParticipant(idx: number) {
    this.participants = [...this.participants.slice(0, idx), ...this.participants.slice(idx+1)]
  }

  addParticipant() {
    this.participants = [...this.participants, {value:''}]
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }
}
