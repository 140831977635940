import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff} from "../../utils/angular";
import {formatAPIError, showAPIError} from "../../utils/api";
import {OrganizationUser, OrgUserPermKey} from "../../interfaces/organizationUser";
import {EnumMetaValue} from "../../interfaces/enums";

@Component({
  selector: 'app-edit-orguser-modal',
  templateUrl: './edit-orguser-modal.component.html',
  styleUrls: ['./edit-orguser-modal.component.scss']
})
export class EditOrgUserModalComponent implements OnInit, OnChanges {

  @Input() show: OrganizationUser|null = null;
  @Output() showChange: EventEmitter<OrganizationUser|null> = new EventEmitter<OrganizationUser|null>();
  @Output() success: EventEmitter<OrganizationUser> = new EventEmitter<OrganizationUser>();

  initLoading: boolean = false;
  okayLoading: boolean = false;

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  mail: string = '';

  permissions: OrgUserPermKey[] = [];
  superuser: boolean = false;

  permlist: EnumMetaValue<OrgUserPermKey, string>[] = [];

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.username              = this.show.username;
        this.firstName             = this.show.firstName;
        this.lastName              = this.show.lastName;
        this.mail                  = this.show.email;
        this.permissions           = [...this.show.permissions];
        this.superuser             = this.show.permissions.includes("SUPER");

        this.loadData().then(() => {});
      }
      return;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.username              = '';
    this.firstName             = '';
    this.lastName              = '';
    this.mail                  = '';
    this.permissions           = [];
    this.superuser             = false;
  }

  async loadData() {
    try {
      this.initLoading = true;
      const data = await this.api.getOrguserPemissionList();
      this.permlist = data.permissions;
    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)

      if (this.show != null) {
        this.show = null;
        this.showChange.emit(null);
        this.clear();
      }
    } finally {
      this.initLoading = false;
    }
  }

  async execute() {
    if (!this.isValidStr(this.firstName))                 { this.notification.error('Fehler', 'Bitte valider Name eingeben');          return; }
    if (!this.isValidStr(this.lastName))                  { this.notification.error('Fehler', 'Bitte valider Name eingeben');          return; }
    if (!this.isValidStr(this.username))                  { this.notification.error('Fehler', 'Bitte valider Benutzername eingeben');  return; }
    if (!this.isValidMail(this.mail))                     { this.notification.error('Fehler', 'Bitte valide Email-Addresse eingeben'); return; }

    try {
      this.okayLoading = true;

      const data = await this.api.updateOrgUser(
        this.show!.organizationID,
        this.show!.id,
        this.firstName, this.lastName,
        this.username,
        this.mail,
        this.permissions);

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht aktualisiert werden', err)
    } finally {
      this.okayLoading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }

  updatePermissions(perm: OrgUserPermKey, evt: boolean) {
    if (evt) {
      this.permissions = [...new Set([perm, ...this.permissions])];
    } else {
      this.permissions = [...new Set(this.permissions.filter(p => p != perm))];
    }
  }
}
