<nz-modal [nzVisible]="show"
          nzTitle="Neuen Administrator anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span>Vorname</span>
      <app-validateable-input placeholder="Max" [preventAutofill]="true" [(value)]="firstName" [validate]="isValidStr"></app-validateable-input>

      <span>Nachname</span>
      <app-validateable-input placeholder="Mustermann" [preventAutofill]="true" [(value)]="lastName" [validate]="isValidStr"></app-validateable-input>

      <span>Benutzername</span>
      <app-validateable-input placeholder="Max1994" [preventAutofill]="true" [(value)]="username" [validate]="isValidStr"></app-validateable-input>

      <span>Mail</span>
      <app-validateable-input placeholder="local@domain.tld" [preventAutofill]="true" [(value)]="mail" [validate]="isValidMail"></app-validateable-input>

      <span>Passwort</span>
      <div style="display: grid; grid-template-columns: 1fr auto auto; grid-column-gap: 0.5rem">
        <app-validateable-input placeholder="" [preventAutofill]="true" [type]="showPW ? 'text' : 'password'" [(value)]="password1" [validate]="isValidPW1()"></app-validateable-input>
        <fa-icon-btn icon="dice-six" nz-tooltip="Zufälliges Passwort generieren" buttonSize="32"  iconSize="17" (btnClick)="randomPassword()"></fa-icon-btn>
        <fa-icon-btn icon="eye"  buttonSize="32"  iconSize="17" (btnClick)="showPW = !showPW"></fa-icon-btn>
      </div>

      <span>Passwort wiederholen</span>
      <app-validateable-input placeholder="" [preventAutofill]="true" [type]="showPW ? 'text' : 'password'" [(value)]="password2" [validate]="isValidPW2()"></app-validateable-input>

    </div>
  </ng-container>
</nz-modal>
