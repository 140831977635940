
<app-no-frame>

  <main>

    <span class="header">
      <img ngSrc="/assets/bo_logo.png" width="500" height="401" alt="logo" style="height:4em; width:4em">
      <h1 style="margin-bottom: 0; align-self: center; margin-left: 0.661em;">Login</h1>
    </span>

    <nz-alert *ngIf="errorText != ''" nzType="error" [nzMessage]="errorText"></nz-alert>

    <input type="email" [(ngModel)]="email" nz-input placeholder="Email" (keyup.enter)="this.inputPassword.focus()" />

    <input type="password" #inputPassword [(ngModel)]="password" nz-input placeholder="Password" (keyup.enter)="login()" />

    <button nz-button class="login-form-button login-form-margin" style="margin-top: 16px" [nzType]="'primary'" [nzLoading]="loginLoading" (click)="login()">Log in</button>

    <a routerLink="/pwreset" style="text-align: right;">Passwort vergessen</a>

  </main>


</app-no-frame>

