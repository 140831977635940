import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {BlobDataOrgUserAttachment, DataBlob} from "../../interfaces/datablobs";
import {ConversationNote} from "../../interfaces/conversationNotes";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation, WeekDay} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {environment} from "../../../environments/environment";
import {getIndirectFieldValue} from "../../utils/indirect";
import {Organization} from "../../interfaces/organization";
import {Post} from "../../interfaces/post";
import {Group, GroupSmall} from "../../interfaces/group";
import {Event} from "../../interfaces/event";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {NzFormatEmitEvent, NzTreeComponent, NzTreeNode, NzTreeNodeOptions} from "ng-zorro-antd/tree";
import {Impressum} from "../../interfaces/impressum";
import {WasteEntry} from "../../interfaces/wasteEntry";
import {WasteCalendarStreet} from "../../interfaces/wasteDistrict";

export type Node = NzTreeNodeOptions & {
  group: Group;
  children: Node[];
}

interface MonthEntries {
  name: string;
  entries: any[]; // Adjust 'any' to the specific type if you know it
}

interface Months {
  [key: string]: MonthEntries;
}

@Component({
  selector: 'app-org-view',
  templateUrl: './org-view.component.html',
  styleUrls: ['./org-view.component.scss']
})
export class OrgViewComponent implements OnInit {


  loading = true;
  loadingFiles = true;
  commentSaving = false;

  id!: ObjectId;
  org: Organization | null = null;

  commentMinHeight?: string = undefined;
  commentEdit: string | null = null;
  files: DataBlob<BlobDataOrgUserAttachment>[] = [];
  filesNextToken: CursorToken = "@start";
  showCreateFileModal = false;
  showEditFileModal: DataBlob<BlobDataOrgUserAttachment> | null = null;

  selectedEditOrg: Organization | null = null;
  selectedEditAvatar: Organization | null = null;
  selectedEditBackgroundImage: Organization | null = null;

  avatarBlobId: string = 'IGetOrganNITIAL';
  backgroundImageBlobId: string = 'INITIAL';

  expandedConvNote: string | null = null;
  loadingConvNotes: boolean = false;
  conversationNotes: ConversationNote[] = [];
  convNotesNextToken: CursorToken = "@start";
  showCreateConvNoteModal = false;
  showEditConvNotesModal: ConversationNote | null = null;

  clearingAvatar: boolean = false;
  clearingBackgroundImage: boolean = false;

  loadingBlobAvatar: boolean = false;
  loadingBlobBackgroundImage: boolean = false;

  avatarBlob: DataBlob<any> | null = null;
  backgroundImageBlob: DataBlob<any> | null = null;

  editProfileText: string = '';
  updatingProfileText: boolean = false;
  profileTextRef: string = '';
  profileTextValue: string = '';

  posts: Post[] = [];
  postsLoading: boolean = true;

  groups: Node[] = [];
  groupsLoading: boolean = true;

  events: Event[] = [];
  eventsLoading: boolean = true;

  orgusers: OrganizationUser[] = [];
  orgusersLoading: boolean = true;
  orgusersNextToken: CursorToken = "@start";

  impress: Impressum[] = [];
  impressLoading: boolean = true;
  selectedImpress: Impressum | null = null;

  showCreateNewPost: boolean = false;
  showCreateNewGroup: boolean = false;
  showCreateNewEvent: boolean = false;
  showAddLink: boolean = false;
  showEditGroup: Group | null = null;
  showCreateImpress: boolean = false;

  addLinkLoading: boolean = false;

  // Waste calendar

  wasteCalendarStreetsLoading: boolean = true;
  wasteCalendarStreets: WasteCalendarStreet[] = []
  showManageDistrictsModal: boolean = false;

  showAddWasteCollectionModal: boolean = false;
  selectedMonth: string = '';

  showStreetEvents: string[] = []
  showStreetEventsLoading: string[] = []

  days: string[] = ['Montag', 'Dienstag', 'Mitwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];

  // map days to WeekDay enum
  dayMap: Map< WeekDay, string> = new Map([
      [WeekDay.Monday, 'Montag'],
      [ WeekDay.Tuesday, 'Dienstag'],
      [ WeekDay.Wednesday, 'Mitwoch'],
      [ WeekDay.Thursday, 'Donnerstag'],
      [ WeekDay.Friday, 'Freitag'],
      [ WeekDay.Saturday, 'Samstag'],
      [ WeekDay.Sunday, 'Sonntag']
  ]);


  @ViewChild('commentBox') public commentBox!: ElementRef<HTMLDivElement>;

  @ViewChild('col1Ref') col1Ref: ElementRef<HTMLElement> | undefined;
  @ViewChild('col2Ref') col2Ref: ElementRef<HTMLElement> | undefined;
  @ViewChild('col3Ref') col3Ref: ElementRef<HTMLElement> | undefined;
  @ViewChild('groupTree', {static: false}) groupTree!: NzTreeComponent;

  orgCache: Map<string, Organization> = new Map<string, Organization>();
  deleteOrgLinkLoading: Set<string> = new Set<string>();
showEditOpeningHoursModal: any;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.id = this.activatedRoute.snapshot.params['id'];
    await this.loadOrg();
    if (!this.org === null) return;
    await Promise.all([
      this.loadFiles(),
      this.loadConvNotes(),
      this.loadPosts(),
      this.loadGroups(),
      this.loadEvents(),
      this.loadImpress(),
      this.loadWasteCalendarStreets(),
      this.loadOrgUsers(true),
    ]);
  }

  nzEvent(event: NzFormatEmitEvent): void {
    console.log(event, this.groups, this.groupTree.getTreeNodes());

    console.log('flattenArray', this.flattenArrayWithChildren(this.groupTree.getTreeNodes()));

    this.adjustReferencesInGroupTree(this.flattenArrayWithChildren(this.groupTree.getTreeNodes()));
    console.log('nzEvent - sorted', this.groups);

    this.updateGroups().then(() => {
    });
  }

  getAllGroups(nodes: Node[], n: Node[] = []): Node[] {

    for (const node of nodes) {
      n.push(node)
      if (node.children.length > 0) {
        n.push(...this.getAllGroups(node.children));
      }
    }

    return n;

  }

  async updateGroups() {

    const nodes = this.getAllGroups(this.groups);
    console.log('updateGroups', nodes);


    const groups: GroupSmall[] = [];

    for (const node of nodes) {
      groups.push({
        id: node.group.id,
        parentGroupID: node.group.parentGroupID,
        position: node.group.position,
        enabled: node.group.enabled
      });
    }

    const data = await this.api.updateGroups(
      this.org!.id,
      groups
    );
  }

  flattenArrayWithChildren(array: NzTreeNode[]): Node[] {
    let result: Node[] = [];

    for (const treeNode of array) {

      result.push({
        title: treeNode.title,
        key: treeNode.key,
        children: this.flattenArrayWithChildren(treeNode.children),
        group: treeNode.origin['group']
      })

    }

    return result;
  }

  /**
   * Checks if the given group has children
   *
   * @param group
   * @param nodes
   */
  hasGroupChildren(group: Group, nodes: Node[]): boolean {

    for (const node of nodes) {
      if (group.id === node.group.parentGroupID) return true;
      if (node.children.length > 0) {
        return this.hasGroupChildren(group, node.children);
      }
    }

    return false;
  }

  hasParentGroup(group: Group): boolean {
    return group.parentGroupID !== null;
  }

  findParentGroup(group: Group, nodes: Node[]): Group | null {
    for (const node of nodes) {
      if (group.parentGroupID === node.group.id) return node.group;
      if (node.children.length > 0) {
        return this.findParentGroup(group, node.children);
      }
    }

    return null;
  }

  isParentGroupActivated(group: Group): boolean {
    const parentGroup = this.findParentGroup(group, this.groups);
    if (parentGroup) return parentGroup.enabled;

    console.error('parentGroup not found', group);
    return false;
  }

  async loadOrg() {

    this.loading = true;

    try {
      this.org = await this.api.getOrganization(this.id);
      this.avatarBlobId = this.org.avatarImageID ?? 'UNSET';
      this.backgroundImageBlobId = this.org.backgroundImageID ?? 'UNSET';

      if (this.org.avatarImageID !== null) this.loadBlobAvatar().then(() => {
      });
      if (this.org.backgroundImageID !== null) this.loadBlobBackgroundImage().then(() => {
      });

      this.orgCache.set(this.org.id, this.org);

      for (const v of this.org.linkedOrganizations) {
        this.loadLinkedOrg(v).then(() => {
        });
      }

    } catch (err) {
      showAPIError(this.notification, 'ContentProvider konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async loadLinkedOrg(orgid: ObjectId) {
    try {
      const data = await this.api.getOrganization(orgid);
      this.orgCache.set(data.id, data);
    } catch (err) {
      showAPIError(this.notification, 'Verlinkter ContentProvider konnte nicht geladen werden', err)
    }
  }

  async loadFiles() {

    this.loadingFiles = true;
    this.showEditFileModal = null;

    try {
      const data = await this.api.listOrgUserAttachments(this.id, this.filesNextToken, 64)
      this.files = data.blobs;
    } catch (err) {
      showAPIError(this.notification, 'Anhänge konnten nicht geladen werden', err)
    } finally {
      this.loadingFiles = false;
    }
  }

  async loadConvNotes() {

    this.loadingConvNotes = true;
    this.showEditConvNotesModal = null;

    try {
      const data = await this.api.listOrgUserConversationNotes(this.id, this.convNotesNextToken, 64)
      this.conversationNotes = data.notes;
    } catch (err) {
      showAPIError(this.notification, 'Gesprächsnotizen konnten nicht geladen werden', err)
    } finally {
      this.loadingConvNotes = false;
    }
  }

  async loadBlobAvatar() {
    if (this.org === null) return;
    if (this.org.avatarImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.avatarBlob = await this.api.getBlob(this.org.avatarImageID);

    } catch (err) {
      showAPIError(this.notification, 'Profilbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobAvatar = false;
    }
  }

  async loadBlobBackgroundImage() {
    if (this.org === null) return;
    if (this.org.backgroundImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.backgroundImageBlob = await this.api.getBlob(this.org.backgroundImageID);

    } catch (err) {
      showAPIError(this.notification, 'Hintergrundbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobBackgroundImage = false;
    }
  }

  async loadPosts() {

    if (!this.org) return;

    this.postsLoading = true;

    try {
      this.posts = (await this.api.listOrgPosts(this.org.id, '@start', 10)).posts;

    } catch (err) {
      showAPIError(this.notification, 'Posts konnte nicht geladen werden', err)
    } finally {
      this.postsLoading = false;
    }
  }

  async loadGroups() {

    if (!this.org) return;

    this.groupsLoading = true;

    try {
      const groups = (await this.api.listOrgGroups(this.org.id, '@start', 100)).groups;

      const g = this.createGroupTree(groups);
      this.groups = this.adjustReferencesInGroupTree(g)[1];

    } catch (err) {
      showAPIError(this.notification, 'Gruppen konnte nicht geladen werden', err)
    } finally {
      this.groupsLoading = false;
    }
  }

  createGroupTree(remaining: Group[]) {
    console.log('Groups', remaining)
    // Convert the flat list of groups into a tree
    const tree: Node[] = [];
    const processed: Node[] = [];

    let changes = 0;
    while (remaining.length > 0) {

      const tmpRemaining = [...remaining];
      for (const group of tmpRemaining) {

        const node: Node = {
          group: group,
          title: group.title,
          key: group.id,
          expanded: false,
          children: [],
          selectable: false
        };

        // The group has no parent, so add it as root node
        if (group.parentGroupID === null) {

          node.expanded = true;
          tree.push(node);
          processed.push(node);
          remaining = remaining.filter(p => p.id !== group.id);
          changes++;

        } else if ((processed.findIndex(p => p.group.id === node.group.parentGroupID)) > -1) {

          const index = processed.findIndex(p => p.group.id === node.group.parentGroupID);
          node.isLeaf = true;
          node.expanded = false;
          processed[index].isLeaf = false;
          processed[index].expanded = true;
          processed[index].children?.push(node);
          processed.push(node);
          remaining = remaining.filter(p => p.id !== group.id);
          changes++;
        }

      }

      if (changes === 0) {
        for (const group of remaining) {

          const node: Node = {
            group: group,
            title: group.title,
            key: group.id,
            expanded: false,
            children: [],
            selectable: false
          };

          node.expanded = true;
          tree.push(node);
          processed.push(node);
        }
        break;
      }

    }

    return tree;
  }

  adjustReferencesInGroupTree(tree: Node[], i = 0): [number, Node[]] {

    if (i === 0) {
      for (const node of tree) {
        node.group.parentGroupID = null;
      }
    }

    for (const node of tree) {
      node.group.position = i;
      i++;
      if (node.children.length > 0) {
        const d = this.adjustReferencesInGroupTree(node.children as Node[], i);
        i = d[0];
        for (let j = 0; j < node.children.length; j++) {
          node.children[j].group.parentGroupID = node.group.id;
        }
      }
    }

    return [i, tree];
  }

  async loadEvents() {

    if (!this.org) return;

    this.eventsLoading = true;

    try {
      this.events = (await this.api.listOrgEvents(this.org.id, '@start', 10)).events;

    } catch (err) {
      showAPIError(this.notification, 'Veranstaltungen konnte nicht geladen werden', err)
    } finally {
      this.eventsLoading = false;
    }
  }

  async loadImpress() {

    if (!this.org) return;

    this.impressLoading = true;

    try {
      this.impress = (await this.api.listOrgImpresss(this.org.id, '@start', 50)).impress;

    } catch (err) {
      showAPIError(this.notification, 'Impressums konnten nicht geladen werden', err)
    } finally {
      this.impressLoading = false;
    }
  }

  async loadWasteCalendarStreets() {

    if (!this.org) return;

    this.wasteCalendarStreetsLoading = true;

    try {
      this.wasteCalendarStreets = (await this.api.listWasteCalendarStreets(this.org.id, '@start', 600)).wasteCalendarStreets;

      console.log('WasteCalendarStreets', this.wasteCalendarStreets);
    } catch (err) {
      showAPIError(this.notification, 'Straßen/Abfuhrbezirke konnten nicht geladen werden', err)
    } finally {
      this.wasteCalendarStreetsLoading = false;
    }
  }

  async loadWasteCalendarStreetsCalendar(street: WasteCalendarStreet) {

    if (!this.org) return;

    this.showStreetEventsLoading.push(street.id);

    const i = this.showStreetEvents.findIndex(p => p === street.id);
    if (i > -1) {
      return;
    }

    try {
      const res = (await this.api.getWasteCalendarByStreet(this.org.id, street.id)).calendar;
      const index = this.wasteCalendarStreets.findIndex(p => p.id === street.id);
      this.wasteCalendarStreets[index].calendar = res;

      console.log('WasteCalendarStreets', this.wasteCalendarStreets);
    } catch (err) {
      showAPIError(this.notification, 'Straßen/Abfuhrbezirke konnten nicht geladen werden', err)
    } finally {
      this.showStreetEventsLoading = this.showStreetEventsLoading.filter(p => p !== street.id);
    }
  }

  async loadOrgUsers(reset: boolean) {
    if (reset) {
      this.orgusersNextToken = '@start';
    }

    try {
      this.orgusersLoading = true;

      const data = await this.api.listOrgUser(this.id, this.orgusersNextToken, 24);

      if (reset) {
        this.orgusers = data.users;
      } else {
        this.orgusers = [...this.orgusers, ...data.users];
      }

      this.orgusersNextToken = data.nextPageToken;

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht geladen werden', err)
    } finally {
      this.orgusersLoading = false;
    }
  }

  deleteOrg() {
    if (this.org === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'ContentProvider löschen?',
      nzContent: 'Möchtest du den ContentProvider "' + this.org.name + '" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          if (this.org === null) return;

          loading = true;
          await this.api.deleteOrganization(this.org.id);
          await this.router.navigate(['/admin/organizations']);
          this.notification.success('Account gelöscht', 'Der ContentProvider "' + this.org.name + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Der ContentProvider konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  activateOrg() {
    if (this.org === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'ContentProvider aktivieren?',
      nzContent: 'Möchtest du den ContentProvider "' + this.org.name + '" aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          if (this.org === null) return;

          loading = true;
          await this.api.enableOrganization(this.org.id);
          this.notification.success('Account aktiviert', 'Der ContentProvider "' + this.org.name + '" wurde aktiviert');
          await this.loadOrg();
        } catch (err) {
          showAPIError(this.notification, 'Der ContentProvider konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivateOrg() {
    if (this.org === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'ContentProvider deaktivieren?',
      nzContent: 'Möchtest du den ContentProvider "' + this.org.name + '" deaktivieren?<br>Dies deaktiviert alle Posts und den Auftritt in der App.',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          if (this.org === null) return;

          loading = true;
          await this.api.disableOrganization(this.org.id);
          this.notification.success('Account deaktiviert', 'Der ContentProvider "' + this.org.name + '" wurde deaktiviert');
          await this.loadOrg();
        } catch (err) {
          showAPIError(this.notification, 'Der ContentProvider konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onOrgEdited(entry: Organization) {
    this.org = entry;
    this.files = [];
    this.conversationNotes = [];
    Promise.all([this.loadFiles(), this.loadConvNotes()]).then(() => {
    });
  }

  editComment() {
    if (this.org === null) return;

    this.commentMinHeight = (this.commentBox.nativeElement.offsetHeight + 12) + 'px';
    this.commentEdit = this.org.comment;
  }

  async saveComment() {
    if (this.org === null || this.commentEdit === null) return;

    this.commentSaving = true;

    try {
      this.org = await this.api.updateOrgComment(this.id, this.commentEdit);
    } catch (err) {
      showAPIError(this.notification, 'Kommentar konnte nicht gespeichert werden', err)
    } finally {
      this.commentSaving = false;
    }

    this.commentEdit = null;
  }

  abortComment() {
    if (this.org === null) return;

    this.commentEdit = null;
  }

  async editFile(blob: DataBlob<BlobDataOrgUserAttachment>) {
    this.showEditFileModal = blob;
  }

  async deleteFile(blob: DataBlob<BlobDataOrgUserAttachment>) {
    if (this.id === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'Datei löschen?',
      nzContent: 'Möchtest du die Datei "' + blob.data.filename + '" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          if (this.org === null) return;

          loading = true;
          await this.api.deleteOrgUserAttachment(this.id, blob.id);
          this.files = this.files.filter(p => p.id != blob.id);
          this.notification.success('Datei gelöscht', 'Der Anhang "' + blob.data.filename + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Die Datei konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  showFileComment(blob: DataBlob<BlobDataOrgUserAttachment>) {
    this.modal.info({
      nzTitle: blob.data.filename,
      nzContent: blob.data.comment,
      nzClassName: 'modal-whitespace-pre',
      nzCentered: true,
    })
  }

  async addFile() {
    this.showCreateFileModal = true;
  }

  async downloadFile(blob: DataBlob<BlobDataOrgUserAttachment>) {
    if (this.id === null) return;

    window.open(this.api.downloadAttachment(this.id, blob.id), '_self');
  }

  getMimeIcon(blob: DataBlob<BlobDataOrgUserAttachment>) {
    if (blob.mimeType === 'application/pdf') return 'file-pdf';
    if (blob.mimeType === 'image/jpeg') return 'file-jpg';
    if (blob.mimeType === 'image/png') return 'file-image';
    if (blob.mimeType === 'image/gif') return 'file-gif';
    if (blob.mimeType === 'image/tiff') return 'file-image';
    if (blob.mimeType === 'image/webp') return 'file-image';
    if (blob.mimeType === 'application/msword') return 'file-word';
    if (blob.mimeType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') return 'file-word';
    if (blob.mimeType === 'application/zip') return 'file-zip';
    if (blob.mimeType === 'application/csv') return 'file-text';
    if (blob.mimeType === 'application/html') return 'file-text';
    if (blob.mimeType === 'application/vnd.ms-powerpoint') return 'file-ppt';
    if (blob.mimeType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') return 'file-ppt';
    if (blob.mimeType === 'image/svg') return 'file-image';
    if (blob.mimeType === 'application/x-tar') return 'file-zip';
    if (blob.mimeType === 'text/plain') return 'file-text';
    if (blob.mimeType === 'text/markdown') return 'file-markdown';
    if (blob.mimeType === 'application/vnd.ms-excel') return 'file-excel';
    if (blob.mimeType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') return 'file-excel';

    return 'file';
  }

  onAttachmentCreated(blob: DataBlob<BlobDataOrgUserAttachment>) {
    this.files = [...this.files, blob]
  }

  onAttachmentEdited(blob: DataBlob<BlobDataOrgUserAttachment>) {
    this.files = this.files.map(p => p.id === blob.id ? blob : p);
  }

  async setAvatar(img: string, mime: string) {
    try {
      if (this.org === null) return;

      const blob = await this.api.setOrgAvatar(this.id, img, mime);

      this.avatarBlobId = blob.id;
      this.org.avatarImageID = blob.id;
      this.avatarBlob = blob;

      this.selectedEditAvatar = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    }
  }

  async setBackgroundImage(img: string, mime: string) {
    try {
      if (this.org === null) return;

      const blob = await this.api.setOrgBackgroundImage(this.id, img, mime);

      this.backgroundImageBlobId = blob.id;
      this.org.backgroundImageID = blob.id;
      this.backgroundImageBlob = blob;

      this.selectedEditBackgroundImage = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    }
  }

  avatarSource() {
    if (this.org === null) return undefined;
    if (this.org.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${this.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.avatarBlobId}`;
  }

  addConvNote() {
    this.showCreateConvNoteModal = true;
  }

  showConvNote(cnote: ConversationNote) {
    if (cnote.id === this.expandedConvNote) this.expandedConvNote = null;
    else this.expandedConvNote = cnote.id;
  }

  editConvNote(cnote: ConversationNote) {
    this.showEditConvNotesModal = cnote;
  }

  deleteConvNote(cnote: ConversationNote) {
    if (this.id === null) return;

    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gesprächsnotiz löschen?',
      nzContent: 'Möchtest du die Gesprächsnotiz wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          if (this.org === null) return;

          loading = true;
          await this.api.deleteConversationNote(this.id, cnote.id);
          this.conversationNotes = this.conversationNotes.filter(p => p.id != cnote.id);
          this.notification.success('Gesprächsnotiz gelöscht', 'Die Gesprächsnotiz wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Die Gesprächsnotiz konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onConversationNoteCreated(cnote: ConversationNote) {
    this.conversationNotes = [...this.conversationNotes, cnote]
  }

  onConversationNoteEdited(cnote: ConversationNote) {
    this.conversationNotes = this.conversationNotes.map(p => p.id === cnote.id ? cnote : p);
  }

  async clearAvatar() {
    if (this.org === null) return;

    try {
      this.clearingAvatar = true;

      await this.api.deleteOrgAvatar(this.id);

      this.avatarBlobId = 'UNSET';
      this.backgroundImageBlobId = 'UNSET';
      this.org.avatarImageID = null;
      this.avatarBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingAvatar = false;
    }
  }

  async clearBackgroundImage() {
    if (this.org === null) return;

    try {
      this.clearingBackgroundImage = true;

      await this.api.deleteOrgBackgroundImage(this.id);

      this.org.backgroundImageID = null;
      this.backgroundImageBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Hintergrundbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingBackgroundImage = false;
    }
  }

  editProfileField(ref: string, name: string) {
    this.editProfileText = name;
    this.updatingProfileText = false;
    this.profileTextRef = ref;
    this.profileTextValue = getIndirectFieldValue<string>(this.org, ref)!;
  }

  async updateProfileText() {
    if (this.org === null) return;

    try {
      this.updatingProfileText = true;

      this.org = await this.api.updateOrgProfileField(this.id, this.profileTextRef, this.profileTextValue);
      this.editProfileText = '';

      this.updatingProfileText = false;
    } catch (err) {
      showAPIError(this.notification, 'Profil konnte nicht editiert werden', err)
    }
  }

  onGroupCreated(groups: Group[]) {
    this.groups = this.adjustReferencesInGroupTree(this.createGroupTree(groups))[1];
  }

  onGroupEdited(entry: Group) {
    alert('TODO: Group edited')
    //this.groups = this.groups.map(p => p.id == entry.id ? entry : p);
  }


  updateGroup(nodes: Node[], group: Group) {
    nodes.forEach((n) => {
      if (n.group.id === group.id) {
        n.group = group;
      } else {
        this.updateGroup(n.children as Node[], group);
      }
    });
  }

  onGroupUpdated(newdata: Group[] | Group) {
    if (Array.isArray(newdata)) {
      for (const group of newdata) {
        this.updateGroup(this.groups, group);
      }
    } else {
      this.updateGroup(this.groups, newdata);
    }
  }

  onGroupActivated(group: Group) {

    if (this.hasParentGroup(group) && !this.isParentGroupActivated(group)) {
      this.modal.info({
        nzTitle: 'Gruppe kann nicht aktiviert werden',
        nzContent: 'Die Gruppe "' + group.title + '" hat übergeordnete deaktivierte Gruppen und kann daher nicht aktiviert werden',
        nzOkText: 'Verstanden'
      })
      return;
    }

    let loading = false;
    this.modal.confirm({
      nzTitle: 'Gruppe aktivieren?',
      nzContent: 'Möchtest du die Gruppe "' + group.title + '" wirklich aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enableGroup(group.organizationID, group.id);
          this.notification.success('Gruppe aktiviert', 'Die Gruppe "' + group.title + '" wurde aktiviert');
          this.updateGroup(this.groups, newdata);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onGroupDeleted(group: Group) {
    let loading = false;

    const hasChildren = this.hasGroupChildren(group, this.groups);

    if (hasChildren) {

      this.modal.error({
        nzTitle: 'Gruppe kann nicht gelöscht werden',
        nzContent: 'Die Gruppe "' + group.title + '" hat noch untergeordnete Gruppen und kann daher nicht gelöscht werden',
        nzOkText: 'Verstanden'
      })
      return;
    }


    this.modal.confirm({
      nzTitle: 'Gruppe löschen?',
      nzContent: 'Möchtest du die Gruppe "' + group.title + '" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          const res = await this.api.deleteGroup(group.organizationID, group.id);
          this.notification.success('Gruppe gelöscht', 'Die Gruppe "' + group.title + '" wurde gelöscht');
          this.groups = this.adjustReferencesInGroupTree(this.createGroupTree(res.groups))[1];
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  groupAvatarSource(data: Group) {
    if (data.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${data.organizationID}/groups/${data.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${data.avatarImageID}`;
  }

  backgroundImageSource() {
    if (this.org === null) return undefined;
    if (this.org.backgroundImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when image has changed
    return `${environment.apiBaseUrl}organizations/${this.org.id}/background-image?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.backgroundImageBlobId}`;
  }

  onLinkAdded(org: Organization) {
    this.orgCache.set(org.id, org);

    if (this.org === null) return;

    const oldlinks = this.org.linkedOrganizations;
    const newlinks = [...this.org.linkedOrganizations, org.id]

    this.org.linkedOrganizations = newlinks;

    void (async () => {
      try {
        this.addLinkLoading = true;
        const data = await this.api.updateOrgLinks(this.org!.id, newlinks);
        if (this.org !== null) this.org.linkedOrganizations = data.linkedOrganizations;
      } catch (err) {
        showAPIError(this.notification, 'Link konnten nicht aktualisiert werden', err)
        if (this.org !== null) this.org.linkedOrganizations = oldlinks;
      } finally {
        this.addLinkLoading = false;
      }
    })();
  }

  async deleteOrgLink(orgid: string) {
    if (this.org === null) return;

    const oldlinks = this.org.linkedOrganizations;
    const newlinks = this.org.linkedOrganizations.filter(p => p != orgid);

    try {
      this.deleteOrgLinkLoading.add(orgid);
      const data = await this.api.updateOrgLinks(this.org!.id, newlinks);
      this.org.linkedOrganizations = data.linkedOrganizations;
    } catch (err) {
      showAPIError(this.notification, 'Link konnten nicht aktualisiert werden', err)
      if (this.org !== null) this.org.linkedOrganizations = oldlinks;
    } finally {
      this.deleteOrgLinkLoading.delete(orgid);
    }
  }

  onPostCreated(v: Post) {
    this.posts = [v, ...this.posts];
  }

  onPostUpdated(newdata: Post) {
    this.posts = this.posts.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onEventCreated(event: Event) {
    this.events = [event, ...this.events];
  }

  onEventUpdated(newdata: Event) {
    this.events = this.events.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onPostDeleted(newdata: Post) {
    this.posts = this.posts.filter(g => g.id !== newdata.id);
  }


  onEventDeleted(newdata: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event löschen?',
      nzContent: 'Möchtest du das Event "' + newdata.title + '" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          const res = await this.api.deleteEvent(newdata.organizationID, newdata.id);
          this.notification.success('Event gelöscht', 'Das Event "' + newdata.title + '" wurde gelöscht');

          this.events = this.events.filter(g => g.id !== newdata.id);

        } catch (err) {
          showAPIError(this.notification, 'Das Event konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });

  }

  onEventToggleStatus({event, active}: { event: Event, active: boolean }) {
    if (active) this.activateEvent(event);
    else this.deactivateEvent(event);
  }

  activateEvent(event: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event aktivieren?',
      nzContent: 'Möchtest du das Event "' + event.title + '" wirklich aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enableEvent(event.organizationID, event.id);
          this.notification.success('Event aktiviert', 'Das Event "' + event.title + '" wurde aktiviert');
          this.events = this.events.map(e => e.id == newdata.id ? newdata : e);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivateEvent(event: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event deaktivieren?',
      nzContent: 'Möchtest du das Event "' + event.title + '" wirklich deaktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.disableEvent(event.organizationID, event.id);
          this.notification.success('Event deaktiviert', 'Das Event "' + event.title + '" wurde deaktiviert');
          this.events = this.events.map(e => e.id == newdata.id ? newdata : e);
        } catch (err) {
          showAPIError(this.notification, 'Das Event konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }


  onImpressCreated(impress: Impressum) {
    console.log('onImpressCreated', impress)
    this.impress.push(impress);
  }

  onImpressUpdated(newdata: Impressum) {
    this.selectedImpress = null;
    this.impress = this.impress.map(g => (g.id === newdata.id) ? newdata : g);
  }

  onImpressDeleted(impress: Impressum) {
    this.modal.confirm({
      nzTitle: 'Impressum löschen?',
      nzContent: 'Möchtest du das Impressum "' + impress.name + '" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: this.loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          this.loading = true;
          const res = await this.api.deleteImpress(impress.organizationID, impress);
          this.notification.success('Impressum gelöscht', 'Das Impressum "' + impress.name + '" wurde gelöscht');
          this.impress = this.impress.filter(g => g.id !== impress.id);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht gelöscht werden', err)
        } finally {
          this.loading = false;
        }
      }
    });

  }


  showCreateGarbageCollection(month: string) {
    this.selectedMonth = month;
    this.showAddWasteCollectionModal = true;
  }


  onWasteCalendarStreetCreated(wasteCalendarStreet: WasteCalendarStreet) {
    console.log('onImpressCreated', wasteCalendarStreet)
    this.wasteCalendarStreets.push(wasteCalendarStreet);
  }

  onWasteCalendarStreetUpdated(wasteCalendarStreet: WasteCalendarStreet) {
    this.wasteCalendarStreets = this.wasteCalendarStreets.map(w => (w.id === wasteCalendarStreet.id) ? wasteCalendarStreet : w);
  }

  onWasteCalendarStreetDeleted(wasteCalendarStreet: WasteCalendarStreet) {
    this.wasteCalendarStreets = this.wasteCalendarStreets.filter(g => g.id !== wasteCalendarStreet.id);
  }

  showCalendarEvents(street: WasteCalendarStreet){
    return this.showStreetEvents.includes(street.id);
  }

  async toggleStreetCalendarEvents(street: WasteCalendarStreet) {
    console.log('toggleStreetCalendarEvents', street, this.showStreetEvents, this.showStreetEventsLoading)

    const index = this.showStreetEvents.findIndex(p => p === street.id)
    if (index === -1) {

      if(street.calendar === null) await this.loadWasteCalendarStreetsCalendar(street);
      else this.showStreetEventsLoading = this.showStreetEventsLoading.filter(p => p !== street.id);

      this.showStreetEvents.push(street.id);

      return;
    }
    this.showStreetEvents = this.showStreetEvents.filter(p => p !== street.id);
  }

  isCalendarEventsLoading(street: WasteCalendarStreet) {
    return this.showStreetEventsLoading.includes(street.id);
  }

  getWasteCalendarStreetCount(street: WasteCalendarStreet){
    if(street.calendar === null) return '';

    return street.calendar.events.length;
  }

  protected readonly Object = Object;
}
