
<app-edit-group-modal [(show)]="showEditGroup" (success)="showEditGroup = null; groupUpdated.emit($event)"></app-edit-group-modal>

<div class="entry" *ngIf="group !== null">

  <span class="icon">
    <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="64" [nzIcon]="'file-image'" [nzSrc]="avatarSource(group) ?? ''"></nz-avatar>
  </span>

  <span class="title">{{group.title}}</span>

  <span class="actionbuttons">


              <fa-icon-btn *ngIf="showEdit"
                           class="btn-url"
                           nzType="primary"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           perm="GROUPS::EDIT"
                           (btnClick)="showEditGroup = group"
                           icon="pen"></fa-icon-btn>

              <fa-icon-btn *ngIf="showDelete"
                           class="btn-url"
                           nzType="primary"
                           [nzDanger]="true"
                           [stopPropagation]="true"
                           buttonSize="24"
                           iconSize="14"
                           perm="GROUPS::DELETE"
                           (btnClick)="deleteGroup(group)"
                           icon="trash"></fa-icon-btn>

              <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           nz-tooltip="Gruppe aktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye"
                           perm="GROUPS::EDIT"
                           [disabled]="group.enabled"
                           (btnClick)="activateGroup(group)"></fa-icon-btn>

              <fa-icon-btn *ngIf="showChangeEnabled"
                           nzType="default"
                           [nzDanger]="true"
                           nz-tooltip="Gruppe deaktivieren"
                           buttonSize="24"
                           iconSize="14"
                           icon="eye-slash"
                           perm="GROUPS::EDIT"
                           [disabled]="!group.enabled"
                           (btnClick)="deactivateGroup(group)"></fa-icon-btn>

          </span>

  <span class="date">{{group.creationTime | displaydate}}</span>

</div>
