
<app-default-frame>

  <main *ngIf="loading" style=" justify-content: center; align-items: center; min-height: 10rem;">
    <nz-spin nzSize="large"></nz-spin>
  </main>

  <main *ngIf="!loading">

    <div class="action-header">
      <div class="filters">
      </div>
      <div class="actions">
        <fa-icon-btn icon="plus"                nzType="primary" text="Neuen Eintrag erstellen"      (btnClick)="addEntry(null, config.length)"></fa-icon-btn>
        <fa-icon-btn icon="wand-magic-sparkles" nzType="default" text="Fehlende Einträge hinzufügen" (btnClick)="autoAddMissingEntries()"></fa-icon-btn>
        <fa-icon-btn icon="arrow-down-a-z"      nzType="default" text="Einträge sortieren"           (btnClick)="autoSortEntries()" [loading]="sortLoading"></fa-icon-btn>
      </div>
    </div>

    <div class="config-list">

      <ng-container *ngFor="let c of config; let idx = index">

        <ng-container *ngIf="editingMappings.has(c.uuid)">

          <nz-select class="input-solseit-paper" nzShowSearch nzAllowClear nzPlaceHolder="Amtsblatt" [(ngModel)]="c.localData.solseitCommunityID">
            <ng-container *ngFor="let entry of communities" >
              <nz-option [nzLabel]="entry.name" [nzValue]="entry.referenceID"></nz-option>
            </ng-container>
            <nz-option *ngIf="c.serverData.solseitCommunityID && !c.serverData.isValidSolseitCommunityID" [nzLabel]="'[['+c.serverData.solseitCommunityID+']]'" [nzValue]="c.serverData.solseitCommunityID"></nz-option>
          </nz-select>

          <nz-select class="input-solseit-cat" nzShowSearch nzAllowClear nzPlaceHolder="Rubrik" [(ngModel)]="c.localData.solseitRubricID">
            <ng-container *ngFor="let entry of communityRubrics.get(c.localData.solseitCommunityID!) ?? []" >
              <nz-option [nzLabel]="entry.fullName" [nzValue]="entry.referenceID"></nz-option>
            </ng-container>
            <nz-option *ngIf="c.localData.solseitCommunityID && c.serverData.solseitRubricID && !c.serverData.isValidSolseitRubricID" [nzLabel]="'[['+c.serverData.solseitRubricID+']]'" [nzValue]="c.serverData.solseitRubricID"></nz-option>
          </nz-select>

          <fa-icon class="icon-arrow" [icon]="'arrow-right'"></fa-icon>

          <nz-select class="input-bnet-cp" nzShowSearch nzAllowClear nzPlaceHolder="ContentProvider" [(ngModel)]="c.localData.badennetOrg">
            <ng-container *ngFor="let org of allOrgs" >
              <nz-option [nzLabel]="org.name" [nzValue]="org.id"></nz-option>
            </ng-container>
          </nz-select>

          <nz-select class="input-bnet-group" nzShowSearch nzAllowClear nzPlaceHolder="Gruppe" [(ngModel)]="c.localData.badennetGroup">
            <nz-option [nzLabel]="'Direkt'" [nzValue]="'@direct'"></nz-option>
            <ng-container *ngFor="let grp of orgGroups.get(c.localData.badennetOrg!) ?? []" >
              <nz-option *ngIf="grp.organizationID == c.localData.badennetOrg" [nzLabel]="grp.title" [nzValue]="grp.id"></nz-option>
            </ng-container>
          </nz-select>

          <fa-icon-btn nzType="default"
                       [disabled]="hasChanged(c) || idx === 0"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="arrow-up"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="moveEntryUp(c, idx)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [disabled]="hasChanged(c) || idx === config.length-1"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="arrow-down"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="moveEntryDown(c, idx)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [disabled]="!canSave(c)"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="save"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="saveEntry(c, idx)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="trash"
                       (btnClick)="deleteEntry(c)"></fa-icon-btn>

          <fa-icon-btn nzType="primary"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="plus"
                       (btnClick)="addEntry(c, idx)"></fa-icon-btn>

        </ng-container>

        <ng-container  *ngIf="!editingMappings.has(c.uuid)">

          <span class="data-span">{{communityMapping.get(c.localData.solseitCommunityID!) ?? '-'}}</span>
          <span class="data-span">{{rubricMapping.get(c.localData.solseitRubricID!) ?? '-'}}</span>

          <fa-icon class="icon-arrow" [icon]="'arrow-right'"></fa-icon>

          <span class="data-span">{{orgMapping.get(c.localData.badennetOrg!) ?? '-'}}</span>
          <span class="data-span" *ngIf="c.localData.badennetGroup === '@direct'">(Direkt)</span>
          <span class="data-span" *ngIf="c.localData.badennetGroup !== '@direct'">{{groupMapping.get(c.localData.badennetGroup!) ?? '-'}}</span>

          <fa-icon-btn nzType="default"
                       [disabled]="hasChanged(c) || idx === 0"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="arrow-up"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="moveEntryUp(c, idx)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [disabled]="hasChanged(c) || idx === config.length-1"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="arrow-down"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="moveEntryDown(c, idx)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="pen"
                       [loading]="saveLoading.has(c.uuid)"
                       (btnClick)="editingMappings.add(c.uuid)"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="trash"
                       (btnClick)="deleteEntry(c)"></fa-icon-btn>

          <fa-icon-btn nzType="primary"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="plus"
                       (btnClick)="addEntry(c, idx)"></fa-icon-btn>

        </ng-container>

      </ng-container>

    </div>

  </main>

</app-default-frame>
