import {Component, Input} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Group} from "../../interfaces/group";

@Component({
  selector: 'app-profile-preview',
  templateUrl: './profile-preview.component.html',
  styleUrls: ['./profile-preview.component.scss']
})
export class ProfilePreviewComponent {

  @Input() avatarSource: string|undefined = undefined;
  @Input() backgroundSource: string|undefined = undefined;
  @Input() name: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

}
