
<div *ngIf="loading" class="wrapper loading">
  <nz-spin></nz-spin>
</div>

<div *ngIf="!loading" class="wrapper">

  <div class="execlist" *ngIf="crawler !== null">

    <div *ngFor="let exec of executions" class="exec">
      <div class="exec-body" (click)="toggle(exec)">

        <div class="status status-error" *ngIf="exec.error !== null"></div>
        <div class="status status-okay"  *ngIf="exec.error === null && exec.endTime !== null"></div>
        <div class="status status-active"  *ngIf="exec.error === null && exec.endTime === null"></div>

        <div class="id">{{exec.id | shortid}}</div>

        <div class="time">{{exec.startTime | displaydate}}</div>

        <div *ngIf="exec.endTime === null" class="duration">{{secondsDiff(exec.startTime, lastRefresh) | displayduration}}</div>
        <div *ngIf="exec.endTime !== null" class="duration">{{secondsDiff(exec.startTime, exec.endTime) | displayduration}}</div>

        <div class="err" [class.empty]="exec.error === null" >{{(exec.error ?? '')  | singleLine}}</div>

        <div class="errors" [class.empty]="exec.errorCounter === 0" >{{exec.errorCounter}}</div>

        <div class="changes" [class.empty]="exec.changes === 0" >{{exec.changes}}</div>

      </div>

      <div *ngIf="expandedLogs.has(exec.id)" class="logs">
        <app-crawler-log-list [execution]="[crawler, exec]" [trigger]="refreshTrigger"></app-crawler-log-list>
      </div>

    </div>

  </div>

  <button *ngIf="executions.length > 0 && nextToken !== '@end'" nz-button nzType="default" class="loadMoreBtn" (click)="loadMore()" [nzLoading]="loadingMore">Mehr Laden</button>

</div>
