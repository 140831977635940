
<app-default-frame>

  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Content-Provider" [valueFunc]="queryContentProviderCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Administratoren" [valueFunc]="queryAdminUserCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Publishing-User" [valueFunc]="queryPublishingUserCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="User" [valueFunc]="queryAppUserCount()" ></app-number-card>
    </div>

  </div>

  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Posts" [valueFunc]="queryPostCount('all')" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Posts heute" [valueFunc]="queryPostCount('today')" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Posts diese Woche" [valueFunc]="queryPostCount('thisweek')" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Posts letzte Woche" [valueFunc]="queryPostCount('lastweek')" ></app-number-card>
    </div>

  </div>

  <div nz-row [nzGutter]="[24, 24]">

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Crawler" [valueFunc]="queryCrawlerCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Fehler" type="error" [valueFunc]="queryErrorCount()" ></app-number-card>
    </div>

    <div nz-col [nzXs]="spanXS" [nzSm]="spanSM" [nzMd]="spanMD" [nzLg]="spanLG"  [nzXl]="spanXL" [nzXXl]="spanXXL">
      <app-number-card title="Crawled Posts" type="okay" [valueFunc]="queryCrawledPostCount()" ></app-number-card>
    </div>

  </div>

</app-default-frame>

