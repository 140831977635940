import {Component} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {Router} from "@angular/router";
import {PlatformLocation} from "@angular/common";
import {AuthenticationService} from "../../services/authentication.service";
import {Title} from "@angular/platform-browser";
import {formatAPIError, isErrorCode, showAPIError} from "../../utils/api";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-settings',
  templateUrl: './org-settings.component.html',
  styleUrls: ['./org-settings.component.scss']
})
export class OrgSettingsComponent {
  loading: boolean = false;

  self: OrganizationUser|null = null;
  editPassword: boolean = false;
  updatingPassword: boolean = false;
  password0: string = '';
  password1: string = '';
  password2: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private auth: AuthenticationService) {

  }

  ngOnInit(): void {
    this.updateOrgUser(this.auth.getSelfOrgID()!, this.auth.getSelfID()!).then(() => {});
  }

  private async updateOrgUser(orgid:ObjectId, userid: ObjectId) {
    this.loading = true;
    try {
      this.self = await this.api.getOrgUser(orgid, userid);
    } catch (err: any) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidPWOld(val: string) {
    return val.length >= 1;
  }

  isValidPW1(v: string) {
    return v.length >= 8;
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1(this.password1) && v == this.password1;
    }
  }

  async updatePassword() {

    if (this.self === null) return;

    try {
      this.updatingPassword = true;

      await this.api.updateOrgUserPassword(this.self.organizationID, this.self.id, this.password1, this.password0);

      this.editPassword = false;
      this.notification.success('Aktualisiert', 'Dein Passwort wurde geändert');

    } catch (err) {
      if (isErrorCode(err, 'WRONG_OLD_PASSWORD')) {
        showAPIError(this.notification, 'Bisheriges Passwort ist falsch', err)
      } else {
        showAPIError(this.notification, 'Passwort konnte nicht aktualisiert werden', err)
      }
    } finally {
      this.updatingPassword = false;
    }
  }
}
