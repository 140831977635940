import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {OrganizationUser, OrgUserPermKey} from "../interfaces/organizationUser";
import {AuthenticationService} from "../services/authentication.service";

@Pipe({ name: 'checkPerm', pure: true })
export class CheckPermPipe implements PipeTransform {

  constructor(private auth: AuthenticationService) {

  }

  transform(perm: OrgUserPermKey | OrgUserPermKey[] | null) {
    if (perm === null) return true;

    const selfType = this.auth.getSelfUserType();
    const self = this.auth.getSelf();

    if (selfType == 'ADMIN_USER') return true;

    if (selfType === null) return false;

    if (selfType === 'ORGANIZATION_USER') {
      if (self !== null) {
        const userPerms = (<OrganizationUser>self).permissions;

        console.log('Checking perm', perm, userPerms)
        if (userPerms.includes('SUPER')) return true;

        if (typeof perm === 'string') {
          return userPerms.includes(perm);
        } else {
          for (const singlePerm of perm) {
            if (userPerms.includes(singlePerm)) return true;
          }
          return false;
        }
      }
    }

    return false;
  }
}
