import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {Group} from "../../interfaces/group";
import {Crawler} from "../../interfaces/crawler";
import {deepclone, isNgDiff} from "../../utils/angular";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-edit-crawler-modal',
  templateUrl: './edit-crawler-modal.component.html',
  styleUrls: ['./edit-crawler-modal.component.scss']
})
export class EditCrawlerModalComponent implements OnChanges, OnInit {

  @Input() show: Crawler|null = null;
  @Output() showChange: EventEmitter<Crawler|null> = new EventEmitter<Crawler|null>();
  @Output() success: EventEmitter<Crawler> = new EventEmitter<Crawler>();
  @Output() deleteSuccess: EventEmitter<ObjectId> = new EventEmitter<ObjectId>();
  @Output() runNowSuccess: EventEmitter<ObjectId> = new EventEmitter<ObjectId>();

  crawler: Crawler|null = null;

  loading: boolean = false;
  runNowLoading: boolean = false;

  configLoading: boolean = false;
  keys: string[] = [];
  allKeys: string[] = [];
  config: {[_:string]:string} = {};

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.crawler = null;
        this.clear();
      } else {
        this.crawler = deepclone(this.show);
        this.loadImplKeys().then(() => {});
      }
      return;
    }
  }

  ngOnInit(): void {}

  close() {
    this.crawler = null;
    this.showChange.emit(this.show = null);
    this.clear();
  }

  clear() {
    this.crawler = null;
    this.keys = [];
    this.config = {};
  }

  async execute() {

    if (this.show    === null) return;
    if (this.crawler === null) return;

    try {
      this.loading = true;

      let conf = deepclone(this.config);

      const data = await this.api.updateCrawler(this.crawler.id, {
        'name':            this.crawler.name,
        'enabled':         this.crawler.enabled,
        'interval':        Number(this.crawler.interval),
        'retryInterval':   Number(this.crawler.retryInterval),
        'timeout':         Number(this.crawler.timeout),
        'maxRetryCount':   Number(this.crawler.maxRetryCount),
        'configuration':   conf,
      });

      this.showChange.emit(this.show = null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Crawler konnte nicht gespeichert werden', err)
    } finally {
      this.loading = false;
    }
  }

  async delete() {

    if (this.show    === null) return;
    if (this.crawler === null) return;

    try {
      this.loading = true;

      const cid = this.crawler.id

      const data = await this.api.deleteCrawler(cid);

      this.showChange.emit(this.show = null);
      this.clear();

      this.deleteSuccess.emit(cid);

    } catch (err) {
      showAPIError(this.notification, 'Crawler konnte nicht gelöscht werden', err)
    } finally {
      this.loading = false;
    }
  }

  async runCrawlerNow() {
    if (this.show === null) return;

    try {
      this.runNowLoading = true;

      const cid = this.show.id

      await this.api.runCrawlerNow(cid);

      this.notification.success('Gestartet', 'Crawler wurde gestartet');

      this.showChange.emit(this.show = null);
      this.clear();

      this.runNowSuccess.emit(cid);

    } catch (err) {
      showAPIError(this.notification, 'Crawler konnte nicht gestartet werden', err)
    } finally {
      this.runNowLoading = false;
    }
  }

  async loadImplKeys() {
    this.keys = [];
    this.config = {};

    if (this.show === null) return;

    try {
      this.configLoading = true;
      const data = await this.api.listCrawlerImplConfigKeys(this.show.implementation);

      this.keys = data.configKeys;

      this.allKeys = [...(new Set([...data.configKeys, ...Object.keys(this.show.configuration)]))].sort()

      let patchedconfig: {[_:string]:string} = {};
      for (const ckey of data.configKeys) {
        if (this.show.configuration[ckey] !== undefined) {
          patchedconfig[ckey] = this.show.configuration[ckey]
        } else {
          patchedconfig[ckey] = '';
        }
      }

      this.config = patchedconfig;

      this.configLoading = false;

    } catch (err) {
      showAPIError(this.notification, 'Crawler-Config konnte nicht geladen werden', err)
    }
  }
}
