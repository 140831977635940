export interface APIError {
  errorcode: string;
  message: string;
  fapiMessage?: string;

  __error?: string;
  __trace?: string[];
}

export function isAPIError(v: any): v is APIError {
  return v !== undefined && v !== null && v.errorcode !== undefined && v.message !== undefined;
}
