import {Component, OnInit} from '@angular/core';
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {Organization} from "../../interfaces/organization";
import {CursorToken, ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-orguser-list',
  templateUrl: './org-orguser-list.component.html',
  styleUrls: ['./org-orguser-list.component.scss']
})
export class OrgOrgUserListComponent implements OnInit {
  orgid!: ObjectId;

  users: OrganizationUser[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  filters: SerializedParamCollection = {};

  showCreateOrgUserModal: boolean = false;
  selectedEditOrgUser: OrganizationUser|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.orgid = this.auth.getSelfOrgID()!;

    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchUsers(true);
  }

  async fetchUsers(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listOrgUser(this.orgid, this.nextToken, 24);

      if (reset) {
        this.users = data.users;
      } else {
        this.users = [...this.users, ...data.users];
      }

      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['org', 'orgusers'], this.filters));
  }

  deleteOrgUser(entry: OrganizationUser) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Zugang löschen?',
      nzContent: 'Möchtest du den Benutzer "'+entry.username+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deleteOrgUser(entry.organizationID, entry.id);
          this.users = this.users.filter(p => p.id != entry.id);
          this.notification.success('Benutzer gelöscht', 'Der Benutzer "' + entry.username + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Der Benutzer konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onOrgUserCreated(entry: OrganizationUser) {
    this.users = [entry, ...this.users];
  }

  onOrgUserEdited(entry: OrganizationUser) {
    this.users = this.users.map(p => p.id == entry.id ? entry : p);
  }
}
