import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {ConversationNote} from "../../interfaces/conversationNotes";
import {APIService} from "../../services/api.service";
import {AuthenticationService} from "../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff} from "../../utils/angular";
import {DateUtils} from "../../utils/date";
import {formatAPIError, showAPIError} from "../../utils/api";
import {Organization} from "../../interfaces/organization";
import {Observable, Subject} from "rxjs";
import { debounceTime, map } from 'rxjs/operators';

@Component({
  selector: 'app-add-org-link-modal',
  templateUrl: './add-org-link-modal.component.html',
  styleUrls: ['./add-org-link-modal.component.scss']
})
export class AddOrgLinkModalComponent implements OnInit, OnDestroy {
  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<Organization> = new EventEmitter<Organization>();

  searchString: string = '';
  realSearchString: string = '';
  searchResults: Organization[] = [];

  loading: boolean = false;
  searching: boolean = false;

  startSearch$ = new Subject<string>();

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
    this.startSearch$
      .pipe(debounceTime(500))
      .subscribe((value) => {
        this.realSearchString = this.searchString;
        this.search(this.realSearchString).then(() => {});
    })
  }

  ngOnDestroy(): void {
    this.startSearch$.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      this.clear();
      return;
    }
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.searchString = '';
    this.searchResults = [];
    this.loading = false;
  }

  async search(ss: string) {
    this.searching = true;
    try {

      this.searchResults = [];
      const data = await this.api.listOrganizations("@start", 24, {search: {active: (ss==='' ? null : ss), default: null, type: 'string'}});
      this.searchResults = data.orgs;

    } catch (err) {
      showAPIError(this.notification, 'Fehler beim Suchen', err)
    } finally {
      this.searching = false;
    }
  }

  async execute(org: Organization) {
    if (this.show === null) return;

    this.show = false;
    this.showChange.emit(false);
    this.clear();

    this.success.emit(org);
  }
}
