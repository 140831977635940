import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-search-bar',
  templateUrl: './search-bar.component.html',
  styleUrls: ['./search-bar.component.scss']
})
export class SearchBarComponent {

  @Input() placeholder!: string;

  @Output() searchTriggered = new EventEmitter();

  @Input() value: string|null = '';
  @Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

  @Input() showClear: boolean = false;
  @Input() disabled: boolean = false;
  @Input() triggerOnChange: boolean = false;

  constructor() { }

  ngOnInit(): void {  }

  onClickSearch(): void {
    this.searchTriggered.emit(this.value);
  }

  onChangeInput(input: any): void {
    this.value = input ? input.target.value  : '';
    this.valueChange.emit(this.value);
  }

  onClickClear() {
    this.value = '';
    this.valueChange.emit(this.value);
    this.searchTriggered.emit(this.value);
  }

  onInput(input: any) {
    if (this.triggerOnChange) {
      this.value = input ? input.target.value  : '';
      this.valueChange.emit(this.value);
      this.searchTriggered.emit(this.value);
    }
  }
}
