import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'suffix', pure: true })
export class SuffixPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: string, len: number|undefined = undefined) {
    if (len === undefined) return value;
    return value.substring(Math.max(0, value.length-len), value.length)
  }
}
