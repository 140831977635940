import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {environment} from "../../../environments/environment";
import {DataBlob} from "../../interfaces/datablobs";
import {getIndirectFieldValue} from "../../utils/indirect";
import {Post} from "../../interfaces/post";
import {Group} from "../../interfaces/group";
import {Organization} from "../../interfaces/organization";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-userprofile',
  templateUrl: './org-userprofile.component.html',
  styleUrls: ['./org-userprofile.component.scss']
})
export class OrgUserProfileComponent implements OnInit {
  loading = true;

  id!: ObjectId;
  orgid!: ObjectId;
  user: OrganizationUser|null = null;
  org: Organization|null = null;

  selectedEditAvatar: OrganizationUser|null = null;

  avatarBlobId: string = 'INITIAL';

  clearingAvatar: boolean = false;

  loadingBlobAvatar: boolean = false;

  avatarBlob: DataBlob<any>|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  async ngOnInit() {
    this.id = this.auth.getSelfID()!;
    this.orgid = this.auth.getSelfOrgID()!;
    await this.loadUser();
  }

  async loadUser() {

    this.loading = true;

    try {
      const d1 = await this.api.getOrgUser(this.orgid, this.id);
      const d2 = await this.api.getOrganization(d1.organizationID);

      this.user = d1;
      this.org = d2;

      this.avatarBlobId = d1.avatarImageID ?? 'UNSET';

      if (d1.avatarImageID !== null) this.loadBlobAvatar().then(()=>{});

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async loadBlobAvatar() {
    if (this.user === null) return;
    if (this.user.avatarImageID === null) return;

    this.loadingBlobAvatar = true;

    try {
      this.avatarBlob = await this.api.getBlob(this.user.avatarImageID);

    } catch (err) {
      showAPIError(this.notification, 'Profilbild-Info konnte nicht geladen werden', err)
    } finally {
      this.loadingBlobAvatar = false;
    }
  }

  async setAvatar(img: string, mime: string) {
    try {
      if (this.user === null) return;

      const blob = await this.api.setOrgUserAvatar(this.orgid, this.id, img, mime);

      this.avatarBlobId = blob.id;
      this.user.avatarImageID = blob.id;
      this.avatarBlob = blob;

      this.selectedEditAvatar = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht hochgeladen werden', err)
    }
  }

  async clearAvatar() {
    if (this.user === null) return;

    try {
      this.clearingAvatar = true;

      await this.api.deleteOrgUserAvatar(this.orgid, this.id);

      this.avatarBlobId = 'UNSET';
      this.user.avatarImageID = null;
      this.avatarBlob = null;
    } catch (err) {
      showAPIError(this.notification, 'Profilbild konnte nicht gelöscht werden', err)
    } finally {
      this.clearingAvatar = false;
    }
  }

  avatarSource() {
    if (this.user === null) return undefined;
    if (this.user.avatarImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when image has changed
    return `${environment.apiBaseUrl}organizations/${this.user.organizationID}/users/${this.id}/avatar?xx-bearer-token=@${this.auth.getToken()}&blobid=${this.avatarBlobId}`;
  }

}
