<nz-modal [nzVisible]="show"
          nzTitle="Neuer Benutzer anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>

    <div *ngIf="!initLoading" class="content">

      <ng-container *ngIf="forcedOrgId === null || selectedOrg === null">
        <span class="key">ContentProvider</span>
        <nz-select class="value" nzShowSearch nzAllowClear nzPlaceHolder="ContentProvider auswählen" [(ngModel)]="selectedOrg" (ngModelChange)="loadData()">
          <ng-container *ngFor="let org of allOrgs" >
            <nz-option [nzLabel]="org.name" [nzValue]="org.id"></nz-option>
          </ng-container>
        </nz-select>

        <span class="spacer"></span>

      </ng-container>

      <span class="key">Name</span>
      <div class="input-block name">
        <app-validateable-input [disabled]="selectedOrg === null" placeholder="Max"        [preventAutofill]="true" [(value)]="firstName" [validate]="isValidStr"   ></app-validateable-input>
        <app-validateable-input [disabled]="selectedOrg === null" placeholder="Mustermann" [preventAutofill]="true" [(value)]="lastName"  [validate]="isValidStr"   ></app-validateable-input>
      </div>

      <span class="key">Benutzername</span>
      <app-validateable-input [disabled]="selectedOrg === null" placeholder="Max1994" [preventAutofill]="true" [(value)]="username"  [validate]="isValidStr"   ></app-validateable-input>

      <span class="key">Mail</span>
      <app-validateable-input [disabled]="selectedOrg === null" placeholder="local@domain.tld" [preventAutofill]="true" [(value)]="mail" [validate]="isValidMail"></app-validateable-input>

      <span class="spacer"></span>

      <ng-container *ngIf="!forceAuthMail">

        <span class="key" [style.opacity]="sendPasswordMail ? 0.5 : 1.0">Passwort</span>
        <div style="display: grid; grid-template-columns: 1fr auto auto; grid-column-gap: 0.5rem">
          <app-validateable-input class="pw1" [preventAutofill]="true" placeholder="" [type]="showPW ? 'text' : 'password'" [disabled]="sendPasswordMail || selectedOrg === null" [(value)]="password1" [validate]="isValidPW1()" ></app-validateable-input>
          <fa-icon-btn [disabled]="selectedOrg === null" icon="dice-six" nz-tooltip="Zufälliges Passwort generieren" buttonSize="32"  iconSize="17" (btnClick)="randomPassword()"></fa-icon-btn>
          <fa-icon-btn [disabled]="selectedOrg === null" icon="eye"  buttonSize="32"  iconSize="17" (btnClick)="showPW = !showPW"></fa-icon-btn>
        </div>

        <span class="key" [style.opacity]="sendPasswordMail ? 0.5 : 1.0">Passwort wiederholen</span>
        <app-validateable-input class="pw2" placeholder="" [preventAutofill]="true" [type]="showPW ? 'text' : 'password'" type="password" [disabled]="sendPasswordMail || selectedOrg === null" [(value)]="password2" [validate]="isValidPW2()" ></app-validateable-input>

        <span class="key"><label nz-checkbox  class="pwmail" [disabled]="selectedOrg === null" [(ngModel)]="sendPasswordMail">Registrierungs Mail senden</label></span>

        <span class="spacer"></span>

      </ng-container>

      <span class="key">Berechtigungen</span>
      <div class="input-block permissions">

        <ng-container *ngFor="let perm of permlist">
          <ng-container *ngIf="perm.value !== 'SUPER'">
            <label nz-checkbox [disabled]="selectedOrg == null" [ngModel]="permissions.includes(perm.value)" (ngModelChange)="updatePermissions(perm.value, $event)">{{perm.description}}</label>
          </ng-container>
        </ng-container>

      </div>

    </div>
  </ng-container>
</nz-modal>
