<nz-modal [nzVisible]="show !== null"
          nzTitle="Administrator bearbeiten"
          nzOkText="Bearbeiten"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span>ID</span>
      <input nz-input [ngModel]="show?.id ?? ''"  readonly>

      <span>Vorname</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="firstName" [validate]="isValidStr"  ></app-validateable-input>

      <span>Nachname</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="lastName"  [validate]="isValidStr"  ></app-validateable-input>

      <span>Benutzername</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="username"  [validate]="isValidStr"  ></app-validateable-input>

      <span>Mail</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="mail"      [validate]="isValidMail" ></app-validateable-input>

    </div>
  </ng-container>
</nz-modal>
