
<app-default-frame>

  <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Einstellungen"></nz-page-header>


  <nz-card class="card-data" nzTitle="Daten" style="max-width: 750px" [nzLoading]="loading">
    <div class="infocardcontainer" *ngIf="self !== null">

      <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
      <span class="key">ID</span>
      <span class="value"><input nz-input [disabled]="true" [ngModel]="self.id" /></span>

      <span class="icon"><fa-icon icon="shirt"></fa-icon></span>
      <span class="key">Benutzername</span>
      <span class="value"><input nz-input [disabled]="true" [ngModel]="self.username" /></span>

      <span class="icon"><fa-icon icon="envelope"></fa-icon></span>
      <span class="key">Mail</span>
      <span class="value"><input nz-input [disabled]="true" [ngModel]="self.email" /></span>

      <ng-container *ngIf="!editPassword">
        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Passwort</span>
        <span class="value"><input nz-input [disabled]="true" [ngModel]="12345678" type="password" /></span>
        <span class="extra"><fa-icon-btn icon="pen" buttonSize="32" (btnClick)="editPassword=true;password0=password1=password2=''"></fa-icon-btn></span>
      </ng-container>

      <ng-container *ngIf="editPassword">

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Bisheriges Passwort</span>
        <span class="value"><app-validateable-input [(value)]="password0" type="password" [validate]="isValidPWOld"></app-validateable-input></span>

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Neues Passwort</span>
        <span class="value"><app-validateable-input [(value)]="password1" type="password" [validate]="isValidPW1"></app-validateable-input></span>

        <span class="icon"><fa-icon icon="key" ></fa-icon></span>
        <span class="key">Neues Passwort wiederholen</span>
        <span class="value"><app-validateable-input [(value)]="password2" type="password" [validate]="isValidPW2()"></app-validateable-input></span>
        <span class="extra"><fa-icon-btn nzType="primary"
                                         [disabled]="!(password0 != '' && isValidPW1(password1) && password2 === password1)"
                                         [loading]="updatingPassword"
                                         icon="floppy-disk"
                                         buttonSize="32"
                                         (btnClick)="updatePassword()"></fa-icon-btn></span>


      </ng-container>

    </div>
  </nz-card>

</app-default-frame>
