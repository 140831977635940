
<div *ngIf="loading" class="wrapper loading">
  <nz-spin></nz-spin>
</div>

<div *ngIf="!loading" class="wrapper">

  <div class="loglist">

    <div *ngFor="let log of logs" class="log">
      <div class="log-body" (click)="toggle(log)">

        <div class="type"
             [class.type-http]="log.type === 'HTTP_CALL'"
             [class.type-msg]="log.type === 'MESSAGE'"
             [class.type-err]="log.type === 'ERROR'">{{log.type}}</div>

        <div class="id">{{log.id | shortid}}</div>

        <div class="time">{{log.creationTime | date:'HH:mm:ss'}}</div>

        <div class="ident" *ngIf="log.type === 'HTTP_CALL'">{{log.dataHTTPCall!.callingMethod | singleLine}}</div>
        <div class="ident" *ngIf="log.type === 'MESSAGE'"  >{{log.dataMessage!.identifier}}</div>
        <div class="ident" *ngIf="log.type === 'ERROR'"    >{{log.dataError!.error | singleLine}}</div>

        <div class="msg" *ngIf="log.type === 'HTTP_CALL'">{{log.dataHTTPCall!.request.url}}</div>
        <div class="msg" *ngIf="log.type === 'MESSAGE'"  >{{log.dataMessage!.message | singleLine}}</div>
        <div class="msg" *ngIf="log.type === 'ERROR'"    >{{log.dataError!.message | singleLine}}</div>

      </div>

      <div *ngIf="expandedData.has(log.id)" class="log-extra">
        <ng-container *ngIf="log.type === 'HTTP_CALL' && log.dataHTTPCall !== null">

          <span class="key">CallingMethod</span>
          <input class="value" nz-input [ngModel]="log.dataHTTPCall.callingMethod" readonly>

          <span class="key">Duration</span>
          <input class="value" nz-input [ngModel]="log.dataHTTPCall.duration | displayduration" readonly>

          <span class="key">Error</span>
          <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataHTTPCall.errorStr" readonly></textarea>

          <span class="key">RequestMethod</span>
          <input class="value" nz-input [ngModel]="log.dataHTTPCall.request.method" readonly>

          <span class="key">RequestBody</span>
          <div class="value span formattable-textarea">
            <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataHTTPCall.request.requestBody" readonly></textarea>
            <button  *ngIf="log.dataHTTPCall.request.requestBody !== ''" nz-button nzType="primary" (click)="formatReqBody(log.dataHTTPCall.request)">(pretty)</button>
          </div>

          <span class="key">RequestURL</span>
          <input class="value" nz-input [ngModel]="log.dataHTTPCall.request.url" readonly>

          <ng-container *ngIf="log.dataHTTPCall.response !== null">
            <span class="key">ResponseStatus</span>
            <input class="value" nz-input [ngModel]="log.dataHTTPCall.response.responseStatus" readonly>

            <span class="key">ResponseMessage</span>
            <input class="value" nz-input [ngModel]="log.dataHTTPCall.response.message" readonly>

            <span class="key">ResponseBody</span>
            <div class="value span formattable-textarea">
              <textarea [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataHTTPCall.response.responseBody" readonly></textarea>
              <button  *ngIf="log.dataHTTPCall.response.responseBody !== ''" nz-button nzType="primary" (click)="formatRespBody(log.dataHTTPCall.response)">(pretty)</button>
            </div>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="log.type === 'MESSAGE' && log.dataMessage !== null">

          <span class="key">Identifier</span>
          <input class="value" nz-input [ngModel]="log.dataMessage.identifier" readonly>

          <span class="key">Message</span>
          <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataMessage.message" readonly></textarea>

          <ng-container *ngIf="log.dataMessage.extra !== null">
            <span class="key">Extra</span>
            <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataMessage.extra | json" readonly></textarea>
          </ng-container>

        </ng-container>
        <ng-container *ngIf="log.type === 'ERROR' && log.dataError !== null">

          <span class="key">Message</span>
          <input class="value" nz-input [ngModel]="log.dataError.message" readonly>

          <span class="key">Error</span>
          <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataError.error" readonly></textarea>

          <span class="key">Trace</span>
          <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataError.trace" readonly></textarea>

          <ng-container *ngIf="log.dataError.extra !== null">
            <span class="key">Extra</span>
            <textarea class="value span" [nzAutosize]="true" rows="4" nz-input [ngModel]="log.dataError.extra | json" readonly></textarea>
          </ng-container>


        </ng-container>
      </div>

    </div>

  </div>

</div>
