import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, showAPIError} from "../../utils/api";
import {generatePronouncablePassword} from "../../utils/pw";

@Component({
  selector: 'app-edit-admin-password-modal',
  templateUrl: './edit-admin-password-modal.component.html',
  styleUrls: ['./edit-admin-password-modal.component.scss']
})
export class EditAdminPasswordModalComponent implements OnInit {

  @Input() show: AdminUser|null = null;
  @Output() showChange: EventEmitter<AdminUser|null> = new EventEmitter<AdminUser|null>();
  @Output() success: EventEmitter<AdminUser> = new EventEmitter<AdminUser>();

  loading: boolean = false;

  showPW: boolean = false;
  password1: string = '';
  password2: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.showPW    = false;
    this.password1 = '';
    this.password2 = '';
  }

  async execute() {
    if (!this.isValidPW1(this.password1))      { this.notification.error('Fehler', 'Bitte valides Passwort eingeben (midestens 8 Zeichen)');   return; }
    if (!this.isValidPW2()(this.password2))    { this.notification.error('Fehler', 'Passwörter stimmern nicht überein'); return; }


    try {
      this.loading = true;

      const data = await this.api.updateAdminPassword(this.show!.id, this.password1);

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht erstellt werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidPW1(v: string) {
    return v.length >= 8;
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1(this.password1) && v == this.password1;
    }
  }

  randomPassword() {
    this.showPW = true;
    this.password2 = this.password1 = generatePronouncablePassword(5);
  }
}
