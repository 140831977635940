import {Component, OnInit} from '@angular/core';
import {AppUser} from "../../interfaces/applicationUser";
import {CursorToken} from "../../interfaces/utils";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Community} from "../../interfaces/community";
import {AdminUser} from "../../interfaces/adminUser";

@Component({
  selector: 'app-community-list',
  templateUrl: './community-list.component.html',
  styleUrls: ['./community-list.component.scss']
})
export class CommunityListComponent implements OnInit {

  communities: Community[] = [];
  nextToken: CursorToken = "@start";
  tableLoading: boolean = true;
  initialLoading: boolean = true;

  showCreateNewCommunity = false;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    await this.fetchData(true, true);
  }

  async fetchData(reset: boolean, initial: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      if (initial) this.initialLoading = true;
      this.tableLoading = true;

      const data = await this.api.listCommunities(this.nextToken, 64, this.filters);

      if (reset) {
        this.communities = data.communities;
      } else {
        this.communities = [...this.communities, ...data.communities];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Gemeinden konnten nicht geladen werden', err)
    } finally {
      this.tableLoading = false;
      if (initial) this.initialLoading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'communities'], this.filters));
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true, false).then(()=>{});
  }

  onCommunityCreated(c: Community) {
      this.communities = [c, ...this.communities];
  }
}
