<nz-modal [nzVisible]="show"
          nzTitle="Neue Gemeinde anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span>PLZ</span>
      <app-validateable-input placeholder="PLZ" [(value)]="zip" [validate]="isValidPLZ"></app-validateable-input>

      <span>Name</span>
      <app-validateable-input placeholder="Name" [(value)]="name" [validate]="isValidStr"></app-validateable-input>

    </div>
  </ng-container>
</nz-modal>
