import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, showAPIError} from "../../utils/api";
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'app-edit-admin-modal',
  templateUrl: './edit-admin-modal.component.html',
  styleUrls: ['./edit-admin-modal.component.scss']
})
export class EditAdminModalComponent implements OnInit, OnChanges {

  @Input() show: AdminUser|null = null;
  @Output() showChange: EventEmitter<AdminUser|null> = new EventEmitter<AdminUser|null>();
  @Output() success: EventEmitter<AdminUser> = new EventEmitter<AdminUser>();

  loading: boolean = false;

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  mail: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show === null) {
        this.clear();
      } else {
        this.username  = this.show.username;
        this.firstName = this.show.firstName;
        this.lastName  = this.show.lastName;
        this.mail      = this.show.email;
      }
      return;
    }
  }

  ngOnInit(): void {
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.firstName = '';
    this.lastName  = '';
    this.username  = '';
    this.mail      = '';
  }

  async execute() {
    if (!this.isValidStr(this.firstName))      { this.notification.error('Fehler', 'Bitte valider Name eingeben');          return; }
    if (!this.isValidStr(this.lastName))       { this.notification.error('Fehler', 'Bitte valider Name eingeben');          return; }
    if (!this.isValidStr(this.username))       { this.notification.error('Fehler', 'Bitte valider Benutzername eingeben');  return; }
    if (!this.isValidMail(this.mail))          { this.notification.error('Fehler', 'Bitte valide Email-Addresse eingeben'); return; }

    try {
      this.loading = true;

      const data = await this.api.updateAdmin(this.show!.id, this.firstName, this.lastName, this.username, this.mail);

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht aktualisiert werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }


}
