
<ng-template #contentTpl><ng-content></ng-content></ng-template>


<ng-container *ngIf="userType == 'ADMIN_USER'">

  <nz-layout class="app-layout layout-admin">
    <nz-sider class="menu-sidebar"
              #sider0
              nzCollapsible
              nzWidth="256px"
              [class]="isCollapsed() ? 'collapsed' : ''"
              [nzCollapsed]="isCollapsed()"
              (nzCollapsedChange)="setCollapsed($event);"
              [nzTrigger]="null">
      <div class="sidebar-logo">
        <fa-icon icon="hammer"></fa-icon>
        <h1>baden online - Admin</h1>
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">

        <li nz-menu-item [nzSelected]="isRoot()"><a routerLink="/admin/"                             ><fa-icon class="menu-icn" [icon]="['fas', 'gauge']"          ></fa-icon><span class="menu-txt">Dashboard</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/adminusers"                   ><fa-icon class="menu-icn" [icon]="['fas', 'user-gear']"      ></fa-icon><span class="menu-txt">Administratoren</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/organizations"                ><fa-icon class="menu-icn" [icon]="['fas', 'building']"       ></fa-icon><span class="menu-txt">ContentProvider</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/orgusers"                     ><fa-icon class="menu-icn" [icon]="['fas', 'user-tie']"       ></fa-icon><span class="menu-txt">Publishing-User</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/users"                        ><fa-icon class="menu-icn" [icon]="['fas', 'user']"           ></fa-icon><span class="menu-txt">User</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/posts"                        ><fa-icon class="menu-icn" [icon]="['fas', 'note-sticky']"    ></fa-icon><span class="menu-txt">Posts</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/groups"                       ><fa-icon class="menu-icn" [icon]="['fas', 'frame']"          ></fa-icon><span class="menu-txt">Gruppen</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/events"                       ><fa-icon class="menu-icn" [icon]="['fas', 'calendar-star']"  ></fa-icon><span class="menu-txt">Veranstaltungen</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/communities"                  ><fa-icon class="menu-icn" [icon]="['fas', 'church']"         ></fa-icon><span class="menu-txt">Gemeinden</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/reports"                      ><fa-icon class="menu-icn" [icon]="['fas', 'robot']"           ></fa-icon><span class="menu-txt">Meldungen</span></a></li>
        <li nz-menu-item nzMatchRouterExact     ><a routerLink="/admin/crawlers"                     ><fa-icon class="menu-icn" [icon]="['fas', 'robot']"          ></fa-icon><span class="menu-txt">Crawler</span></a></li>
        <li nz-menu-item nzMatchRouterExact     ><a routerLink="/admin/crawlers/solseit-config"      ><fa-icon class="menu-icn" [icon]="['fas', 'croissant']"      ></fa-icon><span class="menu-txt">Solseit Config</span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/admin/settings"                     ><fa-icon class="menu-icn" [icon]="['fas', 'gear']"           ></fa-icon><span class="menu-txt">Einstellungen</span></a></li>

      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header" style="display: flex; justify-content: space-between;">
        <span class="header-trigger" (click)="setCollapsed(!isCollapsed())">
            <span class="trigger" nz-icon [nzType]="isCollapsed() ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
          <div style="display: flex; margin-right: 24px; line-height: normal; align-items: center">
            <span class="header_userinfo">Angemeldet als <span *ngIf="adminUser !== null">{{adminUser.username}}</span><span nz-icon *ngIf="adminUser === null" [nzType]="'loading'"></span></span>
            <span style="width: 1em"></span>
            <fa-icon-btn nzType="primary" buttonSize="32" icon="right-from-bracket" (btnClick)="logout()"></fa-icon-btn>
          </div>
        </div>
      </nz-header>
      <nz-content>
        <div class="inner-content">
          <div class="inner-content-wrapper">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
          </div>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>

</ng-container>









<ng-container *ngIf="userType == 'ORGANIZATION_USER'">

  <nz-layout class="app-layout layout-orguser">
    <nz-sider class="menu-sidebar"
              nzCollapsible
              nzWidth="256px"
              nzBreakpoint="md"
              [class]="isCollapsed() ? 'collapsed' : ''"
              [nzCollapsed]="isCollapsed()"
              (nzCollapsedChange)="setCollapsed($event)"
              [nzTrigger]="null">
      <div class="sidebar-logo">
        <img ngSrc="/assets/bo_logo.png" alt="Logo BadenNews" width="500" height="401">
        <h1>baden online</h1>
      </div>
      <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed()">

        <li nz-menu-item [nzSelected]="isRoot()"><a routerLink="/org"             ><fa-icon class="menu-icn" [icon]="['fas', 'building']"     ></fa-icon><span class="menu-txt">Dashboard       </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/orgusers"    ><fa-icon class="menu-icn" [icon]="['fas', 'user-tie']"     ></fa-icon><span class="menu-txt">Zugänge         </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/posts"       ><fa-icon class="menu-icn" [icon]="['fas', 'note-sticky']"  ></fa-icon><span class="menu-txt">Posts           </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/groups"      ><fa-icon class="menu-icn" [icon]="['fas', 'frame']"        ></fa-icon><span class="menu-txt">Gruppen         </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/events"      ><fa-icon class="menu-icn" [icon]="['fas', 'calendar-star']"></fa-icon><span class="menu-txt">Veranstaltungen </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/statistics"  ><fa-icon class="menu-icn" [icon]="['fas', 'gauge']"        ></fa-icon><span class="menu-txt">Statistiken     </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/userprofile" ><fa-icon class="menu-icn" [icon]="['fas', 'id-card']"      ></fa-icon><span class="menu-txt">Profil          </span></a></li>
        <li nz-menu-item nzMatchRouter          ><a routerLink="/org/settings"    ><fa-icon class="menu-icn" [icon]="['fas', 'gear']"         ></fa-icon><span class="menu-txt">Einstellungen   </span></a></li>

      </ul>
    </nz-sider>
    <nz-layout>
      <nz-header>
        <div class="app-header" style="display: flex; justify-content: space-between;">
        <span class="header-trigger" (click)="setCollapsed(!isCollapsed())">
            <span class="trigger" nz-icon [nzType]="isCollapsed() ? 'menu-unfold' : 'menu-fold'"></span>
        </span>
          <div style="display: flex; margin-right: 24px; line-height: normal; align-items: center; gap: 1rem">
            <span class="header_userinfo">Angemeldet als <span *ngIf="orgUser !== null">{{orgUser.username}}</span><span nz-icon *ngIf="orgUser === null" [nzType]="'loading'"></span></span>
            <fa-icon-btn *ngIf="isImpersonating"  nz-tooltip="Benutzer-Ansicht verlassen" nzType="dashed"  buttonSize="32" icon="person-to-door"     (btnClick)="stopImpersonating()"></fa-icon-btn>
            <fa-icon-btn                          nz-tooltip="Logout"                     nzType="primary" buttonSize="32" icon="right-from-bracket" (btnClick)="logout()"></fa-icon-btn>
          </div>
        </div>
      </nz-header>
      <nz-content>

        <div class="inner-content">
          <div class="inner-content-wrapper">
            <ng-container *ngTemplateOutlet="contentTpl"></ng-container>
          </div>
        </div>
      </nz-content>
    </nz-layout>
  </nz-layout>

</ng-container>
