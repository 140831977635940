import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Event} from "../../interfaces/event";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {environment} from "../../../environments/environment";
import {Group} from "../../interfaces/group";

@Component({
  selector: 'app-list-entry-event',
  templateUrl: './list-entry-event.component.html',
  styleUrls: ['./list-entry-event.component.scss']
})
export class ListEntryEventComponent {

  @Input() event: Event|null = null;

  @Input() showDelete: boolean = true;
  @Input() showChangeEnabled: boolean = true;
  @Input() showEdit: boolean = true;

  @Output() eventUpdated: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() eventDeleted: EventEmitter<Event> = new EventEmitter<Event>();
  @Output() eventToggleStatus: EventEmitter<{event: Event, active: boolean}> = new EventEmitter<{event: Event, active: boolean}>();

  showEditEvent: Event|null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  avatarSource(data: Event) {
    if (data.backgroundImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${data.organizationID}/events/${data.id}/background-image?xx-bearer-token=@${this.auth.getToken()}&blobid=${data.backgroundImageID}`;
  }

  activateEvent(event: Event) {
    this.eventToggleStatus.emit({event: event, active: true});
  }

  deactivateEvent(event: Event) {
    this.eventToggleStatus.emit({event: event, active: false});
  }

  deleteEvent(event: Event) {
    this.eventDeleted.emit(event);
  }
}
