import { Component } from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {isErrorCode} from "../../utils/api";

@Component({
  selector: 'app-pw-reset',
  templateUrl: './pw-reset.component.html',
  styleUrls: ['./pw-reset.component.scss']
})
export class PWResetComponent {
  resetLoading: boolean = false;
  errorText: string = "";

  email: string = '';

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    this.activatedRoute.queryParams.subscribe(async params => {
      if (this.auth.isAuthenticated() && this.auth.isAdminUser()) {
        await this.router.navigate(['/admin/'], {queryParams: {}});
      } else if (this.auth.isAuthenticated() && this.auth.isOrgUser()) {
        await this.router.navigate(['/org/'], {queryParams: {}});
      }
    });
  }

  async pwreset() {
    try {
      this.resetLoading = true;
      this.errorText = '';

      await this.api.requestPasswordResetByIdent(this.email);

      this.notification.success('Erfolg!', 'Eine Email mit der Anleitung zu Zurücksetzen des Passworts wurde versandt.');
      await this.router.navigate(['/'], {queryParams: {}});

    } catch (err: any) {
      if (isErrorCode(err, 'ENTITY_NOT_FOUND')) {
        this.errorText = 'Kein Benutzer mit dieser Mail gefunden';
      } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
        this.errorText = 'Interner Server Fehler';
      } else {
        this.errorText = 'Es ist ein Fehler aufgetreten';
      }
    } finally {
      this.resetLoading = false;
    }
  }
}
