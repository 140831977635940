import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {Post} from "../../interfaces/post";
import {showAPIError} from "../../utils/api";
import {isNgDiff} from "../../utils/angular";
import {NzUploadFile} from "ng-zorro-antd/upload";
import {filetoBase64, filetoDataURL} from "../../utils/base64";
import {Subscription, timer} from "rxjs";
import {NzUploadXHRArgs} from "ng-zorro-antd/upload/interface";
import {ObjectId} from "../../interfaces/utils";
import {Group} from "../../interfaces/group";
import {Event} from "../../interfaces/event";
import {Organization} from "../../interfaces/organization";
import * as uuid from "uuid";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";
import {DateUtils} from "../../utils/date";
import {FormControl, FormGroup, NonNullableFormBuilder, Validators} from "@angular/forms";

@Component({
  selector: 'app-edit-post-modal',
  templateUrl: './edit-post-modal.component.html',
  styleUrls: ['./edit-post-modal.component.scss']
})
export class EditPostModalComponent implements OnInit, OnChanges {
  initLoading: boolean = true;
  execLoading: boolean = false;

  fallbackImage = "data:image/svg+xml;base64," +
                  "PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMzUwIDc2OCI+CjxyZWN0IGZpbGw9IiNDQ0MiIHdpZHRoPSIxMzUwIiBoZWlnaHQ9Ijc2OCIgLz4KPHBhdGggZmlsbD0iIzQ0NCIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoNDE5LDEyOCkiIGQ9Ik0wIDk2QzAgNjAuNyAyOC43IDMyIDY0IDMySDQ0OGMzNS4zIDAgNjQgMjguNyA2NCA2NFY0MTZjMCAzNS4zLTI4LjcgNjQtNjQgNjRINjRjLTM1LjMgMC02NC0yOC43LTY0LTY0Vjk2ek0zMjMuOCAyMDIuNWMtNC41LTYuNi0xMS45LTEwLjUtMTkuOC0xMC41cy0xNS40IDMuOS0xOS44IDEwLjVsLTg3IDEyNy42TDE3MC43IDI5N2MtNC42LTUuNy0xMS41LTktMTguNy05cy0xNC4yIDMuMy0xOC43IDlsLTY0IDgwYy01LjggNy4yLTYuOSAxNy4xLTIuOSAyNS40czEyLjQgMTMuNiAyMS42IDEzLjZoOTYgMzJINDI0YzguOSAwIDE3LjEtNC45IDIxLjItMTIuOHMzLjYtMTcuNC0xLjQtMjQuN2wtMTIwLTE3NnpNMTEyIDE5MmE0OCA0OCAwIDEgMCAwLTk2IDQ4IDQ4IDAgMSAwIDAgOTZ6Ii8+Cjwvc3ZnPg==";

  @Input()  postID: {org: ObjectId, post: ObjectId}|null = null;
  @Output() postIDChange: EventEmitter<{org: ObjectId, post: ObjectId}|null> = new EventEmitter<{org: ObjectId, post: ObjectId}|null>();

  @Output() postUpdated: EventEmitter<Post> = new EventEmitter<Post>();
  @Output() updateCancelled: EventEmitter<ObjectId> = new EventEmitter<ObjectId>();

  org: Organization|null = null;
  allGroups: Group[] = []
  allEvents: Event[] = []

  serverData: Post|null = null;

  imageList: NzUploadFile[] = [];
  file: string|File|null = null;
  preview: string|null = null;
  status: 'initial'|'loading'|'active' = 'initial';

  validateForm: FormGroup = this.fb.group({
    title: new FormControl<string>('', [Validators.required]),
    text: new FormControl<string>('', []),
    url: new FormControl<string>('', []),
    imageURL: new FormControl<string>('', []),
    publish: new FormControl<'INACTIVE' | 'ACTIVE' | 'TIMESTAMP'>('ACTIVE', [Validators.required]),
    timestampPublishDate: new FormControl<Date|null>(null, [])
  });

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private fb: NonNullableFormBuilder,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (isNgDiff(changes, 'postID')) {
      if (this.postID !== null) {
        this.clear();
        this.loadData().then(() => {});
      } else {
        this.clear();
      }
    }

  }

  cancel() {
    if (this.postID !== null) {
      const old = this.postID;
      this.postIDChange.emit(this.postID = null);
      this.updateCancelled.emit(old.post);
      this.clear();
    }
  }

  clear() {
    this.initLoading = true;
    this.execLoading = false;
    this.serverData  = null;
  }

  async loadData() {
    try {
      if (this.postID === null) return;

      this.initLoading = true;

      const data = await this.api.getPost(this.postID.org, this.postID.post);


      this.validateForm.patchValue({
        title: data.title,
        text: data.text,
        url: data.url,
        imageURL: data.imageURL,
        publish: data.autoEnableTime !== null ? 'TIMESTAMP' : 'INACTIVE',
        timestampPublishDate: data.autoEnableTime === null ? null : DateUtils.parseRFC3339(data.autoEnableTime),
      })
      this.imageList     = data.images.map(p => ({"name": p, uid: p, url: this.imageSource(p), thumbUrl: this.imageSource(p) }));
      this.file          = data.files?.at(0) ?? null;
      this.preview       = null;
/*      this.status        = 'active';*/

    /*  this.selectedPublishTime  = data.autoEnableTime !== null ? 'TIMESTAMP' : 'INACTIVE';
      this.timestampPublishDate = data.autoEnableTime === null ? null : DateUtils.parseRFC3339(data.autoEnableTime);
*/
      this.serverData = data;

      // ================== LOAD ORG ==================

      this.org = await this.api.getOrganization(data.organizationID);

      // ================== LOAD GROUPS && EVENTS ==================

      if (this.allGroups.length === 0 || this.allGroups[0].organizationID !== this.serverData.organizationID) {
        const data = await this.api.listOrgGroups(this.serverData.organizationID, "@start", 999999); //TODO Pgaesize
        this.allGroups = data.groups;
      }

      if (this.allEvents.length === 0 || this.allEvents[0].organizationID !== this.serverData.organizationID) {
        const data = await this.api.listOrgEvents(this.serverData.organizationID, "@start", 999999); //TODO Pgaesize
        this.allEvents = data.events;
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      this.initLoading = false;
    }
  }

  async submit () {

    if (this.validateForm.valid) {
      console.log('submit', this.validateForm.value);
      await this.execute();
    } else {
      Object.values(this.validateForm.controls).forEach(control => {
        if (control instanceof FormGroup) {
          Object.values(control.controls).forEach(innerControl => {
            innerControl.markAsDirty();
            innerControl.updateValueAndValidity({ onlySelf: true });
          });
        } else {
          control.markAsDirty();
          control.updateValueAndValidity({ onlySelf: true });
        }
      });
    }
  }

  async execute() {

    if (this.serverData === null) return;

    try {
      this.execLoading = true;

      let images: {id?: ObjectId, data?: string, mime?: string}[] = [];
      for (const f of this.imageList) {
        if (f.originFileObj !== null && f.originFileObj !== undefined) {
          const b64 = await filetoBase64(f.originFileObj!); // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744
          const mim = f.type;
          images.push({data: b64!, mime: mim!});
        } else {
          images.push({id: f.uid as ObjectId});
        }
      }

      let filedata = undefined;
      if (this.file !== null && typeof this.file !== 'string') {

        const b64 = (await filetoBase64(this.file!))!; // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744
        const mim = this.file!.type;

        filedata = {"mime": mim, "data": b64};

      }

      const newData = await this.api.updatePost(
        this.serverData.organizationID,
        this.serverData.id,
        {
          title:          this.validateForm.get('title')?.value.trim(),
          text:           this.validateForm.get('text')?.value?.trim() ?? undefined,
          url:            this.validateForm.get('url')?.value?.trim() ?? undefined,
          imageURL:       this.validateForm.get('imageURL')?.value?.trim() ?? undefined,
          images:         images ?? undefined,
          file:           filedata,
          autoEnableTime: this.validateForm.get('publish')?.value === 'TIMESTAMP' ? this.validateForm.get('timestampPublishDate')?.value : null,
        });

      this.postIDChange.emit(this.postID = null);
      this.clear();

      this.postUpdated.emit(newData);


    } catch (err) {
      showAPIError(this.notification, 'Beitrag konnte nicht aktualisiert werden', err)
    } finally {
      this.execLoading = false;
    }
  }

  isValidLink(val: string) {
    return /^(https?:\/\/).+\..+$/.test(val);
  }

  isValidVideoLink(val: string) {
    if (!(val.toLowerCase().includes('youtube.com') || val.toLowerCase().includes('youtu.be'))) return false;

    return /^(https?:\/\/).+\..+$/.test(val);
  }

  queryData () {
    const url = this.validateForm.get('url')?.value;
    if (this.isValidLink(url)) {
      this.queryLinkData();
    } else if (this.isValidVideoLink(url)) {
      this.queryVideoData();
    } else {
      this.notification.error('Fehler', 'Bitte gültige URL eingeben');
    }
  }

  async queryLinkData() {

    try {
      this.status = "loading";

      const data = await this.api.scrapeLinkPost(this.validateForm.get('url')?.value);

      this.validateForm.get('url')?.setValue(data.metaURL);
      this.validateForm.get('title')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('text')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('imageURL')?.setValue(data.metaImageURL?.trim() ?? '');

      if (this.validateForm.get('imageURL')?.value === '') {
        this.notification.error('Fehler', 'Kein Vorschaubild auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('text')?.value === '') {
        this.notification.error('Fehler', 'Keine Beschreibung auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('title')?.value === '') {
        this.notification.error('Fehler', 'Kein Titel auf der Seite gefunden');
        this.status = "initial";
      } else {
        this.status = "active";
      }


    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht aus der URL geladen werden', err)
      this.status = "initial";
    }

  }

  manuallySetStatusActive() {
    this.status   = "active";
  }

  async queryVideoData() {
    try {
      this.status = "loading";

      const data = await this.api.scrapeVideoPost(this.validateForm.get('url')?.value);

      this.validateForm.get('url')?.setValue(data.metaURL);
      this.validateForm.get('title')?.setValue(data.metaTitle?.trim() ?? '');
      this.validateForm.get('imageURL')?.setValue(data.metaImageURL?.trim() ?? '');

      if (this.validateForm.get('imageURL')?.value === '') {
        this.notification.error('Fehler', 'Kein Vorschaubild auf der Seite gefunden');
        this.status = "initial";
      } else if (this.validateForm.get('title')?.value === '') {
        this.notification.error('Fehler', 'Kein Titel auf der Seite gefunden');
        this.status = "initial";
      } else {
        this.status = "active";
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht aus der URL geladen werden', err)
      this.status = "initial";
    }
  }

  handleGalleryPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await filetoDataURL(file.originFileObj!);
    }
    this.preview = file.url || file.preview;
  };

  galleryAction = (args: NzUploadXHRArgs): Subscription =>  {
    return timer(500).subscribe(() => {
      args.onProgress!({percent: 100}, args.file);
      args.onSuccess!("ok", args.file, {});
    });
  };

  beforeUploadFilePostFile = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744


    if (fany instanceof File) {
      this.file = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.file = null;
    }
    return false;
  }

  translateOrg(orgid: ObjectId | undefined): string|null {
    if (orgid === null || orgid === undefined) return null;
    if (this.org === null) return null;
    if (this.org.id === orgid) return this.org.name;
    return null;
  }

  translateEvent(evtid: ObjectId | undefined): string|null {
    if (evtid === null || evtid === undefined) return null;
    for (const evt of this.allEvents) {
      if (evt.id === evtid) return evt.title;
    }
    return null;
  }

  translateGroup(grpid: ObjectId | undefined): string|null {
    if (grpid === null || grpid === undefined) return null;
    for (const grp of this.allGroups) {
      if (grp.id === grpid) return grp.title;
    }
    return null;
  }

  imageSource(blobid: ObjectId) {
    if (this.postID === null) return '';

    return `${environment.apiBaseUrl}organizations/${this.postID.org}/blobs/${blobid}/image?xx-bearer-token=@${this.auth.getToken()}`;
  }

  getFileName() {
    if(this.file instanceof File) {
      return this.file.name;
    }
    return this.file;
  }

}
