import { Component } from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ActivatedRoute, Router} from "@angular/router";
import {formatAPIError, isErrorCode, showAPIError} from "../../utils/api";
import {sleep} from "../../utils/angular";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-register-link',
  templateUrl: './register-link.component.html',
  styleUrls: ['./register-link.component.scss']
})
export class RegisterLinkComponent {

  secret: string|null = null;
  userid: ObjectId|null = null;

  loading: boolean = true;
  error: string = '';
  username: string|null = null;

  setErrorText: string = '';
  password1: string = '';
  password2: string = '';
  setPasswordLoading: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private auth: AuthenticationService,
              private router: Router,
              private activatedRoute: ActivatedRoute)
  {
    this.activatedRoute.params.subscribe(params => {
      this.userid = params['uid'];
      this.secret = params['sec'];
      this.loadUser(params['uid']).then(() => {});
    });
  }

  async loadUser(uid: ObjectId) {

    this.loading = true;

    try {
      this.username = (await this.api.getUserName(uid)).username;
    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async execute() {
    if (this.userid === null) return;
    if (this.secret === null) return;

    try {
      this.setPasswordLoading = true;
      this.setErrorText = '';

      await sleep(500);

      if (!(this.isValidPW1()(this.password1) ?? true))     { this.setErrorText = 'Bitte valides Passwort eingeben (midestens 8 Zeichen)';   return; }
      if (!(this.isValidPW2()(this.password2) ?? true))     { this.setErrorText = 'Passwörter stimmern nicht überein'; return; }

      const data = await this.api.finishUserRegistration(this.userid, this.secret, this.password1);

      this.auth.setAuth(data.token);
      this.notification.success('Angemeldet', 'Du wurdest erfolgreich angemeldet');

      if (data.userType === 'ORGANIZATION_USER') await this.router.navigate(['/org/'], {queryParams: {}});
      if (data.userType === 'ADMIN_USER') await this.router.navigate(['/admin/'], {queryParams: {}});

    } catch (err: any) {
      if (isErrorCode(err, 'WRONG_SECRET')) {
        this.setErrorText = 'Registrierungslink ist ungültig oder abgelaufen';
      } else if (isErrorCode(err, 'INTERNAL_ERROR')) {
        this.setErrorText = 'Interner Server Fehler';
      } else {
        this.setErrorText = 'Es ist ein Fehler aufgetreten';
      }
    } finally {
      this.setPasswordLoading = false;
    }
  }

  isValidPW1() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return v.length >= 8;
    }
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1()(this.password1) && v == this.password1;
    }
  }
}
