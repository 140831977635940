<nz-modal [nzVisible]="show !== null"
          nzTitle="Nutzer bearbeiten"
          nzOkText="Bearbeiten"
          [nzOkLoading]="okayLoading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>

    <nz-skeleton *ngIf="initLoading"></nz-skeleton>

    <div *ngIf="!initLoading" class="content">

      <span class="key">ID</span>
      <input nz-input         [ngModel]="show?.id ?? ''"  readonly>

      <span class="spacer"></span>

      <span class="key">Name</span>
      <div class="input-block name">
        <app-validateable-input [(value)]="firstName" [validate]="isValidStr"   ></app-validateable-input>
        <app-validateable-input [(value)]="lastName"  [validate]="isValidStr"   ></app-validateable-input>
      </div>

      <span class="key">Benutzername</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="username"  [validate]="isValidStr"  ></app-validateable-input>

      <span class="key">Mail</span>
      <app-validateable-input [preventAutofill]="true" [(value)]="mail"      [validate]="isValidMail" ></app-validateable-input>

      <span class="spacer"></span>

      <span class="key">Berechtigungen</span>
      <div class="input-block permissions">

        <ng-container *ngFor="let perm of permlist">
          <label nz-checkbox
                 [disabled]="superuser || perm.value === 'SUPER'"
                 [ngModel]="permissions.includes(perm.value) || superuser"
                 (ngModelChange)="updatePermissions(perm.value, $event)">{{perm.description}}</label>
        </ng-container>

      </div>

    </div>
  </ng-container>
</nz-modal>
