import {Component, EventEmitter, Input, Output} from '@angular/core';
import {OrganizationUser} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {BlobDataOrgUserAttachment, DataBlob} from "../../interfaces/datablobs";
import {formatAPIError, showAPIError} from "../../utils/api";
import {NzUploadChangeParam, NzUploadFile} from "ng-zorro-antd/upload";
import {filetoBase64} from "../../utils/base64";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-create-org-attachment-modal',
  templateUrl: './create-org-attachment-modal.component.html',
  styleUrls: ['./create-org-attachment-modal.component.scss']
})
export class CreateOrgAttachmentModalComponent {

  @Input() orgId: ObjectId|null = null;

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<DataBlob<BlobDataOrgUserAttachment>> = new EventEmitter<DataBlob<BlobDataOrgUserAttachment>>();

  file: File|null = null;

  loading: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.file = null;
  }

  async execute() {
    if (this.orgId === null) return;
    if (this.file === null) return;

    try {
      this.loading = true;

      console.log(this.file);

      let b64 = await filetoBase64(this.file);

      const data = await this.api.createOrgUserAttachment(this.orgId, this.file.name, b64, this.file.type);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

    } catch (err) {
      showAPIError(this.notification, 'Datei konnte nicht hochgeladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  beforeUpload = (file: NzUploadFile) => {
    let fany = file as any;

    // https://github.com/NG-ZORRO/ng-zorro-antd/issues/4744

    if (fany instanceof File) {
      this.file = fany;
    } else {
      console.error('beforeUpload is not a File ?!?', file);
      this.file = null;
    }
    return false;
  }
}
