import { Component } from '@angular/core';
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Crawler} from "../../interfaces/crawler";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {DateUtils} from "../../utils/date";

@Component({
  selector: 'app-crawler-overview',
  templateUrl: './crawler-overview.component.html',
  styleUrls: ['./crawler-overview.component.scss']
})
export class CrawlerOverviewComponent {

  crawlers: Crawler[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  filters: SerializedParamCollection = {};

  editingCrawler: Crawler|null = null;
  expandedCrawler: Set<ObjectId> = new Set<ObjectId>();
  showAddCrawler = false;

  refreshTimer: number|null = null;
  refreshTrigger: number = 0;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchCrawler(true);
  }

  async fetchCrawler(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listCrawler(this.nextToken, 999);

      if (reset) {
        this.crawlers = data.crawlers;
      } else {
        this.crawlers = [...this.crawlers, ...data.crawlers];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Crawler konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'crawlers'], this.filters));
  }

  toggleCrawlerExpanded(crawler: Crawler) {
    if (this.expandedCrawler.has(crawler.id))
      this.expandedCrawler.delete(crawler.id)
    else
      this.expandedCrawler.add(crawler.id)
  }

  updateCrawler(c: Crawler) {
    this.crawlers = this.crawlers.map(p => (p.id === c.id) ? c : p);
  }

  deleteCrawler(c: Object) {
    this.crawlers = this.crawlers.filter(p => (p.id !== c));
  }

  addCrawler(v: Crawler) {
    this.crawlers = [...this.crawlers, v];
  }

  switchRefreshMode() {
    if (this.refreshTimer !== null) {
      clearInterval(this.refreshTimer);
      this.refreshTimer = null;
    } else {
      this.refreshTimer = setInterval(() => this.refreshTrigger = DateUtils.now().getTime(), 1000) as unknown as number;
    }
  }

  startActiveRefreshMode(id: ObjectId) {
    if (this.refreshTimer === null) {
      this.refreshTimer = setInterval(() => this.refreshTrigger = DateUtils.now().getTime(), 1000) as unknown as number;
    }
    this.expandedCrawler.add(id);
  }
}
