import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import {OrgType} from "../interfaces/organization";

@Pipe({ name: 'fmtorgtype', pure: true })
export class FmtOrgTypePipe implements PipeTransform {
  constructor(){}

  transform(value: OrgType, ...args: unknown[]) {
    switch (value) {
      case "COMMUNITY":   return 'Gemeinde';
      case "ASSOCIATION": return 'Verein';
      case "BUSINESS":    return 'Unternehmen';
      case "ARTIST":      return 'Künstler';
      case "PRESS":       return 'Presse';
    }
  }
}
