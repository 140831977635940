import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {Post} from "../../interfaces/post";
import {showAPIError} from "../../utils/api";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {Group} from "../../interfaces/group";
import {environment} from "../../../environments/environment";
import {ObjectId} from "../../interfaces/utils";
import {isNgDiff} from "../../utils/angular";
import {EntityCacheService} from "../../services/entity-cache.service";

@Component({
  selector: 'app-list-entry-post',
  templateUrl: './list-entry-post.component.html',
  styleUrls: ['./list-entry-post.component.scss']
})
export class ListEntryPostComponent implements OnChanges {

  @Input() post: Post | null = null;

  @Input() showLink: boolean = true;
  @Input() showDelete: boolean = true;
  @Input() showChangeEnabled: boolean = true;
  @Input() showEdit: boolean = true;

  @Output() postUpdated: EventEmitter<Post> = new EventEmitter<Post>();
  @Output() postDeleted: EventEmitter<Post> = new EventEmitter<Post>();

  editPostID: { org: ObjectId, post: ObjectId } | null = null;

  creatorStr: string | null = null;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private ecache: EntityCacheService,
              private auth: AuthenticationService) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'post')) {
      this.getCreator().then(() => {});
    }
  }

  changePostActive(event: any, data: Post, v: boolean) {
    event.stopPropagation();
    if (v) {
      this.activatePost(data);
    } else {
      this.deactivatePost(data);
    }
  }

  activatePost(data: Post) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post aktivieren?',
      nzContent: 'Möchtest du den Post "'+data.title+'" wirklich aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enablePost(data.organizationID, data.id);
          this.notification.success('Gruppe aktiviert', 'Den Post "' + data.title + '" wurde aktiviert');
          this.postUpdated.emit(newdata);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivatePost(data: Post) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post deaktivieren?',
      nzContent: 'Möchtest du den Post "'+data.title+'" wirklich deaktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.disablePost(data.organizationID, data.id);
          this.notification.success('Post deaktiviert', 'Der Post "' + data.title + '" wurde deaktiviert');
          this.postUpdated.emit(newdata);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  avatarSource(data: Post) {
    if (data.images.length === 0) return undefined;

    return `${environment.apiBaseUrl}organizations/${data.organizationID}/blobs/${data.images[0]}/image?xx-bearer-token=@${this.auth.getToken()}`;
  }

  editPost = (event: any, post: Post) => {
    this.editPostID = {org: post.organizationID, post: post.id}
  }

  deletePost(event: any, entry: Post) {
    event.stopPropagation();
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post löschen?',
      nzContent: 'Möchtest du den Post "'+entry.title+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deletePost(entry.organizationID, entry.id);
          this.notification.success('Post gelöscht', 'Der Post "' + entry.title + '" wurde gelöscht');
          this.postDeleted.emit(entry);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onPostUpdated(newdata: Post) {
    this.postUpdated.emit(newdata);
  }

  async getCreator() {

    this.creatorStr = null;

    if (this.post === null) { this.creatorStr = null; return; }

    if (this.post.creatorType === 'ADMIN_USER') this.creatorStr = '(Administrator)';

    if (this.post.creatorType === 'CRAWLER_GENERIC') this.creatorStr = '(Crawler)';
    if (this.post.creatorType === 'CRAWLER_DCX')     this.creatorStr = '(DCX)';
    if (this.post.creatorType === 'CRAWLER_SOLSEIT') this.creatorStr = '(Solseit)';
    if (this.post.creatorType === 'CRAWLER_PEIQ')    this.creatorStr = '(PEIQ)';

    if (this.post.creatorType === 'APPLICATION_USER') this.creatorStr = '(User)';

    if (this.post.creatorType === 'ORGANIZATION_USER') {

      try {

        if (this.post.creatorRef === null) return '(Unbekannt)'

        const user = await this.ecache.getOrgUser(this.post.organizationID, this.post.creatorRef);

        this.creatorStr = user.firstName + ' ' + user.lastName;

      } catch (err) {
        showAPIError(this.notification, 'Post-Ersteller konnte nicht geladen werden', err)
      }

    }

    return null
  }

  protected readonly JSON = JSON;
}
