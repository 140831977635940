import {Component, EventEmitter, Input, Output, SimpleChanges} from '@angular/core';
import {Organization} from "../../interfaces/organization";
import {OrganizationUser, OrgUserPermKey} from "../../interfaces/organizationUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff} from "../../utils/angular";
import {formatAPIError, showAPIError} from "../../utils/api";
import {generatePronouncablePassword} from "../../utils/pw";
import {EnumMetaValue} from "../../interfaces/enums";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-create-orguser-modal',
  templateUrl: './create-orguser-modal.component.html',
  styleUrls: ['./create-orguser-modal.component.scss']
})
export class CreateOrguserModalComponent {

  @Input() forcedOrgId: ObjectId|null = null;
  @Input() forceAuthMail: boolean = false;

  @Input() show: boolean = false;
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() success: EventEmitter<OrganizationUser> = new EventEmitter<OrganizationUser>();

  initLoading: boolean = false;
  okayLoading: boolean = false;

  allOrgs: Organization[] = []
  selectedOrg: ObjectId|null = null;

  username: string = '';
  firstName: string = '';
  lastName: string = '';
  mail: string = '';

  sendPasswordMail: boolean = false;
  showPW: boolean = false;
  password1: string = '';
  password2: string = '';

  permissions: OrgUserPermKey[] = [];

  permlist: EnumMetaValue<OrgUserPermKey, string>[] = [];

  constructor(private api: APIService,
              private notification: NzNotificationService) {
  }

  ngOnInit(): void {
    //
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'show')) {
      if (this.show) {
        this.clear();
        this.loadData(true).then(() => {});
      } else {
        this.clear();
      }
    }
  }

  close() {
    this.show = false;
    this.showChange.emit(false);
    this.clear();
  }

  clear() {
    this.initLoading           = false;
    this.okayLoading           = false;
    this.firstName             = '';
    this.lastName              = '';
    this.username              = '';
    this.mail                  = '';
    this.sendPasswordMail      = false;
    this.showPW                = false;
    this.password1             = '';
    this.password2             = '';
    this.permissions           = []; //['LOGIN', 'ORG::EDIT', 'POSTS::CREATE', "POSTS::EDIT", "GROUPS::CREATE", "GROUPS::EDIT", "EVENTS::CREATE", "EVENTS::EDIT"];
    this.selectedOrg           = null;

    if (this.forceAuthMail) {
      this.randomPassword();
      this.sendPasswordMail = true;
    }
  }

  async loadData(initial:boolean = false) {
    try {
      if (initial) this.initLoading = true;

      const dataPL = await this.api.getOrguserPemissionList();

      this.permlist = dataPL.permissions;

      if (this.forcedOrgId === null) {

        const data = await this.api.listOrganizations("@start", null); //TODO Pagesize
        this.allOrgs = data.orgs;

      } else {

        const data = await this.api.getOrganization(this.forcedOrgId);
        this.allOrgs = [data];
        this.selectedOrg = this.forcedOrgId;

      }

      if (this.forceAuthMail) {
        this.randomPassword();
        this.sendPasswordMail = true;
      }

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)

      if (this.show != null) {
        this.show = false;
        this.showChange.emit(false);
        this.clear();
      }

    } finally {
      if (initial) this.initLoading = false;
    }
  }

  async execute() {

    if (this.forceAuthMail) {
      this.randomPassword();
      this.sendPasswordMail = true;
    }

    if(this.selectedOrg === null)                         { this.notification.error('Fehler', 'Bitte ContentProvider auswählen'); return; }
    if (!this.isValidStr(this.firstName))                 { this.notification.error('Fehler', 'Bitte validen Vorname eingeben'); return; }
    if (!this.isValidStr(this.lastName))                  { this.notification.error('Fehler', 'Bitte validen Nachname eingeben'); return; }
    if (!this.isValidStr(this.username))                  { this.notification.error('Fehler', 'Bitte validen Benutzername eingeben'); return; }
    if (!this.isValidMail(this.mail))                     { this.notification.error('Fehler', 'Bitte valide Email-Addresse eingeben'); return; }
    if (!(this.isValidPW1()(this.password1) ?? true))     { this.notification.error('Fehler', 'Bitte valides Passwort eingeben (midestens 8 Zeichen)'); return; }
    if (!(this.isValidPW2()(this.password2) ?? true))     { this.notification.error('Fehler', 'Passwörter stimmern nicht überein'); return; }

    try {
      this.okayLoading = true;

      const data = await this.api.createOrgUser(
        this.selectedOrg,
        this.firstName, this.lastName,
        this.username, this.mail,
        this.sendPasswordMail, this.password1,
        this.permissions);

      this.show = false;
      this.showChange.emit(false);
      this.clear();

      this.success.emit(data);

      this.notification.success('Erfolg!', 'Benutzer wurde erstellt');

    } catch (err) {
      showAPIError(this.notification, 'Benutzer konnte nicht erstellt werden', err)
    } finally {
      this.okayLoading = false;
    }
  }

  isValidStr(val: string) {
    return val.length >= 1 && val.trim() === val;
  }

  isValidMail(mail: string) {
    return /^[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+$/.test(mail);
  }

  isValidPW1() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      if (this.sendPasswordMail) return null;
      return v.length >= 8;
    }
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      if (this.sendPasswordMail) return null;
      return this.isValidPW1()(this.password1) && v == this.password1;
    }
  }

  randomPassword() {
    this.showPW = true;
    this.password2 = this.password1 = generatePronouncablePassword(5);
  }

  updatePermissions(perm: OrgUserPermKey, evt: boolean) {
    if (evt) {
      this.permissions = [...new Set([perm, ...this.permissions])];
    } else {
      this.permissions = [...new Set(this.permissions.filter(p => p != perm))];
    }
  }
}
