import { Component } from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-dashboard',
  templateUrl: './org-dashboard.component.html',
  styleUrls: ['./org-dashboard.component.scss']
})
export class OrgDashboardComponent {

  orgid!: ObjectId;

  spanXS  = 24;
  spanSM  = 12;
  spanMD  = 12;
  spanLG  = 12;
  spanXL  =  8;
  spanXXL =  6;

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {
  }

  async ngOnInit() {
    this.orgid = this.auth.getSelfOrgID()!;
  }

  queryGroupCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countOrgGroups(this.orgid);
      return [`${count.count}`, ''];
    }
  }

  queryPostCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countOrgPosts(this.orgid);
      return [`${count.count}`, ''];
    }
  }

  querySubscriberCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      return ['?', ''];
    }
  }

  queryVisitorCount(filter: 'today'|'thisweek'|'thismonth') {
    return async function (): Promise<[string, 'error'|'warn'|'okay'|'']> {
      return ['?', ''];
    }
  }

  queryEventCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      let count = await this.api.countOrgEvents(this.orgid);
      return [`${count.count}`, ''];
    }
  }

  queryPhotoCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      return ['?', ''];
    }
  }

  queryVideoCount = () => {
    return async (): Promise<[string, 'error'|'warn'|'okay'|'']> => {
      return ['?', ''];
    }
  }
}
