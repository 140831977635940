import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NzButtonType} from "ng-zorro-antd/button/button.component";
import {IconProp} from "@fortawesome/fontawesome-svg-core";
import {OrganizationUser, OrgUserPermKey} from "../../interfaces/organizationUser";
import {AuthenticationService} from "../../services/authentication.service";
import {CheckPermPipe} from "../../pipes/check-perm";

@Component({
  selector: 'fa-icon-btn',
  templateUrl: './icon-btn.component.html',
  styleUrls: ['./icon-btn.component.scss']
})
export class IconBtnComponent {

  @Input() buttonSize: string = "32";
  @Input() iconSize: string = "18";
  @Input() textSize: string = "14";

  @Input() nzType: NzButtonType = "default";
  @Input() nzDanger: boolean = false;
  @Input() icon: IconProp|null = null;
  @Input() iconAnimation: "beat"|"fade"|"beat-fade"|"bounce"|"flip"|"shake"|"spin"|"spin-reverse"|"spin-pulse"|"spin-pulse-reverse"|undefined = undefined;

  @Input() opacity: string = '1';

  @Input() text: string = '';

  @Input() perm: null|OrgUserPermKey|OrgUserPermKey[] = null;

  @Input() loading: boolean = false;
  @Input() disabled: boolean = false;
  @Input() stopPropagation: boolean = false;

  @Output() btnClick = new EventEmitter();

  constructor(private auth: AuthenticationService) {

  }

  click(event: MouseEvent) {
    if (!this.loading && !this.disabled) this.btnClick.emit()
    if (this.stopPropagation) event.stopPropagation();
  }
}
