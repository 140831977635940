import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AdminUser} from "../../interfaces/adminUser";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {formatAPIError, showAPIError} from "../../utils/api";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {generatePronouncablePassword} from "../../utils/pw";

@Component({
  selector: 'app-edit-orguser-password-modal',
  templateUrl: './edit-orguser-password-modal.component.html',
  styleUrls: ['./edit-orguser-password-modal.component.scss']
})
export class EditOrgUserPasswordModalComponent implements OnInit {

  @Input() show: OrganizationUser|null = null;
  @Output() showChange: EventEmitter<OrganizationUser|null> = new EventEmitter<OrganizationUser|null>();
  @Output() success: EventEmitter<{}> = new EventEmitter<{}>();

  loading: boolean = false;

  showPW: boolean = false;
  password1: string = '';
  password2: string = '';

  passwordResetLoading: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  close() {
    this.show = null;
    this.showChange.emit(null);
    this.clear();
  }

  clear() {
    this.showPW = false;
    this.password1 = '';
    this.password2 = '';
  }

  async execute() {
    if (!this.isValidPW1(this.password1))      { this.notification.error('Fehler', 'Bitte valides Passwort eingeben (midestens 8 Zeichen)');   return; }
    if (!this.isValidPW2()(this.password2))    { this.notification.error('Fehler', 'Passwörter stimmern nicht überein'); return; }


    try {
      this.loading = true;

      await this.api.updateOrgUserPassword(this.show!.organizationID, this.show!.id, this.password1);

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit({});

    } catch (err) {
      showAPIError(this.notification, 'Passwort konnte nicht geändert werden', err)
    } finally {
      this.loading = false;
    }
  }

  isValidPW1(v: string) {
    return v.length >= 8;
  }

  isValidPW2() {
    // return lambda to keep this reference (to compare against pw1)
    return (v: string) => {
      return this.isValidPW1(this.password1) && v == this.password1;
    }
  }

  async sendPasswordResetMail() {
    try {
      this.passwordResetLoading = true;

      await this.api.requestPasswordResetByID(this.show!.id, 'ORGANIZATION_USER');

      this.show = null;
      this.showChange.emit(null);
      this.clear();

      this.success.emit({});

    } catch (err) {
      showAPIError(this.notification, 'Email konnte nicht erstellt werden', err)
    } finally {
      this.passwordResetLoading = false;
    }
  }

  randomPassword() {
    this.showPW = true;
    this.password2 = this.password1 = generatePronouncablePassword(5);
  }
}
