import {Component, OnInit} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {Post} from "../../interfaces/post";
import {formatAPIError, showAPIError} from "../../utils/api";
import {OrganizationUser} from "../../interfaces/organizationUser";
import { Organization } from 'src/app/interfaces/organization';
import {SerializedParamCollection} from "../../utils/serializedParameter";
import {environment} from "../../../environments/environment";
import {AuthenticationService} from "../../services/authentication.service";
import {CursorToken, ObjectId} from "../../interfaces/utils";
import {EntityCacheService} from "../../services/entity-cache.service";
import {PostCreatorType} from "../../interfaces/userType";

@Component({
  selector: 'app-post-list',
  templateUrl: './post-list.component.html',
  styleUrls: ['./post-list.component.scss']
})
export class PostListComponent implements OnInit {
  loading: boolean = true;
  loadingMore: boolean = false;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  editPostID: {org: ObjectId, post: ObjectId}|null = null;

  showCreateNewPost = false;

  posts: Post[] = [];
  nextToken: CursorToken = "@start";

  orgTranslationMap = new Map<ObjectId, Organization>();

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private auth: AuthenticationService,
              private ecache: EntityCacheService,
              private modal: NzModalService) {
  }

  async ngOnInit() {
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      if (reset) this.loading = true; else this.loadingMore = true;

      const data = await this.api.listPosts(this.nextToken, 24, this.filters);

      for (const p of data.posts) this.ecache.getOrganization(p.organizationID).then(org => this.orgTranslationMap.set(org.id, org));

      if (reset) {
        this.posts = data.posts;
      } else {
        this.posts = [...this.posts, ...data.posts];
      }
      this.nextToken = data.nextPageToken;

    } catch (err) {
      showAPIError(this.notification, 'Posts konnten nicht geladen werden', err)
    } finally {
      if (reset) this.loading = false; else this.loadingMore = false;
    }
  }

  translateOrgID(orgid: ObjectId) {
    let org = this.orgTranslationMap.get(orgid);
    if (org === undefined) return '???';
    return org.name;
  }

  onPostCreated(v: Post) {
    this.posts = [v, ...this.posts];
  }

  onPostUpdated(v: Post) {
    this.posts = this.posts.map(g => (g.id === v.id) ? v : g);
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }

  changePostActive(data: Post, v: boolean) {
    if (v) {
      this.activatePost(data);
    } else {
      this.deactivatePost(data);
    }
  }

  activatePost(data: Post) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post aktivieren?',
      nzContent: 'Möchtest du den Post "'+data.title+'" wirklich aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enablePost(data.organizationID, data.id);
          this.notification.success('Gruppe aktiviert', 'Den Post "' + data.title + '" wurde aktiviert');
          this.posts = this.posts.map(g => (g.id === newdata.id) ? newdata : g);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivatePost(data: Post) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post deaktivieren?',
      nzContent: 'Möchtest du den Post "'+data.title+'" wirklich deaktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.disablePost(data.organizationID, data.id);
          this.notification.success('Post deaktiviert', 'Der Post "' + data.title + '" wurde deaktiviert');
          this.posts = this.posts.map(g => (g.id === newdata.id) ? newdata : g);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  postImageSource(data: Post): string | undefined {

    if (data.images.length === 0) return undefined;

    return `${environment.apiBaseUrl}organizations/${data.organizationID}/blobs/${data.images[0]}/image?xx-bearer-token=@${this.auth.getToken()}`;

  }

  deletePost(entry: Post) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Post löschen?',
      nzContent: 'Möchtest du den Post "'+entry.title+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deletePost(entry.organizationID, entry.id);
          this.notification.success('Post gelöscht', 'Der Post "' + entry.title + '" wurde gelöscht');
          this.posts = this.posts.filter(g => g.id !== entry.id);
        } catch (err) {
          showAPIError(this.notification, 'Der Post konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  translateCreatorType(ctype: PostCreatorType) {
    switch (ctype) {
      case "ADMIN_USER":        return "Administrator";
      case "ORGANIZATION_USER": return "Nutzer";
      case "APPLICATION_USER":  return "Frontend";
      case "CRAWLER_GENERIC":   return "Crawler";
      case "CRAWLER_SOLSEIT":   return "Solseit";
      case "CRAWLER_DCX":       return "DCX";
      case "CRAWLER_PEIQ":      return "PEIQ";
    }
  }
}
