import { Injectable } from '@angular/core';
import {AdminUser} from "../interfaces/adminUser";
import {OrganizationUser} from "../interfaces/organizationUser";
import {AppUser} from "../interfaces/applicationUser";
import {BehaviorSubject, firstValueFrom, lastValueFrom, Subject} from "rxjs";
import {ObjectId} from "../interfaces/utils";
import {Router} from "@angular/router";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {CookieService} from "ngx-cookie-service";
import {APIService} from "./api.service";
import {Organization} from "../interfaces/organization";

@Injectable({ providedIn: 'root' })
export class EntityCacheService {

    private appUserCache:   Map<ObjectId, AppUser>          = new Map<ObjectId, AppUser>();
    private adminUserCache: Map<ObjectId, AdminUser>        = new Map<ObjectId, AdminUser>();
    private orgUserCache:   Map<ObjectId, OrganizationUser> = new Map<ObjectId, OrganizationUser>();
    private orgCache:       Map<ObjectId, Organization>     = new Map<ObjectId, Organization>();

    private appUserSubjects$:   Map<ObjectId, Subject<AppUser>>          = new Map<ObjectId, Subject<AppUser>>();
    private adminUserSubjects$: Map<ObjectId, Subject<AdminUser>>        = new Map<ObjectId, Subject<AdminUser>>();
    private orgUserSubjects$:   Map<ObjectId, Subject<OrganizationUser>> = new Map<ObjectId, Subject<OrganizationUser>>();
    private orgSubjects$:       Map<ObjectId, Subject<Organization>>     = new Map<ObjectId, Subject<Organization>>();

    constructor(private api: APIService) { }

    getOrgUserDirect(uid: ObjectId): Promise<OrganizationUser> {

        if (this.orgUserCache.has(uid)) return Promise.resolve(this.orgUserCache.get(uid)!);

        if (this.orgUserSubjects$.has(uid)) return lastValueFrom(this.orgUserSubjects$.get(uid)!);

        const subj = new Subject<OrganizationUser>();

        this.orgUserSubjects$.set(uid, subj);

        return this.api.getOrgUserDirect(uid).then((v) => {
            this.orgUserCache.set(uid, v);
            subj.next(v);
            subj.complete();
            this.orgUserSubjects$.delete(uid);
            return v;
        }, (v) => {
            subj.error(v);
            subj.complete();
            return v;
        });
    }

    getOrgUser(orgid: ObjectId, uid: ObjectId): Promise<OrganizationUser> {

        if (this.orgUserCache.has(uid)) return Promise.resolve(this.orgUserCache.get(uid)!);

        if (this.orgUserSubjects$.has(uid)) return lastValueFrom(this.orgUserSubjects$.get(uid)!);

        const subj = new Subject<OrganizationUser>();

        this.orgUserSubjects$.set(uid, subj);

        return this.api.getOrgUser(orgid, uid).then((v) => {
            this.orgUserCache.set(uid, v);
            subj.next(v);
            subj.complete();
            this.orgUserSubjects$.delete(uid);
            return v;
        }, (v) => {
            subj.error(v);
            subj.complete();
            return v;
        });
    }

    getAdminUser(uid: ObjectId): Promise<AdminUser> {

        if (this.adminUserCache.has(uid)) return Promise.resolve(this.adminUserCache.get(uid)!);

        if (this.adminUserSubjects$.has(uid)) return lastValueFrom(this.adminUserSubjects$.get(uid)!);

        const subj = new Subject<AdminUser>();

        this.adminUserSubjects$.set(uid, subj);

        return this.api.getAdmin(uid).then((v) => {
            this.adminUserCache.set(uid, v);
            subj.next(v);
            subj.complete();
            this.adminUserSubjects$.delete(uid);
            return v;
        }, (v) => {
            subj.error(v);
            subj.complete();
            return v;
        });
    }

    getAppUser(uid: ObjectId): Promise<AppUser> {

        if (this.appUserCache.has(uid)) return Promise.resolve(this.appUserCache.get(uid)!);

        if (this.appUserSubjects$.has(uid)) return lastValueFrom(this.appUserSubjects$.get(uid)!);

        const subj = new Subject<AppUser>();

        this.appUserSubjects$.set(uid, subj);

        return Promise.reject("NotImplemented");
    }

    getOrganization(orgid: ObjectId): Promise<Organization> {

        if (this.orgCache.has(orgid)) return Promise.resolve(this.orgCache.get(orgid)!);

        if (this.orgSubjects$.has(orgid)) return lastValueFrom(this.orgSubjects$.get(orgid)!);

        const subj = new Subject<Organization>();

        this.orgSubjects$.set(orgid, subj);

        return this.api.getOrganization(orgid).then((v) => {
            this.orgCache.set(orgid, v);
            subj.next(v);
            subj.complete();
            this.orgSubjects$.delete(orgid);
            return v;
        }, (v) => {
            subj.error(v);
            subj.complete();
            return v;
        });
    }

}
