import { Component } from '@angular/core';
import {Organization, OrgType} from "../../interfaces/organization";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {CursorToken} from "../../interfaces/utils";

@Component({
  selector: 'app-organization-list',
  templateUrl: './organization-list.component.html',
  styleUrls: ['./organization-list.component.scss']
})
export class OrganizationListComponent {

  orgs: Organization[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  filters: SerializedParamCollection = {
    search: {
      active: null,
      default: null,
      type: 'string',
    },
  };

  selectedEditOrg: Organization|null = null;
  showCreateOrgModal: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchData(true);
  }

  async fetchData(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listOrganizations(this.nextToken, 24, this.filters);

      if (reset) {
        this.orgs = data.orgs;
      } else {
        this.orgs = [...this.orgs, ...data.orgs];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'ContentProvider konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['admin', 'organizations'], this.filters));
  }

  deleteOrg(org: Organization) {

    let loading = false;
    this.modal.confirm({
      nzTitle: 'ContentProvider löschen?',
      nzContent: 'Möchtest du den ContentProvider "'+org.name+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          await this.api.deleteOrganization(org.id);
          this.orgs = this.orgs.filter(p => p.id !== org.id);
          this.notification.success('Account gelöscht', 'Der ContentProvider "' + org.name + '" wurde gelöscht');
        } catch (err) {
          showAPIError(this.notification, 'Der ContentProvider konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  onOrgEdited(entry: Organization) {
    this.orgs = this.orgs.map(p => p.id == entry.id ? entry : p);
  }

  onOrgCreated(entry: {org:Organization,user:OrganizationUser}) {
    this.orgs = [entry.org, ...this.orgs];
  }

  onSearch(search: string): void {
    this.filters["search"].active = search;
    this.fetchData(true).then(()=>{});
  }
}
