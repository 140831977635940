<nz-modal [nzVisible]="show"
          nzTitle="Öffnungszeiten bearbeiten"
          (nzOnCancel)="close()"
          (nzOnOk)="submitForm()"
          nzOkText="Speichern"
          [nzOkLoading]="okayLoading"
          nzWidth="800px">

  <ng-container *nzModalContent>
    <div style="margin-bottom: 2rem;">
      <h3>Neue Öffnungszeiten hinzufügen</h3>
      <div class="row">
        <nz-select [(ngModel)]="newOpeningWeekDay" style="width: 8rem;">
          <nz-option *ngFor="let day of dayOptions" [nzLabel]="day.label" [nzValue]="day.value" nzRequired="true"></nz-option>
        </nz-select>
        <nz-time-picker [(ngModel)]="newOpeningTime.open" nzRequired="true"></nz-time-picker>
        <nz-time-picker [(ngModel)]="newOpeningTime.close" nzRequired="true"></nz-time-picker>
        <button nz-button nzType="primary" style="width: 8rem;" (click)="addOpeningTime()">Hinzufügen</button>
      </div>
      <p style="margin-top: 6px;">Nicht hinterlegte Tage gelten als geschlossen!</p>
    </div>


    <div *ngIf="organization !== null">
      <h3>Öffnungszeiten:</h3>
      <div *ngIf="organization.openingHours !== null" class="col">
        <div *ngFor="let openingTime of sortedOpeningTimes()" class="row">
          <nz-select [(ngModel)]="openingTime.day" style="width: 8rem;" disabled>
            <nz-option *ngFor="let day of dayOptions" [nzLabel]="day.label" [nzValue]="day.value"></nz-option>
          </nz-select>
          <nz-time-picker [(ngModel)]="openingTime.open"></nz-time-picker>
          <nz-time-picker [(ngModel)]="openingTime.close"></nz-time-picker>
          <button nz-button nzType="text" nzDanger (click)="removeOpeningTime(openingTime)">
            <span nz-icon nzType="delete"></span>
          </button>
        </div>
      </div>
    </div>

  </ng-container>
</nz-modal>
