<nz-modal [nzVisible]="show"
          nzTitle="Crawler erstellen"
          [nzWidth]="700"
          (nzOnCancel)="close()">

  <ng-container *nzModalContent>
    <div  class="content">

      <div class="kvbox">

        <span class="key">Name</span>
        <input class="value" nz-input [(ngModel)]="name">

        <span class="key">Implementation</span>
        <nz-select class="value" nzShowSearch nzAllowClear nzPlaceHolder="Implementation auswählen" [(ngModel)]="selectedImplementation" (ngModelChange)="implementationChanged($event)">
          <nz-option *ngFor="let impl of implementations" [nzLabel]="impl.name" [nzValue]="impl.key"></nz-option>
        </nz-select>

        <span class="key">Interval</span>
        <div class="value col2">
          <nz-input-number class="value" [(ngModel)]="interval"></nz-input-number>
          <input class="value" nz-input [ngModel]="interval | displayduration" readonly>
        </div>

        <span class="key">Interval (Retry)</span>
        <div class="value col2">
          <nz-input-number class="value" [(ngModel)]="retryInterval"></nz-input-number>
          <input class="value" nz-input [ngModel]="retryInterval | displayduration" readonly>
        </div>

        <span class="key">Timeout</span>
        <div class="value col2">
          <nz-input-number class="value" [(ngModel)]="timeout"></nz-input-number>
          <input class="value" nz-input [ngModel]="timeout | displayduration" readonly>
        </div>

        <span class="key">Max. RetryCount</span>
        <input class="value" nz-input [(ngModel)]="maxRetryCount">

        <div class="spacer"></div>

        <ng-container *ngFor="let kv of keys">

          <span class="key">Config[ {{kv}} ]</span>
          <input class="value" nz-input [ngModel]="config[kv] ?? ''" (ngModelChange)="config[kv] = $event">

        </ng-container>

      </div>

    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div>
      <div style="display:grid; grid-template-columns: 1fr auto auto; grid-column-gap: 0.5rem">
        <span></span>
        <button nz-button nzType="default" (click)="close()">Abbrechen</button>
        <button nz-button nzType="primary" [disabled]="name === '' || selectedImplementation === '' || selectedImplementation === null" (click)="execute()" [nzLoading]="loading">Erstellen</button>
      </div>
    </div>
  </ng-container>

</nz-modal>
