
function randomItem(array: string) {
  return array[Math.floor(Math.random() * array.length)];
}

export function generatePronouncablePassword(len: number): string {
  let res = '';

  for (let j = 0; j < len; j++) {

    const cc =  Math.floor(Math.random() * 4)

    res += randomItem('BCDFGHJKLMNPQRSTVWXZ');
    res += randomItem('aeiouy');

    if (cc === 1 || cc === 2 || cc === 3) {
      res += randomItem('bdfghklmnprstwxz');
      res += randomItem('aeiouy');
    }
    if (cc === 2 || cc === 3) {
      res += randomItem('bdfghklmnprstwxz');
      res += randomItem('aeiouy');
    }
    if (cc === 3) {
      res += randomItem('bdfghklmnprstwxz');
      res += randomItem('aeiouy');
    }
  }

  return res;
}
