import {ObjectId} from "./utils";
import {RFC3339} from "./datetime";
import {AppUser} from "./applicationUser";
import {Organization} from "./organization";
import {Post} from "./post";
import {Event} from "./event";

export interface Report {
  id: ObjectId;
  creationTime: RFC3339;
  deleted: RFC3339|null;
  reason: string;
  status: ReportStatus;
  user: AppUser;
  organization: Organization;
  post: Post | null;
  event: Event | null;
}

export enum ReportStatus {
  PENDING = 'pending',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}
