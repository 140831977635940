
<app-default-frame>

  <nz-modal [nzVisible]="jwt !== null" nzTitle="JWT Token" (nzOnOk)="jwt = null" (nzOnCancel)="jwt = null">
    <ng-container *nzModalContent>
      <textarea rows="8" nz-input [ngModel]="jwt ?? ''" [readonly]="true" style="resize: none"></textarea>
    </ng-container>
  </nz-modal>

  <main>

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="users"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Erstellt am</th>
        <th>Mail</th>
        <th>Phone</th>
        <th>Auth Methode</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of users">

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.id | shortid }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.creationTime | displaydate}}</a></td>

        <td><a *ngIf="data.email !== null" href="mailto:{{data.email}}">{{ data.email }}</a></td>

        <td><a *ngIf="data.phoneNumber !== null" href="tel:{{data.phoneNumber}}">{{ data.phoneNumber }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.authType }}</a></td>

        <td class="actionlist">

          <fa-icon-btn nzType="default"
                       nz-tooltip="JWT Token erstellen"
                       buttonSize="24"
                       iconSize="14"
                       icon="shield-keyhole"
                       (btnClick)="queryJWT(data)"></fa-icon-btn>

        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

