
// [set|get] (recursive) object fields by ref-string (e.g. 'field.sub.value' )

export function getIndirectFieldValue<T>(obj: any, ref: string): T|undefined {
  if (ref === '.') return obj;

  let value = obj;

  for (const key of ref.split('.')) {
    value = value[key];
    if (value === undefined) return undefined;
  }

  return value as T;
}

export function setIndirectFieldValue<TBase, TVal>(_obj: TBase, ref: string, value: TVal): TBase|undefined {
  let obj = _obj as any;

  if (ref === ' ') return undefined;

  if (ref === '.') return value as any as TBase;

  if (!ref.includes('.')) {
    obj[ref] = value;
    return obj;
  }

  const sepidx = ref.indexOf('.')

  const p0 = ref.substring(0, sepidx);
  const p1 = ref.substring(sepidx + 1);

  obj[p0] = setIndirectFieldValue(obj[p0], p1, value);
  return obj;
}
