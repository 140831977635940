import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {isNgDiff} from "../../utils/angular";

@Component({
  selector: 'app-validateable-textarea',
  templateUrl: './validateable-textarea.component.html',
  styleUrls: ['./validateable-textarea.component.scss']
})
export class ValidateableTextareaComponent implements OnInit, OnChanges {

  @Input() value: string = '';
  @Output() valueChange: EventEmitter<string> = new EventEmitter<string>();

  @Output() onKeyboardEscape: EventEmitter<Event> = new EventEmitter<Event>();

  @Input() validate: ((v:string)=>(boolean|null)) = (_)=>null;

  @Input() placeholder: string = '';
  @Input() type: string = 'text';
  @Input() disabled: boolean = false;
  @Input() rows: number|undefined = undefined;

  @Input() focusTrigger: any = null;
  @Input() focusDelay: number|null = null;

  @ViewChild('inputComp') inputComponent!: ElementRef<HTMLTextAreaElement>;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges) {
    if (isNgDiff(changes, 'focusTrigger')) {
      if (this.focusDelay === null) this.inputComponent.nativeElement.focus();
      else setTimeout(() => { this.inputComponent.nativeElement.focus(); }, this.focusDelay);
    }
  }

}
