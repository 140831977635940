<nz-modal [nzVisible]="show !== null && orgId !== null"
          nzTitle="Gesprächsnotiz bearbeiten"
          nzOkText="Speichern"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div *ngIf="orgId !== null && show !== null" class="content">

      <nz-input-group [nzAddOnBefore]="prefix0">
        <ng-template #prefix0>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">ID</span>
        </ng-template>
        <input nz-input type="text" [ngModel]="show.id" [disabled]="true" >
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix1">
        <ng-template #prefix1>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Titel</span>
        </ng-template>
        <app-validateable-input  [(value)]="title" [validate]="isValidStr"></app-validateable-input>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix2">
        <ng-template #prefix2>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Datum</span>
        </ng-template>
        <nz-date-picker [(ngModel)]="date" [nzAllowClear]="false" class="nz-input-group-picker"></nz-date-picker>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix3">
        <ng-template #prefix3>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Zeit</span>
        </ng-template>
        <nz-time-picker [(ngModel)]="time" [nzAllowEmpty]="false" nzFormat="HH:mm" class="nz-input-group-picker"></nz-time-picker>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix4" class="commentGroup">
        <ng-template #prefix4>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Text</span>
        </ng-template>
        <app-validateable-textarea [rows]="12" [(value)]="body"></app-validateable-textarea>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix5">
        <ng-template #prefix5>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Teilnehmer</span>
        </ng-template>
        <div class="participants-container">
          <div *ngFor="let pp of participants; let i = index" class="participant-row">
            <app-validateable-input  [(value)]="pp.value" [validate]="isValidStr"></app-validateable-input>
            <fa-icon-btn                                     class="btn-del" nzType="primary" [nzDanger]="true" buttonSize="32" icon="trash" (btnClick)="deleteParticipant(i)"  ></fa-icon-btn>
            <fa-icon-btn *ngIf="i === participants.length-1" class="btn-add" nzType="primary"                   buttonSize="32" icon="plus"  (btnClick)="addParticipant()"      ></fa-icon-btn>
          </div>
          <div *ngIf="participants.length === 0" class="participant-row">
            <input nz-input type="text" [ngModel]="" [disabled]="true" >
            <fa-icon-btn                                     class="btn-add" nzType="primary"                   buttonSize="32" icon="plus"  (btnClick)="addParticipant()"      ></fa-icon-btn>
          </div>
        </div>
      </nz-input-group>

    </div>
  </ng-container>
</nz-modal>
