import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Crawler, CrawlerExecution, CrawlerLog} from "../../../../interfaces/crawler";
import {APIService} from "../../../../services/api.service";
import {AuthenticationService} from "../../../../services/authentication.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {isNgDiff, sleep} from "../../../../utils/angular";
import {showAPIError} from "../../../../utils/api";
import {CursorToken, ObjectId} from "../../../../interfaces/utils";
import {RFC3339, Seconds} from "../../../../interfaces/datetime";
import {DateUtils} from "../../../../utils/date";

@Component({
  selector: 'app-crawler-execution-list',
  templateUrl: './crawler-execution-list.component.html',
  styleUrls: ['./crawler-execution-list.component.scss']
})
export class CrawlerExecutionListComponent implements OnInit, OnChanges {

  @Input() crawler: Crawler|null = null;

  executions: CrawlerExecution[] = [];

  loading: boolean = false;
  loadingMore: boolean = false;
  nextToken: CursorToken = '@start';

  expandedLogs: Set<ObjectId> = new Set<ObjectId>();
  @Input() set trigger(v: number) { this.refreshData().then(() => { this.refreshTrigger = DateUtils.now().getTime() }) };

  refreshTrigger: number = 0;
  lastRefresh: RFC3339 = DateUtils.formatRFC3339(DateUtils.now());

  constructor(private api: APIService,
              private auth: AuthenticationService,
              private notification: NzNotificationService) {

  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (isNgDiff(changes, 'crawler')) {
      if (this.crawler === null) {
        this.executions = [];
      } else {
        this.loadData().then(() => {});
      }
      return;
    }
  }

  async loadData() {

    this.executions = [];

    if (this.crawler === null) return;

    try {
      this.loading = true;

      const data = (await Promise.all([this.api.listCrawlerExecutions(this.crawler.id, '@start', 32), sleep(600)]))[0];

      this.executions = data.executions;
      this.nextToken  = data.nextPageToken;

      this.lastRefresh = DateUtils.formatRFC3339(DateUtils.now());

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  async refreshData() {

    if (this.crawler === null) return;

    try {
      const data = (await Promise.all([this.api.listCrawlerExecutions(this.crawler.id, '@start', 32), sleep(600)]))[0];

      if (JSON.stringify(this.executions) != JSON.stringify(data.executions)) {

        if (JSON.stringify(this.executions.map(p => p.id)) === JSON.stringify(data.executions.map(p => p.id))) {

          // very hacky - but when we only update the individual object keys, Angular does not trigger a re-eval of the *ngFor
          // and the underlying <crawler-log-list> is not re-created
          // and we can smoothly update the data when the refreshTrigger comes
          for (let i = 0; i < data.executions.length; i++) {
            for (const key of Object.keys(data.executions[i])) {
              // @ts-ignore
              this.executions[i][key] = data.executions[i][key];
            }
          }

        } else {

          console.log("fullre", [JSON.stringify(this.executions.map(p => p.id)), JSON.stringify(data.executions.map(p => p.id))])
          this.executions = data.executions;
          this.nextToken  = data.nextPageToken;

        }

      }

      this.lastRefresh = DateUtils.formatRFC3339(DateUtils.now());

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht refreshed werden', err)
    }
  }

  async loadMore() {

    if (this.crawler === null) return;

    try {
      this.loadingMore = true;

      const data = (await Promise.all([this.api.listCrawlerExecutions(this.crawler.id, this.nextToken, 64), sleep(300)]))[0];

      this.executions = [...this.executions, ...data.executions];
      this.nextToken  = data.nextPageToken;

    } catch (err) {
      showAPIError(this.notification, 'Daten konnten nicht geladen werden', err)
    } finally {
      this.loadingMore = false;
    }
  }

  secondsDiff(startTime: RFC3339, endTime: RFC3339): Seconds {
    return (DateUtils.parseRFC3339(endTime).getTime() - DateUtils.parseRFC3339(startTime).getTime()) / 1000
  }

  toggle(exec: CrawlerExecution) {
    if (this.expandedLogs.has(exec.id))
      this.expandedLogs.delete(exec.id)
    else
      this.expandedLogs.add(exec.id)
  }
}
