import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({ name: 'trustResourceURL', pure: true })
export class TrustResourceURLPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer){}

  transform(value: string, ...args: unknown[]) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(value);
  }
}
