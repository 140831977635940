import { Component } from '@angular/core';
import {Event} from "../../interfaces/event";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {Group} from "../../interfaces/group";
import {CursorToken, ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-event-list',
  templateUrl: './org-event-list.component.html',
  styleUrls: ['./org-event-list.component.scss']
})
export class OrgEventListComponent {

  events: Event[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  orgid!: ObjectId;

  filters: SerializedParamCollection = {};
  showCreateNewEvent: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.orgid = this.auth.getSelfOrgID()!;
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchEvents(true);
  }

  async fetchEvents(reset: boolean) {
    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listOrgEvents(this.orgid, this.nextToken, 24);

      if (reset) {
        this.events = data.events;
      } else {
        this.events = [...this.events, ...data.events];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Veranstaltungen konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['org', 'events'], this.filters));
  }

  onEventCreated(event: Event) {
    this.events = [event, ...this.events];
  }

  onEventUpdated(newdata: Event) {
    this.events = this.events.map(g => (g.id === newdata.id) ? newdata : g);
  }


  onEventDeleted(newdata: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event löschen?',
      nzContent: 'Möchtest du das Event "'+newdata.title+'" wirklich löschen?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Löschen',
      nzOnOk: async () => {
        try {
          loading = true;
          const res = await this.api.deleteEvent(newdata.organizationID, newdata.id);
          this.notification.success('Event gelöscht', 'Das Event "' + newdata.title + '" wurde gelöscht');

          this.events = this.events.filter(g => g.id !== newdata.id);

        } catch (err) {
          showAPIError(this.notification, 'Das Event konnte nicht gelöscht werden', err)
        } finally {
          loading = false;
        }
      }
    });

  }


  onEventToggleStatus({event, active}: {event: Event, active: boolean}) {
    if(active) this.activateEvent(event);
    else this.deactivateEvent(event);
  }

  activateEvent(event: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event aktivieren?',
      nzContent: 'Möchtest du das Event "'+event.title+'" wirklich aktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Aktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.enableEvent(event.organizationID, event.id);
          this.notification.success('Event aktiviert', 'Das Event "' + event.title + '" wurde aktiviert');
          this.events = this.events.map(e => e.id == newdata.id ? newdata : e);
        } catch (err) {
          showAPIError(this.notification, 'Die Gruppe konnte nicht aktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }

  deactivateEvent(event: Event) {
    let loading = false;
    this.modal.confirm({
      nzTitle: 'Event deaktivieren?',
      nzContent: 'Möchtest du das Event "'+event.title+'" wirklich deaktivieren?',
      nzCancelText: 'Abbrechen',
      nzOkLoading: loading,
      nzOkText: 'Deaktivieren',
      nzOnOk: async () => {
        try {
          loading = true;
          const newdata = await this.api.disableEvent(event.organizationID, event.id);
          this.notification.success('Event deaktiviert', 'Das Event "' + event.title + '" wurde deaktiviert');
          this.events = this.events.map(e => e.id == newdata.id ? newdata : e);
        } catch (err) {
          showAPIError(this.notification, 'Das Event konnte nicht deaktiviert werden', err)
        } finally {
          loading = false;
        }
      }
    });
  }
}
