<nz-modal [nzVisible]="show && orgId !== null"
          nzTitle="Anhang bearbeiten"
          nzOkText="Speichern"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div *ngIf="orgId !== null && show !== null" class="content">

      <nz-input-group [nzAddOnBefore]="prefix1">
        <ng-template #prefix1>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Datum</span>
        </ng-template>
        <input nz-input  [ngModel]="show.creationTime | displaydate" [readOnly]="true" [disabled]="true" type="text">
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix2">
        <ng-template #prefix2>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Dateityp</span>
        </ng-template>
        <input nz-input  [ngModel]="show.mimeType" [readOnly]="true" [disabled]="true" type="text">
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix3">
        <ng-template #prefix3>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Dateiname</span>
        </ng-template>
        <input nz-input  [(ngModel)]="filename" type="text">
      </nz-input-group>

      <div style="display: grid; grid-template-columns: 1fr auto; grid-column-gap: 2px;" >
        <nz-input-group [nzAddOnBefore]="prefix4">
          <ng-template #prefix4>
            <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Typ</span>
          </ng-template>
          <nz-select class="nz-input-group-select" nzAllowClear [nzLoading]="typesLoading" [ngModel]="selectedAttachmentType">
            <nz-option *ngFor="let type of attachmentTypes" [nzValue]="type" [nzLabel]="type"></nz-option>
          </nz-select>
        </nz-input-group>
        <fa-icon-btn [disabled]="typesLoading" buttonSize="32" icon="plus" (btnClick)="showAddType=true" nz-tooltip="Neuen Typ hinzufügen"></fa-icon-btn>
      </div>

      <nz-input-group [nzAddOnBefore]="prefix6" class="commentGroup">
        <ng-template #prefix6>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Kommentar</span>
        </ng-template>
        <textarea rows="4" nz-input [(ngModel)]="comment"></textarea>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix5">
        <ng-template #prefix5>
          <span [style.display]="'inline-flex'" [style.min-width]="'92px'">Dateigröße</span>
        </ng-template>
        <input nz-input  [ngModel]="show.filesize | filesize" [readOnly]="true" [disabled]="true" type="text">
      </nz-input-group>

    </div>
  </ng-container>
</nz-modal>


<nz-modal [nzVisible]="showAddType"
          nzTitle="Typ hinzufügen"
          nzOkText="Hinzufügen"
          (nzOnOk)="showAddType = false; addNewType(addType)"
          (nzOnCancel)="showAddType = false; addType = ''">
  <ng-container *nzModalContent>
    <input nz-input [(ngModel)]="addType" type="text">
  </ng-container>
</nz-modal>
