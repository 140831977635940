import { Pipe, PipeTransform } from '@angular/core';
import {formatBytes} from "../utils/bytes";

@Pipe({ name: 'singleLine', pure: true })
export class SingleLinePipe implements PipeTransform {

  transform(value: string): string {
    return value.split(/\r?\n/).at(0) ?? '';
  }

}
