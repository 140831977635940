
<app-default-frame>

  <main>

    <app-edit-org-modal     [(show)]="selectedEditOrg"      (success)="onOrgEdited($event)"></app-edit-org-modal>
    <app-create-org-modal   [(show)]="showCreateOrgModal"   (success)="onOrgCreated($event)" ></app-create-org-modal>

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [triggerOnChange]="true" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary" text="Neuen ContentProvider & Nutzer anlegen" (btnClick)="showCreateOrgModal = true"></fa-icon-btn>
      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="orgs"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Erstellt am</th>
        <th>Typ</th>
        <th>Vorausgewählter Channel</th>
        <th>Status</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of orgs">

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.id | shortid }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.name}}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{data.creationTime | displaydate}}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.type | fmtorgtype }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="[data.id]">{{ data.isPreselected ? 'Ausgewählt' : '' }}</a></td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [style.color]="data.enabled ? '#08A045' : ''" [style.font-weight]="data.enabled ? 'bold' : ''" [routerLink]="[data.id]">{{ data.enabled ? 'Aktiv' : 'Nicht aktiv' }}</a></td>

        <td class="actionlist">

          <fa-icon-btn nzType="default"
                       nz-tooltip="ContentProvider bearbeiten"
                       buttonSize="24"  iconSize="14" icon="edit"
                       (btnClick)="selectedEditOrg = data"></fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       nz-tooltip="ContentProvider löschen"
                       buttonSize="24"  iconSize="14" icon="trash"
                       (btnClick)="deleteOrg(data)"></fa-icon-btn>

        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

