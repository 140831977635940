<nz-modal [nzVisible]="show !== null"
          nzTitle="ContentProvider bearbeiten"
          nzOkText="Bearbeiten"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span class="key">ID</span>
      <input nz-input         [ngModel]="show?.id ?? ''"  readonly>

      <span class="spacer"></span>

      <span class="key">Name</span>
      <app-validateable-input [(value)]="name"      [validate]="isValidStr" ></app-validateable-input>

      <span class="key">Typ</span>
      <nz-select class="value" nzPlaceHolder="Typ auswählen" [(ngModel)]="orgType">
        <nz-option nzLabel="Gemeinde"    [nzValue]="'COMMUNITY'"></nz-option>
        <nz-option nzLabel="Verein"      [nzValue]="'ASSOCIATION'"></nz-option>
        <nz-option nzLabel="Unternehmen" [nzValue]="'BUSINESS'"></nz-option>
        <nz-option nzLabel="Künstler"    [nzValue]="'ARTIST'"></nz-option>
        <nz-option nzLabel="Presse"      [nzValue]="'PRESS'"></nz-option>
      </nz-select>

      <ng-container *ngIf="orgType !== null">
        <span class="key" *ngIf="orgType === 'COMMUNITY'">Art der Gemeinde</span>
        <span class="key" *ngIf="orgType === 'ASSOCIATION'">Art des Vereins</span>
        <span class="key" *ngIf="orgType === 'BUSINESS'">Branche</span>
        <span class="key" *ngIf="orgType === 'ARTIST'">Ausrichtung</span>
        <span class="key" *ngIf="orgType === 'PRESS'">Branche</span>
        <input class="value" nz-input [(ngModel)]="orgPurpose" [nzAutocomplete]="auto"/>
        <nz-autocomplete [nzDataSource]="purposes" nzBackfill #auto></nz-autocomplete>
      </ng-container>

      <span class="spacer"></span>

      <label nz-checkbox [(ngModel)]="isPreselected">Content-Provider vorausgewählt</label>

      <span class="key">Kundennummer</span>
      <app-validateable-input placeholder="0000-0000-0000"                   [(value)]="customernumber" [validate]="isValidCustomerNumber"  ></app-validateable-input>

      <span class="key">Address</span>
      <div class="input-block address">
        <app-validateable-input class="plz"    [mintitlewidth]="65" inputtitle="PLZ"        placeholder="00000"       [(value)]="addressPLZ"    ></app-validateable-input>
        <app-validateable-input class="city"   [mintitlewidth]="65" inputtitle="Stadt"      placeholder="Stadt"       [(value)]="addressCity"   ></app-validateable-input>
        <app-validateable-input class="street" [mintitlewidth]="65" inputtitle="Straße"     placeholder="Straße"      [(value)]="addressStreet" ></app-validateable-input>
        <app-validateable-input class="num"    [mintitlewidth]="65" inputtitle="Hausnmr."   placeholder="Nummer"      [(value)]="addressNumber" ></app-validateable-input>
        <app-validateable-input class="extra"  [mintitlewidth]="65" inputtitle="Extra"      placeholder=""            [(value)]="addressExtra"  ></app-validateable-input>
      </div>

      <span class="key">Bankverbindung</span>
      <div class="input-block banking">
        <app-validateable-input class="iban"  [mintitlewidth]="65" inputtitle="IBAN"    placeholder="XX00-00000-00000000000"  [(value)]="bankingIban"   [validate]="isValidIBAN"  ></app-validateable-input>
        <app-validateable-input class="name"  [mintitlewidth]="65" inputtitle="Inhaber" placeholder="Kontoinhaber"            [(value)]="bankingName"                             ></app-validateable-input>
      </div>

      <span class="key">Telefonnummer</span>
      <app-validateable-input placeholder="0000/00000000"  [(value)]="telephonenumber" [validate]="isValidTelephone"  ></app-validateable-input>

    </div>
  </ng-container>
</nz-modal>
