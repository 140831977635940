<app-default-frame>

  <app-edit-org-modal [(show)]="selectedEditOrg"
                      (success)="onOrgEdited($event)"></app-edit-org-modal>

  <app-create-org-attachment-modal [(show)]="showCreateFileModal"
                                   (success)="onAttachmentCreated($event)"
                                   [orgId]="id"></app-create-org-attachment-modal>

  <app-edit-org-attachment-modal [(show)]="showEditFileModal"
                                 (success)="onAttachmentEdited($event)"
                                 [orgId]="id"></app-edit-org-attachment-modal>

  <app-upload-org-avatar-modal [(show)]="selectedEditAvatar"
                               (success)="setAvatar($event, 'image/png')"></app-upload-org-avatar-modal>

  <app-upload-org-backgroundimage-modal [(show)]="selectedEditBackgroundImage"
                                        (success)="setBackgroundImage($event, 'image/png')"></app-upload-org-backgroundimage-modal>

  <app-create-conversation-note-modal [(show)]="showCreateConvNoteModal"
                                      (success)="onConversationNoteCreated($event)"
                                      [orgId]="org?.id ?? null"></app-create-conversation-note-modal>

  <app-edit-conversation-note-modal [(show)]="showEditConvNotesModal"
                                    (success)="onConversationNoteEdited($event)"
                                    [orgId]="id"></app-edit-conversation-note-modal>

  <app-create-event-modal [(show)]="showCreateNewEvent"
                          [orgId]="id"
                          (success)="onEventCreated($event)"></app-create-event-modal>

  <app-create-post-modal [(show)]="showCreateNewPost"
                         (success)="onPostCreated($event)"
                         [forcedOrgId]="id"></app-create-post-modal>

  <app-create-group-modal [(show)]="showCreateNewGroup"
                          [forcedOrgId]="org?.id ?? null"
                          (success)="onGroupCreated($event)"></app-create-group-modal>

  <app-add-org-link-modal [(show)]="showAddLink"
                          (success)="onLinkAdded($event)"></app-add-org-link-modal>

  <app-edit-group-modal [(show)]="showEditGroup"
                        (success)="onGroupEdited($event)"></app-edit-group-modal>

  <app-add-impressum-modal [(show)]="showCreateImpress"
                           [forcedOrgId]="org?.id ?? null"
                           (success)="onImpressCreated($event)"></app-add-impressum-modal>

  <app-edit-impressum-modal [show]="selectedImpress != null"
                            [impress]="selectedImpress"
                            (success)="onImpressUpdated($event)"></app-edit-impressum-modal>

  <!-- <app-add-waste-entry-modal [(show)]="showAddWasteCollectionModal"
                             [month]="selectedMonth"
                             (success)="onGarbageEntryCreated($event)"></app-add-waste-entry-modal>-->

  <app-manage-waste-calendar-streets-modal [(show)]="showManageDistrictsModal"
                                           [orgId]="id"
                                           [wasteCalendarStreets]="wasteCalendarStreets"
                                           (wasteCalendarStreetCreated)="onWasteCalendarStreetCreated($event)"
                                           (wasteCalendarStreetUpdated)="onWasteCalendarStreetUpdated($event)"
                                           (wasteCalendarStreetDeleted)="onWasteCalendarStreetDeleted($event)"/>

    <app-edit-opening-hours-modal [(show)]="showEditOpeningHoursModal"
                                  [orgId]="id"
                                  [organization]="org"
                                  ></app-edit-opening-hours-modal>

  <nz-modal [nzVisible]="editProfileText !== ''"
            nzTitle="{{editProfileText}} bearbeiten"
            nzOkText="Speichern"
            [nzOkLoading]="loading"
            (nzOnOk)="updateProfileText()"
            (nzOnCancel)="editProfileText = ''">
    <ng-container *nzModalContent>
      <app-validateable-input [focusTrigger]="editProfileText"
                              [focusDelay]="400"
                              (onKeyboardEnter)="updateProfileText()"
                              (onKeyboardEscape)="editProfileText = ''"
                              [(value)]="profileTextValue"></app-validateable-input>
    </ng-container>
  </nz-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading && org !== null">

    <div class="topbar">

      <div class="avatarbox">
        <nz-avatar class="avatar" [nzShape]="'square'" [nzSize]="128" [nzIcon]="'user'"
                   [nzSrc]="avatarSource() ?? ''"></nz-avatar>

        <fa-icon-btn nzType="default" nz-tooltip="Profilbild bearbeiten" iconSize="18" buttonSize="32" icon="edit"
                     (btnClick)="selectedEditAvatar = org"></fa-icon-btn>
      </div>

      <span class="name">{{ org.name }}</span>

      <span class="usertype">{{ org.type | fmtorgtype }}</span>

      <span class="actionbuttons">
        <fa-icon-btn nzType="primary" text="Neuen Post erstellen" iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky" (btnClick)="showCreateNewPost = true"></fa-icon-btn>

        <fa-icon-btn nzType="default" nz-tooltip="ContentProvider bearbeiten" iconSize="18" buttonSize="32" icon="edit"
                     (btnClick)="selectedEditOrg = org"></fa-icon-btn>
        <fa-icon-btn nzType="default" [nzDanger]="true" nz-tooltip="ContentProvider löschen" iconSize="18"
                     buttonSize="32" icon="trash" (btnClick)="deleteOrg()"></fa-icon-btn>
        <fa-icon-btn [disabled]="org.enabled" nzType="primary" [nzDanger]="true" nz-tooltip="ContentProvider aktivieren"
                     iconSize="18" buttonSize="32" icon="eye" (btnClick)="activateOrg()"></fa-icon-btn>
        <fa-icon-btn [disabled]="!org.enabled" nzType="primary" [nzDanger]="true"
                     nz-tooltip="ContentProvider deaktivieren" iconSize="18" buttonSize="32" icon="eye-slash"
                     (btnClick)="deactivateOrg()"></fa-icon-btn>
      </span>

    </div>

    <nz-divider></nz-divider>

    <div class="rootdiv">

      <div #col1Ref class="col1Ref"></div>
      <div #col2Ref class="col2Ref"></div>
      <div #col3Ref class="col3Ref"></div>

      <nz-card class="card-maindata" nzTitle="Stammdaten" [nzExtra]="maindata_extra">
        <ng-template #maindata_extra>
          <fa-icon-btn nzType="default"
                       nz-tooltip="Nutzer bearbeiten"
                       iconSize="12" buttonSize="26" icon="edit"
                       (btnClick)="selectedEditOrg = org"></fa-icon-btn>
        </ng-template>
        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="id-card"></fa-icon></span>
          <span class="key">ID</span>
          <span class="value">{{ org.id }}</span>

          <span class="icon"><fa-icon icon="planet-moon"></fa-icon></span>
          <span class="key">Status</span>
          <span class="value" *ngIf="!org.enabled">Deaktiviert</span>
          <span class="value" *ngIf="org.enabled">Aktiviert</span>

          <span class="icon"><fa-icon icon="clock"></fa-icon></span>
          <span class="key">Erstellt am</span>
          <span class="value">{{ org.creationTime | displaydate }}</span>

          <span class="icon"><fa-icon icon="shirt"></fa-icon></span>
          <span class="key">Name</span>
          <span class="value">{{ org.name }}</span>

          <span class="icon"><fa-icon icon="hashtag"></fa-icon></span>
          <span class="key">Kundennummer</span>
          <span class="value">{{ org.customerNumber }}</span>

          <span class="icon"><fa-icon icon="phone"></fa-icon></span>
          <span class="key">Telefonnummer</span>
          <span class="value">{{ org.phone }}</span>

          <!--  <span class="icon"><fa-icon icon="link"></fa-icon></span>
            <span class="key">URL</span>
            <span class="value unpublished">(none)</span>
            <span class="value"             *ngIf="!org.enabled"><a target="_blank" href="https://beta.badennews.com/{{org.id}}">(link)</a></span>
            <span class="value"             *ngIf="org.enabled"><a target="_blank" href="https://beta.badennews.com/{{org.id}}">(link)</a></span>-->

        </div>
      </nz-card>

      <nz-card class="card-impress" nzTitle="Impressum" [nzExtra]="addImpressumExtra">

        <ng-template #addImpressumExtra>

          <fa-icon-btn nzType="primary"
                       text="Impressum hinzufügen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       perm="GROUPS::CREATE"
                       (btnClick)="showCreateImpress = true"></fa-icon-btn>

        </ng-template>

        <div class="impress-list">
          <app-list-entry-impress *ngFor="let im of impress"
                                  [impress]="im"
                                  [showDelete]="true"
                                  [showEdit]="true"
                                  (showEditImpress)="selectedImpress = $event"
                                  (impressUpdated)="onImpressUpdated($event)"
                                  (impressDeleted)="onImpressDeleted($event)"></app-list-entry-impress>

          <ng-container *ngIf="impress.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>
        </div>
      </nz-card>

      <!-- <nz-card class="card-address" nzTitle="Addresse" [nzExtra]="address_extra">
         <ng-template #address_extra>
           <fa-icon-btn nzType="default"
                        nz-tooltip="Addresse bearbeiten"
                        iconSize="12" buttonSize="26" icon="edit"
                        (btnClick)="selectedEditOrg = org"></fa-icon-btn>
         </ng-template>
         <div class="infocardcontainer">

           <span class="icon"></span>
           <span class="key">PLZ</span>
           <span class="value">{{org.address.zipcode}}</span>

           <span class="icon"></span>
           <span class="key">Stadt</span>
           <span class="value">{{org.address.city}}</span>

           <span class="icon"></span>
           <span class="key">Straße</span>
           <span class="value">{{org.address.street}}</span>

           <span class="icon"></span>
           <span class="key">Hausnummer</span>
           <span class="value">{{org.address.number}}</span>

           <span class="icon"></span>
           <span class="key">Zusatz</span>
           <span class="value">{{org.address.extra}}</span>

         </div>
       </nz-card>-->

      <!--<nz-card class="card-banking" nzTitle="Bankdaten" [nzExtra]="banking_extra">
        <ng-template #banking_extra>
          <fa-icon-btn nzType="default"
                       nz-tooltip="Bankdaten bearbeiten"
                       iconSize="12" buttonSize="26" icon="edit"
                       (btnClick)="selectedEditOrg = org"></fa-icon-btn>
        </ng-template>
        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="money-check-dollar"></fa-icon></span>
          <span class="key">IBAN</span>
          <span class="value">{{org.banking.iban}}</span>

          <span class="icon"><fa-icon icon="signature"></fa-icon></span>
          <span class="key">Kontoinhaber</span>
          <span class="value">{{org.banking.owner}}</span>

        </div>
      </nz-card>-->

      <nz-card class="card-profile" nzTitle="Profil">
        <div class="infocardcontainer">

          <span class="icon"><fa-icon icon="image"></fa-icon></span>
          <span class="key">Profilbild</span>
          <span class="value" *ngIf="org.avatarImageID !== null &&  loadingBlobAvatar"><fa-icon icon="loader"
                                                                                                [spin]="true"></fa-icon></span>
          <span class="value"
                *ngIf="org.avatarImageID !== null && !loadingBlobAvatar && avatarBlob !== null">{{ avatarBlob.mimeType }}
            ({{ avatarBlob.filesize | filesize }}) <span style="opacity: 0.5;">[[ {{ avatarBlob.checksum | suffix:8 }}
              ]]</span></span>
          <span class="value image-empty" *ngIf="org.avatarImageID === null">(nicht gesetzt)</span>
          <span class="extra" *ngIf="!loadingBlobAvatar"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                                                      (btnClick)="selectedEditAvatar = org"
                                                                      nz-tooltip="Profilbild ändern"></fa-icon-btn></span>
          <span class="extra2" *ngIf="!loadingBlobAvatar && org.avatarImageID !== null"><fa-icon-btn icon="trash"
                                                                                                     buttonSize="22"
                                                                                                     iconSize="10"
                                                                                                     nzType="primary"
                                                                                                     [nzDanger]="true"
                                                                                                     (btnClick)="clearAvatar()"
                                                                                                     [loading]="clearingAvatar"
                                                                                                     nz-tooltip="Profilbild löschen"></fa-icon-btn></span>


          <span class="icon"><fa-icon icon="image"></fa-icon></span>
          <span class="key">Hintergrundbild</span>
          <span class="value" *ngIf="org.backgroundImageID !== null &&  loadingBlobBackgroundImage"><fa-icon
            icon="loader" [spin]="true"></fa-icon></span>
          <span class="value"
                *ngIf="org.backgroundImageID !== null && !loadingBlobBackgroundImage && backgroundImageBlob !== null">{{ backgroundImageBlob.mimeType }}
            ({{ backgroundImageBlob.filesize | filesize }}) <span
              style="opacity: 0.5;">[[ {{ backgroundImageBlob.checksum | suffix:8 }} ]]</span></span>
          <span class="value image-empty" *ngIf="org.backgroundImageID === null">(nicht gesetzt)</span>
          <span class="extra" *ngIf="!loadingBlobBackgroundImage"><fa-icon-btn icon="pen-to-square" buttonSize="22"
                                                                               iconSize="10"
                                                                               (btnClick)="selectedEditBackgroundImage = org"
                                                                               nz-tooltip="Hintergrundbild ändern"></fa-icon-btn></span>
          <span class="extra2" *ngIf="!loadingBlobBackgroundImage && org.backgroundImageID !== null"><fa-icon-btn
            icon="trash" buttonSize="22" iconSize="10" nzType="primary" [nzDanger]="true"
            (btnClick)="clearBackgroundImage()" [loading]="clearingBackgroundImage"
            nz-tooltip="Hintergrundbild ändern"></fa-icon-btn></span>

        </div>
      </nz-card>
      <!-- <nz-divider></nz-divider>

       <span class="icon"><fa-icon icon="location-dot"></fa-icon></span>
       <span class="key">Lebt In</span>
       <span class="value">{{org.profileData.location}}</span>
       <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" (btnClick)="editProfileField('profileData.location', 'Ort')"></fa-icon-btn></span>

       <span class="icon"><fa-icon icon="globe"></fa-icon></span>
       <span class="key">Webseite</span>
       <span class="value" *ngIf="org.profileData.website !== ''"><a href="{{org.profileData.website}}">{{org.profileData.website}}</a></span>
       <span class="value" *ngIf="org.profileData.website === ''">{{org.profileData.website}}</span>
       <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" (btnClick)="editProfileField('profileData.website', 'Webseite')"></fa-icon-btn></span>

       <span class="icon"><fa-icon icon="phone"></fa-icon></span>
       <span class="key">Telefonnummer</span>
       <span class="value">{{org.profileData.phone}}</span>
       <span class="extra" ><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10" (btnClick)="editProfileField('profileData.phone', 'Telefonnummer')"></fa-icon-btn></span>-->

      <nz-card class="card-social" nzTitle="Sozial">
        <div class="infocardcontainer">

          <span class="icon"><fa-icon [icon]="['fab', 'whatsapp']"></fa-icon></span>
          <span class="key">WhatsApp</span>
          <span class="value">{{ org.profileData.social.whatsapp }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.whatsapp', 'WhatsApp')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'facebook']"></fa-icon></span>
          <span class="key">Facebook</span>
          <span class="value">{{ org.profileData.social.facebook }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.facebook', 'Facebook')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'tiktok']"></fa-icon></span>
          <span class="key">TikTok</span>
          <span class="value">{{ org.profileData.social.tikTok }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.tikTok', 'TikTok')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'instagram']"></fa-icon></span>
          <span class="key">Instagram</span>
          <span class="value">{{ org.profileData.social.instagram }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.instagram', 'Instagram')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'youtube']"></fa-icon></span>
          <span class="key">Youtube</span>
          <span class="value">{{ org.profileData.social.youtube }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.youtube', 'Youtube')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></span>
          <span class="key">Snapchat</span>
          <span class="value">{{ org.profileData.social.snapchat }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.snapchat', 'Snapchat')"></fa-icon-btn></span>

          <span class="icon"><fa-icon icon="messages"></fa-icon></span>
          <span class="key">Signal</span>
          <span class="value">{{ org.profileData.social.signal }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.signal', 'Signal')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'telegram']"></fa-icon></span>
          <span class="key">Telegram</span>
          <span class="value">{{ org.profileData.social.telegram }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.telegram', 'Telegram')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'twitter']"></fa-icon></span>
          <span class="key">X (ehem. Twitter)</span>
          <span class="value">{{ org.profileData.social.twitter }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.twitter', 'Twitter')"></fa-icon-btn></span>

          <!--<span class="icon"><fa-icon [icon]="['fab', 'mastodon']"></fa-icon></span>
          <span class="key">Mastodon</span>
          <span class="value">{{ org.profileData.social.mastodon }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.mastodon', 'Mastodon')"></fa-icon-btn></span>
-->
<!--          <span class="icon"><fa-icon [icon]="['fab', 'tumblr']"></fa-icon></span>
          <span class="key">Tumblr</span>
          <span class="value">{{ org.profileData.social.tumblr }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.tumblr', 'Tumblr')"></fa-icon-btn></span>-->

          <span class="icon"><fa-icon [icon]="['fab', 'pinterest']"></fa-icon></span>
          <span class="key">Pinterest</span>
          <span class="value">{{ org.profileData.social.pinterest }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.pinterest', 'Pinterest')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'linkedin']"></fa-icon></span>
          <span class="key">LinkedIn</span>
          <span class="value">{{ org.profileData.social.linkedIn }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.linkedIn', 'LinkedIn')"></fa-icon-btn></span>

          <span class="icon"><fa-icon [icon]="['fab', 'xing']"></fa-icon></span>
          <span class="key">Xing</span>
          <span class="value">{{ org.profileData.social.xing }}</span>
          <span class="extra"><fa-icon-btn icon="pen-to-square" buttonSize="22" iconSize="10"
                                           (btnClick)="editProfileField('profileData.social.xing', 'Xing')"></fa-icon-btn></span>

        </div>
      </nz-card>

      <nz-card class="card-comment" nzTitle="Kommentar" [nzExtra]="comment_extra">
        <div #commentBox *ngIf="commentEdit === null" class="commentbox">{{ org.comment }}</div>
        <textarea *ngIf="commentEdit !== null" nz-input [(ngModel)]="commentEdit"
                  [style.min-height]="commentMinHeight"></textarea>
        <ng-template #comment_extra>
          <div style="display: flex">
            <fa-icon-btn *ngIf="commentEdit === null" nzType="default" iconSize="12" buttonSize="26" icon="edit"
                         (btnClick)="editComment()" style="margin-left: 4px"></fa-icon-btn>
            <fa-icon-btn *ngIf="commentEdit !== null" nzType="primary" [loading]="commentSaving" iconSize="12"
                         buttonSize="26" icon="save" (btnClick)="saveComment()" style="margin-left: 4px"></fa-icon-btn>
            <fa-icon-btn *ngIf="commentEdit !== null" nzType="primary" [nzDanger]="true" iconSize="12" buttonSize="26"
                         icon="close" (btnClick)="abortComment()" style="margin-left: 4px"></fa-icon-btn>
          </div>
        </ng-template>
      </nz-card>

      <!--  <nz-card class="card-orglinks" nzTitle="Verknüpfte Entitäten" [nzExtra]="orglinks_extra">

          <div class="orglink-list">
            <ng-container *ngFor="let link of org.linkedOrganizations">
              <div class="orglink-entry">
                <span class="rawid"   *ngIf="!orgCache.has(link)">{{link}}</span>
                <span class="orgname" *ngIf=" orgCache.has(link)">{{orgCache.get(link)?.name ?? 'ERROR'}}</span>
                <fa-icon-btn [nzDanger]="true" nz-tooltip="Löschen" buttonSize="24" iconSize="14" icon="trash" [loading]="deleteOrgLinkLoading.has(link)" (click)="deleteOrgLink(link)"></fa-icon-btn>
              </div>
            </ng-container>

            <ng-container *ngIf="org.linkedOrganizations.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

            <fa-icon-btn icon="plus" nzType="default" text="Link Hinzufügen" [loading]="addLinkLoading" (click)="showAddLink=true"></fa-icon-btn>
          </div>

          <ng-template #orglinks_extra>

          </ng-template>
        </nz-card>-->

        <!--opening hours-->
        <nz-card class="card-openinghours" nzTitle="Öffnungszeiten" [nzExtra]="opening_hours">
            <ng-template #opening_hours>
                <div style="display: flex">
                  <fa-icon-btn nzType="default" iconSize="12" buttonSize="26" icon="edit"
                               (btnClick)="showEditOpeningHoursModal = true" style="margin-left: 4px"></fa-icon-btn>
                </div>
              </ng-template>

              <div *ngIf="org !== null">
                <div *ngIf="org.openingHours !== null">
                    <div *ngFor="let openingTime of org.openingHours.openTimes" class="row">
                        <div style="width: 6rem">{{ dayMap.get(openingTime.day)}}</div>
                        <div style="width: 7rem">von: {{openingTime.open | date:'HH:mm:ss'}}</div>
                        <div>bis: {{openingTime.close | date:'HH:mm:ss'}}</div>
                    </div>
                </div>
            </div>
        </nz-card>

      <nz-card class="card-files" nzTitle="Dateien" [nzLoading]="loadingFiles" [nzExtra]="filesExtra">
        <ng-template #filesExtra>

          <fa-icon-btn nzType="primary"
                       text="Datei hochladen"
                       buttonSize="32"
                       icon="plus"
                       (btnClick)="addFile()"></fa-icon-btn>

        </ng-template>

        <div class="filelist">

          <ng-container *ngFor="let blob of files">

            <div class="file" (click)="downloadFile(blob)">

              <fa-icon-btn *ngIf="blob.data.comment !== ''" class="btn-showcomm" nzType="default"
                           [stopPropagation]="true" iconSize="12" buttonSize="26" icon="info"
                           (btnClick)="showFileComment(blob)"></fa-icon-btn>
              <fa-icon-btn class="btn-edit" nzType="primary" [stopPropagation]="true" iconSize="12" buttonSize="26"
                           icon="edit" (btnClick)="editFile(blob)"></fa-icon-btn>
              <fa-icon-btn class="btn-delete" nzType="primary" [nzDanger]="true" [stopPropagation]="true" iconSize="12"
                           buttonSize="26" icon="trash" (btnClick)="deleteFile(blob)"></fa-icon-btn>

              <span class="fileimage" nz-icon [nzType]="getMimeIcon(blob)" nzTheme="outline"></span>

              <div class="footer">
                <span class="filename"
                      [nz-tooltip]="blob.data.filename">{{ blob.data.filename === '' ? '&nbsp;' : blob.data.filename }}</span>
                <span class="filedate">{{ blob.creationTime | displaydate }}</span>
              </div>

            </div>

          </ng-container>

          <ng-container *ngIf="files.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>

        </div>

      </nz-card>

      <nz-card class="card-convnotes" nzTitle="Gesprächsnotizen" [nzLoading]="loadingConvNotes"
               [nzExtra]="convNotesExtra">
        <ng-template #convNotesExtra>

          <fa-icon-btn nzType="primary"
                       text="Gesprächsnotiz erstellen"
                       buttonSize="32"
                       icon="plus"
                       (btnClick)="addConvNote()"></fa-icon-btn>

        </ng-template>

        <div class="convnotelist">

          <div *ngFor="let cnote of conversationNotes" class="conversation-note" (click)="showConvNote(cnote)">

            <span class="icon"><i nz-icon nzType="schedule" nzTheme="outline"></i></span>

            <span class="title">{{ cnote.title }}</span>

            <span class="participants">
              <span *ngFor="let pp of cnote.participants">{{ pp }}</span>
            </span>

            <span class="actionbuttons">

              <fa-icon-btn *ngIf="expandedConvNote !== cnote.id" class="btn-show" nzType="primary"
                           [stopPropagation]="true" iconSize="12" buttonSize="26" icon="eye"
                           (btnClick)="showConvNote(cnote)"></fa-icon-btn>
              <fa-icon-btn *ngIf="expandedConvNote === cnote.id" class="btn-show" nzType="primary"
                           [stopPropagation]="true" iconSize="12" buttonSize="26" icon="eye-slash"
                           (btnClick)="showConvNote(cnote)"></fa-icon-btn>
              <fa-icon-btn class="btn-edit" nzType="primary" [stopPropagation]="true" iconSize="12" buttonSize="26"
                           icon="edit" (btnClick)="editConvNote(cnote)"></fa-icon-btn>
              <fa-icon-btn class="btn-delete" nzType="primary" [nzDanger]="true" [stopPropagation]="true" iconSize="12"
                           buttonSize="26" icon="trash" (btnClick)="deleteConvNote(cnote)"></fa-icon-btn>

            </span>

            <span class="date">{{ cnote.date | displaydate:'no-seconds' }}</span>

            <span class="body" *ngIf="expandedConvNote === cnote.id">{{ cnote.body }}</span>

          </div>

          <ng-container *ngIf="conversationNotes.length === 0">
            <nz-empty style="margin: auto;"></nz-empty>
          </ng-container>

        </div>

      </nz-card>

      <nz-card class="card-groups" nzTitle="Gruppen" [nzExtra]="groupsExtra">
        <ng-template #groupsExtra>

          <fa-icon-btn nzType="primary"
                       text="Neue Gruppe erstellen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       (btnClick)="showCreateNewGroup = true"></fa-icon-btn>

        </ng-template>

        <div class="group-list">

          <ng-container *ngIf="groupsLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!groupsLoading">

            <nz-tree
              #groupTree
              [nzData]="groups" nzDraggable nzShowLine
              [nzShowExpand]="false" (nzOnDrop)="nzEvent($event)" [nzTreeTemplate]="treeTest"></nz-tree>

            <ng-template #treeTest let-group let-origin="origin">
              <div class="custom-node">
                <!--         Parent: {{origin.group.parentGroupID}}
                         Sort: {{origin.group.position}}-->
                <app-list-entry-group
                  [group]="origin.group"
                  (groupUpdated)="onGroupUpdated($event)"
                  (groupDeleted)="onGroupDeleted($event)"
                  (groupActivated)="onGroupActivated($event)"
                ></app-list-entry-group>
              </div>
            </ng-template>

            <!--<app-list-entry-group *ngFor="let group of groups"
                                  [group]="group"
                                  (groupUpdated)="onGroupUpdated($event)"
                                  (groupDeleted)="onGroupDeleted($event)"></app-list-entry-group>-->

            <ng-container *ngIf="groups.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

          </ng-container>

        </div>
      </nz-card>

      <nz-card class="card-events" nzTitle="Veranstaltungen" [nzExtra]="eventsExtra">
        <ng-template #eventsExtra>

          <fa-icon-btn nzType="primary"
                       text="Neue Veranstaltung erstellen"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       (btnClick)="showCreateNewEvent = true"></fa-icon-btn>

        </ng-template>

        <div class="event-list">

          <ng-container *ngIf="eventsLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!eventsLoading">

            <app-list-entry-event *ngFor="let event of events"
                                  [event]="event"
                                  (eventUpdated)="onEventUpdated($event)"
                                  (eventDeleted)="onEventDeleted($event)"
                                  (eventToggleStatus)="onEventToggleStatus($event)"></app-list-entry-event>

            <ng-container *ngIf="events.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

          </ng-container>

        </div>
      </nz-card>

      <nz-card class="card-posts" nzTitle="Letzte Posts" [nzExtra]="postsExtra">
        <ng-template #postsExtra>

          <fa-icon-btn
            nzType="primary"
            text="Neuen Post erstellen"
            iconSize="18" buttonSize="32" textSize="16"
            icon="note-sticky"
            (btnClick)="showCreateNewPost = true"></fa-icon-btn>

        </ng-template>

        <div class="post-list">

          <ng-container *ngIf="postsLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!postsLoading">

            <app-list-entry-post *ngFor="let post of posts"
                                 [post]="post"
                                 (postUpdated)="onPostUpdated($event)"
                                 (postDeleted)="onPostDeleted($event)"></app-list-entry-post>

            <ng-container *ngIf="posts.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

          </ng-container>

        </div>
      </nz-card>

      <nz-card *ngIf="org?.type === 'COMMUNITY'" class="card-waste-calendar" nzTitle="Müllkalender"
               [nzExtra]="wasteCalendarExtra">

        <ng-template #wasteCalendarExtra>

          <fa-icon-btn nzType="primary"
                       text="Ortsteile verwalten"
                       iconSize="18" buttonSize="32" textSize="16"
                       icon="note-sticky"
                       (btnClick)="showManageDistrictsModal = true"></fa-icon-btn>

        </ng-template>

        <div class="waste-calendar-list">

          <ng-container *ngIf="orgusersLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!orgusersLoading">

            <nz-list nzBordered>
              <ng-container *ngFor="let street of wasteCalendarStreets">
                <nz-list-item>
                  <div class="header">
                    <span style="display: flex; flex-direction: row; gap: .5rem; align-items: center">
                      <fa-icon [icon]="['fas', 'road']" size="sm"/><h6 class="month"> {{ street.name }}
                    </h6><small>[{{ getWasteCalendarStreetCount(street) }}]</small>
                    </span>
                    <fa-icon-btn
                      nzType="default"
                      buttonSize="24"
                      iconSize="14"
                      icon="chevron-down"
                      (btnClick)="toggleStreetCalendarEvents(street)"></fa-icon-btn>
                  </div>
                </nz-list-item>
                <nz-list-item class="street-events" *ngIf="showCalendarEvents(street)">
                  <div *ngIf="isCalendarEventsLoading(street)">
                    Loading....
                  </div>
                  <ng-container *ngIf="!isCalendarEventsLoading(street) && street.calendar">
                    <ng-container *ngIf="street.calendar!.events.length === 0">
                      Keine Einträge vorhanden
                    </ng-container>
                    <ng-container *ngIf="street.calendar!.events.length > 0">
                      <div *ngFor="let event of street.calendar!.events">
                        <div class="date">
                          <span>{{ event.DTStart | casttodate: 'YYYYMMDD' | date: 'EEE, d MMM YYYY' }}</span>
                        </div>
                        <div class="event-header">
                          <h4>{{ event.Summary }}</h4>
                          <p class="description">{{ event.Description }}</p>
                        </div>
                      </div>
                    </ng-container>
                  </ng-container>
                </nz-list-item>
              </ng-container>
            </nz-list>
          </ng-container>

        </div>

      </nz-card>

      <nz-card class="card-orgusers" nzTitle="Publishing-User">

        <div class="orguser-list">

          <ng-container *ngIf="orgusersLoading">
            <nz-spin style="margin: auto;" [nzSize]="'large'"></nz-spin>
          </ng-container>

          <ng-container *ngIf="!orgusersLoading">

            <app-list-entry-orguser *ngFor="let orguser of orgusers" [orguser]="orguser"
                                    urlprefix="/admin/orgusers"></app-list-entry-orguser>

            <app-pagination-button *ngIf="orgusers.length > 0 && orgusersNextToken !== '@end'"
                                   [nextToken]="orgusersNextToken"
                                   (click)="loadOrgUsers(false)"></app-pagination-button>

            <ng-container *ngIf="orgusers.length === 0">
              <nz-empty style="margin: auto;"></nz-empty>
            </ng-container>

          </ng-container>

        </div>

      </nz-card>

    </div>

  </main>

</app-default-frame>
