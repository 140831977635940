import { Pipe, PipeTransform } from '@angular/core';
import {Seconds} from "../interfaces/datetime";

@Pipe({ name: 'displayduration', pure: true })
export class DisplaydurationPipe implements PipeTransform {

  transform(value: Seconds, ...args: unknown[]): unknown {
    if (value < 10)     return `${value.toPrecision(2)} seconds`
    if (value < 100)    return `${value.toFixed(0)} seconds`

    if (value < 60*10)  return `${(value/60).toPrecision(2)} minutes`
    if (value < 60*100) return `${(value/60).toFixed(0)} minutes`

    if (value < 60*60*10)  return `${(value/3600).toPrecision(2)} hours`
    if (value < 60*60*100) return `${(value/3600).toFixed(0)} hours`

    if (value < 24*60*60*10)  return `${(value/86400).toPrecision(2)} days`
    if (value < 24*60*60*100) return `${(value/86400).toFixed(0)} days`

    return `@@${value}`;
  }

}
