import { Component } from '@angular/core';

import {FaConfig, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import * as faSolid from '@fortawesome/pro-solid-svg-icons';
import * as faBrand from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  constructor(library: FaIconLibrary, faConfig: FaConfig) {

    //library.addIconPacks(fas, far, fad, fal, fat, fab);

    library.addIcons(
      faSolid.faBan,
      faSolid.faCalendarDays,
      faSolid.faCaretUp,
      faSolid.faCaretDown,
      faSolid.faClock,
      faSolid.faClose,
      faSolid.faEdit,
      faSolid.faPaperPlane,
      faSolid.faEnvelope,
      faSolid.faEye,
      faSolid.faEyeSlash,
      faSolid.faFloppyDisk,
      faSolid.faGauge,
      faSolid.faGear,
      faSolid.faGlobe,
      faSolid.faHashtag,
      faSolid.faIdCard,
      faSolid.faIdCardClip,
      faSolid.faImage,
      faSolid.faInfo,
      faSolid.faInputText,
      faSolid.faKey,
      faSolid.faLoader,
      faSolid.faLocationDot,
      faSolid.faMessages,
      faSolid.faMoneyCheckDollar,
      faSolid.faNoteSticky,
      faSolid.faPen,
      faSolid.faPenToSquare,
      faSolid.faPhone,
      faSolid.faPlus,
      faSolid.faRightFromBracket,
      faSolid.faRobot,
      faSolid.faSave,
      faSolid.faSignature,
      faSolid.faTrash,
      faSolid.faUser,
      faSolid.faUserGear,
      faSolid.faUserTie,
      faSolid.faXmark,
      faSolid.faCircleExclamation,
      faSolid.faHammer,
      faSolid.faBriefcase,
      faSolid.faCloudArrowDown,
      faSolid.faLink,
      faSolid.faAlignLeft,
      faSolid.faFilmSimple,
      faSolid.faImages,
      faSolid.faArrowUpRightFromSquare,
      faSolid.faArrowRight,
      faSolid.faBuilding,
      faSolid.faFrame,
      faSolid.faDiceSix,
      faSolid.faLink,
      faSolid.faPlanetMoon,
      faSolid.faCalendarStar,
      faSolid.faMagnifyingGlass,
      faSolid.faFile,
      faSolid.faLinkSlash,
      faSolid.faCalendarDays,
      faSolid.faCroissant,
      faSolid.faArrowUp,
      faSolid.faArrowDown,
      faSolid.faWrenchSimple,
      faSolid.faGhost,
      faSolid.faPersonToDoor,
      faSolid.faShirt,
      faSolid.faWandMagicSparkles,
      faSolid.faArrowDownAZ,
      faSolid.faShieldKeyhole,
      faSolid.faUniversalAccess,
      faSolid.faChurch,
      faSolid.faArrowsRotate,
      faSolid.faRoad,
      faSolid.faFileArrowUp,
      faSolid.faChevronDown,
      faSolid.faVideoArrowDownLeft
      )

    library.addIcons(
      faBrand.faWhatsapp,
      faBrand.faFacebook,
      faBrand.faSquareGooglePlus,
      faBrand.faTiktok,
      faBrand.faInstagram,
      faBrand.faYoutube,
      faBrand.faTwitter,
      faBrand.faTelegram,
      faBrand.faTwitter,
      faBrand.faMastodon,
      faBrand.faTumblr,
      faBrand.faPinterest,
      faBrand.faLinkedin,
      faBrand.faXing,
      faBrand.faWhatsapp,
      faBrand.faFacebook,
      faBrand.faSquareGooglePlus,
      faBrand.faTiktok,
      faBrand.faInstagram,
      faBrand.faYoutube,
      faBrand.faTwitter,
      faBrand.faTelegram,
      faBrand.faTwitter,
      faBrand.faMastodon,
      faBrand.faTumblr,
      faBrand.faPinterest,
      faBrand.faLinkedin,
      faBrand.faXing,
    )

    faConfig.defaultPrefix = 'fas';
    faConfig.fixedWidth = true;
  }
}
