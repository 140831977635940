

<app-no-frame>

  <main>

    <div class="loading-box" *ngIf="loading">

      <fa-icon icon="loader" [spin]="true" style="font-size: 96px; opacity: 0.5"></fa-icon>

    </div>

    <ng-container *ngIf="error !== ''">

      <div style="display: grid; grid-template-rows: auto auto; align-items: center;">

        <fa-icon style=" font-size: 16rem; text-align: center; color: #E0282E" class="menu-icn" [icon]="['fas', 'circle-exclamation']" ></fa-icon>
        <span    style=" font-size: 2rem;  text-align: center;               ">{{error}}</span>
      </div>

    </ng-container>

    <div class="setpwform" *ngIf="!loading">

    <span class="header">
      <img ngSrc="/assets/bo_logo.png" width="500" height="401" alt="logo" style="height:4em; width:4em">
      <h1 style="margin-bottom: 0; align-self: center; margin-left: 0.661em;">Passwort setzen</h1>
    </span>

      <nz-alert *ngIf="setErrorText != ''" nzType="error" [nzMessage]="setErrorText"></nz-alert>

      <nz-input-group [nzAddOnBefore]="prefix0">
        <ng-template #prefix0>
          <span [style.display]="'inline-flex'" [style.min-width]="'135px'">Benutzername</span>
        </ng-template>
        <input type="text" [ngModel]="username ?? '???'" nz-input placeholder="Email" [disabled]="true" />
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix1">
        <ng-template #prefix1>
          <span [style.display]="'inline-flex'" [style.min-width]="'135px'">Passwort</span>
        </ng-template>
        <app-validateable-input type="password" [(value)]="password1" [validate]="isValidPW1()" placeholder="Password" ></app-validateable-input>
      </nz-input-group>

      <nz-input-group [nzAddOnBefore]="prefix2">
        <ng-template #prefix2>
          <span [style.display]="'inline-flex'" [style.min-width]="'135px'">Passwort wiederholen</span>
        </ng-template>
        <app-validateable-input type="password" [(value)]="password2" [validate]="isValidPW2()" placeholder="Password wiederholen" (onKeyboardEnter)="execute()" ></app-validateable-input>
      </nz-input-group>

      <button nz-button class="login-form-button login-form-margin" style="margin-top: 16px" [nzType]="'primary'" [nzLoading]="setPasswordLoading" (click)="execute()">Passwort setzen</button>

    </div>

  </main>

</app-no-frame>

