
<app-default-frame>

  <app-create-event-modal [(show)]="showCreateNewEvent"
                          [orgId]="orgid"
                          (success)="onEventCreated($event)"></app-create-event-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">

      <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Veranstaltungen"></nz-page-header>

      <fa-icon-btn nzType="primary"
                   text="Neue Veranstaltung erstellen"
                   iconSize="18" buttonSize="32" textSize="16"
                   icon="note-sticky"
                   (btnClick)="showCreateNewEvent = true"></fa-icon-btn>

    </div>

    <div class="event-list">

      <app-list-entry-event *ngFor="let event of events"
                            [event]="event"
                            (eventUpdated)="onEventUpdated($event)"
                            (eventDeleted)="onEventDeleted($event)"
                            (eventToggleStatus)="onEventToggleStatus($event)"></app-list-entry-event>

      <app-pagination-button [count]="events.length" [nextToken]="nextToken" (click)="fetchEvents(false)"></app-pagination-button>

    </div>

  </main>

</app-default-frame>

