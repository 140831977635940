<div class="base">

  <textarea nz-input #inputComp
            [placeholder]="placeholder"
            [(ngModel)]="value"
            (ngModelChange)="valueChange.emit($event)"
            (keydown.escape)="onKeyboardEscape.emit($event)"
            [rows]="rows"
            [disabled]="disabled"></textarea>

  <i nz-icon class="icn_validator icn_warn" nzType="warning"      *ngIf="validate(value) === false" nzTheme="outline"></i>
  <i nz-icon class="icn_validator icn_okay" nzType="check-square" *ngIf="validate(value) === true"  nzTheme="outline"></i>

</div>
