<nz-modal [nzVisible]="show"
          nzTitle="Neuer ContentProvider & Benutzer anlegen"
          nzOkText="Erstellen"
          [nzOkLoading]="loading"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div class="content">

      <span class="key">Publishing-User Name</span>
      <div class="input-block name">
        <app-validateable-input placeholder="Max"        [preventAutofill]="true" [(value)]="firstName" [validate]="isValidStr"   ></app-validateable-input>
        <app-validateable-input placeholder="Mustermann" [preventAutofill]="true" [(value)]="lastName"  [validate]="isValidStr"   ></app-validateable-input>
      </div>

      <span class="key">CP-Admin Benutzername</span>
      <app-validateable-input placeholder="Max1994" [preventAutofill]="true" [(value)]="username"  [validate]="isValidStr"   ></app-validateable-input>

      <span class="key">CP-Admin Mail</span>
      <app-validateable-input placeholder="local@domain.tld" [preventAutofill]="true" [(value)]="mail" [validate]="isValidMail"></app-validateable-input>

      <span class="spacer"></span>

      <span class="key">ContentProvider Name</span>
      <app-validateable-input placeholder="ContentProvider Name" [preventAutofill]="true" [(value)]="orgName" [validate]="isValidStr"></app-validateable-input>

      <span class="key">ContentProvider Typ</span>
      <nz-select class="value" nzAllowClear nzPlaceHolder="Typ auswählen" [(ngModel)]="orgType">
        <nz-option nzLabel="Kommune"    [nzValue]="'COMMUNITY'"></nz-option>
        <nz-option nzLabel="Verein"      [nzValue]="'ASSOCIATION'"></nz-option>
        <nz-option nzLabel="Unternehmen" [nzValue]="'BUSINESS'"></nz-option>
        <nz-option nzLabel="Künstler"    [nzValue]="'ARTIST'"></nz-option>
        <nz-option nzLabel="Presse"      [nzValue]="'PRESS'"></nz-option>
      </nz-select>

      <ng-container *ngIf="orgType !== null">
        <span class="key" *ngIf="orgType === 'COMMUNITY'">Art der Gemeinde</span>
        <span class="key" *ngIf="orgType === 'ASSOCIATION'">Art des Vereins</span>
        <span class="key" *ngIf="orgType === 'BUSINESS'">Branche</span>
        <span class="key" *ngIf="orgType === 'ARTIST'">Ausrichtung</span>
        <span class="key" *ngIf="orgType === 'PRESS'">Branche</span>
        <input class="value" nz-input [(ngModel)]="orgPurpose" [nzAutocomplete]="auto"/>
        <nz-autocomplete [nzDataSource]="purposes" nzBackfill #auto></nz-autocomplete>
      </ng-container>

      <span class="spacer"></span>

      <span class="key">ContentProvider Kundennummer</span>
      <app-validateable-input placeholder="0000-0000-0000" [preventAutofill]="true" [(value)]="customernumber" [validate]="isValidCustomerNumber"  ></app-validateable-input>

      <span class="key">ContentProvider Addresse</span>
      <div class="input-block address">
        <app-validateable-input class="plz"     [preventAutofill]="true" [mintitlewidth]="65" inputtitle="PLZ"        placeholder="00000"       [(value)]="addressPLZ"     ></app-validateable-input>
        <app-validateable-input class="city"    [preventAutofill]="true" [mintitlewidth]="65" inputtitle="Stadt"      placeholder="Stadt"       [(value)]="addressCity"    ></app-validateable-input>
        <app-validateable-input class="street"  [preventAutofill]="true" [mintitlewidth]="65" inputtitle="Straße"     placeholder="Straße"      [(value)]="addressStreet"  ></app-validateable-input>
        <app-validateable-input class="num"     [preventAutofill]="true" [mintitlewidth]="65" inputtitle="Hausnmr."   placeholder="Nummer"      [(value)]="addressNumber"  ></app-validateable-input>
        <app-validateable-input class="extra"   [preventAutofill]="true" [mintitlewidth]="65" inputtitle="Extra"      placeholder=""            [(value)]="addressExtra"   ></app-validateable-input>
      </div>

      <span class="key">ContentProvider Bankverbindung</span>
      <div class="input-block banking">
        <app-validateable-input class="iban" [preventAutofill]="true" [mintitlewidth]="65" inputtitle="IBAN"    placeholder="XX00-00000-00000000000"  [(value)]="bankingIban"   [validate]="isValidIBAN"  ></app-validateable-input>
        <app-validateable-input class="name" [preventAutofill]="true" [mintitlewidth]="65" inputtitle="Inhaber" placeholder="Kontoinhaber"            [(value)]="bankingName"                             ></app-validateable-input>
      </div>

      <span class="key">ContentProvider Telefonnummer</span>
      <app-validateable-input placeholder="0000/00000000" [preventAutofill]="true" [(value)]="telephonenumber" [validate]="isValidTelephone"  ></app-validateable-input>

      <span class="spacer"></span>

      <span class="key" [style.opacity]="sendPasswordMail ? 0.5 : 1.0">CP-Admin Passwort</span>
      <div style="display: grid; grid-template-columns: 1fr auto auto; grid-column-gap: 0.5rem">
        <app-validateable-input class="pw1" [preventAutofill]="true" placeholder="" [type]="showPW ? 'text' : 'password'" [disabled]="sendPasswordMail" [(value)]="password1" [validate]="isValidPW1()" ></app-validateable-input>
        <fa-icon-btn icon="dice-six" nz-tooltip="Zufälliges Passwort generieren" buttonSize="32"  iconSize="17" (btnClick)="randomPassword()"></fa-icon-btn>
        <fa-icon-btn icon="eye"  buttonSize="32"  iconSize="17" (btnClick)="showPW = !showPW"></fa-icon-btn>
      </div>

      <span class="key" [style.opacity]="sendPasswordMail ? 0.5 : 1.0">Passwort wiederholen</span>
      <app-validateable-input class="pw2" placeholder="" [preventAutofill]="true" [type]="showPW ? 'text' : 'password'" type="password" [disabled]="sendPasswordMail" [(value)]="password2" [validate]="isValidPW2()" ></app-validateable-input>

      <span class="key"><label nz-checkbox  class="pwmail" [(ngModel)]="sendPasswordMail">Registrierungs Mail senden</label></span>

    </div>
  </ng-container>
</nz-modal>
