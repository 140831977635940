import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {AuthenticationService} from "../../services/authentication.service";
import {AdminUser} from "../../interfaces/adminUser";
import {formatAPIError, isErrorCode, showAPIError} from "../../utils/api";
import {Router} from "@angular/router";
import {PlatformLocation} from "@angular/common";
import {OrganizationUser} from "../../interfaces/organizationUser";
import {Title} from "@angular/platform-browser";
import {SidebarService} from "../../services/sidebar.service";
import {NzSiderComponent} from "ng-zorro-antd/layout";
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import {skip} from "rxjs";
import {ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-default-frame',
  templateUrl: './default-frame.component.html',
  styleUrls: ['./default-frame.component.scss']
})
export class DefaultFrameComponent implements OnInit {
  userid: ObjectId|null = null;
  orgid: ObjectId|null = null;

  userType: 'ADMIN_USER'|'ORGANIZATION_USER'|'ERROR' = 'ERROR';

  adminUser: AdminUser|null = null;
  orgUser: OrganizationUser|null = null;
  isImpersonating: boolean = false;

  constructor(private api: APIService,
              private breakpointObserver: BreakpointObserver,
              private sidebar: SidebarService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private auth: AuthenticationService,
              private title: Title) {

  }

  ngOnInit(): void {
    this.userid = this.auth.getSelfID();
    this.orgid = this.auth.getSelfOrgID();
    this.isImpersonating = this.auth.isImpersonating();

    if (this.userid != null) {
      if (this.auth.isAdminUser()) {

        this.title.setTitle("baden online - Adminpanel");

        const _self = this.auth.getSelf();
        if (_self !== null) {
          this.adminUser = _self as AdminUser;
        } else {
          this.updateAdminUser().then(() => {});
        }
        this.userType = "ADMIN_USER";

      } else if (this.auth.isOrgUser()) {

        this.title.setTitle("baden online - Panel");

        const _self = this.auth.getSelf();
        if (_self !== null) {
          this.orgUser = _self as OrganizationUser;
        } else {
          this.updateOrgUser().then(() => {});
        }
        this.userType = "ORGANIZATION_USER";

      }
    } else {
      this.title.setTitle("baden online");
    }

    this.breakpointObserver
      .observe(['(max-width: 768px)'])
      .pipe(skip(1))
      .subscribe((state: BreakpointState) => { console.log('breakpoint: ' + state.matches); this.setCollapsed(state.matches); });
  }

  private async updateAdminUser() {
    try {
      const data = await this.api.getAdmin(this.userid!);
      this.adminUser = data;
      this.auth.setSelf(this.userid!, data);
    } catch (err) {
      showAPIError(this.notification, 'Der Benutzer konnte nicht geladen werden', err)
    }
  }

  private async updateOrgUser() {
    try {
      const data = await this.api.getOrgUser(this.orgid!, this.userid!);
      this.orgUser = data;
      this.auth.setSelf(this.userid!, data);
    } catch (err) {
      showAPIError(this.notification, 'Der Benutzer konnte nicht geladen werden', err)
    }
  }

  async logout() {

    this.auth.clearAuth();
    this.notification.success('Abgemeldet', 'Du wurdest erfolgreich abgemeldet');
    await this.router.navigate(['/login'], {queryParams: {}});

  }

  isRoot() {
    return (this.platformLocation.pathname === '/org') || (this.platformLocation.pathname === '/admin');
  }

  isCollapsed() {
    return this.sidebar.isCollapsed;
  }

  setCollapsed(v: boolean) {
    this.sidebar.isCollapsed = v;
  }

  async stopImpersonating() {
    const oldURL = this.auth.stopImpersonating();
    await this.router.navigate([oldURL])
  }
}
