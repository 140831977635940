
<app-default-frame>

  <app-create-group-modal [(show)]="showCreateNewGroup"
                          [forcedOrgId]="orgid"
                          (success)="onGroupCreated($event)"></app-create-group-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">

      <nz-page-header class="site-page-header" nzTitle="baden online Panel" nzSubtitle="Gruppen"></nz-page-header>

      <fa-icon-btn nzType="primary"
                   text="Neue Gruppe erstellen"
                   iconSize="18" buttonSize="32" textSize="16"
                   icon="note-sticky"
                   (btnClick)="showCreateNewGroup = true"></fa-icon-btn>


    </div>

    <div class="group-list">

      <app-list-entry-group *ngFor="let group of groups" [group]="group" (groupUpdated)="onGroupUpdated($event)"></app-list-entry-group>

      <app-pagination-button [count]="groups.length" [nextToken]="nextToken" (click)="fetchGroups(false)"></app-pagination-button>

    </div>

  </main>

</app-default-frame>
