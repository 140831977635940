
<app-default-frame>

  <main>

    <app-create-admin-modal        [(show)]="showCreateAdminModal" (success)="onAdminCreated($event)" ></app-create-admin-modal>
    <app-edit-admin-modal          [(show)]="selectedEditAdmin"    (success)="onAdminEdited($event)"  ></app-edit-admin-modal>
    <app-edit-admin-password-modal [(show)]="selectedEditPassword" (success)="onAdminEdited($event)"  ></app-edit-admin-password-modal>

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary" text="Neuen Administrator erstellen" (btnClick)="showCreateAdminModal = true"></fa-icon-btn>
      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="users"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Erstellt am</th>
        <th>Name</th>
        <th>Username</th>
        <th>Mail</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of users">

        <td>{{ data.id | shortid }}</td>

        <td>{{data.creationTime | displaydate}}</td>

        <td><span class="">{{data.firstName + ' ' + data.lastName}}</span></td>

        <td><span class="">{{data.username}}</span></td>

        <td><a href="mailto:{{data.email}}">{{ data.email }}</a></td>

        <td class="actionlist">
          <fa-icon-btn nzType="default"                   nz-tooltip="Administrator bearbeiten" buttonSize="24"  iconSize="14" icon="edit"  (btnClick)="selectedEditAdmin = data"   ></fa-icon-btn>
          <fa-icon-btn nzType="default"                   nz-tooltip="Passwort ändern"          buttonSize="24"  iconSize="14" icon="key"   (btnClick)="selectedEditPassword = data"></fa-icon-btn>
          <fa-icon-btn nzType="default" [nzDanger]="true" nz-tooltip="Benutzer löschen"         buttonSize="24"  iconSize="14" icon="trash" (btnClick)="deleteAdmin(data)" ></fa-icon-btn>
        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

