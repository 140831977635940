
<app-default-frame>
  <div style="display: flex; justify-content: center; align-items: center; min-height: 100%">

    <div style="display: grid; grid-template-rows: auto auto; align-items: center;">
      <fa-icon style=" font-size: 16rem; text-align: center; color: #E0282E" class="menu-icn" [icon]="['fas', 'ban']" ></fa-icon>
      <span    style=" font-size: 2rem;  text-align: center;               ">Keine Berechtigung zum Zugriff auf diese Seite</span>
    </div>

  </div>
</app-default-frame>

