<nz-modal [nzVisible]="show && orgId !== null"
          nzTitle="Neuer Anhang hochladen"
          nzOkText="Speichern"
          [nzOkLoading]="loading"
          [nzOkDisabled]="file === null"
          (nzOnOk)="execute()"
          (nzOnCancel)="close()">
  <ng-container *nzModalContent>
    <div *ngIf="orgId !== null" class="content">

      <nz-upload nzType="drag" [nzMultiple]="false" [nzBeforeUpload]="beforeUpload">
        <p class="ant-upload-drag-icon">
          <span nz-icon [nzType]="file === null ? 'upload' : 'file'"></span>
        </p>
        <p class="ant-upload-text">Klicke hier oder ziehe eine Datei in diesen Bereich zum hochladen</p>
        <p *ngIf="file !== null && file.name !== undefined" class="ant-upload-hint">{{file.name}}</p>
      </nz-upload>

    </div>
  </ng-container>
</nz-modal>
