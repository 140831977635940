import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { APIService } from 'src/app/services/api.service';
import { ObjectId } from 'src/app/interfaces/utils';
import { Event } from 'src/app/interfaces/event';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { DatePipe, Location } from '@angular/common';
import { DateUtils } from 'src/app/utils/date';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-event-details',
  templateUrl: './event-details.component.html',
  styleUrl: './event-details.component.scss'
})
export class EventDetailsComponent {

    eventId!: ObjectId;
    orgid: ObjectId | null = null;
    event: Event | null = null;
    showCreateNewPostModal: boolean = false;
    editDataActive: boolean = false;
    editDescriptionActive: boolean = false;
    startDate: Date = new Date();
    endDate: Date = new Date();
    creationDate: Date = new Date();

    constructor(private activatedRoute: ActivatedRoute,
                private auth: AuthenticationService,
                private api: APIService,
                private location: Location
            ) { }

    async ngOnInit() {
        try{
            this.eventId = this.activatedRoute.snapshot.params['id'];
            this.orgid = this.activatedRoute.snapshot.params['orgid'];
            console.log("EVENT ID: " + this.eventId);
            console.log("ORG ID: " + this.orgid);
            if(this.eventId === null) { return; }
            if(this.orgid === null) { return; }

            const data = await this.api.getOrgEvent(this.orgid, this.eventId);
            console.log(data);
            this.event = data;

            this.startDate = new Date(this.event.startTime);
            this.endDate = new Date(this.event.endTime);
            this.creationDate = new Date(this.event.creationTime);

            console.log(this.startDate);
            console.log(this.endDate);
            console.log(this.creationDate);

        }catch(err){
            console.error(err);
            return;
        }
    }

    async changeEventActive(event: Event|null, enable: boolean) {
        if(event === null) { return; }
        if(this.orgid === null) { return; }

        this.event = await this.api.updateEvent(this.orgid, this.eventId, {'enabled': enable}, null)

    }

    reloadPage() {
        this.location.go(this.location.path());
        window.location.reload();
    }

    editEventData() {
        this.editDataActive = !this.editDataActive;
    }

    saveEventDescription() {
        if(this.event === null) { return; }
        if(this.orgid === null) { return; }

        console.log("--------EVENT----------");
        console.log(this.event);
        console.log(this.event.description);

        this.api.updateEvent(this.orgid, this.eventId, {'description': this.event.description}, null);
    }

    saveEventData() {
        if(this.event === null) { return; }
        if(this.orgid === null) { return; }

        //this.event.startTime = DateUtils.fparseRFC3339(this.startDate.toISOString(), 'yyyy-MM-ddTHH:mm:ssZ');
        //this.event.endTime = DateUtils.fparseRFC3339(this.endDate.toISOString(), 'yyyy-MM-ddTHH:mm:ssZ');

        console.log(this.event);

        this.api.updateEvent(this.orgid, this.eventId, this.event, null);
    }

    isValidString(): (v: string) => boolean|null {
        return (v: string) => {
            if(v === null || v === undefined || v === '') { return false; }
            return true;
        }
    }

    isValidNum(): (v: string) => boolean|null {
        return (v: string) => {
            if(v === null || v === undefined || v === '') { return false; }
            if(isNaN(Number(v))) { return false; }
            return true;
        }
    }

    isValidLink(val: string){
        return /^(https?:\/\/).+\..+$/.test(val);
    }

  avatarSource() {
    if (this.event === null) return undefined;
    if (this.event.backgroundImageID === null) return undefined;

    // blobid param is not used in backend, but is useful to trigger reload when avatar has changed
    return `${environment.apiBaseUrl}organizations/${this.event.organizationID}/events/${this.event.id}/background-image?xx-bearer-token=@${this.auth.getToken()}`;
  }
}
