
<app-default-frame>

  <app-create-event-modal [orgId]="orgId" [show]="isCreateEventModalVisible" (showChange)="isCreateEventModalVisible = false" (success)="fetchData(true)"></app-create-event-modal>

  <div class="fullpageloader" *ngIf="loading">
    <nz-spin></nz-spin>
  </div>

  <main *ngIf="!loading">

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary"
                     text="Neue Veranstaltung erstellen"
                     iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky"
                     
                     (btnClick)="openCreateEventModal()"
                     ></fa-icon-btn>

      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="events"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="loading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Name</th>
        <th>Erstellt am</th>
        <th>ContentProvider</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of events" class="rlink" [routerLink]="['/admin/edit-event', data.id, data.organizationID]">

        <td>{{ data.id | shortid }}</td>

        <td>{{ data.title }}</td>

        <td>{{ data.creationTime | displaydate }}</td>

        <td class="rlink" [routerLink]="[data.id]"><a class="nostyle" [routerLink]="'/admin/organizations/' + data.organizationID">{{translateOrgID(data.organizationID)}}</a></td>

        <td class="actionlist">
                <fa-icon-btn nzType="default"
                       nz-tooltip="Veranstaltung bearbeiten"
                       buttonSize="24" iconSize="14"
                       icon="edit"
                       [routerLink]="['/admin/edit-event', data.id, data.organizationID]" 
                       [disabled]="false">
                </fa-icon-btn>

          <fa-icon-btn nzType="default"
                       [nzDanger]="true"
                       nz-tooltip="Veranstaltung löschen"
                       buttonSize="24" iconSize="14"
                       icon="trash"
                       [disabled]="true"></fa-icon-btn>
        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

