
<app-default-frame>

  <div class="fullpageloader" *ngIf="initialLoading">
    <nz-spin></nz-spin>
  </div>

  <app-create-community-modal [(show)]="showCreateNewCommunity" (success)="onCommunityCreated($event)" ></app-create-community-modal>

  <main *ngIf="!initialLoading">

    <div class="action-header">
      <div class="filters">
        <app-search-bar placeholder="Suche..." [value]="filters['search'].active | caststring" (searchTriggered)="onSearch($event)" [showClear]="true" [disabled]="true" ></app-search-bar>
      </div>
      <div class="actions">
        <fa-icon-btn nzType="primary"
                     text="Neue Gemeinde erstellen"
                     iconSize="18" buttonSize="32" textSize="16"
                     icon="note-sticky"
                     (btnClick)="showCreateNewCommunity = true"></fa-icon-btn>
      </div>
    </div>

    <nz-table
      #mainTab
      style="width: 100%"
      [nzData]="communities"
      [nzFrontPagination]="false"
      [nzShowPagination]="false"
      [nzLoading]="tableLoading">

      <thead>
      <tr>
        <th>ID</th>
        <th>Erstellt am</th>
        <th>PLZ</th>
        <th>Name</th>
        <th></th>
      </tr>
      </thead>

      <tbody>
      <tr *ngFor="let data of communities">

        <td>{{ data.id | shortid }}</td>
        <td>{{ data.creationTime | displaydate }}</td>
        <td>{{ data.zip }}</td>
        <td>{{ data.name }}</td>

        <td class="actionlist">

        </td>

      </tr>
      </tbody>

      <tfoot>
      <td colspan="100%">
        <app-pagination-button [linkedTable]="mainTab" [nextToken]="nextToken" (click)="fetchData(false, false)"></app-pagination-button>
      </td>
      </tfoot>

    </nz-table>

  </main>

</app-default-frame>

