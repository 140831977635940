import { Component } from '@angular/core';
import {Group} from "../../interfaces/group";
import {SerializedParamCollection, SerializedParamUtil} from "../../utils/serializedParameter";
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {ActivatedRoute, Router} from "@angular/router";
import {Location, PlatformLocation} from "@angular/common";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {formatAPIError, showAPIError} from "../../utils/api";
import {manipulateURLComponents} from "../../utils/url";
import {environment} from "../../../environments/environment";
import {CursorToken, ObjectId} from "../../interfaces/utils";

@Component({
  selector: 'app-org-group-list',
  templateUrl: './org-group-list.component.html',
  styleUrls: ['./org-group-list.component.scss']
})
export class OrgGroupListComponent {

  groups: Group[] = [];
  nextToken: CursorToken = "@start";
  loading: boolean = true;

  orgid: ObjectId|null = null;
  userid: ObjectId|null = null;

  filters: SerializedParamCollection = {};

  showCreateNewGroup: boolean = false;

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private router: Router,
              private platformLocation: PlatformLocation,
              private activatedRoute: ActivatedRoute,
              private location: Location,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  ngOnInit(): void {
    this.userid = this.auth.getSelfID();
    this.orgid = this.auth.getSelfOrgID();
    this.activatedRoute.queryParams.subscribe(async params => {
      await this.initializeFromQueryParams(params);
    });
  }

  async initializeFromQueryParams(params: any) {
    this.filters = SerializedParamUtil.ParameterFromPath(params, this.filters);
    await this.fetchGroups(true);
  }

  async fetchGroups(reset: boolean) {
    if (this.orgid === null) return;

    if (reset) {
      this.nextToken = '@start';
    }

    try {
      this.loading = true;

      const data = await this.api.listOrgGroups(this.orgid, this.nextToken, 24);

      if (reset) {
        this.groups = data.groups;
      } else {
        this.groups = [...this.groups, ...data.groups];
      }
      this.nextToken = data.nextPageToken;
      this.manipulatePath();

    } catch (err) {
      showAPIError(this.notification, 'Gruppen konnten nicht geladen werden', err)
    } finally {
      this.loading = false;
    }
  }

  manipulatePath(): void {
    this.location.go(manipulateURLComponents(this.router.url, ['org', 'groups'], this.filters));
  }

  onGroupCreated(groups: Group[]) {
    alert('TODO: Implement onGroupCreated');
    //this.groups = [...this.groups, entry];
  }

  onGroupUpdated(newdata: Group | Group[]) {
    alert('TODO: Implement onGroupUpdated in org-group-list.component.ts')
    //this.groups = this.groups.map(g => (g.id === newdata.id) ? newdata : g);
  }
}
