
<app-default-frame>

  <app-edit-crawler-modal [(show)]="editingCrawler"
                          (success)="updateCrawler($event)"
                          (deleteSuccess)="deleteCrawler($event)"
                          (runNowSuccess)="startActiveRefreshMode($event)"></app-edit-crawler-modal>

  <app-create-crawler-modal [(show)]="showAddCrawler"
                            (success)="addCrawler($event)"></app-create-crawler-modal>

  <main>

    <div class="fullpageloader" *ngIf="loading">
      <nz-spin></nz-spin>
    </div>

    <div *ngIf="!loading" class="crawler-header">
      <fa-icon-btn buttonSize="32" icon="arrows-rotate" nzType="primary" [iconAnimation]="(refreshTimer!== null) ? 'spin' : undefined" (btnClick)="switchRefreshMode()"></fa-icon-btn>

      <span class="stretch"></span>

      <fa-icon-btn buttonSize="32" icon="plus" nzType="primary" (btnClick)="showAddCrawler=true" text="Neuen Crawler hinzufügen"></fa-icon-btn>
    </div>

    <div *ngIf="!loading" class="crawler-list">

      <nz-empty *ngIf="crawlers.length === 0" style="margin: auto;"></nz-empty>

      <div class="crawler" *ngFor="let crawler of crawlers">

        <div class="c1">
          <div *ngIf="crawler.enabled &&  crawler.lastRunSuccess" class="status status-okay"    ></div>
          <div *ngIf="crawler.enabled && !crawler.lastRunSuccess" class="status status-error"   ></div>
          <div *ngIf="!crawler.enabled"                           class="status status-disabled"></div>
        </div>

        <div class="c2">
          <span class="name">{{crawler.name}}</span>

          <span class="implementation">{{crawler.implementation}}</span>

          <nz-tag  *ngIf="!crawler.lastRunSuccess" [nzColor]="'red'" class="last-error">{{crawler.lastError === '' ? '&nbsp;' : crawler.lastError}}</nz-tag>
        </div>

        <div class="c3">
          <span class="last-interval-key key">every</span>
          <span class="last-interval-val val">{{crawler.interval | displayduration}}</span>

          <ng-container *ngIf="crawler.nextRun !== null">
            <span class="last-nextrun-key key">next</span>
            <span class="last-nextrun-val val">{{crawler.nextRun | displaydate}}</span>
          </ng-container>

          <span class="totalcount-key key">count</span>
          <span class="totalcount-val val">{{crawler.succesfullRunCount}} / {{crawler.runCount}}</span>

          <span class="enabled-key key">enabled</span>
          <span class="enabled-val val">{{crawler.enabled ? 'true' : 'false'}}</span>
        </div>

        <div class="c4">
          <fa-icon-btn class="icon-edit" nzType="default" iconSize="12" buttonSize="26" icon="edit"       (btnClick)="editingCrawler = crawler"       ></fa-icon-btn>
          <fa-icon-btn class="icon-more" nzType="default" iconSize="12" buttonSize="26" [icon]="expandedCrawler.has(crawler.id) ? 'caret-up' : 'caret-down'" (btnClick)="toggleCrawlerExpanded(crawler)" ></fa-icon-btn>
        </div>

        <div *ngIf="expandedCrawler.has(crawler.id)" class="r2">
          <app-crawler-execution-list [crawler]="crawler" [trigger]="refreshTrigger"></app-crawler-execution-list>
        </div>

      </div>

    </div>

  </main>

</app-default-frame>

