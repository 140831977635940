import {Component, EventEmitter, Input, Output} from '@angular/core';
import {APIService} from "../../services/api.service";
import {NzNotificationService} from "ng-zorro-antd/notification";
import {NzModalService} from "ng-zorro-antd/modal";
import {AuthenticationService} from "../../services/authentication.service";
import {Impressum} from "../../interfaces/impressum";

@Component({
  selector: 'app-list-entry-impress',
  templateUrl: './list-entry-impress.component.html',
  styleUrls: ['./list-entry-impress.component.scss']
})
export class ListEntryImpressComponent {

  @Input() impress: Impressum|null = null;

  @Input() showDelete: boolean = true;
  @Input() showEdit: boolean = true;

  @Output() impressUpdated: EventEmitter<Impressum> = new EventEmitter<Impressum>();
  @Output() impressDeleted: EventEmitter<Impressum> = new EventEmitter<Impressum>();
  @Output() showEditImpress: EventEmitter<Impressum> = new EventEmitter<Impressum>();

  constructor(private api: APIService,
              private notification: NzNotificationService,
              private modal: NzModalService,
              private auth: AuthenticationService) {
  }

  deleteImpress(impress: Impressum) {
    this.impressDeleted.emit(impress);
  }

  editImpress (impress: Impressum) {
    this.showEditImpress.emit(impress);
  }
}
