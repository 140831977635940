<nz-modal [nzVisible]="show !== null"
          nzTitle="Crawler bearbeiten"
          [nzWidth]="1280"
          (nzOnCancel)="close()">

  <ng-container *nzModalContent>
    <div  class="content" *ngIf="crawler !== null">

      <div class="kvbox">

        <span class="key">ID</span>
        <input class="value" nz-input [ngModel]="crawler.id " readonly>

        <span class="key">Name</span>
        <input class="value" nz-input [(ngModel)]="crawler.name">

        <span class="key">Implementation</span>
        <input class="value" nz-input [ngModel]="crawler.implementation" readonly>

        <span class="key">Status</span>
        <input class="value" nz-input *ngIf="crawler.lastRunSuccess"  [ngModel]="'Success'" style="background-color: limegreen" readonly>
        <input class="value" nz-input *ngIf="!crawler.lastRunSuccess" [ngModel]="'Error'"   style="background-color: firebrick" readonly>

        <span class="key">Datum (erstellt)</span>
        <input class="value" nz-input [ngModel]="crawler.creationTime | displaydate" readonly>

        <div class="spacer"></div>

        <span class="key">Interval</span>
        <div class="value col2">
          <nz-input-number class="value" [(ngModel)]="crawler.interval"></nz-input-number>
          <input class="value" nz-input [ngModel]="crawler.interval | displayduration" readonly>
        </div>

        <span class="key">Interval (Retry)</span>
        <div class="value col2">
          <nz-input-number class="value" [(ngModel)]="crawler.retryInterval"></nz-input-number>
          <input class="value" nz-input [ngModel]="crawler.retryInterval | displayduration" readonly>
        </div>

        <span class="key">Timeout</span>
          <div class="value col2">
            <nz-input-number class="value" [(ngModel)]="crawler.timeout"></nz-input-number>
          <input class="value" nz-input [ngModel]="crawler.timeout | displayduration" readonly>
        </div>

        <span class="key">Max. RetryCount</span>
        <input class="value" nz-input [(ngModel)]="crawler.maxRetryCount">

        <div class="spacer"></div>

        <span class="key">Aktiviert</span>
        <nz-select class="value" [(ngModel)]="crawler.enabled">
          <nz-option nzLabel="Aktiviert"   [nzValue]="true"></nz-option>
          <nz-option nzLabel="Deaktiviert" [nzValue]="false"></nz-option>
        </nz-select>

        <div class="spacer"></div>

        <ng-container *ngFor="let kv of allKeys">

          <span class="key" [class.invalidKey]="!keys.includes(kv)">Config[ {{kv}} ]</span>
          <input class="value" nz-input [ngModel]="config[kv] ?? ''" (ngModelChange)="config[kv] = $event">

        </ng-container>

      </div>

      <div class="kvbox">

        <span class="key">Letzter Run</span>
        <input class="value" *ngIf="crawler.lastRun !== null" nz-input [ngModel]="crawler.lastRun | displaydate" readonly>
        <input class="value" *ngIf="crawler.lastRun === null" nz-input [ngModel]="'-'" readonly>
        <span class="key"></span>
        <input class="value" *ngIf="crawler.lastRunDuration !== null" nz-input [ngModel]="crawler.lastRunDuration | displayduration" readonly>
        <input class="value" *ngIf="crawler.lastRunDuration === null" nz-input [ngModel]="'-'" readonly>

        <span class="key">Letzter erfolgreicher Run</span>
        <input class="value" *ngIf="crawler.lastSuccesfullRun !== null" nz-input [ngModel]="crawler.lastSuccesfullRun | displaydate" readonly>
        <input class="value" *ngIf="crawler.lastSuccesfullRun === null" nz-input [ngModel]="'-'" readonly>
        <span class="key"></span>
        <input class="value" *ngIf="crawler.lastSuccesfullRunDuration !== null" nz-input [ngModel]="crawler.lastSuccesfullRunDuration | displayduration" readonly>
        <input class="value" *ngIf="crawler.lastSuccesfullRunDuration === null" nz-input [ngModel]="'-'" readonly>

        <span class="key">Nächster Run</span>
        <input class="value" nz-input [ngModel]="crawler.nextRun ?? '-'" readonly>

        <div class="spacer"></div>

        <span class="key">Run Count</span>
        <input class="value" nz-input [ngModel]="crawler.runCount" readonly>

        <span class="key">Run Count (successful)</span>
        <input class="value" nz-input [ngModel]="crawler.succesfullRunCount" readonly>

        <span class="key">Run Count (consec. Error)</span>
        <input class="value" nz-input [ngModel]="crawler.consecutiveErrorCount" readonly>

        <span class="key">Run Count (consec. Success)</span>
        <input class="value" nz-input [ngModel]="crawler.consecutiveSuccessCount" readonly>

        <div class="spacer"></div>

        <span class="key" style="justify-self: flex-start">Last Error</span>
        <textarea class="value span" rows="4" nz-input [ngModel]="crawler.lastError" readonly></textarea>

      </div>

    </div>
  </ng-container>

  <ng-container *nzModalFooter>
    <div>
      <div style="display:grid; grid-template-columns: auto 1fr auto auto auto; grid-column-gap: 0.5rem">
        <button nz-button nzType="dashed" (click)="runCrawlerNow()" [nzLoading]="runNowLoading">Jetzt ausführen</button>
        <span></span>
        <button nz-button nzType="default" (click)="close()">Abbrechen</button>
        <button nz-button nzType="primary" (click)="execute()" [nzLoading]="loading">Speichern</button>
        <button nz-button nzType="primary" nzDanger nz-popconfirm nzPopconfirmTitle="Wirklick löschen?, Dies kann nciht rückgängig gemacht werden!" (nzOnConfirm)="delete()" nzOkText="Löschen" [nzLoading]="loading">Löschen</button>
      </div>
    </div>
  </ng-container>

</nz-modal>
